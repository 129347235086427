import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { ScheduleStore, EmployeeStore } from '@/store';
import LegendLayout, { Props as LegendLayoutProps } from '../LegendLayout';
import AreaGroupTitle from '../AreaGroupTitle';

interface Props extends LegendLayoutProps {
  areaId?: number;
  employeeFilter?: string;
}

interface Injected {
  scheduleStore: ScheduleStore;
  employeeStore: EmployeeStore;
}

const AreaLegend: React.SFC<Props & Injected> = ({
  areaId,
  employeeFilter,
  scheduleStore,
  employeeStore,
  ...legendProps
}) => {
  const areaSchedule = scheduleStore
    .forceGetCurrent()
    .filteredAreaSchedule(areaId, employeeFilter, (id) => employeeStore.get(id));

  return (
    <LegendLayout {...legendProps}>
      {areaSchedule?.map((areaSchedule) => (
        <AreaGroupTitle key={areaSchedule.area.id} areaSchedule={areaSchedule} />
      ))}
    </LegendLayout>
  );
};

export default inject<Injected>(({ scheduleStore, employeeStore }) => ({
  scheduleStore,
  employeeStore,
}))(observer(AreaLegend));
