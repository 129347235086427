import * as React from 'react';
import { Dimmer, Loader, Message } from '@/semantic-ui/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import style from '@/less/main.less';
import { getErrorMessage } from '@/lib/utils';
import CostCalcError, { getCostCalcError, CostCalculationErrorData } from '../CostCalcError';

interface BaseProps {
  text?: string;
  state?: 'loading' | string;
  handleDismiss?: () => void;
  isModal?: boolean;
  isLoading?: boolean;
}

export interface ErrorObject {
  success: boolean;
  message: string;
  code: number;
  data: CostCalculationErrorData;
}

type Props = (BaseProps & { error?: unknown }) | (BaseProps & { errorMessage?: string });

const Bulletin: React.FC<Props> = (props) => {
  const { text, state, handleDismiss, isModal, isLoading, children } = props;

  let error;
  let errorMessage;
  let costCalcError;

  if ('error' in props && props.error) {
    error = props.error;
    costCalcError = getCostCalcError(error);
    errorMessage = getErrorMessage(error);
  }

  if ('errorMessage' in props) {
    errorMessage = props.errorMessage;
  }

  let content;

  if (children) {
    content = children;
  } else if (costCalcError) {
    content = <CostCalcError data={costCalcError} />;
  } else {
    content = errorMessage;
  }

  return (
    <>
      <Dimmer active={state === 'loading' || isLoading} inverted>
        <Loader size="huge" className={classnames({ modal_spinner: isModal })}>
          {text}
        </Loader>
      </Dimmer>
      {errorMessage && (
        <Message onDismiss={handleDismiss} negative icon>
          {!costCalcError && (
            <FontAwesomeIcon icon={['fas', 'exclamation']} className={style.error_message_icon} />
          )}
          <Message.Content>
            <Message.Header>Oops, something went wrong</Message.Header>
            {content}
          </Message.Content>
        </Message>
      )}
    </>
  );
};

export default Bulletin;
