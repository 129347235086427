import InjectedComponent from '@/components/InjectedComponent';
import { RequestsView } from '@/components/RequestsView';
import { withNavigation, WithNavigationProps } from '@/components/Route';
import TabLayout, { TabLayoutPane } from '@/components/TabLayout';
import { Menu } from '@/semantic-ui/components';
import { AreaStore, DepartmentStore, EmployeeStore, PositionStore, ScheduleStore } from '@/store';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Link } from 'react-router';
import { Authority } from 'sol-data';

const panes: TabLayoutPane[] = [
  {
    route: 'open',
    menuItem: (
      <Menu.Item as={Link} to="/requests/open" key="open">
        Open Requests
      </Menu.Item>
    ),
    render: () => <RequestsView key="open" pending />,
  },
  {
    route: 'past',
    menuItem: (
      <Menu.Item as={Link} to="/requests/past" key="past">
        Past Requests
      </Menu.Item>
    ),
    access: Authority.Admin,
    render: () => <RequestsView key="past" pending={false} />,
  },
];

interface Props extends WithNavigationProps {}

interface Injected {
  scheduleStore: ScheduleStore;
  areaStore: AreaStore;
  departmentStore: DepartmentStore;
  positionStore: PositionStore;
  employeeStore: EmployeeStore;
}

@inject('scheduleStore', 'areaStore', 'departmentStore', 'employeeStore', 'positionStore')
@observer
export class Requests extends InjectedComponent<Props, {}, Injected> {
  componentDidMount() {
    const { areaStore, departmentStore, positionStore } = this.injected;

    positionStore.fetchAll();
    areaStore.fetchAll();
    departmentStore.fetchAll();
  }

  render() {
    const { path } = this.props;

    return <TabLayout path={path} panes={panes} activeBreadcrumb="Requests" />;
  }
}

export default withNavigation<{}>(Requests);
