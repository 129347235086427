import AppContext from '@/AppContext';
import Breadcrumbs from '@/components/Breadcrumbs';
import Bulletin from '@/components/Bulletin';
import ButtonBar from '@/components/ButtonBar';
import { ObservingListLayout } from '@/components/ListLayout';
import ModalBase from '@/components/ModalBase';
import { TotalsCard } from '@/components/TimesheetDetails/TotalsCard';
import TimesheetEntryModal from '@/components/TimesheetEntryModal';
import style from '@/containers/Timesheets/index.less';
import { displayHours } from '@/lib/utils';
import { useStores } from '@/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EntityReference, ID } from '@lcdev/store';
import classnames from 'classnames';
import { sortBy } from 'lodash';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import {
  Button,
  Grid,
  Icon,
  Label,
  List,
  ListItem,
  Message,
  MessageHeader,
  Modal,
  Popup,
  Segment,
} from 'semantic-ui-react';
import {
  AssignmentCost,
  AssignmentCostItem,
  IBaseTimesheetEntry,
  PayrollItem,
  TimesheetEntry,
  toDollar,
} from 'sol-data';

const { Row, Column } = Grid;

interface State {
  entryModalOpen: boolean;
  approveModalOpen: boolean;
  entry?: TimesheetEntry['Props'];
  cost?: AssignmentCost<TimesheetEntry>;
}

export const TimesheetDetails = observer(() => {
  const { timesheetStore, employeeStore, payrollStore, areaStore, departmentStore } = useStores();
  const navigate = useNavigate();
  const params = useParams();
  const timesheetId = params.timesheetId!;

  const [state, setState] = useState<State>({
    entryModalOpen: false,
    approveModalOpen: false,
  });

  const context = useContext(AppContext);
  const { isPayrollAdmin, isAdmin } = context;

  const fetchData = async () => {
    timesheetStore.setCurrent(timesheetId);
    await fetchCurrentTimesheet();

    const employee = timesheetStore.current && timesheetStore.current.$.employee;

    if (employee && !employeeStore.has(employee)) employeeStore.fetchEmployees([employee.id]);
    if (areaStore.isEmpty) areaStore.fetchAll();
    if (departmentStore.isEmpty) departmentStore.fetchAll();

    payrollStore.fetchAll();
    fetchTimesheetCost();
  };

  const fetchTimesheetCost = async () => {
    const cost = await payrollStore.fetchTimesheetCost(timesheetId);
    setState((prev) => ({ ...prev, cost }));
  };

  const fetchCurrentTimesheet = () => {
    return timesheetStore.fetch(timesheetId);
  };

  const approveTimesheet = async () => {
    await timesheetStore.approve(timesheetId);
    navigate('/timesheets/pending');
  };

  const deleteTimesheetEntry = async (id: ID) => {
    await timesheetStore.delete(id);
    await fetchTimesheetCost();
  };

  const openEntryModal = (entry?: TimesheetEntry['Props']) => {
    setState((prev) => ({
      ...prev,
      entry,
      entryModalOpen: true,
    }));
  };

  const openApproveModal = () => {
    setState((prev) => ({
      ...prev,
      approveModalOpen: true,
    }));
  };

  const handleEntrySubmit = async (values: IBaseTimesheetEntry) => {
    const { entry } = state;

    if (entry) {
      await timesheetStore.update(entry, values);
    } else {
      const {
        props: { employee },
      } = timesheetStore.forceGetCurrent();

      await timesheetStore.create({ ...values, employee });
    }

    await fetchCurrentTimesheet();

    setState((prev) => ({ ...prev, entryModalOpen: false }));

    await fetchTimesheetCost();
  };

  const columns = useMemo(() => {
    const { cost } = state;

    const { allEntries } = timesheetStore;
    const overlappingEntries = timesheetStore.current?.props.overlappingEntries;

    const categoriesForEntry = (id: number) => {
      if (!cost) {
        const entry = allEntries.get(id);
        if (!entry) return [];

        return entry
          .get('categories')
          .map((id) => payrollStore.categories.get(id)!)
          .filter((c) => !!c);
      }

      const applicableCategories = payrollStore.categories.values.filter(
        (c) => !!cost.minutesOfCategoryForEntry(id, c.id, payrollStore.items),
      );

      return sortBy(applicableCategories, 'description');
    };

    return [
      {
        name: 'Date',
        key: 'date',
        width: 2,
        render: ({ id, startDate, confirmedOn }) => (
          <div className={classnames(style.timesheet_cell, style.timesheet_highlight)}>
            <div className={`${style.details_subcell} ${style.timesheet_subcell}`}>
              <span className={style.timesheet_cell}>{startDate.format('MMM D')}</span>
            </div>
            {confirmedOn ? (
              <Icon
                title={`Confirmed on ${confirmedOn}`}
                size="large"
                color="green"
                name="check circle"
                className={style.timesheet_confirm_icon}
              />
            ) : (
              <Icon
                link
                size="large"
                color="grey"
                name="check circle outline"
                className={style.timesheet_confirm_icon}
                onClick={(e: React.SyntheticEvent) => {
                  timesheetStore.confirmEntry(id);
                  e.stopPropagation();
                }}
              />
            )}
          </div>
        ),
      },
      {
        name: 'Location',
        key: 'location',
        width: { sm: 3, lg: 4 },
        verticalAlign: 'middle',
        render: ({ area, department }) => {
          const foundArea = area && areaStore.get(area);
          const foundDept = !area && department ? departmentStore.get(department) : undefined;

          return (
            <div className={style.flex_center}>
              <Label
                color={foundArea ? 'blue' : foundDept ? 'red' : 'grey'}
                className={style.details_label}
              >
                <div className={style.flex_between}>
                  {foundArea ? (
                    <span>AREA</span>
                  ) : foundDept ? (
                    <span>DEPT</span>
                  ) : (
                    <span>No Location</span>
                  )}
                  {(foundArea || foundDept) && (
                    <span className={style.details_label_info}>
                      {foundArea ? foundArea.label : foundDept!.label}
                    </span>
                  )}
                </div>
              </Label>
            </div>
          );
        },
      },
      {
        name: 'Category',
        key: 'category',
        width: 3,
        render: ({ id }) => (
          <div className={style.timesheet_cell}>
            <div className={`${style.details_subcell} ${style.timesheet_subcell}`}>
              {categoriesForEntry(id).map(({ id, description }) => (
                <p key={id} className={style.timesheet_cell}>
                  {description}
                </p>
              ))}
              <p className={style.timesheet_cell}>Shift Total</p>
            </div>
          </div>
        ),
      },
      {
        name: 'Timing',
        key: 'timing',
        width: 3,
        render: ({ id, startDate, endDate, breakStart }) => {
          const isOverlapping = overlappingEntries?.some(
            (entry) => entry.entry1Id === id || entry.entry2Id === id,
          );

          return (
            <div
              className={style.timesheet_cell}
              style={{ color: isOverlapping ? 'red' : undefined }}
              title={isOverlapping ? 'Overlapping' : ''}
            >
              <div className={`${style.details_subcell} ${style.timesheet_subcell}`}>
                {categoriesForEntry(id).map(({ id }) => (
                  <p key={id} className={style.timesheet_cell}>
                    -
                  </p>
                ))}
                <p className={style.timesheet_cell}>
                  {startDate && endDate
                    ? `${startDate.format('HH:mm')} - ${endDate.format('HH:mm')}`
                    : 'Unknown'}

                  {!breakStart && (
                    <>
                      <br />
                      (No Break)
                    </>
                  )}
                </p>
              </div>
            </div>
          );
        },
      },
      {
        name: 'Total Hours',
        key: 'totalHours',
        width: 3,
        render: ({ id, totalMinutes }) => {
          let entryAssignmentCostItems: AssignmentCostItem<TimesheetEntry>[] = [];

          if (cost) {
            const { cost: assignmentCost } = cost;
            const assignmentCostItemsForThisEntry = assignmentCost.items.filter(
              ({ entry }) => entry.id === id,
            );

            const payrollItems = new Set(
              assignmentCostItemsForThisEntry.map(({ item: { id } }) => id),
            );

            // Below is for when there are two of the same payroll items in the breakdown,
            // the totals are then added together to display one breakdown for each unique payroll item
            // ie. night shifts starting at 11PM have one hour for one day and 7 hours on the next both of which
            // are the same payroll item
            entryAssignmentCostItems = Array.from(payrollItems).map((item) => {
              return assignmentCostItemsForThisEntry
                .filter(({ item: { id } }) => id === item)
                .reduce<AssignmentCostItem<TimesheetEntry>>(
                  (acc, cost) => {
                    return {
                      ...acc,
                      minutes: acc.minutes + cost.minutes,
                      total: cost.total ? (acc.total || 0) + cost.total : undefined,
                    };
                  },
                  {
                    entry: new EntityReference(TimesheetEntry, id),
                    item: new EntityReference(PayrollItem, item),
                    minutes: 0,
                    rate: 0,
                    total: undefined,
                  },
                );
            });
          }

          return (
            <div className={style.timesheet_cell}>
              <div className={`${style.details_subcell} ${style.timesheet_subcell}`}>
                {categoriesForEntry(id).map((category) => (
                  <p key={category.id} className={style.timesheet_cell}>
                    {cost
                      ? displayHours(
                          cost.minutesOfCategoryForEntry(id, category.id, payrollStore.items),
                        )
                      : '-'}
                  </p>
                ))}
                <p className={style.timesheet_cell}>{displayHours(totalMinutes)}</p>
                <Popup
                  wide="very"
                  size="mini"
                  trigger={
                    <div className={style.breakdown}>
                      <p className={style.breakdown_title}>Breakdown</p>
                      <FontAwesomeIcon icon={['far', 'info-circle']} />
                    </div>
                  }
                  content={
                    <Grid>
                      <Row columns={isPayrollAdmin ? 3 : 2}>
                        <Column>
                          <p className={style.breakdown_headers}>Item</p>
                        </Column>
                        <Column>
                          <p className={style.breakdown_headers}>Length</p>
                        </Column>
                        {isPayrollAdmin && (
                          <Column>
                            <p className={style.breakdown_headers}>Total</p>
                          </Column>
                        )}
                        {entryAssignmentCostItems.map(({ item, minutes, total }) => {
                          const payrollItem = payrollStore.items.get(item.id);

                          return (
                            <React.Fragment key={item.id}>
                              {payrollItem && (
                                <>
                                  <Column>
                                    <p>{payrollItem.description}</p>
                                  </Column>
                                  <Column>
                                    <p>{displayHours(minutes)}</p>
                                  </Column>
                                  {total && (
                                    <Column>
                                      <p>{toDollar(total)}</p>
                                    </Column>
                                  )}
                                </>
                              )}
                            </React.Fragment>
                          );
                        })}
                      </Row>
                    </Grid>
                  }
                  className={style.breakdown_content}
                />
              </div>
            </div>
          );
        },
      },
    ];
  }, [
    timesheetStore,
    payrollStore.items,
    payrollStore.categories,
    areaStore,
    departmentStore,
    state.cost,
    isPayrollAdmin,
  ]);

  useEffect(() => {
    fetchData();

    return () => {
      payrollStore.clearError();
      employeeStore.clearError();
      timesheetStore.clearError();
      areaStore.clearError();
      departmentStore.clearError();
    };
  }, []);

  const { current: timesheet, isLoading } = timesheetStore;
  const { entryModalOpen, approveModalOpen, entry, cost } = state;

  if (!timesheet) return null;

  const {
    employee: { id: employeeId },
    entries,
    startDate,
    endDate,
  } = timesheet.props;

  const employee = employeeStore.get(employeeId);
  const fullName = employee ? employee.user.fullName : 'Unknown';

  const displayableEntries = entries
    .map((entry) => entry.props)
    .sort((a, b) => a.startDate.diff(b.startDate));

  const error =
    timesheetStore.error ||
    payrollStore.error ||
    payrollStore.getError(`timesheetCost:${timesheet.id}`);

  return (
    <Segment padded="very">
      <Breadcrumbs
        linkTitle="Timesheet"
        link="/timesheets/pending"
        activeBreadcrumb={`${fullName} - ${startDate.format('MMM D')} ${endDate.format('MMM D')}`}
      />

      <div className={style.tab_spacer}>
        <Grid>
          <Bulletin
            isLoading={isLoading}
            error={error}
            handleDismiss={() => {
              timesheetStore.clearError();
              payrollStore.clearError();
            }}
          />

          {(timesheetStore.current?.props.overlappingEntries?.length ?? 0) > 0 && (
            <Message negative style={{ width: '100%' }}>
              <MessageHeader>Overlapping Timesheet Entries</MessageHeader>
              <List bulleted>
                {timesheetStore.current?.props.overlappingEntries?.map((entry) => (
                  <ListItem key={entry.entry1Id}>
                    {`${entry.startDate1.format('MMM D, HH:mm')} - ${entry.endDate1.format(
                      'MMM D, HH:mm',
                    )} overlaps with ${entry.startDate2.format(
                      'MMM D, HH:mm',
                    )} - ${entry.endDate2.format('MMM D, HH:mm')}`}
                  </ListItem>
                ))}
              </List>
            </Message>
          )}

          <Row>
            <Column computer={9} tablet={16}>
              <ObservingListLayout
                items={displayableEntries}
                columns={columns}
                onClick={openEntryModal}
                onRemove={isAdmin ? deleteTimesheetEntry : undefined}
                center
              />
            </Column>
            <Column computer={7} tablet={16}>
              <TotalsCard
                cost={cost}
                timesheet={timesheet}
                onApprove={openApproveModal}
                disabledApproval={!!error}
              />
            </Column>
          </Row>
        </Grid>
      </div>

      <ButtonBar>
        <Button color="blue" type="button" onClick={() => openEntryModal()}>
          Add new entry
        </Button>
      </ButtonBar>

      <Modal open={entryModalOpen} size="mini" onClose={() => setState({ entryModalOpen: false })}>
        <TimesheetEntryModal
          entry={entry}
          employee={employee}
          timesheet={timesheet.withID}
          onSubmit={handleEntrySubmit}
          onCancel={() => setState({ entryModalOpen: false })}
        />
      </Modal>

      <Modal
        open={approveModalOpen}
        size="mini"
        onClose={() => setState({ approveModalOpen: false })}
      >
        <ModalBase
          header="Approve Timesheet"
          actionWord="Confirm"
          onAccept={approveTimesheet}
          onCancel={() => setState({ approveModalOpen: false })}
        >
          <p>Are you sure you want to approve this timesheet?</p>
          <p>It will be synced to payroll software.</p>
        </ModalBase>
      </Modal>
    </Segment>
  );
});
