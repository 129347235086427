import * as React from 'react';
import { Formik } from 'formik';
import { Form, Button } from '@/semantic-ui/components';
import { object, string } from 'yup';
import { observer, inject } from 'mobx-react';
import { UserStore } from '@/store';
import { Pane } from '@/components/LoginForm';
import style from '../index.less';

const { Field, Input } = Form;

interface Props {
  changePane: (pane: Pane) => void;
}

interface Injected {
  userStore: UserStore;
}

const ForgotPassword: React.FC<Props & Injected> = ({ userStore, changePane }) => (
  <Formik
    initialValues={{
      email: '',
    }}
    validationSchema={object().shape({
      email: string().required('Please enter your email'),
    })}
    onSubmit={async (values, { setSubmitting }) => {
      const { email } = values;

      try {
        await userStore.requestPasswordReset(email);
        changePane(Pane.Success);
      } finally {
        setSubmitting(false);
      }
    }}
    render={({ values, touched, errors, handleChange, handleBlur, handleSubmit, isSubmitting }) => {
      const showEmailError = !!touched.email && !!errors.email;
      const { errorMessage } = userStore;

      return (
        <Form className={style.login_form} onSubmit={handleSubmit}>
          <p className={style.error_message}>{errorMessage}</p>
          <Field error={showEmailError}>
            <Input
              type="text"
              name="email"
              placeholder="Email Address"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
            />
            {showEmailError && <p className={style.error_message}>{errors.email}</p>}
          </Field>
          <Button loading={isSubmitting} primary width="100%" type="submit">
            Reset Password
          </Button>
          <button type="button" onClick={() => changePane(Pane.Login)}>
            Return to login
          </button>
        </Form>
      );
    }}
  />
);

export default inject<Injected>(({ userStore }) => ({ userStore }))(observer(ForgotPassword));
