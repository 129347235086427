import { IconPrefix, IconName } from '@fortawesome/fontawesome-svg-core';

export enum ConflictType {
  SICK_NOTICE = 'sick-notice',
  SHIFT_SWAP = 'shift-swap',
  TIME_OFF = 'time-off',
}

export const conflictOptions: {
  [key in ConflictType]: { color: string; icon: [IconPrefix, IconName] };
} = {
  [ConflictType.SICK_NOTICE]: {
    color: '#E9423F',
    icon: ['fal', 'briefcase-medical'],
  },
  [ConflictType.SHIFT_SWAP]: {
    color: '#7DBBC0',
    icon: ['fal', 'exchange-alt'],
  },
  [ConflictType.TIME_OFF]: {
    color: '#778BEB',
    icon: ['fal', 'clock'],
  },
};

export const weekOptions = [
  {
    text: '1 week',
    value: 1,
  },
  {
    text: '2 weeks',
    value: 2,
  },
  {
    text: '3 weeks',
    value: 3,
  },
  {
    text: '4 weeks',
    value: 4,
  },
];

// Ratio of row height to cell in schedule
export const rowCellRatio = 1.25;
