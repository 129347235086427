/* eslint-disable jsx-a11y/no-static-element-interactions */
import * as React from 'react';
import {
  Button,
  Checkbox,
  Grid,
  GridColumn,
  Header,
  Icon,
  Input,
  InputOnChangeData,
} from '@/semantic-ui/components';
import InjectedComponent from '@/components/InjectedComponent';
import { PositionStore } from '@/store';
import { inject, observer } from 'mobx-react';
import { Employee, Position } from 'sol-data';
import style from './index.less';

interface Props {
  employees: Employee[];
  positions: Position[];
  wagePositions: Position[];
  onLinkWages: (positions: Position[]) => void;
}

interface State {
  id?: number;
  activeValue: string | undefined;
  activeIndex: number;
  searchText: string;
  wagePositions: Position[];
}

interface Injected {
  positionStore: PositionStore;
}

@inject('positionStore')
@observer
class WagePositionDropdown extends InjectedComponent<Props, State, Injected> {
  state: State = {
    activeValue: undefined,
    wagePositions: [],
    activeIndex: 0,
    searchText: '',
  };

  componentDidMount = async () => {
    this.setState({ wagePositions: this.props.wagePositions });
  };

  handleDropdown = () => {
    const { activeIndex } = this.state;
    const newIndex = activeIndex === 0 ? 1 : 0;
    this.setState({ activeIndex: newIndex });
  };

  handleWageEmployee = (checked: boolean, item: Position) => {
    if (checked)
      return this.setState((state) => {
        return { wagePositions: [...state.wagePositions, item] };
      });
    return this.setState((state) => {
      return {
        wagePositions: state.wagePositions.filter(
          (filterPosition) => filterPosition.id !== item.id,
        ),
      };
    });
  };

  handleSearch = (_event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    this.setState({ searchText: data.value });
  };

  getNumberOfEmployees = (id: number) => {
    if (!this.props.employees.length) return 0;
    const filterEmployee = this.props.employees.filter(({ position }) => position.id === id);
    return filterEmployee?.length;
  };

  getBodyContent = () => {
    const data = this.props.positions
      .filter((item) => {
        const regx = new RegExp(this.state.searchText, 'i');
        return regx.test(`${item.description} (${item.label})`);
      })
      .map((item) => {
        return (
          <div key={item.id} className={style.wage_position_list}>
            <div>{`${item.description} (${item.label})`}</div>
            {
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events
              <div
                onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()}
              >
                <span>
                  # Employees:
                  {this.getNumberOfEmployees(item.id)}
                </span>
                <Checkbox
                  checked={this.state.wagePositions.some(
                    (wagePosition) => wagePosition.id === item.id,
                  )}
                  onChange={(e, { checked }) => this.handleWageEmployee(checked || false, item)}
                />
              </div>
            }
          </div>
        );
      });
    return <>{data}</>;
  };

  getTitleContent = () => {
    if (this.state.activeIndex === 0) {
      return (
        <Grid.Column className={style.wage_dropdown_title}>
          Select employee groups from the dropdown
          <Icon name="angle down" />
        </Grid.Column>
      );
    }
    return <div />;
  };

  linkToWageGrid = () => {
    this.props.onLinkWages(this.state.wagePositions);
    this.handleDropdown();
  };

  render() {
    return (
      <Grid className="wage_dropdown_container">
        <Grid.Row />
        <GridColumn width={16} className="wage_dropdown_header">
          <Header>Link employees to this wage grid</Header>
        </GridColumn>
        <Grid.Row onClick={this.handleDropdown}>
          <Grid.Column width={10} className="wage_dropdown_body">
            <Grid.Row>{this.getTitleContent()}</Grid.Row>
            <Grid.Row
              className={
                this.state.activeIndex ? style.wage_container_body : style.wage_dropdown_hide
              }
            >
              <Grid.Column
                className={style.wage_position_search}
                onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
                  e.stopPropagation()
                }
              >
                <Input
                  icon="search"
                  iconPosition="left"
                  placeholder="Search"
                  className={style.dropdown_search_input}
                  onChange={this.handleSearch}
                />
                <Button
                  className="wage_link_button"
                  content="Link to wage grid"
                  type="button"
                  color="blue"
                  onClick={this.linkToWageGrid}
                />
              </Grid.Column>
              <Grid.Column width={16} className={style.wage_list_container}>
                {this.getBodyContent()}
                <Grid.Row
                  className={style.wage_list_button}
                  onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
                    e.stopPropagation()
                  }
                >
                  <Button
                    basic
                    color="blue"
                    id={style.wage_list_button_footer}
                    onClick={() => this.setState({ wagePositions: [] })}
                  >
                    CLEAR
                  </Button>
                  <Button
                    basic
                    color="blue"
                    id={style.wage_list_button_footer}
                    type="button"
                    onClick={() => this.setState({ wagePositions: [...this.props.positions] })}
                  >
                    SELECT ALL
                  </Button>
                </Grid.Row>
              </Grid.Column>
            </Grid.Row>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default WagePositionDropdown;
