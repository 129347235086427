import InjectedComponent from '@/components/InjectedComponent';
import { Dropdown, Icon, Table, TableCellProps } from '@/semantic-ui/components';
import { EmployeeStore } from '@/store';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import {
  Authority,
  DepartmentStore,
  UserStore,
  EssentialEmployeeData,
  Period,
  StatInLieuFormat,
} from 'sol-data';
import { displayHours } from '@/lib/utils';
import { reaction } from 'mobx';
import style from './index.less';
import EditEssentialEmployee from '../EditEssentialEmployee';
import { getEssentialsPermissions } from '@/lib/essentials-utils';

interface Props {
  onLinkWageModal: (employee: EssentialEmployeeData) => void;
  onStepModal: (employee: EssentialEmployeeData) => void;
  onRatesModal: (employee: EssentialEmployeeData) => void;
  onRowEdit: (employee: EssentialEmployeeData) => void;
  payPeriod: Period;
}

export const DashboardTableItem = ({ children, ...props }: TableCellProps) => {
  return (
    <Table.Cell textAlign={'center'} {...props}>
      {children}
    </Table.Cell>
  );
};

interface State {
  error: null | [];
  rowEdit: boolean;
  selectedRow: number;
}

interface Injected {
  employeeStore: EmployeeStore;
  departmentStore: DepartmentStore;
  userStore: UserStore;
}

@inject('employeeStore')
@inject('departmentStore')
@inject('userStore')
@observer
class EssentialTable extends InjectedComponent<Props, State, Injected> {
  state: State = {
    selectedRow: -1,
    error: null,
    rowEdit: false,
  };

  dataChangeReactionDisposer?: Function;

  componentDidMount(): void {
    const { employeeStore } = this.injected;
    this.dataChangeReactionDisposer = reaction(
      () => employeeStore.essentialsDashboardData,
      () =>
        this.setState({
          selectedRow: -1,
        }),
      { fireImmediately: false },
    );
  }

  componentWillUnmount(): void {
    this.dataChangeReactionDisposer?.();
  }

  getFallbackValue = (value?: number | string, prefix?: string) => {
    if (!value || value === 0 || value === '0.00') {
      return 'N/A';
    }
    if (prefix) {
      return `${prefix} ${value}`;
    }
    return value;
  };

  iconDropDown = (index: number, employee: EssentialEmployeeData) => {
    const {
      userStore: { current: currentUser },
    } = this.injected;
    const permissions = getEssentialsPermissions(currentUser);
    if (!permissions.read) {
      return null;
    }
    const viewWageDetail = (index: number) => {
      // if click in a open row
      if (this.state.selectedRow === index) {
        this.setState({ selectedRow: -1 });
      } else {
        this.setState({
          selectedRow: index,
        });
        this.props.onRowEdit(employee);
      }
    };

    if (this.state.selectedRow === index) {
      return <Icon link name="angle up" key={index} onClick={() => viewWageDetail(index)} />;
    }
    return (
      <Icon
        disabled={!employee.wage}
        link
        name="angle down"
        key={index}
        onClick={() => viewWageDetail(index)}
      />
    );
  };

  renderTableHeader = () => {
    return [
      <Table.HeaderCell key={`thead-1`} className="markBackTable">
        EMPLOYEE NAME
      </Table.HeaderCell>,
      <Table.HeaderCell key={`thead-2`} className="markBackTable">
        DEPT
      </Table.HeaderCell>,
      <Table.HeaderCell key={`thead-3`} className="markBackTable">
        EMPLOYEE #
      </Table.HeaderCell>,
      <Table.HeaderCell key={`thead-4`} className="markBackTable">
        RATE
      </Table.HeaderCell>,
      <Table.HeaderCell key={`thead-5`} className="markBackTable">
        STEP
      </Table.HeaderCell>,
      <Table.HeaderCell key={`thead-6`} className="markBackTable">
        PP HOURS
      </Table.HeaderCell>,
      <Table.HeaderCell key={`thead-7`} className="markBackTable">
        ACCUMULATED
      </Table.HeaderCell>,
      <Table.HeaderCell key={`thead-8`} className="markBackTable">
        VACATION
      </Table.HeaderCell>,
      <Table.HeaderCell key={`thead-9`} className="markBackTable">
        SICK ACCRUED
      </Table.HeaderCell>,
      <Table.HeaderCell key={`thead-10`} className="markBackTable">
        STAT IN LIEU
      </Table.HeaderCell>,
      <Table.HeaderCell key={`thead-10`} className="markBackTable">
        STAT IN LIEU ACCRUED
      </Table.HeaderCell>,
      <Table.HeaderCell key={`thead-11`} className="markBackTable">
        {' '}
      </Table.HeaderCell>,
    ];
  };

  renderTableBody = () => {
    const {
      userStore: { current: currentUser },
      employeeStore,
    } = this.injected;
    const permissions = getEssentialsPermissions(currentUser);
    return (employeeStore.essentialsDashboardData?.data || []).map(({ props: employee }, index) => {
      let rowClass = index % 2 ? style.normal_row : style.row_highlight;
      if (employee.stepChangeReached && employee.isActive) {
        rowClass = style.notification_board;
      }
      if (!employee.isActive) {
        rowClass = `${rowClass} ${style.disabled_employee}`;
      }

      return (
        <>
          <Table.Row key={employee.id} className={rowClass}>
            <DashboardTableItem>{`${employee.lastName}, ${employee.firstName}`}</DashboardTableItem>
            <DashboardTableItem>
              {employee.departments.map((itm) => itm.label).join(', ') ?? '--'}
            </DashboardTableItem>
            <DashboardTableItem>{employee.employeeNumber}</DashboardTableItem>
            <DashboardTableItem>
              {`${this.getFallbackValue(employee.wageRate?.toFixed(2), '$')}`}
            </DashboardTableItem>
            <DashboardTableItem>{this.getFallbackValue(employee.stepNumber)}</DashboardTableItem>
            <DashboardTableItem>
              {`${displayHours(employee.hoursInPayPeriod * 60)} H`}
            </DashboardTableItem>
            <DashboardTableItem>{employee.workedHours}</DashboardTableItem>
            <DashboardTableItem>
              {`$ ${employee.vacationAccruedInPayPeriod.toFixed(2)}`}
            </DashboardTableItem>
            <DashboardTableItem>{employee.sickAccrued.toFixed(2)}</DashboardTableItem>
            <DashboardTableItem>{employee.statInLieu.toFixed(2)}</DashboardTableItem>
            {employee.position.statInLieuFormatId === StatInLieuFormat.Hours && (
              <DashboardTableItem>{employee.statInLieuAccrued.toFixed(2)}</DashboardTableItem>
            )}
            {employee.position.statInLieuFormatId === StatInLieuFormat.Value && (
              <DashboardTableItem>{`$ ${employee.statInLieuValue.toFixed(2)}`}</DashboardTableItem>
            )}
            <DashboardTableItem>
              <div className={style.link_aditional}>
                <Dropdown icon="ellipsis vertical" pointing="right" className="link item">
                  <Dropdown.Menu>
                    {permissions.read && [
                      <Dropdown.Item
                        disabled={!employee.stepChangeReached || !employee.isActive}
                        onClick={() => this.props.onStepModal(employee)}
                      >
                        Step Overview
                      </Dropdown.Item>,
                      <Dropdown.Divider />,
                      <Dropdown.Item onClick={() => this.props.onLinkWageModal(employee)}>
                        Wage Information
                      </Dropdown.Item>,
                      <Dropdown.Divider />,
                    ]}
                    <Dropdown.Item
                      disabled={!employee.wage?.wageId}
                      onClick={() => {
                        this.props.onRatesModal(employee);
                      }}
                    >
                      Rates
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                {this.iconDropDown(index, employee)}
              </div>
            </DashboardTableItem>
          </Table.Row>
          {index === this.state.selectedRow && (
            <Table.Cell
              className={employee.stepChangeReached ? style.notification_board : rowClass}
              textAlign={'left'}
              colSpan={11}
              key={1}
            >
              <EditEssentialEmployee
                employee={employee}
                endDate={this.props.payPeriod.props.endDate}
                onSave={() => this.setState({ selectedRow: index })}
              />
            </Table.Cell>
          )}
        </>
      );
    });
  };

  render() {
    const { employeeStore } = this.injected;
    return (
      <>
        <div className={style.wage_table_container}>
          <Table id={style.wage_grid_table} striped>
            <Table.Header>
              <Table.Row textAlign="center">{this.renderTableHeader()}</Table.Row>
            </Table.Header>
            <Table.Body>
              {employeeStore.essentialsDashboardData?.data.length ? (
                this.renderTableBody()
              ) : (
                <Table.Row>
                  <Table.Cell textAlign={'center'} colSpan={11}>
                    No data found for the selected pay period
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </div>
      </>
    );
  }
}

export default EssentialTable;
