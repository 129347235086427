import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { ScheduleStore, EmployeeStore } from '@/store';
import GroupRow from '../GroupRow';
import ScheduleLayout from '../ScheduleLayout';
import NoContentMessage from '../NoContentMessage';
import style from '../index.less';

interface Props {
  positionId?: number;
  employeeFilter?: string;
}

interface Injected {
  scheduleStore: ScheduleStore;
  employeeStore: EmployeeStore;
}

const PositionSchedule: React.FC<Props & Injected> = ({
  positionId,
  employeeFilter,
  scheduleStore,
  employeeStore,
}) => {
  const positionSchedule = scheduleStore
    .forceGetCurrent()
    .filteredPositionSchedule(positionId, employeeFilter, (id) => employeeStore.get(id));

  return (
    <ScheduleLayout>
      <div className={style.schedule_buffer} />
      {positionSchedule && positionSchedule.length > 0 ? (
        positionSchedule.map((position, i) => {
          return position.map((group) => {
            const isShaded = i % 2 !== 0;

            return (
              <div
                key={`${group.position.id}${group.id}`}
                className={isShaded ? style.schedule_row_container : null}
              >
                <GroupRow group={group} />
              </div>
            );
          });
        })
      ) : (
        <NoContentMessage absolute />
      )}
    </ScheduleLayout>
  );
};

export default inject<Injected>(({ scheduleStore, employeeStore }) => ({
  scheduleStore,
  employeeStore,
}))(observer(PositionSchedule));
