import { authService } from '@/api';
import AppContext from '@/AppContext';
import CreateEducationalDocument from '@/components/CreateEducationalDocument';
import { ModifyAreas } from '@/components/ModifyAreas';
import { ModifyDepartments } from '@/components/ModifyDepartments';
import ModifyNotices from '@/components/ModifyNotices';
import { ModifyPositions } from '@/components/ModifyPositions';
import ModifyRotations from '@/components/ModifyRotations';
import PublishedSchedule from '@/components/PublishedSchedule';
import { SideNavigation } from '@/components/SideNavigation/SideNavigation';
import { TimesheetDetails } from '@/components/TimesheetDetails';
import { UnpublishedSchedule } from '@/components/UnpublishedSchedule';
import Admin from '@/containers/Admin';
import EducationalDocuments from '@/containers/Education';
import EmployeeProfile from '@/containers/EmployeeProfile';
import { Employees } from '@/containers/Employees';
import Home from '@/containers/Home';
import Notices from '@/containers/Notices';
import { Organization } from '@/containers/Organization';
import Payroll from '@/containers/Payroll';
import Requests from '@/containers/Requests';
import ScheduleManagement from '@/containers/Schedules';
import ShiftPlanning from '@/containers/ShiftPlanning';
import { Timesheets } from '@/containers/Timesheets';
import Users from '@/containers/Users';
import Wage from '@/containers/Wage';
import { WageGrid } from '@/containers/WageGrid';
import { useAsyncEffect } from '@/hooks';
import { Container, Segment } from '@/semantic-ui/components';
import { useStores } from '@/store';
import * as Sentry from '@sentry/react';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useState } from 'react';
import { Route, Routes } from 'react-router';
import { SemanticToastContainer } from 'react-semantic-toasts';
import 'react-semantic-toasts/styles/react-semantic-alert.css';
import { Authority } from 'sol-data';
import Essentials from '../Essentials';
import { MasterStaffDatabase } from '../MasterStaffDatabase';
import style from './index.less';
import { BidHistory } from '../BidHistory/BidHistory';

export const AppContainer = observer(() => {
  const [collapsed, setCollapsed] = useState(window.innerWidth < 1075);
  const { userStore } = useStores();

  useAsyncEffect(async () => {
    if (!authService.token) {
      return authService.logout();
    }

    try {
      const user = await authService.fetchUser();

      userStore.setCurrent(user);
      Sentry.setUser({ id: user.id, email: user.email });
    } catch {
      Sentry.setUser(null);

      await authService.logout();
    }
  }, [userStore]);

  if (!userStore.current) {
    return null;
  }

  const [authority] = (userStore.current.access && userStore.current.access.authority) || [
    Authority.ReadOnly,
  ];

  const scope = (userStore.current.access && userStore.current.access.scope) || {
    positions: null,
    departments: null,
  };

  const isAdmin = authority >= Authority.Admin;
  const isPayrollAdmin = authority >= Authority.PayrollAdmin;

  const SentryRoutes = Sentry.withSentryReactRouterV7Routing(Routes);

  return (
    <AppContext.Provider value={{ collapsed, isAdmin, isPayrollAdmin, scope }}>
      <Container fluid>
        <SideNavigation collapsed={collapsed} setCollapsed={setCollapsed} authority={authority} />

        <Segment
          className={classnames(style.app_content, {
            [style.app_content_thin]: collapsed,
          })}
        >
          <SemanticToastContainer
            animation="fade down"
            className={classnames(style.floating_toast_container)}
            position="top-right"
          />

          <SentryRoutes>
            <Route element={<Home />} path="/" />
            <Route element={<Employees />} path="/employees" />
            <Route element={<EmployeeProfile />} path="/employees/:employeeId/*" />
            <Route element={<Organization />} path="/organization/*" />
            <Route element={<ModifyDepartments />} path="/organization/departments/:departmentId" />
            <Route element={<ModifyAreas />} path="/organization/areas/:areaId" />
            <Route element={<ModifyPositions />} path="/organization/positions/:positionId" />
            <Route element={<ModifyRotations />} path="/organization/rotations/:rotationId" />
            <Route element={<ShiftPlanning />} path="/shift-planning" />
            <Route element={<Payroll />} path="/payroll/*" />
            <Route element={<ScheduleManagement />} path="/scheduling/*" />
            <Route element={<UnpublishedSchedule />} path="/scheduling/unpublished/:scheduleId/*" />
            <Route element={<PublishedSchedule />} path="/scheduling/published/:scheduleId/*" />
            <Route
              element={<BidHistory />}
              path="/bid-history/shift/:shiftId/schedule/:scheduleId"
            />
            <Route element={<Requests />} path="/requests/*" />
            <Route element={<Timesheets />} path="/timesheets/*" />
            <Route element={<Admin />} path="/admin/*" />
            <Route element={<TimesheetDetails />} path="/timesheets/details/:timesheetId" />
            <Route element={<Users />} path="/user-profile/*" />
            <Route element={<EducationalDocuments />} path="/education" />
            <Route element={<CreateEducationalDocument />} path="/education/:documentId" />
            <Route element={<Notices />} path="/notices/*" />
            <Route element={<ModifyNotices />} path="/notice/:noticeId" />
            <Route element={<Wage />} path="/essentials/wage" />
            <Route element={<WageGrid />} path="/essentials/wagegrid/:wageId" />
            <Route element={<Essentials />} path="/essentials/dashboard" />
            <Route element={<MasterStaffDatabase />} path="/essentials/database/*" />
          </SentryRoutes>
        </Segment>
      </Container>
    </AppContext.Provider>
  );
});
