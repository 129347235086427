import Bulletin from '@/components/Bulletin';
import { Form, Grid, Input, Modal, Radio } from '@/semantic-ui/components';
import { useStores } from '@/store';
import classnames from 'classnames';
import { Formik } from 'formik';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { SingleDatePicker, isInclusivelyBeforeDay } from 'react-dates';
import { Schedule, moment, toID } from 'sol-data';
import { array, boolean, number, object, string } from 'yup';

import { useNavigate } from 'react-router';
import ModalBase from '../ModalBase';
import PositionFilterDropdown from '../PositionFilterDropdown';
import style from './index.less';

const { Column, Row } = Grid;
const { Field } = Form;

interface ScheduleCreateModalProps {
  open: boolean;
  onCancel?: () => void;
  scheduleToCopy?: Schedule;
}

export const ScheduleCreateModal = observer(
  ({ open, onCancel, scheduleToCopy }: ScheduleCreateModalProps) => {
    const navigate = useNavigate();
    const { departmentStore, shiftPlanStore, scheduleStore } = useStores();
    const [datePickerFocused, setDatePickerFocused] = useState(false);

    const toggleDatePickerFocus = () => {
      setDatePickerFocused(!datePickerFocused);
    };

    const handleDismiss = () => {
      scheduleStore.clearError();
    };

    const { current: department } = departmentStore;

    if (!department) {
      return null;
    }

    const shiftPlans = shiftPlanStore.getShiftPlansForDepartment(department);
    const positions = shiftPlans.reduce((positions, shiftPlan) => {
      for (const { position } of shiftPlan.shiftPlanPositions) {
        positions.add(toID(position));
      }
      return positions;
    }, new Set<number>());

    const { isLoading, errorMessage } = scheduleStore;

    const validationSchema = scheduleToCopy
      ? object().shape({
          startDate: string()
            .typeError('Date must be in MM/DD/YYYY format')
            .required('Start date is required'),
        })
      : object().shape({
          departmentId: number().required(),
          isEmpty: boolean(),
          positions: array()
            .of(number())
            .when('isEmpty', {
              is: false,
              then: array().min(1, 'At least one position is required'),
              otherWise: array().min(0),
            }),
          startDate: string()
            .typeError('Date must be in MM/DD/YYYY format')
            .required('Start date is required'),
          numberOfWeeks: number()
            .typeError('Number of weeks should be a number')
            .required('Number of weeks is required'),
          description: string(),
        });

    return (
      <Modal
        open={open}
        size="mini"
        className={classnames(style.gen_modal, 'generate_schedule')}
        onClose={onCancel}
      >
        <Bulletin
          isModal
          isLoading={isLoading}
          errorMessage={errorMessage}
          handleDismiss={handleDismiss}
        />
        <Formik
          initialValues={{
            departmentId: department.id,
            positions: Array.from(positions),
            startDate: moment(),
            numberOfWeeks: undefined,
            description: undefined,
            isEmpty: false,
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, { setSubmitting }) => {
            const submittedValues = {
              department,
              numberOfWeeks: parseFloat(values.numberOfWeeks!),
              startDate: moment(values.startDate),
              description: values.description,
            };

            let schedule;
            if (scheduleToCopy) {
              schedule = await scheduleStore.copy(
                scheduleToCopy,
                values.startDate,
                values.description,
              );
            } else if (values.isEmpty) {
              schedule = await scheduleStore.createEmpty(submittedValues);
            } else {
              schedule = await scheduleStore.generate({
                ...submittedValues,
                shiftPlans: shiftPlans.filter(({ shiftPlanPositions }) => {
                  return shiftPlanPositions.some(({ position }) =>
                    values.positions.includes(toID(position)),
                  );
                }),
              });
            }

            setSubmitting(false);

            if (schedule) {
              navigate(`/scheduling/unpublished/${toID(schedule)}`);
            }
          }}
        >
          {({ values, errors, touched, setFieldValue, handleChange, handleBlur, handleSubmit }) => {
            const showStartDateError = !!errors.startDate;
            const showNumberOfWeeksError = !!touched.numberOfWeeks && !!errors.numberOfWeeks;
            const showPositionsError = !!touched.positions && !!errors.positions;

            const datePicker = (
              <Field error={showStartDateError}>
                <p className={style.form_headers}>Start:</p>
                <SingleDatePicker
                  id="generate_modal"
                  date={values.startDate}
                  placeholder="MM/DD/YYYY"
                  onDateChange={(date) => setFieldValue('startDate', date)}
                  focused={datePickerFocused}
                  onFocusChange={toggleDatePickerFocus}
                  isOutsideRange={(day) => isInclusivelyBeforeDay(day, moment())}
                  numberOfMonths={1}
                  hideKeyboardShortcutsPanel
                  noBorder
                  block
                />
                {showStartDateError && <p className={style.error_message}>{errors.startDate}</p>}
              </Field>
            );

            const weekNumberPicker = (
              <Field error={showNumberOfWeeksError}>
                <p className={style.form_headers}>Number of weeks</p>
                <Input
                  name="numberOfWeeks"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="How many weeks?"
                />
                {showNumberOfWeeksError && (
                  <p className={style.error_message}>{errors.numberOfWeeks}</p>
                )}
              </Field>
            );

            const positionsPicker = (
              <Field error={showPositionsError}>
                <p className={style.form_headers}>Positions</p>
                <PositionFilterDropdown
                  name="positions"
                  placeholder="Select positions"
                  onChange={(_, value) => setFieldValue('positions', value)}
                  positionIds={values.positions}
                  disabled={values.isEmpty}
                  multiple
                />
                {showPositionsError && <p className={style.error_message}>{errors.positions}</p>}
              </Field>
            );

            return (
              <ModalBase
                header={scheduleToCopy ? 'Copy Schedule' : 'Generate Schedule'}
                onAccept={() => handleSubmit()}
                onCancel={onCancel}
              >
                <Grid>
                  <Row>
                    <Column width={16}>{datePicker}</Column>
                  </Row>
                  {!scheduleToCopy && (
                    <>
                      <Row>
                        <Column width={16}>{weekNumberPicker}</Column>
                      </Row>
                      <Row>
                        <Column width={16}>{positionsPicker}</Column>
                      </Row>
                    </>
                  )}
                  <Row>
                    <Column width={16}>
                      <Field>
                        <p className={style.form_headers}>Name of Schedule</p>
                        <Input
                          name="description"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Name"
                        />
                      </Field>
                    </Column>
                  </Row>
                  {!scheduleToCopy && (
                    <Row>
                      <Column className={style.empty} width={8}>
                        <span>Empty Schedule</span>
                      </Column>
                      <Column width={8} textAlign="right">
                        <Radio
                          toggle
                          className={style.radio}
                          checked={values.isEmpty}
                          onChange={() => setFieldValue('isEmpty', !values.isEmpty)}
                        />
                      </Column>
                    </Row>
                  )}
                </Grid>
              </ModalBase>
            );
          }}
        </Formik>
      </Modal>
    );
  },
);
