import * as React from 'react';
import { Formik } from 'formik';
import { Input, Form, Grid, Column, Row } from '@/semantic-ui/components';
import { object, string, number, array } from 'yup';
import { inject } from 'mobx-react';
import { moment } from 'sol-data';
import { PayrollStore } from '@/store';
import MonthPicker from '@/components/MonthPicker';
import DatePicker from '@/components/DatePicker';
import YearPicker from '@/components/YearPicker';
import ModalBase from '@/components/ModalBase';
import PayrollSelect from '@/components/PayrollSelect';
import InjectedComponent from '@/components/InjectedComponent';
import Bulletin from '@/components/Bulletin';
import style from '@/less/main.less';

interface Props {
  onCancel: () => void;
}

interface Injected {
  payrollStore: PayrollStore;
}

interface State {
  dayCount: number;
}

interface InitialValues {
  name: string;
  month: number | undefined;
  date: number | undefined;
  year: number | undefined;
  categories: number[];
}

interface SubmittedValues extends InitialValues {
  month: number;
  date: number;
  year: number;
}

@inject('payrollStore')
class HolidayModal extends InjectedComponent<Props, State, Injected> {
  state: State = {
    dayCount: 30,
  };

  handleMonthChange = ({ days: dayCount }: { days: number }) => this.setState({ dayCount });

  render() {
    const { dayCount } = this.state;
    const { onCancel } = this.props;
    const { payrollStore } = this.injected;

    const { isLoading, errorMessage } = payrollStore;

    const { current: currentHoliday } = payrollStore.holidays;

    let initialValues: InitialValues = {
      name: '',
      month: undefined,
      date: undefined,
      year: undefined,
      categories: [],
    };

    if (currentHoliday) {
      initialValues = {
        name: currentHoliday.description,
        date: currentHoliday.date.date(),
        month: currentHoliday.date.month(),
        year: currentHoliday.date.year(),
        categories: currentHoliday.categories,
      };
    }

    return (
      <Formik
        validateOnChange={false}
        initialValues={initialValues}
        validationSchema={object().shape({
          name: string().required('This holiday needs a name'),
          month: number().required('Select a month'),
          date: number().required('Day of the month is required'),
          year: number().required('Year is required'),
          categories: array().of(number()).min(1, 'At least 1 day is required'),
        })}
        onSubmit={async (submittedValues) => {
          const {
            name: description,
            month,
            date,
            year,
            categories,
          } = submittedValues as SubmittedValues;
          const { payrollStore } = this.injected;
          const holiday = {
            description,
            categories,
            date: moment(`${year}/${month + 1}/${date + 1}`),
          };

          await payrollStore.createHoliday(holiday);
          if (!payrollStore.errorMessage) {
            onCancel();
          }
        }}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
          setFieldTouched,
          handleSubmit,
        }) => {
          const showNameError = !!errors.name && !!touched.name;
          const showMonthError = !!errors.month && !!touched.month;
          const showDateError = !!errors.date && !!touched.date;
          const showYearError = !!errors.year && !!touched.year;
          const showCategoryError = !!errors.categories && !!touched.categories;

          return (
            <ModalBase
              header={!currentHoliday ? 'Add Holiday' : 'Edit Holiday'}
              actionWord={!currentHoliday ? 'Create' : 'Update'}
              onCancel={onCancel}
              handleSubmit={handleSubmit}
            >
              <Bulletin
                isLoading={isLoading}
                errorMessage={errorMessage}
                handleDismiss={() => payrollStore.clearError()}
                isModal
              />
              <Grid>
                <Column width={16}>
                  <Form.Field error={showNameError} className={style.payroll_form_field}>
                    <p className={style.form_headers}>Name</p>
                    <Input
                      fluid
                      name="name"
                      placeholder="Enter a name"
                      value={values.name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      className={style.holiday_input}
                    />
                    {showNameError && <p className={style.error_class}>{errors.name}</p>}
                  </Form.Field>
                </Column>
                <Row columns={2}>
                  <Column>
                    <Form.Field error={showMonthError} className={style.payroll_form_field}>
                      <p className={style.form_headers}>Month</p>
                      <MonthPicker
                        fluid
                        name="month"
                        value={values.month}
                        placeholder="Select a Month"
                        onMonthChange={this.handleMonthChange}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                        clearDate
                      />
                      {showMonthError && <p className={style.error_class}>{errors.month}</p>}
                    </Form.Field>
                  </Column>
                  <Column>
                    <Form.Field error={showDateError} className={style.payroll_form_field}>
                      <p className={style.form_headers}>Day</p>
                      <DatePicker
                        fluid
                        name="date"
                        value={values.date}
                        placeholder="Select a date"
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                        numberOfDays={dayCount}
                      />
                      {showDateError && <p className={style.error_class}>{errors.date}</p>}
                    </Form.Field>
                  </Column>
                </Row>
                <Row>
                  <Column>
                    <Form.Field error={showYearError} className={style.payroll_form_field}>
                      <p className={style.form_headers}>Year</p>
                      <YearPicker
                        fluid
                        name="year"
                        value={values.year}
                        placeholder={moment().year().toString()}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                      />
                      {showYearError && <p className={style.error_class}>{errors.year}</p>}
                    </Form.Field>
                  </Column>
                </Row>
                <Row>
                  <Column>
                    <p className={style.form_headers}>Type</p>
                    <PayrollSelect
                      onChange={(options) => setFieldValue('categories', options)}
                      values={values.categories}
                      categoryType="isHoliday"
                      errors={showCategoryError}
                    />
                    {showCategoryError && <p className={style.error_class}>{errors.categories}</p>}
                  </Column>
                </Row>
              </Grid>
            </ModalBase>
          );
        }}
      />
    );
  }
}

export default HolidayModal;
