import { DependencyList, useEffect } from 'react';

export const useAsyncEffect = (
  effect: (fn: () => void) => void,
  destroy?: unknown,
  inputs?: unknown,
) => {
  const hasDestroy = typeof destroy === 'function';
  const deps = (hasDestroy ? inputs : destroy) as DependencyList;

  useEffect(
    () => {
      let result: unknown;
      let mounted = true;

      const maybePromise = effect(() => mounted);

      Promise.resolve(maybePromise).then((value) => {
        result = value;
      });

      return () => {
        mounted = false;

        if (hasDestroy) {
          destroy(result);
        }
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps,
  );
};
