import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Moment } from 'sol-data';
import { ScheduleComponentStore } from '@/store';
import style from '../index.less';

interface Props {
  week: Moment;
}

interface Injected {
  scheduleComponentStore: ScheduleComponentStore;
}

const MINUTES_IN_WEEK = 10080;

const WeekLabel: React.FC<Props & Injected> = (props) => {
  const {
    week,
    scheduleComponentStore: {
      cellDimens: { width },
      headerHeight: height,
    },
  } = props;

  const [start, end] = [week, week.clone().add(6, 'days')];

  return (
    <div className={style.schedule_week_label} style={{ height, width: `${width * 7 - 2}px` }}>
      <span>{start.format('ddd, MMM')}</span>
      <span>
        <b>{` ${start.format('Do')}`}</b>
      </span>
      <span> - </span>
      <span>{`${end.format('ddd, MMM')}`}</span>
      <span>
        <b>{end.format('Do')}</b>
      </span>
    </div>
  );
};

export default inject<Injected>(({ scheduleComponentStore }) => ({ scheduleComponentStore }))(
  observer(WeekLabel),
);
