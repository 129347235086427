import InjectedComponent from '@/components/InjectedComponent';
import { ListColumn } from '@/components/ListLayout';
import { NoticeStore } from '@/store';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Segment, Grid, Pagination } from '@/semantic-ui/components';
import AppContext from '@/AppContext';
import { EntityList } from '@/components/EntityList';
import { Moment, moment } from 'sol-data';
import { paginate } from '@/lib/utils';
import { computed } from 'mobx';
import style from './index.less';

const RESULTS_PER_PAGE = 10;
interface Injected {
  noticeStore: NoticeStore;
}

interface State {
  currentPage: number;
}

interface Props {
  archived?: boolean;
}

const deleteWarning = 'Are you sure you want to delete this notice?';

const isInNextThreeMonths = (dateToCheck: Moment) => {
  return moment().diff(dateToCheck, 'months') > 3;
};

@inject('noticeStore')
@observer
class AllNotices extends InjectedComponent<Props, State, Injected> {
  state: State = {
    currentPage: 1,
  };

  async fetchData() {
    const { noticeStore } = this.injected;
    await noticeStore.fetchAll();
  }

  @computed get notices() {
    const {
      archived,
      noticeStore: { filtered: allNotices },
    } = this.injected;

    return allNotices.filter(({ insertDatetime }) =>
      archived ? isInNextThreeMonths(insertDatetime) : !isInNextThreeMonths(insertDatetime),
    );
  }

  render() {
    const { noticeStore } = this.injected;
    const { isLoading, errorMessage } = noticeStore;
    const { currentPage } = this.state;

    const columns: ListColumn[] = [
      {
        name: 'Title',
        key: 'title',
        width: { sm: 4, lg: 4 },
      },
      {
        name: 'Description',
        key: 'description',
        width: { sm: 6, lg: 6 },
        render: ({ description }) => (
          <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
            {description}
          </div>
        ),
      },
      {
        name: 'Created By',
        key: 'createdBy',
        textAlign: 'center',
        width: { sm: 2, lg: 2 },
        render: ({ createdBy }) => (
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {createdBy}
          </div>
        ),
      },
      {
        name: 'Date',
        key: 'date',
        width: { sm: 2, lg: 2 },
        textAlign: 'center',
        render: ({ date }) => (
          <div style={{ textAlign: 'center' }}>{moment(date).format('YYYY-MM-DD')}</div>
        ),
      },
    ];

    const handleDel = async (id: number) => {
      await noticeStore.delete(id);
    };

    const notices = this.notices.map(
      ({ id, title, description, postedBy: createdBy, insertDatetime: date }) => ({
        id,
        title,
        description,
        createdBy,
        date,
      }),
    );

    return (
      <Segment className={style.body_container}>
        <AppContext.Consumer>
          {({ isAdmin }) => (
            <>
              <EntityList
                title="Notices"
                searchLabel="Search a notice"
                columns={columns}
                items={paginate(notices, RESULTS_PER_PAGE, currentPage)}
                newLink="/notice/add"
                titleProp="title"
                onRemove={isAdmin ? ({ id }) => handleDel(id) : undefined}
                dialog={deleteWarning}
                // eslint-disable-next-line prettier/prettier
                onSearchChange={(filter) => noticeStore.setFilter(filter)}
                onDismiss={() => noticeStore.clearError()}
                onRefresh={() => this.fetchData}
                errorMessage={errorMessage}
                isLoading={isLoading}
                link={isAdmin ? ({ id }) => `/notice/${id}` : undefined}
                noNewButton={!isAdmin}
              />
              <Grid centered>
                <Grid.Row>
                  <Pagination
                    activePage={currentPage}
                    totalPages={Math.ceil(notices.length / RESULTS_PER_PAGE)}
                    onPageChange={(_, { activePage }) => {
                      this.setState({ currentPage: activePage as number });
                    }}
                    siblingRange={2}
                    boundaryRange={0}
                    firstItem={{ content: 'First' }}
                    lastItem={{ content: 'Last' }}
                    ellipsisItem={null}
                    prevItem={null}
                    nextItem={null}
                  />
                </Grid.Row>
              </Grid>
            </>
          )}
        </AppContext.Consumer>
      </Segment>
    );
  }
}

export default AllNotices;
