import AppContext from '@/AppContext';
import WarningIcon from '@/images/warning.svg';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Link } from 'react-router';
import { Popup } from 'semantic-ui-react';
import { TimesheetStore } from 'sol-data';
import { clearInterval, setInterval } from 'timers';
import InjectedComponent from '../InjectedComponent';
import style from './index.less';

interface Injected {
  timesheetStore: TimesheetStore;
}

/* 30 seconds */
const POLLING_INTERVAL_MS = 1000 * 30;

@inject('timesheetStore')
@observer
class TimesheetSyncErrorNotifier extends InjectedComponent<{}, {}, Injected> {
  static contextType = AppContext;
  private intervalId?: NodeJS.Timeout;
  context!: React.ContextType<typeof AppContext>;

  componentDidMount(): void {
    const fetchData = () => {
      if (this.context.isPayrollAdmin) {
        this.injected.timesheetStore.fetchIncompleteTimesheetEntrySyncCount();
      }
    };
    this.intervalId = setInterval(fetchData, POLLING_INTERVAL_MS);
    fetchData();
  }

  componentWillUnmount(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  render() {
    const incompleteTimesheetEntrySyncCount =
      this.injected.timesheetStore.incompleteTimesheetEntrySyncCount?.total ?? 0;

    return (
      <div>
        {!!incompleteTimesheetEntrySyncCount && (
          <Popup
            hoverable
            on={['focus', 'hover', 'click']}
            content={
              <div className={style.alert_content} tabIndex={0}>
                <strong>Error on QB Sync</strong>
                <Link to="/payroll/qb-reconciler">
                  There are entries ({incompleteTimesheetEntrySyncCount}) with incomplete processing
                  on QB.
                </Link>
              </div>
            }
            trigger={
              <div tabIndex={0} className={style.notifier}>
                <WarningIcon height="24px" width="24px" />
                <div className={style.alert_count}>
                  <p>{incompleteTimesheetEntrySyncCount}</p>
                </div>
              </div>
            }
          />
        )}
      </div>
    );
  }
}

export default TimesheetSyncErrorNotifier;
