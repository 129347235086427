import { observable, action, computed } from 'mobx';

export class ScheduleComponentStore {
  @observable width = 0;

  @observable isAreaView = false;

  // < 1075px is when we default to 1 week display
  @observable weekDisplayCount = window.innerWidth > 1075 ? 2 : 1;

  @observable scrollLeft = 0;

  @observable showStickyHeader = false;

  @computed get cellDimens() {
    return {
      width: Math.max(this.width / (this.weekDisplayCount * 7), 48),
      height: 80,
    };
  }

  @observable headerDimens = {
    width: 56,
    height: 40,
  };

  @observable actionDimens = {
    width: 56,
    height: 64,
  };

  @action.bound setWidth(width: number) {
    this.width = width;
  }

  @action.bound setWeekDisplayCount(count: number) {
    this.weekDisplayCount = count;
  }

  @action.bound setIsAreaView(isAreaView: boolean) {
    this.isAreaView = isAreaView;
  }

  @action.bound setScrollLeft(scrollLeft: number) {
    this.scrollLeft = scrollLeft;
  }

  @action.bound setShowStickyHeader(showStickyHeader: boolean) {
    this.showStickyHeader = showStickyHeader;
  }

  @computed get cellWidth() {
    return `${this.cellDimens.width}px`;
  }

  @computed get cellHeight() {
    return `${this.cellDimens.height}px`;
  }

  @computed get headerHeight() {
    return `${this.headerDimens.height}px`;
  }

  @computed get actionWidth() {
    return `${this.actionDimens.width}px`;
  }

  @computed get actionHeight() {
    return `${this.actionDimens.height}px`;
  }

  @computed get sidebarWidth() {
    return `${Math.max(Math.min(this.cellDimens.width * 1.75, 112), 112)}px`;
  }
}
