import * as React from 'react';
import { Form, Grid, Button, Input, Icon } from 'semantic-ui-react';
import { Row, Column } from '@/semantic-ui/components';
import { SingleDatePicker } from 'react-dates';
import isBeforeDay from 'react-dates/lib/utils/isBeforeDay';
import { Moment } from 'sol-data';
import DepartmentFilterDropdown from '@/components/DepartmentFilterDropdown';
import style from './index.less';

interface Props {
  filters: {
    startDate?: Moment;
    endDate?: Moment;
  };
  handleStartChange: (date: Moment | null) => void;
  toggleStartFocus: () => void;
  startFocused: boolean;
  handleEndChange: (date: Moment | null) => void;
  toggleEndFocus: () => void;
  endFocused: boolean;
  toggleFiltersModal: () => void;
  hasNoButton?: boolean;
  departments?: number[];
  onDepartmentChange?: (name: string, value: number) => void;
  departmentDropdownName?: string;
  onSearchChange?: (value: string) => void;
  searchPlaceholder?: string;
  scopedFilters?: boolean;
}

const FiltersHeader: React.FC<Props> = ({
  filters,
  handleStartChange,
  toggleStartFocus,
  startFocused,
  handleEndChange,
  toggleEndFocus,
  endFocused,
  toggleFiltersModal,
  hasNoButton,
  departments,
  onDepartmentChange,
  departmentDropdownName,
  onSearchChange,
  searchPlaceholder,
  scopedFilters,
}) => (
  <Form>
    <Grid>
      <Row columns={4}>
        {onSearchChange && (
          <Column>
            <Input
              fluid
              type="search"
              icon="search"
              iconPosition="left"
              placeholder={searchPlaceholder}
              onChange={(_, { value }) => onSearchChange(value)}
            />
          </Column>
        )}
        <Column>
          <Form.Field>
            <div className={style.date_picker}>
              <SingleDatePicker
                hideKeyboardShortcutsPanel
                noBorder
                block
                id="requests_start"
                date={filters.startDate || null}
                placeholder="From:"
                onDateChange={handleStartChange}
                focused={startFocused}
                onFocusChange={toggleStartFocus}
                numberOfMonths={1}
                showClearDate
                customCloseIcon={<Icon name="times" color="grey" size="small" fitted />}
              />
            </div>
          </Form.Field>
        </Column>
        <Column>
          <Form.Field>
            <div className={style.date_picker}>
              <SingleDatePicker
                hideKeyboardShortcutsPanel
                noBorder
                block
                id="requests_end"
                date={filters.endDate || null}
                placeholder="To:"
                onDateChange={handleEndChange}
                focused={endFocused}
                onFocusChange={toggleEndFocus}
                numberOfMonths={1}
                isOutsideRange={(day) => isBeforeDay(day, filters.startDate)}
                showClearDate
                customCloseIcon={<Icon name="times" color="grey" size="small" fitted />}
              />
            </div>
          </Form.Field>
        </Column>
        {departmentDropdownName && (
          <Column>
            <Form.Field>
              <DepartmentFilterDropdown
                multiple
                onChange={onDepartmentChange}
                departmentIds={departments || []}
                name={departmentDropdownName}
                limited={scopedFilters}
              />
            </Form.Field>
          </Column>
        )}
        <Column />
        {!hasNoButton && (
          <Column className={style.button_column}>
            <Button fluid color="yellow" onClick={toggleFiltersModal}>
              Filters
            </Button>
          </Column>
        )}
      </Row>
    </Grid>
  </Form>
);

export default FiltersHeader;
