import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { ListColumn } from '@/components/ListLayout';
import { EntityList } from '@/components/EntityList';
import { DepartmentStore, AreaStore, PositionStore, RotationStore } from '@/store';
import InjectedComponent from '@/components/InjectedComponent';
import AppContext from '@/AppContext';

interface Injected {
  departmentStore: DepartmentStore;
  areaStore: AreaStore;
  positionStore: PositionStore;
  rotationStore: RotationStore;
}

@inject('departmentStore', 'areaStore', 'positionStore', 'rotationStore')
@observer
class Rotations extends InjectedComponent<{}, {}, Injected> {
  fetch = async () => {
    const { departmentStore, areaStore, positionStore, rotationStore } = this.injected;

    await Promise.all([
      departmentStore.fetchAll(),
      areaStore.fetchAll(),
      positionStore.fetchAll(),
      rotationStore.fetchAll(),
    ]);
  };

  componentWillUnmount() {
    this.injected.rotationStore.clearFilter();
  }

  render() {
    const { rotationStore, positionStore } = this.injected;
    const { filtered: filteredRotations, errorMessage, isLoading } = rotationStore;

    const columns: ListColumn[] = [
      {
        name: 'ID',
        key: 'id',
        width: { sm: 2, lg: 1 },
        render: ({ id }) => <span>{id.toString().padStart(4, '0')}</span>,
      },
      {
        name: 'Name',
        key: 'description',
        width: { sm: 5, lg: 2 },
        render: (item) => <span>{item.description}</span>,
      },
      {
        name: 'Position',
        key: 'positionId',
        width: { sm: 5, lg: 4 },
        render: ({ positionId }) => (
          <span>
            {positionStore.has(positionId) ? positionStore.get(positionId)!.description : 'Unknown'}
          </span>
        ),
      },
      {
        name: 'Frequency',
        key: 'frequency',
        width: { sm: 4, lg: 2 },
        template: (item) => `Every ${item} weeks`,
      },
    ];

    return (
      <AppContext.Consumer>
        {({ isAdmin }) => (
          <EntityList
            title="Rotation"
            searchLabel="Search by rotation name"
            columns={columns}
            items={filteredRotations}
            newLink="/organization/rotations/add"
            titleProp="description"
            onRemove={isAdmin ? (rotation) => rotationStore.delete(rotation) : undefined}
            onSearchChange={(filter) => rotationStore.setFilter(filter)}
            onDismiss={() => rotationStore.clearError()}
            onRefresh={() => this.fetch()}
            errorMessage={errorMessage}
            isLoading={isLoading}
            link={isAdmin ? (rot) => `/organization/rotations/${rot.id}` : undefined}
            noNewButton={!isAdmin}
          />
        )}
      </AppContext.Consumer>
    );
  }
}

export default Rotations;
