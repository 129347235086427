import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import AddButton from '../AddButton';
import ButtonBar from '../ButtonBar';
import DeleteModal from '../DeleteModal';
import ListLayout, { Item, ListColumn } from '../ListLayout';
import { WithNavigationProps } from '../Route';

type WithID = { id: number };

interface Props<E extends WithID> extends WithNavigationProps {
  title: string;
  columns: ListColumn[];
  items: E[];
  newLink?: string;
  titleProp: keyof E;
  onRemove?: (e: E) => Promise<void>;
  onSearchChange: (filter: string) => void;
  onDismiss: () => void;
  onRefresh: () => void;
  errorMessage?: string;
  getInitial?: boolean;
  searchLabel?: string;
  noNewButton?: boolean;
  isLoading?: boolean;
  onClick?: (item: Item) => void;
  searchHeaderContent?: React.ReactNode;
  dialog?: string;
  link?: (item: Item) => string;
}

export const EntityList = <Entity extends WithID>({
  title,
  columns,
  items,
  newLink,
  titleProp,
  onRemove,
  onSearchChange,
  onDismiss,
  onRefresh,
  errorMessage,
  getInitial = true,
  searchLabel,
  noNewButton = false,
  isLoading,
  onClick,
  searchHeaderContent,
  dialog,
  link,
}: Props<Entity>) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [toDelete, setToDelete] = useState<Entity | undefined>();
  const navigate = useNavigate();

  useEffect(() => {
    if (getInitial) {
      onRefresh();
    }
  }, [getInitial]);

  const addNew = () => {
    if (newLink) {
      navigate(newLink);
    }
  };

  const openDeleteModal = (item: Entity) => {
    setToDelete(item);
    setModalOpen(true);
    setModalTitle(String(item[titleProp]));
  };

  const closeDeleteModal = () => {
    setToDelete(undefined);
    setModalOpen(false);
    setModalTitle('');
  };

  const handleDelete = async () => {
    if (toDelete && onRemove) {
      await onRemove(toDelete);
    }
    closeDeleteModal();
  };

  return (
    <>
      <ListLayout<Entity>
        columns={columns}
        headerProps={{
          errorMessage,
          onDismiss,
          onSearchChange,
          searchLabel,
          isLoading,
          searchHeaderContent,
        }}
        items={items}
        onRemove={onRemove && openDeleteModal}
        onClick={onClick}
        link={link}
      />
      {!noNewButton && (
        <ButtonBar>
          <AddButton onAdd={addNew} addLabel={title} />
        </ButtonBar>
      )}
      {modalOpen && (
        <DeleteModal
          onConfirm={handleDelete}
          onCancel={closeDeleteModal}
          label={title}
          description={modalTitle}
          open={modalOpen}
          dialog={dialog}
        />
      )}
    </>
  );
};
