import * as React from 'react';

export interface Props {
  horizontal?: boolean;
  vertical?: boolean;
  onScroll: React.DOMAttributes<Element>['onScroll'];
  onSetWidth: (width: number) => void;
  onSetScrollTo: (scrollTo: ScrollableContent['scrollTo']) => void;
  onSetScrollArea: (ref: HTMLElement) => void;
}

const scrollContainerStyle = (horizontal: boolean, vertical: boolean) => ({
  width: '100%',
  overflowX: horizontal ? ('scroll' as const) : ('visible' as const),
  overflowY: vertical ? ('scroll' as const) : ('visible' as const),
  padding: '0 0 16px 0',
});

export default class ScrollableContent extends React.Component<Props> {
  static defaultProps: Partial<Props> = {
    horizontal: false,
    vertical: false,
    onScroll: () => {},
    onSetWidth: () => {},
    onSetScrollTo: () => {},
    onSetScrollArea: () => {},
  };

  scrollArea?: HTMLElement | null;

  componentDidMount() {
    const { onSetWidth, onSetScrollTo, onSetScrollArea } = this.props;

    const { scrollArea } = this;

    if (!scrollArea) throw new Error('No scrollArea');

    onSetWidth(scrollArea.clientWidth);
    onSetScrollTo(this.scrollTo);
    onSetScrollArea(scrollArea);

    window.addEventListener('resize', this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  onResize = () => {
    const { onSetWidth } = this.props;

    if (this.scrollArea) {
      onSetWidth(this.scrollArea.clientWidth);
    }
  };

  scrollTo = (x = 0, y = 0) => {
    if (this.scrollArea) {
      this.scrollArea.scrollTo({
        top: y,
        left: x,
        behavior: 'smooth',
      });
    }
  };

  render() {
    const { horizontal = false, vertical = false, children, onScroll } = this.props;

    return (
      <div
        style={scrollContainerStyle(horizontal, vertical)}
        onScroll={onScroll}
        ref={(ref) => {
          this.scrollArea = ref;
        }}
      >
        <div className="mp-scrollable" style={{ display: 'flex' }}>
          {children}
        </div>
      </div>
    );
  }
}
