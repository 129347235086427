import * as React from 'react';
import { Column, Button } from '@/semantic-ui/components';
import { Department } from 'sol-data';
import * as classnames from 'classnames';
import style from './index.less';

interface IDistributionPillProps {
  department: Department;
  selected: boolean;
  handleClick: (id: number) => void;
}

const DistributionPill = ({ department, selected, handleClick }: IDistributionPillProps) => {
  return (
    <Column mobile={16} tablet={8} computer={4}>
      <Button
        fluid
        className={classnames(style.pill, {
          [style.selectedPill]: selected,
          [style.unSelectedPill]: !selected,
        })}
        onClick={() => handleClick(department.id)}
        type="button"
      >
        <p className={style.header}>
          {department.label} - #Employees:
          {department.numberOfEmployees}
        </p>
        <p style={{ fontWeight: 400 }}>
          {department.id.toString().padStart(4, '0')} -{department.description}
        </p>
      </Button>
    </Column>
  );
};

export default DistributionPill;
