import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { ScheduleStore, EmployeeStore } from '@/store';
import LegendLayout, { Props as LegendLayoutProps } from '../LegendLayout';
import PositionGroupTitle from '../PositionGroupTitle';
import style from '../index.less';

interface Props extends LegendLayoutProps {
  positionId?: number;
  employeeFilter?: string;
}

interface Injected {
  scheduleStore: ScheduleStore;
  employeeStore: EmployeeStore;
}

const PositionLegend: React.SFC<Props & Injected> = ({
  positionId,
  employeeFilter,
  scheduleStore,
  employeeStore,
  ...legendProps
}) => {
  const positionSchedule = scheduleStore
    .forceGetCurrent()
    .filteredPositionSchedule(positionId, employeeFilter, (id) => employeeStore.get(id));

  return (
    <LegendLayout {...legendProps}>
      <div className={style.schedule_buffer} />
      {positionSchedule?.map((position, i) =>
        position.map((shiftGroup) => {
          const isShaded = i % 2 !== 0;

          return (
            <PositionGroupTitle key={shiftGroup.id} shiftGroup={shiftGroup} isShaded={isShaded} />
          );
        }),
      )}
    </LegendLayout>
  );
};

export default inject<Injected>(({ scheduleStore, employeeStore }) => ({
  scheduleStore,
  employeeStore,
}))(observer(PositionLegend));
