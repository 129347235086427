import * as React from 'react';
import * as classNames from 'classnames';
import { Checkbox, TextArea } from 'semantic-ui-react';
import InjectedComponent from '../InjectedComponent';
import style from './index.less';
import {
  EmployeeContainerTabProps,
  EmployeeCertificatesValues,
} from '../EmployeeInformationModal/utils';

export class EmployeeCertificatesContainer extends InjectedComponent<
  EmployeeContainerTabProps,
  {},
  {}
> {
  render() {
    const {
      formProps: { values: formValues, setFieldValue: formSetFieldValue },
      readOnly,
    } = this.props;
    const setFieldValue = (name: keyof EmployeeCertificatesValues, value: any) =>
      formSetFieldValue(`certificate.${name}`, value);
    const values = formValues.certificate;

    return (
      <div className={style.cert_main}>
        <div>
          <label className={style.form_headers}>Onboarding</label>
          <div className={style.cert_block}>
            <Checkbox
              onChange={(_, { checked }) => setFieldValue('orientation', checked)}
              checked={values.orientation}
              disabled={readOnly}
              label="Orientation"
            />
            <Checkbox
              checked={values.jobDescription}
              onChange={(_, { checked }) => setFieldValue('jobDescription', checked)}
              disabled={readOnly}
              label="Job Description"
            />
            <Checkbox
              checked={values.benefits}
              onChange={(_, { checked }) => setFieldValue('benefits', checked)}
              disabled={readOnly}
              label="Benefits"
            />
            <Checkbox
              checked={values.newHirePaperwork}
              onChange={(_, { checked }) => setFieldValue('newHirePaperwork', checked)}
              disabled={readOnly}
              label="New Hire Paperwork"
            />
            <Checkbox
              checked={values.criminalCheck}
              onChange={(_, { checked }) => setFieldValue('criminalCheck', checked)}
              disabled={readOnly}
              label="Criminal Check"
            />
            <Checkbox
              checked={values.licensePermit}
              onChange={(_, { checked }) => setFieldValue('licensePermit', checked)}
              disabled={readOnly}
              label="License Permit"
            />
            <Checkbox
              checked={values.agreementBook}
              onChange={(_, { checked }) => setFieldValue('agreementBook', checked)}
              disabled={readOnly}
              label="Agreement Book"
            />
            <Checkbox
              checked={values.identification}
              onChange={(_, { checked }) => setFieldValue('identification', checked)}
              disabled={readOnly}
              label="Identification"
            />
            <Checkbox
              checked={values.foodSafety}
              onChange={(_, { checked }) => setFieldValue('foodSafety', checked)}
              disabled={readOnly}
              label=" Food Safety"
            />
          </div>
        </div>
        <div>
          <label className={style.form_headers}>Safety</label>
          <div className={style.cert_block}>
            <Checkbox
              checked={values.whmis}
              onChange={(_, { checked }) => setFieldValue('whmis', checked)}
              disabled={readOnly}
              label="WHMIS"
            />
            <Checkbox
              checked={values.firstAid}
              onChange={(_, { checked }) => setFieldValue('firstAid', checked)}
              disabled={readOnly}
              label="First Aid"
            />
            <Checkbox
              checked={values.fireSafetyEmergency}
              onChange={(_, { checked }) => setFieldValue('fireSafetyEmergency', checked)}
              disabled={readOnly}
              label="Fire & Safety Emergency"
            />
          </div>
        </div>
        <div>
          <label className={style.form_headers}>Annual Vaccines</label>
          <div className={style.cert_block}>
            <Checkbox
              checked={values.fluShot}
              onChange={(_, { checked }) => setFieldValue('fluShot', checked)}
              disabled={readOnly}
              label="Flu Shot"
            />
            <Checkbox
              checked={values.covid}
              onChange={(_, { checked }) => setFieldValue('covid', checked)}
              disabled={readOnly}
              label="Covid 19"
            />
          </div>
        </div>
        <div className={classNames(style.grid, style.date_container)}>
          <label className={style.form_headers}>Notes</label>
          <TextArea
            placeholder="Notes"
            value={values.notes}
            disabled={readOnly}
            onChange={(val) => setFieldValue('notes', val.currentTarget.value)}
          />
        </div>
      </div>
    );
  }
}
