import Bulletin from '@/components/Bulletin';
import DepartmentFilterDropdown from '@/components/DepartmentFilterDropdown';
import InjectedComponent from '@/components/InjectedComponent';
import { ListColumn, ObservingListLayout } from '@/components/ListLayout';
import PositionFilterDropdown from '@/components/PositionFilterDropdown';
import style from '@/less/main.less';
import { EmployeeStore } from '@/store';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Dropdown, Input, Radio } from 'semantic-ui-react';
import { Authority, Employee, UserStore } from 'sol-data';
import debounce from 'lodash/debounce';

interface Injected {
  employeeStore: EmployeeStore;
  userStore: UserStore;
}

interface State {}

type Options = {
  text: string;
  key: 'unknown' | Authority;
  value: 0 | Authority;
  disabled?: boolean;
}[];

const options: Options = [
  {
    text: 'Read Only',
    key: Authority.ReadOnly,
    value: Authority.ReadOnly,
  },
  {
    text: 'Employee',
    key: Authority.Employee,
    value: Authority.Employee,
  },
  {
    text: 'Approver',
    key: Authority.Approver,
    value: Authority.Approver,
  },
  {
    text: 'Admin',
    key: Authority.Admin,
    value: Authority.Admin,
  },
  {
    text: 'AdminApprover',
    key: Authority.AdminApprover,
    value: Authority.AdminApprover,
  },
  {
    text: 'Payroll Admin',
    key: Authority.PayrollAdmin,
    value: Authority.PayrollAdmin,
  },
  {
    text: 'Super User',
    key: Authority.Super,
    value: Authority.Super,
  },
];

const DEBOUNCE_MS = 150;

@inject('employeeStore', 'userStore')
@observer
class UserPermissions extends InjectedComponent<{}, State, Injected> {
  state: State = {};

  componentDidMount() {
    const { employeeStore } = this.injected;

    employeeStore
      .fetchAll()
      .then(() => employeeStore.fetchUsers(employeeStore.values.map(({ user }) => user)));
  }

  // Even though we aren't making API requests, it's slow to filter so many employees, so we debounce the search
  debouncedSearch = debounce((value: string) => {
    const { employeeStore } = this.injected;

    employeeStore.setFilter(value);
  }, DEBOUNCE_MS);

  @computed get columns(): ListColumn<Employee>[] {
    const { userStore } = this.injected;

    return [
      {
        name: 'Employee',
        key: 'fullName',
        width: 3,
        render: ({ user }) => <span>{user.fullName}</span>,
      },
      {
        name: 'Authority',
        key: 'authority',
        width: 3,
        render: ({ user }) => {
          if (user.access) {
            const {
              id,
              access: {
                authority: [current],
                scope,
              },
            } = user;

            return (
              <Dropdown
                selection
                options={options}
                value={current}
                onChange={async (_, { value }) => {
                  const authority = value as number as Authority;

                  await userStore.setUserPermission(id, authority, scope);

                  user.assign({ access: { scope, authority: [authority] } });
                }}
              />
            );
          }
        },
      },
      {
        name: 'Departments',
        key: 'departments',
        width: 3,
        render: ({ user }) => {
          if (user.access && user.access.scope) {
            const {
              id,
              access: {
                authority: [authority],
                scope: { departments, positions },
              },
            } = user;

            return (
              <DepartmentFilterDropdown
                multiple
                departmentIds={departments || []}
                onChange={async (_, value) => {
                  const departments = Array.isArray(value) && value.length > 0 ? value : null;

                  const scope = {
                    positions,
                    departments,
                  };

                  await userStore.setUserPermission(id, authority, scope);

                  user.assign({ access: { scope, authority: [authority] } });
                }}
              />
            );
          }
          return 'Unknown';
        },
      },
      {
        name: 'Positions',
        key: 'positions',
        width: 3,
        render: ({ user }) => {
          if (user.access && user.access.scope) {
            const {
              id,
              access: {
                authority: [authority],
                scope: { departments, positions },
              },
            } = user;

            return (
              <PositionFilterDropdown
                multiple
                positionIds={positions || []}
                onChange={async (_, value) => {
                  const positions = Array.isArray(value) && value.length > 0 ? value : null;

                  const scope = {
                    positions,
                    departments,
                  };

                  await userStore.setUserPermission(id, authority, scope);

                  user.assign({ access: { scope, authority: [authority] } });
                }}
              />
            );
          }
          return 'Unknown';
        },
      },
      {
        name: 'Email',
        key: 'email',
        render: ({ user }) => {
          if (user.access) {
            const {
              id,
              access: {
                authority: [authority],
                scope,
              },
              shouldReceiveEmails,
            } = user;

            return (
              <Radio
                toggle
                className={style.radio}
                checked={shouldReceiveEmails}
                onChange={async () => {
                  await userStore.setUserPermission(id, authority, scope, !shouldReceiveEmails);
                  user.assign({ shouldReceiveEmails: !shouldReceiveEmails });
                }}
              />
            );
          }
        },
      },
    ];
  }

  render() {
    const { employeeStore, userStore } = this.injected;

    return (
      <>
        <Bulletin
          errorMessage={userStore.errorMessage || employeeStore.errorMessage}
          isLoading={userStore.isLoading || employeeStore.isLoading}
          handleDismiss={() => {
            userStore.clearError();
            employeeStore.clearError();
          }}
        />

        <Input
          type="search"
          icon="search"
          iconPosition="left"
          placeholder="Search by employee name"
          className={style.search_header_input}
          style={{ marginBottom: 20, width: 250 }}
          onChange={(_, { value }) => this.debouncedSearch(value)}
        />

        <ObservingListLayout items={employeeStore.filtered} columns={this.columns} />
      </>
    );
  }
}

export default UserPermissions;
