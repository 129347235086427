import AppContext from '@/AppContext';
import ShiftSwapModal from '@/components//ShiftSwapModal';
import DeleteModal from '@/components/DeleteModal';
import InjectedComponent from '@/components/InjectedComponent';
import ModalBase from '@/components/ModalBase';
import { Label, Modal, Popup } from '@/semantic-ui/components';
import { ScheduleComponentStore, ScheduleStore } from '@/store';
import * as classnames from 'classnames';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import {
  AreaStore,
  HasID,
  ID,
  IShift,
  IShiftGroup,
  moment,
  RequestType,
  Shift,
  ShiftType,
} from 'sol-data';
import { BidHistoryModal } from '../BidHistoryModal/BidHistoryModal';
import { CopyShiftModal } from '../CopyShiftModal';
import EditShiftModal from '../EditShiftModal';
import MoveShiftModal from '../MoveShiftModal';
import NewRequestModal from '../NewRequestModal';
import NoticeModal from '../NoticeModal';
import ShiftMenu from '../ShiftMenu';
import style from '../index.less';

export enum Action {
  Edit = 'edit',
  Move = 'move',
  Copy = 'copy',
  Unassign = 'unassign',
  Remove = 'remove',
  Request = 'request',
  NewSickNotice = 'sick-notice',
  NewTimeOff = 'time-off',
  ViewBidHistory = 'bid-history',
}

interface State {
  modalOpen: boolean;
  popupOpen: boolean;
  action?: Action;
}

interface Props {
  shifts?: Shift[];
  shiftGroup: IShiftGroup & HasID;
}

interface Injected {
  scheduleStore: ScheduleStore;
  scheduleComponentStore: ScheduleComponentStore;
  areaStore: AreaStore;
}

@inject('scheduleComponentStore', 'scheduleStore', 'areaStore')
@observer
class ShiftBlock extends InjectedComponent<Props, State, Injected> {
  constructor(props: Props) {
    super(props);

    this.state = {
      modalOpen: false,
      popupOpen: false,
    };
  }

  get shift() {
    return this.props.shifts?.[0];
  }

  get multipleShiftsOnDay() {
    const { shifts } = this.injected;
    if (shifts) return shifts.length > 1;
    return false;
  }

  openModal = (action: Action) => {
    this.setState({
      action,
      modalOpen: true,
    });
    this.closePopup();
  };

  closeModal = () => {
    this.setState({
      modalOpen: false,
    });
  };

  openPopup = () => this.setState({ popupOpen: true });

  closePopup = () => this.setState({ popupOpen: false });

  editShift = async (values: IShift) => {
    const { scheduleStore } = this.injected;

    if (!this.shift) throw new Error('No shift selected');

    await scheduleStore.saveShift(this.shift.assign(values));

    this.closeModal();
  };

  moveShift = async (shiftGroupId: ID) => {
    const { scheduleStore } = this.injected;

    if (!this.shift) throw new Error('No shift selected');

    await scheduleStore.assignShift(this.shift, shiftGroupId);

    this.closeModal();
  };

  copyShift = async (values: IShift, override: boolean) => {
    const { shiftGroup } = this.props;
    const { scheduleStore } = this.injected;

    if (!this.shift) throw new Error('No shift selected');

    await scheduleStore.copyShift(
      {
        ...this.shift,
        startDate: moment(values.startDate),
        endDate: moment(values.endDate),
        area: values.area,
        shiftGroup,
      },
      override,
    );

    this.closeModal();
  };

  unassignShift = async () => {
    const { scheduleStore } = this.injected;

    if (!this.shift) throw new Error('No shift selected');

    await scheduleStore.unassignShift(this.shift);

    this.closeModal();
  };

  deleteShift = async () => {
    const { scheduleStore } = this.injected;

    if (!this.shift) throw new Error('No shift selected');

    await scheduleStore.deleteShift(this.shift);

    this.closeModal();
  };

  getBadgeColor = () => {
    if (!this.shift) return;

    if (this.shift.$.sickNotices && this.shift.$.sickNotices.length) {
      return 'red';
    }

    if (this.shift.$.shiftSwapTo || this.shift.$.shiftSwapFrom) {
      return 'teal';
    }

    return 'violet';
  };

  render() {
    const {
      shifts,
      shiftGroup,
      scheduleComponentStore: { weekDisplayCount, cellWidth: width, cellHeight: height },
      areaStore,
    } = this.injected;

    const { modalOpen, popupOpen, action } = this.state;

    if (!this.shift) {
      return <div style={{ width, height }} />;
    }

    const { shift } = this;

    const { startDate, endDate } = shift.withID;

    const hasRequests = shift.$.type !== ShiftType.Open && shift.$.type !== ShiftType.Normal;

    const shouldShowCircle = weekDisplayCount > 2;

    const isWeekend = [0, 6].includes(shift.$.startDate.day());

    const className = classnames(
      style.shift_block_item,
      isWeekend ? style.shift_block_item_weekend : style.shift_block_item_weekday,
      shouldShowCircle ? style.shift_block_item_small : style.shift_block_item_big,
      {
        [style.shift_block_item_small_sick]:
          shift.$.sickNotices && shift.$.sickNotices.length > 0 && shouldShowCircle,
        [style.shift_block_item_small_time_off]: shift.$.timeOffRequest && shouldShowCircle,
      },
    );

    const label = this.multipleShiftsOnDay
      ? `${shifts!.length} Shifts`
      : shift
      ? areaStore.has(shift.$.area)
        ? areaStore.get(shift.$.area)!.label
        : 'Unknown'
      : '';

    const trigger = (
      <div className={style.block_wrapper}>
        <div className={className}>
          <span>{startDate.format('HH:mm')}</span>
          <span>{endDate.format('HH:mm')}</span>
          <span
            className={classnames(
              style.shift_block_item_label,
              isWeekend
                ? style.shift_block_item_label_weekend
                : style.shift_block_item_label_weekday,
              {
                [style.shift_block_item_label_multi]: this.multipleShiftsOnDay,
              },
            )}
            title={label}
          >
            {label}
          </span>
          {hasRequests && !shouldShowCircle && (
            <Label
              color={this.getBadgeColor()}
              className={style.shift_badge}
              size="mini"
              circular
              floating
            />
          )}
        </div>
      </div>
    );

    const sickNoticeOrTimeOff =
      shift.$.timeOffRequest ||
      (shift.$.sickNotices && shift.$.sickNotices.length && shift.$.sickNotices[0]);
    const shiftSwap = shift.$.shiftSwapTo || shift.$.shiftSwapFrom;

    return (
      <AppContext.Consumer>
        {({ isAdmin }) => (
          <div className={style.shift_block} style={{ width, height }}>
            <Popup
              trigger={trigger}
              content={
                <ShiftMenu shift={shift} openModal={this.openModal} shiftGroup={shiftGroup} />
              }
              open={popupOpen}
              on="click"
              onOpen={isAdmin ? this.openPopup : undefined}
              onClose={this.closePopup}
              position="right center"
            />

            {action === Action.Edit && (
              <Modal size="mini" open={modalOpen} onClose={this.closeModal}>
                <EditShiftModal
                  closeModal={this.closeModal}
                  onSubmit={this.editShift}
                  shiftGroup={shiftGroup}
                  shift={shift.withID}
                />
              </Modal>
            )}
            {action === Action.Move && (
              <Modal size="mini" open={modalOpen} onClose={this.closeModal}>
                <MoveShiftModal
                  shiftGroup={shiftGroup}
                  shift={shift.withID}
                  onSubmit={this.moveShift}
                  onCancel={this.closeModal}
                />
              </Modal>
            )}
            {action === Action.Copy && (
              <Modal size="mini" open={modalOpen} onClose={this.closeModal}>
                <CopyShiftModal
                  shift={shift.props}
                  shiftGroup={shiftGroup}
                  onSubmit={this.copyShift}
                  closeModal={this.closeModal}
                />
              </Modal>
            )}
            {action === Action.Unassign && (
              <Modal size="mini" open={modalOpen} onClose={this.closeModal}>
                <ModalBase
                  header="Unassign Shift"
                  actionWord="Unassign"
                  onAccept={this.unassignShift}
                  onCancel={this.closeModal}
                >
                  The selected shift will be removed from this shift line and moved to the
                  &apos;Open Shifts&apos; tab of the Schedules section until reassigned.
                </ModalBase>
              </Modal>
            )}
            {action === Action.Remove && (
              <DeleteModal
                onConfirm={this.deleteShift}
                onCancel={this.closeModal}
                description={` the shift on ${startDate.format('dddd, MMMM Do YYYY')}`}
                open={modalOpen}
                label="Shift"
              />
            )}
            {(action === Action.NewSickNotice || action === Action.NewTimeOff) && (
              <Modal
                size="mini"
                closeIcon
                open={action === Action.NewSickNotice || action === Action.NewTimeOff}
                onClose={() => this.setState({ action: undefined })}
              >
                <NewRequestModal
                  shift={shift}
                  type={
                    action === Action.NewSickNotice ? RequestType.SickNotice : RequestType.TimeOff
                  }
                  closeModal={() => this.setState({ action: undefined })}
                />
              </Modal>
            )}
            {action === Action.Request && (
              <Modal
                size="mini"
                open={action === Action.Request}
                closeIcon
                onClose={() => this.setState({ action: undefined })}
              >
                {!!sickNoticeOrTimeOff && (
                  <NoticeModal
                    request={sickNoticeOrTimeOff}
                    closeModal={() => this.setState({ action: undefined })}
                    type={shift.$.timeOffRequest ? RequestType.TimeOff : RequestType.SickNotice}
                  />
                )}
                {!!shiftSwap && (
                  <ShiftSwapModal
                    request={shiftSwap}
                    closeModal={() => this.setState({ action: undefined })}
                  />
                )}
              </Modal>
            )}
          </div>
        )}
      </AppContext.Consumer>
    );
  }
}

export default ShiftBlock;
