import Breadcrumbs from '@/components/Breadcrumbs';
import { ProbationList } from '@/components/ProbationList';
import { SeniorityList } from '@/components/SeniorityList';
import { StaffList } from '@/components/StaffList';
import Tabs from '@/components/Tabs';
import { useAsyncEffect } from '@/hooks';
import { useStores } from '@/store';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useLocation } from 'react-router';
import { Dimmer, Loader, Segment } from 'semantic-ui-react';
import { Department, Employee } from 'sol-data';
import style from './index.less';

enum TabsItem {
  StaffList = 'STAFF LIST',
  Probation = 'PROBATION',
  SeniorityList = 'SENIORITY LIST',
}

const tabPaths = {
  [TabsItem.StaffList]: 'staff-list',
  [TabsItem.Probation]: 'probation',
  [TabsItem.SeniorityList]: 'seniority-list',
};

export interface iEmployee extends Employee {
  isProbationEnded: boolean;
}

export const MasterStaffDatabase = observer(() => {
  const { positionStore, employeeStore, departmentStore } = useStores();
  const [selectedTab, setSelectedTab] = useState(TabsItem.StaffList);
  const location = useLocation();

  const fetchDepartments = async () => {
    try {
      const dept = await departmentStore.fetchAll();
      return dept.map(({ id, label, description }) => {
        return { id, label, description } as Department;
      });
    } catch (error) {
      return [];
    }
  };

  const fetchData = async () => {
    await Promise.all([
      positionStore.fetchPositionsWage(),
      fetchDepartments(),
      employeeStore.fetchAll(),
    ]);
  };

  useAsyncEffect(async () => {
    if (location.pathname.includes(tabPaths[TabsItem.Probation])) {
      setSelectedTab(TabsItem.Probation);
    } else if (location.pathname.includes(tabPaths[TabsItem.SeniorityList])) {
      setSelectedTab(TabsItem.SeniorityList);
    } else {
      setSelectedTab(TabsItem.StaffList);
    }

    await fetchData();
  }, [location.pathname]);

  const renderTabContent = () => {
    switch (selectedTab) {
      case TabsItem.Probation:
        return departmentStore.values.length ? (
          <ProbationList departments={departmentStore.values} />
        ) : (
          <Dimmer active inverted>
            <Loader size="large">Loading Employees Timesheets</Loader>
          </Dimmer>
        );
      case TabsItem.SeniorityList:
        return departmentStore.values.length ? (
          <SeniorityList departments={departmentStore.values} />
        ) : (
          <Dimmer active inverted>
            <Loader size="large">Loading Departments</Loader>
          </Dimmer>
        );
      default:
        return departmentStore.values.length && positionStore.values.length ? (
          <StaffList departments={departmentStore.values} positions={positionStore.values} />
        ) : (
          <Dimmer active inverted>
            <Loader size="large">Loading Departments</Loader>
          </Dimmer>
        );
    }
  };

  return (
    <Segment basic padded="very" className={style.body_container}>
      <Breadcrumbs
        tabbed
        linkTitle="Essentials"
        activeBreadcrumb={'Master Staff Database'}
        link={'/essentials/database/staff-list'}
        title="Essentials"
      />
      <div className={style.actions_container}>
        <Tabs
          selected={selectedTab}
          items={Object.values(TabsItem).map((item) => ({
            path: `/essentials/database/${tabPaths[item]}`,
            item,
          }))}
        />
      </div>

      {renderTabContent()}
    </Segment>
  );
});
