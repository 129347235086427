import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Popup, Modal } from '@/semantic-ui/components';
import { ScheduleStore, ScheduleComponentStore, EmployeeStore, PositionStore } from '@/store';
import { IShift, PositionScheduleShiftGroup } from 'sol-data';
import classnames from 'classnames';
import InjectedComponent from '@/components/InjectedComponent';
import DeleteModal from '@/components/DeleteModal';
import AssignEmployee from '@/components/AssignEmployee';
import ModalBase from '@/components/ModalBase';
import PositionMenu from '@/components/Schedule/PositionMenu';
import EditShiftModal from '@/components/Schedule/EditShiftModal';
import AssignOverrideModal from '@/components/AssignOverrideModal';
import AppContext from '@/AppContext';
import { rowCellRatio } from '../utils';
import style from '../index.less';

interface Props {
  shiftGroup: PositionScheduleShiftGroup;
  isShaded: boolean;
}

interface Injected {
  scheduleStore: ScheduleStore;
  scheduleComponentStore: ScheduleComponentStore;
  employeeStore: EmployeeStore;
  positionStore: PositionStore;
}

interface State {
  modalOpen: boolean;
  action?: ModalActions;
  popupOpen: boolean;
}

export enum ModalActions {
  Assign = 'assign',
  Unassign = 'unassign',
  NewShift = 'newShift',
  Delete = 'delete',
  Override = 'override',
  UnassignAllShifts = 'unassignAllShifts',
}

@inject('scheduleStore', 'scheduleComponentStore', 'employeeStore', 'positionStore')
@observer
class PositionGroupTitle extends InjectedComponent<Props, State, Injected> {
  state: State = {
    modalOpen: false,
    popupOpen: false,
  };

  openModal = (action: ModalActions) => {
    this.setState({
      action,
      modalOpen: true,
      popupOpen: false,
    } as any);
  };

  closeModal = () => {
    this.setState({
      modalOpen: false,
      action: undefined,
    });
  };

  deleteShiftGroup = async () => {
    const { shiftGroup, scheduleStore } = this.injected;

    await scheduleStore.deleteShiftGroup(shiftGroup.id);

    this.closeModal();
  };

  openPopup = () => {
    this.setState({ popupOpen: true });
  };

  closePopup = () => {
    this.setState({ popupOpen: false });
  };

  createShift = async (values: IShift, overrideOvertime: boolean) => {
    const { shiftGroup, scheduleStore } = this.injected;

    await scheduleStore.createShift({ ...values, shiftGroup }, overrideOvertime);

    this.closeModal();
  };

  unassignShiftGroup = () => {
    const { scheduleStore, shiftGroup } = this.injected;
    scheduleStore.unassignShiftGroup(shiftGroup.id);
    this.closeModal();
  };

  unassignAllShifts = () => {
    const { scheduleStore, shiftGroup } = this.injected;
    scheduleStore.unassignAllShifts(shiftGroup);
    this.closeModal();
  };

  render() {
    const {
      shiftGroup,
      isShaded,
      scheduleComponentStore: {
        sidebarWidth,
        cellDimens: { height },
      },
      employeeStore,
    } = this.injected;
    const { modalOpen, popupOpen, action } = this.state;

    const { label, fte } = shiftGroup;
    const employee = shiftGroup.employee ? employeeStore.get(shiftGroup.employee) : undefined;

    const popupTrigger = (
      <AppContext.Consumer>
        {({ isAdmin }) => (
          <Popup
            open={popupOpen}
            on="click"
            position="right center"
            onOpen={isAdmin ? this.openPopup : undefined}
            onClose={this.closePopup}
            trigger={
              <div className={classnames({ [style.schedule_group_title_shaded]: isShaded })}>
                <div
                  className={style.schedule_group_title}
                  style={{ width: sidebarWidth, height: `${height * rowCellRatio}px` }}
                >
                  <span className={style.position_group}>
                    {employee ? `${employee.user.lastName}, ${employee.user.firstName}` : label}
                  </span>
                  <span className={style.position_group}>{`${fte.toFixed(2)}FTE`}</span>
                </div>
              </div>
            }
            content={<PositionMenu hasEmployee={!!employee} openModal={this.openModal} />}
          />
        )}
      </AppContext.Consumer>
    );

    return (
      <>
        {action === ModalActions.Delete && (
          <DeleteModal
            onConfirm={this.deleteShiftGroup}
            onCancel={this.closeModal}
            description={`${label}'s shift group`}
            open={modalOpen}
            label="Shift Group"
          />
        )}
        {action === ModalActions.Unassign && (
          <DeleteModal
            onConfirm={this.unassignShiftGroup}
            onCancel={this.closeModal}
            open={modalOpen}
            header="Unassign Shift Group"
            dialog={`Are you sure you want to unassign
              ${employee?.user.firstName} from this shift group?`}
            actionWord="Unassign"
          />
        )}
        {action === ModalActions.UnassignAllShifts && (
          <DeleteModal
            onConfirm={this.unassignAllShifts}
            onCancel={this.closeModal}
            open={modalOpen}
            header="Unassign All Shifts"
            dialog="Are you sure you want to unassign all shifts in this shift group?"
            actionWord="Unassign"
          />
        )}
        <Modal
          size={action === ModalActions.NewShift ? 'mini' : 'tiny'}
          open={
            action === ModalActions.NewShift ||
            action === ModalActions.Assign ||
            action === ModalActions.Override
          }
          trigger={popupTrigger}
          onClose={this.closeModal}
        >
          {action === ModalActions.Assign && (
            <AssignEmployee
              shiftGroup={shiftGroup}
              closeModal={this.closeModal}
              title={`Assign Shift Group: ${label}`}
            />
          )}
          {action === ModalActions.NewShift && (
            <EditShiftModal
              shiftGroup={shiftGroup}
              onSubmit={this.createShift}
              closeModal={this.closeModal}
            />
          )}
          {action === ModalActions.Override && (
            <AssignOverrideModal shiftGroup={shiftGroup} onDismiss={this.closeModal} />
          )}
        </Modal>
      </>
    );
  }
}

export default PositionGroupTitle;
