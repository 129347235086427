import * as React from 'react';
import { Checkbox, Icon } from 'semantic-ui-react';
import { Department } from 'sol-data';
import InjectedComponent from '../InjectedComponent';
import style from './index.less';

interface State {
  isOpen: boolean;
  departmentsId: number[];
}

interface Props {
  deptString: string;
  departments: Department[];
  disabled?: boolean;
  onChange?: (args: number[]) => void;
}

class DeptSelect extends InjectedComponent<Props, State, {}> {
  state: State = {
    isOpen: false,
    departmentsId: [],
  };
  componentDidMount(): void {
    const departmentsId = this.props.departments
      .filter((dpt) => {
        return this.props.deptString.split(', ')?.includes(dpt.label);
      })
      .map((dpt) => dpt.id);

    this.setState((prev) => ({ ...prev, departmentsId }));
  }
  render() {
    const { isOpen } = this.state;
    const { disabled } = this.props;

    return (
      <div className={style.dept_select_wrapper}>
        <label>Dept</label>
        <div className={`${disabled ? style.disabled : ''}`} onClick={this.toggle}>
          <span>{this?.getLabel()}</span>
          <Icon name="angle down" />
        </div>
        {isOpen && (
          <section className={`${style.drop_down_body} ${!isOpen ? style.hidden : ''}`}>
            {this?.renderOptions()}
          </section>
        )}
      </div>
    );
  }

  renderOptions = () => {
    return this.props.departments.map((dept) => {
      return (
        <div key={dept.id}>
          <span>{dept.label}</span>
          <Checkbox
            checked={this.state.departmentsId?.includes(dept.id)}
            onChange={() => this.onOptionChange(dept.id)}
          />
        </div>
      );
    });
  };

  onOptionChange = (id: number) => {
    const auxIds = this.state.departmentsId;
    if (this.state.departmentsId.includes(id) && this.state.departmentsId.length > 1) {
      const index = auxIds.indexOf(id);
      auxIds.splice(index, 1);
      this.setState((prev) => ({ ...prev, departmentsId: auxIds }));
    } else {
      auxIds.push(id);
      this.setState((prev) => ({ ...prev, departmentsId: auxIds }));
    }
    this.props.onChange?.(auxIds);
  };

  toggle = () => {
    if (!this.props.disabled) {
      this.setState((prev) => {
        return { ...prev, isOpen: !prev.isOpen };
      });
    }
  };

  getLabel = () => {
    return this.props.departments
      .filter((dpt) => {
        return this.state.departmentsId.includes(dpt.id);
      })
      .map((dpt) => dpt.label)
      .join(', ');
  };
}

export default DeptSelect;
