import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Formik } from 'formik';
import { object, string, array, number } from 'yup';
import { EntityReference } from '@lcdev/store';
import { PayrollStore, PositionStore, Position } from 'sol-data';
import { Form, Input, Dropdown, Grid, Row, Column } from '@/semantic-ui/components';
import Bulletin from '@/components/Bulletin';
import InjectedComponent from '@/components/InjectedComponent';
import ModalBase from '@/components/ModalBase';
import PayrollSelect from '@/components/PayrollSelect';
import PositionFilterDropdown from '@/components/PositionFilterDropdown';
import style from '@/containers/Payroll/index.less';
import IncrementalButton from '@/components/IncrementalButton';

const { Field } = Form;

const intervalOptions = [
  {
    text: 'Day',
    value: 'day',
  },
  {
    text: 'Week',
    value: 'week',
  },
];

interface Props {
  onFormToggle: () => void;
}

interface Injected {
  payrollStore: PayrollStore;
  positionStore: PositionStore;
}

type InitialValues = {
  maxTime?: number;
  maxTimeUnit?: 'day' | 'week';
  categories: number[];
  position?: number;
};

@inject('payrollStore', 'positionStore')
@observer
class OvertimeForm extends InjectedComponent<Props, {}, Injected> {
  render() {
    const { onFormToggle } = this.props;

    const { payrollStore, positionStore } = this.injected;
    const { isLoading, errorMessage } = payrollStore;

    const initialValues: InitialValues = {
      maxTime: undefined,
      maxTimeUnit: undefined,
      categories: [],
    };

    return (
      <Formik
        enableReinitialize
        validateOnChange={false}
        validationSchema={object().shape({
          maxTime: string().required('Maximum time required'),
          maxTimeUnit: string().required('What unit is the maximum in?'),
          categories: array().of(number()).min(1, 'At least 1 day is required'),
        })}
        initialValues={initialValues}
        onSubmit={async ({ maxTime, maxTimeUnit, categories, position }) => {
          const submittedValues = {
            categories,
            unit: maxTimeUnit!,
            hours: maxTime!,
            position: position ? new EntityReference(Position, position) : undefined,
          };

          await payrollStore.createLimit(submittedValues);

          if (!payrollStore.errorMessage) {
            onFormToggle();
          }
        }}
        render={({ values, errors, handleChange, setFieldValue, handleSubmit }) => (
          <ModalBase
            header="Add Overtime Limit"
            actionWord="Create"
            onCancel={onFormToggle}
            handleSubmit={handleSubmit}
          >
            <Bulletin
              isLoading={isLoading}
              errorMessage={errorMessage}
              handleDismiss={() => payrollStore.clearError()}
              isModal
            />
            <Grid>
              <Row columns={2}>
                <Column>
                  <Field error={!!errors.maxTime} className={style.payroll_form_field}>
                    <p className={style.form_headers}>Maximum Hours</p>
                    <IncrementalButton
                      name="maxTime"
                      id="maxTime"
                      value={values.maxTime}
                      onChange={setFieldValue}
                      step={0.25}
                    />
                    {errors.maxTime && <p className={style.error_class}>{errors.maxTime}</p>}
                  </Field>
                </Column>
                <Column>
                  <Field error={!!errors.maxTimeUnit} className={style.payroll_form_field}>
                    <p className={style.form_headers}>Per Period</p>
                    <Dropdown
                      selection
                      basic
                      fluid
                      options={intervalOptions}
                      name="maxTimeUnit"
                      onChange={(_, { name, value }) => setFieldValue(name, value)}
                      value={values.maxTimeUnit}
                      placeholder="Day or Week"
                    />
                    {errors.maxTimeUnit && (
                      <p className={style.error_class}>{errors.maxTimeUnit}</p>
                    )}
                  </Field>
                </Column>
              </Row>
              <Row>
                <Column>
                  <p className={style.form_headers}>Position (optional)</p>
                  <PositionFilterDropdown
                    name="position"
                    positionId={values.position}
                    positionIds={positionStore.values.map((v) => v.id)}
                    onChange={(_, value) => setFieldValue('position', value)}
                  />
                  {errors.position && <p className={style.error_class}>{errors.position}</p>}
                </Column>
              </Row>
              <Row>
                <Column>
                  <p className={style.form_headers}>Type</p>
                  <PayrollSelect
                    onChange={(options) => setFieldValue('categories', options)}
                    values={values.categories}
                    categoryType="isOvertime"
                    errors={!!errors.categories}
                  />
                  {errors.categories && <p className={style.error_class}>{errors.categories}</p>}
                </Column>
              </Row>
            </Grid>
          </ModalBase>
        )}
      />
    );
  }
}

export default OvertimeForm;
