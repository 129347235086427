import { EmployeeStatus } from 'sol-data';

export const EmployeeStatusArray = Object.values(EmployeeStatus);

export const EmployeeStatusColor: {
  [key in EmployeeStatus]: { color: string };
} = {
  [EmployeeStatus.COMPLETE]: {
    color: '#56A86C',
  },
  [EmployeeStatus.INCOMPLETE]: {
    color: '#EE5D5D',
  },
};
