import AllNotices from '@/components/AllNotices';
import InjectedComponent from '@/components/InjectedComponent';
import { withNavigation, WithNavigationProps } from '@/components/Route';
import TabLayout, { TabLayoutPane } from '@/components/TabLayout';
import { Menu } from '@/semantic-ui/components';
import { NoticeStore } from '@/store';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Link } from 'react-router';

interface Props extends WithNavigationProps {}

interface Injected {
  noticeStore: NoticeStore;
}

@inject('noticeStore')
@observer
class Notices extends InjectedComponent<Props, {}, Injected> {
  componentDidMount = async () => {
    await this.fetchData();
  };

  async fetchData() {
    const { noticeStore } = this.injected;
    await noticeStore.fetchAll();
  }

  render() {
    const { path } = this.props;

    const panes: TabLayoutPane[] = [
      {
        route: 'all',
        menuItem: (
          <Menu.Item as={Link} to="/notices/all" key="all">
            Notices
          </Menu.Item>
        ),
        render: () => <AllNotices />,
      },
      {
        route: 'archived',
        menuItem: (
          <Menu.Item as={Link} to="/notices/archived" key="archived">
            Archived
          </Menu.Item>
        ),
        render: () => <AllNotices archived />,
      },
    ];

    return <TabLayout path={path} panes={panes} activeBreadcrumb="Notices" />;
  }
}

export default withNavigation(Notices);
