import makeMobxLocation from '@servall/mobx-location';
import { capitalize, kebabCase } from 'lodash';
import { redirect } from 'react-router';
import {
  Employee,
  EmployeeStore,
  EntityReference,
  ID,
  moment,
  ShiftSwap,
  SickNotice,
  TimeOff,
} from 'sol-data';

export const isTimeshseetRegular = (categories: number[]) => {
  return !categories.length || categories.includes(7);
};

export const displayHours = (minutes = 0) =>
  `${Math.floor(minutes / 60)}:${(minutes % 60).toString().padStart(2, '0')}`;

export const days = (() => {
  const startOfWeek = moment().startOf('week');
  const intervals = [];

  for (let i = 0; i < 7; i += 1) {
    intervals.push({
      day: startOfWeek.format('dddd'),
      value: parseFloat(startOfWeek.format('d')),
    });
    startOfWeek.add(1, 'days');
  }

  return intervals;
})();

export const times = (() => {
  const startOfDay = moment().startOf('day');
  const intervals = [];

  for (let i = 0; i < 24 * 4; i += 1) {
    intervals.push({
      name: startOfDay.format('HH:mm'),
      text: startOfDay.format('HH:mm'),
      value: startOfDay.format('HH:mm'),
    });
    startOfDay.add(15, 'minutes');
  }

  return intervals;
})();

const location = makeMobxLocation({});

export const getQueryParameter = (key: string) => {
  return new URLSearchParams(location.search).get(key);
};

export const getQueryParams = (...keys: string[]) => {
  const query = new URLSearchParams(location.search);

  return keys.map((k) => query.get(k)).map((v) => (v === null ? undefined : v));
};

export const getNumQueryParam = (key: string, fallback?: number) => {
  const [value] = getQueryParams(key);

  if (value === undefined) {
    return fallback;
  }

  const parsed = parseFloat(value);

  if (Number.isNaN(parsed)) {
    return fallback;
  }

  return parsed;
};

export const addQueryParam = (name: string, value: string) => addQueryParams({ [name]: value });

export const addQueryParams = (values: Record<string, string | null>) => {
  const query = new URLSearchParams(location.search);
  const newQuery = new URLSearchParams(location.search);
  Object.entries(values).forEach(([name, value]) => {
    if (!value) {
      newQuery.delete(name);
    } else {
      newQuery.set(name, value);
    }
  });
  if (newQuery.toString() !== query.toString()) {
    redirect(`?${newQuery.toString()}`);
  }
};

export const removeQueryParam = (name: string) => {
  const query = new URLSearchParams(location.search);
  const newQuery = new URLSearchParams(location.search);

  newQuery.delete(name);

  if (newQuery.toString() !== query.toString()) {
    redirect(`?${newQuery.toString()}`);
  }
};

export const fetchMissingEmployees = async (
  store: EmployeeStore,
  entities: ({ employee: ID<Employee> | null } | (SickNotice | TimeOff | ShiftSwap))[],
) => {
  const missingEmployees = entities
    .reduce<(EntityReference<Employee> | ID<Employee>)[]>((acc, entity) => {
      if ('employee' in entity) {
        if (entity.employee && !store.has(entity.employee)) {
          return [...acc, entity.employee];
        }
        return acc;
      }

      if ('shiftFrom' in entity.$) {
        const employees = [];
        if (!store.has(entity.$.employeeSwapFrom)) {
          employees.push(entity.$.employeeSwapFrom);
        }
        if (!store.has(entity.$.employeeSwapTo)) {
          employees.push(entity.$.employeeSwapTo);
        }
        return [...acc, ...employees];
      }

      if ('shifts' in entity.$) {
        if (!store.has(entity.$.employee)) {
          return [...acc, entity.$.employee];
        }
      }
      return acc;
    }, [])
    .filter((v) => v)
    .map((v) => v);
  if (missingEmployees.length) {
    await store.fetchEmployees(missingEmployees);
  }
};

export const downloadBlob = (blob: Blob, filename: string) => {
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');

  a.href = url;
  a.download = filename || 'download';

  // Click handler that releases the object URL after the element has been clicked
  // This is required for one-off downloads of the blob content
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener('click', clickHandler);
    }, 150);
  };

  // Programmatically click the a tag to force a download in the user's browser
  a.addEventListener('click', clickHandler, false);
  a.click();

  return a;
};

export function paginate<X>(arr: X[], pageSize: number, pageNumber: number): X[] {
  return arr.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
}

export function validateNumberInput(input: string): boolean {
  const numberValue = Number(input);
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(numberValue)) {
    return false; // input is not a valid number
  }
  if (Number.isInteger(numberValue) || Number.isFinite(numberValue)) {
    return true; // input is an integer or a decimal
  }
  return false;
}

export const getErrorMessage = (err: unknown): string => {
  if (err instanceof Error) {
    if (err.message) {
      return err.message;
    }
  }
  if (err instanceof Object) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((err as Record<string, any>).message) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return (err as Record<string, any>).message;
    }
    return err.toString();
  }
  if (typeof err === 'string') {
    return err;
  }
  return 'Unknown error';
};

export const camelCaseToReadable = (value: string) =>
  capitalize(kebabCase(value).split('-').join(' '));
