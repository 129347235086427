import ModalBase from '@/components/ModalBase';
import { Card, CardGroup, Modal, Pagination, Table } from '@/semantic-ui/components';
import { useStores } from '@/store';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { Link, useParams } from 'react-router';
import { Moment, OpenShiftAlert, toNum, User } from 'sol-data';
import style from './bid-history-modal.less';

interface TableRow {
  employeeId: number;
  employeeName: string;
  bidCreationTime: string;
  startDate: string;
  seniority: string;
  read: boolean;
  delivered: boolean;
  accepted: boolean;
  acceptedAt: string;
}

interface Bid {
  sentOutDate: Moment;
  lockdownDate: Moment | undefined;
  wonByUser: User | undefined;
  openToAll: boolean;
  tableRows: TableRow[];
}

interface BidHistoryModalProps {
  open: boolean;
  onClose: () => void;
  openShiftAlert: OpenShiftAlert;
}

const RESULTS_PER_PAGE = 10;

export const BidHistoryModal = observer(
  ({ openShiftAlert, open, onClose }: BidHistoryModalProps) => {
    const { shiftStore, scheduleStore } = useStores();
    const { shiftId: shiftIdValue, scheduleId: scheduleIdValue } = useParams<{
      shiftId: string;
      scheduleId: string;
    }>();
    const shiftId = shiftIdValue ? toNum(shiftIdValue) : undefined;
    const scheduleId = scheduleIdValue ? toNum(scheduleIdValue) : undefined;
    const [activePage, setActivePage] = useState(1);
    const shift = shiftId ? shiftStore.get(shiftId) : undefined;
    const schedule = scheduleId ? scheduleStore.get(scheduleId) : undefined;
    const acceptedByUser = openShiftAlert.props.userOpenShiftAlerts?.find(
      ({ props: { accepted } }) => accepted,
    )?.props.user;
    const wasBidManuallyAssigned = !!shift?.props.employee && !acceptedByUser;
    const bid = {
      sentOutDate: openShiftAlert.props.insertDatetime,
      lockdownDate: openShiftAlert.props.event?.dueDate,
      wonByUser: acceptedByUser,
      openToAll: openShiftAlert.props.openToAll,
      tableRows:
        openShiftAlert.props.userOpenShiftAlerts?.map(
          ({ props: { user, ...userOpenShiftAlert } }) => {
            // @ts-ignore
            const employee = user?.employee;

            return {
              // @ts-ignore
              employeeId: employee.id,
              employeeName: `${user?.firstName} ${user?.lastName}`,
              bidCreationTime: userOpenShiftAlert.insertDatetime.format('MMM DD, YYYY'),
              // @ts-ignore
              startDate: employee.startDate
                ? // @ts-ignore
                  employee.startDate.format('MMM DD, YYYY')
                : 'N/A',
              // @ts-ignore
              seniority: employee.seniority ?? 'N/A',
              read: userOpenShiftAlert.read,
              delivered: userOpenShiftAlert.delivered,
              accepted: userOpenShiftAlert.accepted ?? false,
              acceptedAt: userOpenShiftAlert.accepted
                ? userOpenShiftAlert.updateDatetime?.format('MMM DD, YYYY [at] HH:mm') ?? ''
                : 'N/A',
            };
          },
        ) ?? [],
    };

    return (
      <Modal size="large" open={open} onClose={onClose}>
        <ModalBase
          header={`${schedule?.description} - ${shift?.props.startDate.format(
            'MMM DD, YYYY',
          )} Bid History`}
          actionWord="View"
          noButtons
        >
          {bid && (
            <CardGroup itemsPerRow={4}>
              <Card>
                <Card.Content>
                  <Card.Header>
                    {wasBidManuallyAssigned ? 'Manually Assigned To' : 'Bid Won By'}
                  </Card.Header>
                  <Card.Description>
                    {bid.wonByUser ? (
                      <Link
                        // @ts-ignore
                        to={`/employees/${bid.wonByUser?.employee?.id}/profile`}
                        style={{ color: '#1E6FA9' }}
                      >
                        {`${bid.wonByUser?.firstName} ${bid.wonByUser?.lastName}`}
                      </Link>
                    ) : (
                      'N/A'
                    )}
                  </Card.Description>
                </Card.Content>
              </Card>

              <Card>
                <Card.Content>
                  <Card.Header>Bid Sent Out On</Card.Header>
                  <Card.Description>
                    {bid.sentOutDate.format('MMM DD, YYYY [at] HH:mm') ?? 'N/A'}
                  </Card.Description>
                </Card.Content>
              </Card>

              <Card>
                <Card.Content>
                  <Card.Header>Bid Lockdown Date</Card.Header>
                  <Card.Description>
                    {bid.lockdownDate?.format('MMM DD, YYYY [at] HH:mm') ?? 'N/A'}
                  </Card.Description>
                </Card.Content>
              </Card>

              <Card>
                <Card.Content>
                  <Card.Header>Open To All</Card.Header>
                  <Card.Description>{bid.openToAll ? 'Yes' : 'No'}</Card.Description>
                </Card.Content>
              </Card>
            </CardGroup>
          )}

          <Table className={style.table}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Employee Name</Table.HeaderCell>
                <Table.HeaderCell>Start Date</Table.HeaderCell>
                <Table.HeaderCell>Seniority Level</Table.HeaderCell>
                <Table.HeaderCell>Read</Table.HeaderCell>
                <Table.HeaderCell>Delivered</Table.HeaderCell>
                <Table.HeaderCell>Accepted</Table.HeaderCell>
                <Table.HeaderCell>Accepted At</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {bid?.tableRows
                .slice((activePage - 1) * RESULTS_PER_PAGE, activePage * RESULTS_PER_PAGE)
                .map(
                  ({
                    employeeId,
                    employeeName,
                    startDate,
                    seniority,
                    read,
                    delivered,
                    accepted,
                    acceptedAt,
                  }) => (
                    <Table.Row
                      key={employeeName}
                      className={accepted ? style.row_selected : undefined}
                    >
                      <Table.Cell>
                        <Link to={`/employees/${employeeId}/profile`} style={{ color: '#1E6FA9' }}>
                          {employeeName}
                        </Link>
                      </Table.Cell>
                      <Table.Cell>{startDate}</Table.Cell>
                      <Table.Cell>{seniority}</Table.Cell>
                      <Table.Cell positive={read} negative={!read}>
                        {read ? 'Yes' : 'No'}
                      </Table.Cell>
                      <Table.Cell positive={delivered} negative={!delivered}>
                        {delivered ? 'Yes' : 'No'}
                      </Table.Cell>
                      <Table.Cell positive={accepted} negative={!accepted}>
                        {accepted ? 'Yes' : 'No'}
                      </Table.Cell>
                      <Table.Cell>{acceptedAt}</Table.Cell>
                    </Table.Row>
                  ),
                )}
            </Table.Body>
          </Table>

          {bid?.tableRows && (
            <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
              <Pagination
                activePage={activePage}
                totalPages={Math.ceil((bid.tableRows.length ?? 0) / RESULTS_PER_PAGE)}
                onPageChange={(_, { activePage }) => {
                  setActivePage(activePage as number);
                }}
                siblingRange={2}
                boundaryRange={0}
                firstItem={{ content: 'First' }}
                lastItem={{ content: 'Last' }}
                ellipsisItem={null}
                prevItem={null}
                nextItem={null}
              />
            </div>
          )}
        </ModalBase>
      </Modal>
    );
  },
);
