/* eslint-disable react/react-in-jsx-scope */
import { useAsyncEffect } from '@/hooks';
import { getErrorMessage } from '@/lib/utils';
import { useStores } from '@/store';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useState } from 'react';
import { useSearchParams } from 'react-router';
import { Button, Checkbox, Dimmer, Loader, Pagination } from 'semantic-ui-react';
import { IncompleteTimesheetSyncEntry } from 'sol-data';
import ButtonBar from '../ButtonBar';
import ListLayout, { ListColumn } from '../ListLayout';
import NoContentMessage from '../Schedule/NoContentMessage';
import style from './index.less';

const queryParams = {
  page: 'page',
};

export const QBReconciler = observer(() => {
  const { timesheetStore } = useStores();
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get(queryParams.page) ? Number(searchParams.get(queryParams.page)) : 1;

  const fetchData = async (pageNumber: number) => {
    return timesheetStore.fetchIncompleteTimesheetEntrySync({
      page: pageNumber,
    });
  };

  const changePage = async (pageNumber: number) => {
    if (!pageNumber || Number.isNaN(pageNumber)) {
      return;
    }

    searchParams.set(queryParams.page, pageNumber.toString());
    setSearchParams(searchParams);
    setSelectedIds([]);

    await fetchData(pageNumber);
  };

  const applyChanges = async (action: 'MARK_AS_SYNCED' | 'RELEASE_TO_SYNC') => {
    await timesheetStore.dispatchIncompleteTimesheetEntrySync({
      action,
      entryIds: selectedIds,
    });

    await changePage(1);
  };

  useAsyncEffect(async () => {
    await changePage(page);
  }, []);

  const {
    isLoadingIncompleteTimesheetEntrySync: isLoading,
    incompleteTimesheetEntrySync: data,
    errorIncompleteTimesheetEntrySync: error,
    isDispatchingIncompleteTimesheetEntrySync: isDispatching,
  } = timesheetStore;

  const columns: ListColumn<{
    data: IncompleteTimesheetSyncEntry;
    isSelected: boolean;
  }>[] = [
    {
      name: ' ',
      key: 'selected',
      customHeader: (
        <div className={style.parent_checkbox_all}>
          <Checkbox
            onChange={(_, { checked }) => {
              if (checked) {
                setSelectedIds((data?.data ?? []).map((itm) => itm.id));
                return;
              }
              setSelectedIds([]);
            }}
          />
        </div>
      ),
      render: ({ isSelected, data: { id } }) => (
        <Checkbox
          checked={isSelected}
          onChange={(_, { checked }) => {
            if (checked) {
              setSelectedIds((prev) => [...prev, id]);
            } else {
              setSelectedIds((prev) => prev.filter((itm) => itm !== id));
            }
          }}
        />
      ),
      width: '1',
    },
    {
      name: 'Sync To QuickBooks Started On',
      key: 'startDate',
      render: ({ data: { syncToQuickbooksStartedOn } }) => (
        <span>{syncToQuickbooksStartedOn.format('MMM DD, YYYY - , h:mm:ss a')}</span>
      ),
      width: '4',
    },
    {
      name: 'Start Date',
      key: 'startDate',
      render: ({ data: { startDate } }) => <span>{startDate.format('MMM DD, YYYY')}</span>,
      width: '2',
    },
    {
      name: 'Employee',
      key: 'employee',
      render: ({ data: { firstName, lastName } }) => <span>{`${lastName}, ${firstName}`}</span>,
      width: '6',
    },
  ];

  const calculatedData = (data?.data ?? []).map((itm) => ({
    data: itm,
    isSelected: !!selectedIds.includes(itm.id),
  }));
  const hasSelection = !!selectedIds.length;

  return (
    <div>
      <ListLayout
        columns={columns}
        items={calculatedData}
        onGetIsRowHighlighted={({ isSelected }) => isSelected}
        headerProps={{
          isLoading,
          errorMessage: error ? getErrorMessage(error) : undefined,
          onDismiss: () => {},
        }}
      />
      {!isLoading && !error && !calculatedData.length ? (
        <NoContentMessage type="entries" />
      ) : (
        <>
          <div className={style.pagination_parent}>
            <Pagination
              activePage={data?.meta.page ?? 1}
              totalPages={data?.meta.totalPages ?? 1}
              onPageChange={(_, { activePage }) => changePage(activePage as number)}
              siblingRange={2}
              boundaryRange={0}
              firstItem={{ content: 'First' }}
              lastItem={{ content: 'Last' }}
              ellipsisItem={null}
              prevItem={null}
              nextItem={null}
            />
          </div>
          <ButtonBar>
            <Button
              color="blue"
              type="button"
              disabled={!hasSelection}
              onClick={() => applyChanges('MARK_AS_SYNCED')}
            >
              Mark entries as synced
            </Button>
            <Button
              color="blue"
              type="button"
              disabled={!hasSelection}
              onClick={() => applyChanges('RELEASE_TO_SYNC')}
            >
              Release entries to be synced again
            </Button>
          </ButtonBar>
        </>
      )}

      {isDispatching && (
        <Dimmer active inverted>
          <Loader>Applying Updates</Loader>
        </Dimmer>
      )}
    </div>
  );
});
