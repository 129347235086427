import * as React from 'react';
import { ComponentType } from 'react';
import { Params, useLocation, useParams, useSearchParams } from 'react-router';

export interface WithNavigationProps {
  path: string;
  params: Readonly<Params<string>>;
  searchParams: URLSearchParams;
}

export const withNavigation = <T,>(Component: ComponentType<T & WithNavigationProps>) => {
  return (props: T) => {
    const location = useLocation();
    const path = location.pathname;
    const params = useParams();
    const [searchParams] = useSearchParams();

    return <Component {...props} path={path} params={params} searchParams={searchParams} />;
  };
};
