import * as React from 'react';

export const defaultConfig = {
  hasConflictFilters: true,
  hasEmployeeSearch: true,
  shift: {
    canMove: true,
    canCopy: true,
    canEdit: true,
    canUnassign: true,
    canRemove: true,
    canHaveRequests: false,
  },
  group: {
    canAssign: true,
    canAddShift: true,
    canRemove: true,
    canUnassignAllShifts: true,
  },
};

export default React.createContext(defaultConfig);
