import InjectedComponent from '@/components/InjectedComponent';
import * as React from 'react';
import { Dimmer, Loader, Pagination } from '@/semantic-ui/components';
import { EmployeeStore, WageStore } from '@/store';
import { inject, observer } from 'mobx-react';
import {
  Department,
  DepartmentStore,
  EssentialEmployeeData,
  EssentialEmployeeStatus,
  Period,
  Position,
  Wage,
  moment,
} from 'sol-data';
import { toJS } from 'mobx';
import EssentialTable from '../EssentialTable';
import style from './index.less';
import EmployeeWageModal from '../EmployeeWageModal';
import EmployeeRatesModal from '../EmployeeRatesModal';
import StepIncreaseOverviewModal from '../StepIncreaseOverviewModal';

interface Props {
  wageGrids: Wage[];
  position: Position;
  employeeId?: number;
  selectedPayPeriod: Period | undefined;
  employeeStatuses?: EssentialEmployeeStatus[];
}

interface State {
  isStepModalVisible: boolean;
  isRateModalVisible: boolean;
  isWageModalVisible: boolean;
  selectedEmployee?: EssentialEmployeeData;
  activeWageGrids: Wage[];
}

interface Injected {
  wageStore: WageStore;
  employeeStore: EmployeeStore;
  departmentStore: DepartmentStore;
}

@inject('wageStore')
@inject('employeeStore')
@inject('departmentStore')
@observer
class EssentialTab extends InjectedComponent<Props, State, Injected> {
  private readonly timerID: ReturnType<typeof setTimeout> | undefined;

  state: State = {
    isStepModalVisible: false,
    isRateModalVisible: false,
    isWageModalVisible: false,
    activeWageGrids: [],
  };

  async fetchData(page?: number) {
    const { employeeStore, departmentStore } = this.injected;
    if (page !== undefined) {
      employeeStore.setEssentialsDashboardPage(page);
    }
    await Promise.all([
      employeeStore.fetchEssentialsDashboard({
        page: page || employeeStore.essentialsDashboardPage,
        positionId: this.props.position.id,
        employeeId: this.props.employeeId,
        employeeStatuses: this.props.employeeStatuses,
        startDate: this.props.selectedPayPeriod?.props.startDate ?? moment(new Date()),
      }),
      this.getCurrentWageGrid(this.props.selectedPayPeriod),
      !departmentStore.values.length ? departmentStore.fetchAll() : undefined,
    ]);
  }

  componentDidMount = async () => {
    const { wageStore } = this.injected;
    wageStore.clearCurrent();
    await this.fetchData();
  };

  componentWillUnmount() {
    if (this.timerID) {
      clearTimeout(this.timerID);
    }
  }

  async componentDidUpdate(prevProps: Props) {
    if (
      prevProps.position.label !== this.props.position.label ||
      prevProps.employeeId !== this.props.employeeId ||
      prevProps.employeeStatuses !== this.props.employeeStatuses
    ) {
      await this.fetchData(1);
      return;
    }
    if (prevProps.selectedPayPeriod?.id !== this.props.selectedPayPeriod?.id) {
      await this.fetchData();
    }
  }

  getDepartmentByEmployee = (departments?: Department[]) => {
    if (departments) {
      const dept = departments.map(({ label }) => label).join(', ');
      return dept || '--';
    }
    return '--';
  };

  getCurrentWageGrid = async (selectedPeriod?: Period) => {
    const { wageStore } = this.injected;
    if (!wageStore.values.length) {
      await wageStore.fetchAll();
    }
    const wages = wageStore.values.filter(
      (wg) => !moment(selectedPeriod?.props.endDate || new Date()).isBefore(wg.startEffectiveDate),
    );
    this.setState({ activeWageGrids: toJS(wages) } as State);
  };

  ensureDepartmentId(dept: string) {
    const { departmentStore } = this.injected;
    const isLabels = dept.split(',').some((d) => isNaN(+d));
    if (isLabels) {
      return departmentStore.values
        .filter((dp) => {
          return dept.split(', ').includes(dp.label);
        })
        .map((d) => d.id);
    }
    return dept.split(',').map((id) => +id);
  }

  changePage(page: number) {
    const { employeeStore } = this.injected;
    employeeStore.setEssentialsDashboardPage(page);
    this.fetchData();
  }

  render() {
    const { employeeStore } = this.injected;
    return (
      <>
        {employeeStore.isFetchingEssentialsDashboard && (
          <Dimmer active inverted>
            <Loader size="large">Loading Employees Data</Loader>
          </Dimmer>
        )}
        {this.props.selectedPayPeriod && employeeStore.essentialsDashboardData && (
          <section className={style.table_section}>
            <EssentialTable
              onLinkWageModal={(employeeRow) =>
                this.setState({ selectedEmployee: employeeRow, isWageModalVisible: true })
              }
              onStepModal={(employeeRow) =>
                this.setState({ selectedEmployee: employeeRow, isStepModalVisible: true })
              }
              onRatesModal={(employeeRow) =>
                this.setState({ selectedEmployee: employeeRow, isRateModalVisible: true })
              }
              onRowEdit={(employeeRow) => this.setState({ selectedEmployee: employeeRow })}
              payPeriod={this.props.selectedPayPeriod}
            />
            <Pagination
              activePage={employeeStore.essentialsDashboardPage}
              totalPages={Math.ceil((employeeStore.essentialsDashboardData?.count ?? 0) / 20)}
              onPageChange={(_, { activePage }) => this.changePage(activePage as number)}
              siblingRange={2}
              boundaryRange={0}
              firstItem={{ content: 'First' }}
              lastItem={{ content: 'Last' }}
              ellipsisItem={null}
              prevItem={null}
              nextItem={null}
            />
          </section>
        )}
        {this.state.isStepModalVisible &&
          this.props.selectedPayPeriod &&
          this.state.selectedEmployee && (
            <StepIncreaseOverviewModal
              onClose={() => this.setState({ isStepModalVisible: false })}
              payPeriod={this.props.selectedPayPeriod?.props}
              onApproveChange={(value) =>
                this.setState({
                  selectedEmployee: value,
                  isStepModalVisible: false,
                  isRateModalVisible: true,
                })
              }
              employee={this.state.selectedEmployee}
            />
          )}
        {this.state.isRateModalVisible &&
          this.state.selectedEmployee &&
          this.props.selectedPayPeriod && (
            <EmployeeRatesModal
              open={this.state.isRateModalVisible}
              onCancel={() => this.setState({ isRateModalVisible: false })}
              selectedEmployee={this.state.selectedEmployee}
              endDate={this.props.selectedPayPeriod.props.endDate}
            />
          )}
        {this.state.isWageModalVisible &&
          this.state.selectedEmployee &&
          this.props.selectedPayPeriod && (
            <EmployeeWageModal
              open={this.state.isWageModalVisible}
              onCancel={() => this.setState({ isWageModalVisible: false })}
              employee={this.state.selectedEmployee}
              wageGrids={this.state?.activeWageGrids ?? []}
              payPeriod={this.props.selectedPayPeriod.props}
            />
          )}
      </>
    );
  }
}

export default EssentialTab;
