import * as React from 'react';
import { Pane } from '@/components/LoginForm';
import style from '../index.less';

interface Props {
  changePane: (pane: Pane) => void;
}

const ResetSuccess: React.FC<Props> = ({ changePane }) => (
  <div className={style.login_form}>
    <button type="button" onClick={() => changePane(Pane.Login)}>
      Return to login
    </button>
  </div>
);

export default ResetSuccess;
