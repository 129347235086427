import Bulletin from '@/components/Bulletin';
import { useStores } from '@/store';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { toast } from 'react-semantic-toasts';
import { Button, Input, Segment, Icon } from 'semantic-ui-react';

export const RequestPasswordReset = observer(() => {
  const [passwordResetLink, setPasswordResetLink] = useState<string>();
  const { userStore, employeeStore } = useStores();

  const handleCopy = () => {
    if (passwordResetLink) {
      navigator.clipboard.writeText(passwordResetLink);

      toast({
        type: 'success',
        icon: 'check circle',
        title: 'Password Reset Link Copied',
        description: 'The password reset link has been copied to your clipboard',
        time: 5000,
      });
    }
  };

  return (
    <>
      <Bulletin
        errorMessage={userStore.errorMessage}
        isLoading={userStore.isLoading}
        handleDismiss={userStore.clearError}
      />

      <Segment vertical basic style={{ marginBottom: '10px' }}>
        <Button
          color="blue"
          type="button"
          onClick={async () => {
            if (employeeStore.currentDetail?.user?.email) {
              const requestedPasswordLink = await userStore.requestPasswordResetManual(
                employeeStore.currentDetail?.user?.email,
              );

              setPasswordResetLink(requestedPasswordLink);
            }
          }}
        >
          Request Password Reset
        </Button>
      </Segment>

      <Segment vertical basic>
        <Input
          name="passwordResetLink"
          value={passwordResetLink}
          placeholder="Password Reset Link"
          style={{ width: '100%' }}
          icon={<Icon name="copy" link onClick={handleCopy} />}
        />
      </Segment>
    </>
  );
});
