import AppContext from '@/AppContext';
import { useAsyncEffect } from '@/hooks';
import { getEssentialsPermissions } from '@/lib/essentials-utils';
import { Column } from '@/semantic-ui/components';
import { useStores } from '@/store';
import { isNaN } from 'lodash';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useContext, useRef, useState } from 'react';
import { useSearchParams } from 'react-router';
import { Dimmer, Form, Grid, Loader, Message, Pagination, Table } from 'semantic-ui-react';
import {
  Department,
  EmployeeActiveStatus,
  employeeActiveStatuses,
  HasID,
  isStaffListOrderByField,
  IStaffEmployee,
  Position,
  StaffListOrderByField,
  toNum,
} from 'sol-data';
import { ID } from 'sol-data/src';
import Bulletin from '../Bulletin';
import EmployeeFilterDropdown from '../EmployeeFilterDropdown';
import EmployeeInformationModal from '../EmployeeInformationModal';
import PositionFilterDropdown from '../PositionFilterDropdown';
import style from './index.less';

interface Props {
  departments: Department[];
  positions: Position[];
}

interface State {
  selectedEmployee?: IStaffEmployee;
  isModalOpen: boolean;
  isSuccess: boolean;
}

const queryParams = {
  page: 'staffListPage',
  employee: 'staffListEmployee',
  position: 'staffListPosition',
  order: 'staffListOrder',
  sortBy: 'staffListSortBy',
};

export const StaffList = observer(({ departments, positions }: Props) => {
  const appContext = useContext(AppContext);
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const tableHeaderRef = useRef<HTMLTableSectionElement>(null);
  const [state, setState] = useState<State>({
    selectedEmployee: undefined,
    isModalOpen: false,
    isSuccess: false,
  });
  const { staffStore, userStore } = useStores();
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get(queryParams.page) ? Number(searchParams.get(queryParams.page)) : 1;
  const employee = searchParams.get(queryParams.employee)
    ? Number(searchParams.get(queryParams.employee))
    : undefined;
  const position = searchParams.get(queryParams.position)
    ? Number(searchParams.get(queryParams.position))
    : undefined;
  const order = searchParams.get(queryParams.order) ?? undefined;
  const sortBy = searchParams.get(queryParams.sortBy) ?? undefined;

  useAsyncEffect(async () => {
    changePage(page ? Number(page) : 1, employee, position, order, sortBy);

    window.onscroll = calculateHorizontalTableHeader;

    return () => {
      window.onscroll = null;
    };
  }, [page, employee, position, order, sortBy]);

  const fetchData = async (
    pageNumber?: number,
    employeeId?: number,
    positionId?: number,
    sortOrder?: string,
    sortByField?: string,
  ) => {
    const {
      scope: { positions },
    } = appContext;

    await staffStore.fetchStaffPage({
      pageOptions: { page: pageNumber ?? 1 },
      query: {
        employee: employeeId,
        positions: positionId ? [positionId] : positions || undefined,
        order: sortOrder === 'desc' ? 'desc' : 'asc',
        sortBy: sortByField && isStaffListOrderByField(sortBy) ? sortBy : undefined,
      },
    });
  };

  const changePage = async (
    pageNumber: number,
    employeeId?: number,
    positionId?: number,
    sortOrder?: string,
    sortByField?: string,
  ) => {
    if (!pageNumber || isNaN(pageNumber)) {
      return;
    }

    searchParams.append(queryParams.page, pageNumber.toString());
    setSearchParams(searchParams);

    await fetchData(pageNumber, employeeId, positionId, sortOrder, sortByField);
  };

  const changeDropdown = (type: string, id?: ID) => {
    const value = id ? id.toString() : undefined;

    switch (type) {
      case queryParams.employee:
        if (value) {
          searchParams.append(queryParams.employee, value);
        } else {
          searchParams.delete(queryParams.employee);
        }

        searchParams.delete(queryParams.position);
        setSearchParams(searchParams);

        return changePage(1, value ? Number(value) : undefined);
      case queryParams.position:
        if (value) {
          searchParams.append(queryParams.position, value);
        } else {
          searchParams.delete(queryParams.position);
        }

        searchParams.delete(queryParams.employee);
        setSearchParams(searchParams);

        return changePage(1, employee, value ? Number(value) : undefined, order, sortBy);
      default:
        break;
    }
  };

  const calculateHorizontalTableHeader = () => {
    if (tableContainerRef.current && tableHeaderRef.current) {
      const header = tableHeaderRef.current;
      const container = tableContainerRef.current;
      const headerTop = container.offsetTop;
      const scroll = window.scrollY;
      if (scroll > headerTop) {
        const yTranslation = Math.floor(Math.abs(scroll - headerTop)) - 1;
        header.style.setProperty('transform', `translateY(${yTranslation}px)`);
      } else {
        header.style.removeProperty('transform');
      }
    }
  };

  const handleSortChange = async (sortByValue: StaffListOrderByField) => {
    searchParams.append(queryParams.order, 'asc');
    searchParams.append(queryParams.sortBy, sortByValue);

    if (sortBy === sortByValue) {
      const newOrder = order === 'asc' ? 'desc' : 'asc';
      searchParams.append(queryParams.order, newOrder);

      setSearchParams(searchParams);

      return changePage(page, employee, position, newOrder, sortByValue);
    }

    setSearchParams(searchParams);

    return changePage(page, employee, position, order, sortByValue);
  };

  const openModal = (employee: IStaffEmployee) => {
    setState((prev) => ({ ...prev, selectedEmployee: employee, isModalOpen: true }));
  };

  const onModalClose = () => {
    setState((prev) => ({ ...prev, isModalOpen: false, selectedEmployee: undefined }));
  };

  const totalPages = staffStore.staffList?.meta.totalPages ?? 0;

  const getDepartmentLabels = (deptIds: number[]) => {
    const deptArr = departments.filter((dept) => {
      return deptIds.includes(dept.id);
    });
    return !deptArr.length ? 'N/A' : deptArr.map((d) => d.label).join(', ');
  };

  const parseBoolean = (value?: boolean) => {
    return value ? 'Yes' : 'No';
  };

  const getIcon = () => {
    return (
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.8005 12.6667C13.8005 13.0333 13.6699 13.3473 13.4085 13.6087C13.1476 13.8696 12.8339 14 12.4672 14H3.13385C2.76719 14 2.45319 13.8696 2.19185 13.6087C1.93097 13.3473 1.80052 13.0333 1.80052 12.6667L1.80052 6.71667L3.13385 8.05L3.13385 12.6667H12.4672V3.33333H7.83385L6.50052 2H12.4672C12.8339 2 13.1476 2.13044 13.4085 2.39133C13.6699 2.65267 13.8005 2.96667 13.8005 3.33333V12.6667ZM2.18385 5.21667L3.11719 4.26667L7.51719 8.66667H8.46719V7.73333L4.05052 3.31667L4.98385 2.36667L9.80052 7.16667V10H6.96719L2.18385 5.21667ZM4.98385 2.36667L2.18385 5.21667L0.517188 3.55C0.250521 3.28333 0.117188 2.96378 0.117188 2.59133C0.117188 2.21933 0.250521 1.90556 0.517188 1.65L1.46719 0.716667C1.72274 0.461111 2.03385 0.333333 2.40052 0.333333C2.76719 0.333333 3.0783 0.461111 3.33385 0.716667L4.98385 2.36667Z"
          fill="black"
        />
      </svg>
    );
  };

  const getEmergencyContactName = (firstName?: string, lastName?: string) => {
    if (!firstName && !lastName) {
      return 'N/A';
    }
    return `${lastName}, ${firstName}`;
  };

  const renderTableHeader = () => {
    return [
      <Table.HeaderCell className={`markBackTable ${style.table_header_fixed}`}>
        <div
          aria-hidden="true"
          className={style.sort_action}
          onClick={() => handleSortChange('department')}
        >
          DEPARTMENT
        </div>
      </Table.HeaderCell>,
      <Table.HeaderCell className={`markBackTable ${style.table_header_fixed}`}>
        <div
          aria-hidden="true"
          className={style.sort_action}
          onClick={() => handleSortChange('fte')}
        >
          FTE
        </div>
      </Table.HeaderCell>,
      <Table.HeaderCell className={`markBackTable ${style.table_header_fixed}`}>
        <div aria-hidden="true">VACATION %</div>
      </Table.HeaderCell>,
      <Table.HeaderCell className={`markBackTable ${style.table_header_fixed}`}>
        <div
          aria-hidden="true"
          className={style.sort_action}
          onClick={() => handleSortChange('phone')}
        >
          PHONE
        </div>
      </Table.HeaderCell>,
      <Table.HeaderCell className={`markBackTable ${style.table_header_fixed}`}>
        <div
          aria-hidden="true"
          className={style.sort_action}
          onClick={() => handleSortChange('email')}
        >
          EMAIL
        </div>
      </Table.HeaderCell>,
      <Table.HeaderCell className={`markBackTable ${style.table_header_fixed}`}>
        <div
          aria-hidden="true"
          className={style.sort_action}
          onClick={() => handleSortChange('dob')}
        >
          DOB
        </div>
      </Table.HeaderCell>,
      <Table.HeaderCell className={`markBackTable ${style.table_header_fixed}`}>
        <div
          aria-hidden="true"
          className={style.sort_action}
          onClick={() => handleSortChange('startDate')}
        >
          START DATE
        </div>
      </Table.HeaderCell>,
      <Table.HeaderCell className={`markBackTable ${style.table_header_fixed}`}>
        <div
          aria-hidden="true"
          className={style.sort_action}
          onClick={() => handleSortChange('probationEnd')}
        >
          PROBATION END
        </div>
      </Table.HeaderCell>,
      <Table.HeaderCell className={`markBackTable ${style.table_header_fixed}`}>
        <div
          aria-hidden="true"
          className={style.sort_action}
          onClick={() => handleSortChange('lastDay')}
        >
          LAST DAY
        </div>
      </Table.HeaderCell>,
      <Table.HeaderCell className={`markBackTable ${style.table_header_fixed}`}>
        <div
          aria-hidden="true"
          className={style.sort_action}
          onClick={() => handleSortChange('status')}
        >
          STATUS
        </div>
      </Table.HeaderCell>,
      <Table.HeaderCell className={`markBackTable ${style.table_header_fixed}`}>
        <div
          aria-hidden="true"
          className={style.sort_action}
          onClick={() => handleSortChange('statusNotes')}
        >
          STATUS NOTES
        </div>
      </Table.HeaderCell>,
      <Table.HeaderCell className={`markBackTable ${style.table_header_fixed}`}>
        <div
          aria-hidden="true"
          className={style.sort_action}
          onClick={() => handleSortChange('emergencyContact')}
        >
          EMERGENCY CONTACT
        </div>
      </Table.HeaderCell>,
      <Table.HeaderCell className={`markBackTable ${style.table_header_fixed}`}>
        <div
          aria-hidden="true"
          className={style.sort_action}
          onClick={() => handleSortChange('contactNumber')}
        >
          CONTACT NUMBER
        </div>
      </Table.HeaderCell>,
      <Table.HeaderCell className={`markBackTable ${style.table_header_fixed}`}>
        <div
          aria-hidden="true"
          className={style.sort_action}
          onClick={() => handleSortChange('orientation')}
        >
          ORIENTATION
        </div>
      </Table.HeaderCell>,
      <Table.HeaderCell className={`markBackTable ${style.table_header_fixed}`}>
        <div
          aria-hidden="true"
          className={style.sort_action}
          onClick={() => handleSortChange('newHirePaperwork')}
        >
          NEW HIRE PAPERWORK
        </div>
      </Table.HeaderCell>,
      <Table.HeaderCell className={`markBackTable ${style.table_header_fixed}`}>
        <div
          aria-hidden="true"
          className={style.sort_action}
          onClick={() => handleSortChange('agreementBook')}
        >
          AGREEMENT BOOK
        </div>
      </Table.HeaderCell>,
      <Table.HeaderCell className={`markBackTable ${style.table_header_fixed}`}>
        <div
          aria-hidden="true"
          className={style.sort_action}
          onClick={() => handleSortChange('jobDescription')}
        >
          JOB DESCRIPTION
        </div>
      </Table.HeaderCell>,
      <Table.HeaderCell className={`markBackTable ${style.table_header_fixed}`}>
        <div
          aria-hidden="true"
          className={style.sort_action}
          onClick={() => handleSortChange('criminalCheck')}
        >
          CRIMINAL CHECK
        </div>
      </Table.HeaderCell>,
      <Table.HeaderCell className={`markBackTable ${style.table_header_fixed}`}>
        <div
          aria-hidden="true"
          className={style.sort_action}
          onClick={() => handleSortChange('identification')}
        >
          IDENTIFICATION
        </div>
      </Table.HeaderCell>,
      <Table.HeaderCell className={`markBackTable ${style.table_header_fixed}`}>
        <div
          aria-hidden="true"
          className={style.sort_action}
          onClick={() => handleSortChange('benefits')}
        >
          BENEFITS
        </div>
      </Table.HeaderCell>,
      <Table.HeaderCell className={`markBackTable ${style.table_header_fixed}`}>
        <div
          aria-hidden="true"
          className={style.sort_action}
          onClick={() => handleSortChange('licensePermit')}
        >
          LICENSE PERMIT
        </div>
      </Table.HeaderCell>,
      <Table.HeaderCell className={`markBackTable ${style.table_header_fixed}`}>
        <div
          aria-hidden="true"
          className={style.sort_action}
          onClick={() => handleSortChange('foodSafety')}
        >
          FOOD SAFETY
        </div>
      </Table.HeaderCell>,
      <Table.HeaderCell className={`markBackTable ${style.table_header_fixed}`}>
        <div
          aria-hidden="true"
          className={style.sort_action}
          onClick={() => handleSortChange('whmis')}
        >
          WHMIS
        </div>
      </Table.HeaderCell>,
      <Table.HeaderCell className={`markBackTable ${style.table_header_fixed}`}>
        <div
          aria-hidden="true"
          className={style.sort_action}
          onClick={() => handleSortChange('firstAid')}
        >
          FIRST AID
        </div>
      </Table.HeaderCell>,
      <Table.HeaderCell className={`markBackTable ${style.table_header_fixed}`}>
        <div
          aria-hidden="true"
          className={style.sort_action}
          onClick={() => handleSortChange('fireSafety')}
        >
          FIRE SAFETY
        </div>
      </Table.HeaderCell>,
      <Table.HeaderCell className={`markBackTable ${style.table_header_fixed}`}>
        <div
          aria-hidden="true"
          className={style.sort_action}
          onClick={() => handleSortChange('fluShot')}
        >
          FLU SHOT
        </div>
      </Table.HeaderCell>,
      <Table.HeaderCell className={`markBackTable ${style.table_header_fixed}`}>
        <div
          aria-hidden="true"
          className={style.sort_action}
          onClick={() => handleSortChange('covid191')}
        >
          COVID 19-1
        </div>
      </Table.HeaderCell>,
      <Table.HeaderCell className={`markBackTable ${style.table_header_fixed}`}>
        <div
          aria-hidden="true"
          className={style.sort_action}
          onClick={() => handleSortChange('covid192')}
        >
          COVID 19-2
        </div>
      </Table.HeaderCell>,
      <Table.HeaderCell className={`markBackTable ${style.table_header_fixed}`}>
        <div
          aria-hidden="true"
          className={style.sort_action}
          onClick={() => handleSortChange('covid193')}
        >
          COVID 19-3
        </div>
      </Table.HeaderCell>,
    ];
  };

  const renderEmployeeTableHeader = () => {
    return (
      <Table.HeaderCell className={`markBackTable ${style.table_header_fixed}`}>
        <div
          aria-hidden="true"
          className={style.sort_action}
          onClick={() => handleSortChange('description')}
        >
          EMPLOYEE NAME
        </div>
      </Table.HeaderCell>
    );
  };

  const renderEmployeeTableBody = () => {
    const permissions = getEssentialsPermissions(userStore.current);

    return (staffStore.staffList?.data ?? []).map(({ props: employee }) => {
      return (
        <Table.Row key={employee.id}>
          <Table.Cell textAlign="center" className={style.employee_name_container}>
            <div className={style.employee_name_cell}>
              <p>
                {employee.user.lastName},{employee.user.firstName}
              </p>
            </div>
            {permissions.read && (
              <div className={style.employee_name_button}>
                <div onClick={() => openModal(employee)} className={style.iconBtn}>
                  {getIcon()}
                </div>
              </div>
            )}
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  const renderTableBody = () => {
    return (staffStore.staffList?.data || []).map(({ props: employee }) => {
      const phone = employee.user.phoneNumbers?.[0]?.phoneNumber || 'N/A';
      const ecFistName = employee.emergencyContacts?.firstName;
      const ecLastName = employee.emergencyContacts?.lastName;
      const ecPhone = employee.emergencyContacts?.phone;
      const vacationAccrualPercentage =
        employee.vacationAccrualPercentage ?? employee.positionVacationAccrualPercentage;
      const vacationAccrualPercentageText = vacationAccrualPercentage
        ? `${vacationAccrualPercentage}%`
        : '-';
      const { status } = employee;
      const isInactive = !!(employee.deleteDatetime || status !== EmployeeActiveStatus.Active);
      return (
        <Table.Row key={employee.id} className={isInactive && style.row_inactive}>
          <Table.Cell textAlign="center" className={style.table_header_fixed}>
            {getDepartmentLabels(employee.departments.map((d: HasID) => d.id))}
          </Table.Cell>
          <Table.Cell textAlign="center">{employee.fte}</Table.Cell>
          <Table.Cell textAlign="center">{vacationAccrualPercentageText}</Table.Cell>
          <Table.Cell textAlign="center" className={style.medium_column}>
            {phone}
          </Table.Cell>
          <Table.Cell textAlign="center" className={style.long_column}>
            {employee.user.email}
          </Table.Cell>
          <Table.Cell textAlign="center">{employee.user.dateOfBirth?.format('L')}</Table.Cell>
          <Table.Cell textAlign="center">{employee.startDate?.format('L')}</Table.Cell>
          <Table.Cell textAlign="center">
            {employee.probationEndAt?.format('L') || 'N/A'}
          </Table.Cell>
          <Table.Cell textAlign="center">{employee.lastDay?.format('L') || 'N/A'}</Table.Cell>
          <Table.Cell textAlign="center" className={style.medium_column}>
            {employeeActiveStatuses.find((itm) => itm.id === employee.status)?.name}
          </Table.Cell>
          <Table.Cell
            textAlign="center"
            className={`${style.long_column} ${style.txt_small} ${style.fixed_h}`}
          >
            {employee.statusChange}
          </Table.Cell>
          <Table.Cell textAlign="center" className={style.medium_column}>
            {getEmergencyContactName(ecFistName, ecLastName)}
          </Table.Cell>
          <Table.Cell textAlign="center" className={style.medium_column}>
            {ecPhone || 'N/A'}
          </Table.Cell>

          <Table.Cell textAlign="center">
            {parseBoolean(employee.certificates?.orientation)}
          </Table.Cell>
          <Table.Cell textAlign="center">
            {parseBoolean(employee.certificates?.newHirePaperwork)}
          </Table.Cell>
          <Table.Cell textAlign="center">
            {parseBoolean(employee.certificates?.agreementBook)}
          </Table.Cell>
          <Table.Cell textAlign="center">
            {parseBoolean(employee.certificates?.jobDescription)}
          </Table.Cell>
          <Table.Cell textAlign="center">
            {parseBoolean(employee.certificates?.criminalCheck)}
          </Table.Cell>
          <Table.Cell textAlign="center">
            {parseBoolean(employee.certificates?.identification)}
          </Table.Cell>
          <Table.Cell textAlign="center">
            {parseBoolean(employee.certificates?.benefits)}
          </Table.Cell>
          <Table.Cell textAlign="center">
            {parseBoolean(employee.certificates?.licensePermit)}
          </Table.Cell>
          <Table.Cell textAlign="center">
            {parseBoolean(employee.certificates?.foodSafety)}
          </Table.Cell>
          <Table.Cell textAlign="center">{parseBoolean(employee.certificates?.whmis)}</Table.Cell>
          <Table.Cell textAlign="center">
            {parseBoolean(employee.certificates?.firstAid)}
          </Table.Cell>
          <Table.Cell textAlign="center">
            {parseBoolean(employee.certificates?.fireSafetyEmergency)}
          </Table.Cell>
          <Table.Cell textAlign="center">{parseBoolean(employee.certificates?.fluShot)}</Table.Cell>
          <Table.Cell textAlign="center">{parseBoolean(employee.certificates?.covid)}</Table.Cell>
          <Table.Cell textAlign="center">{parseBoolean(employee.certificates?.covid)}</Table.Cell>
          <Table.Cell textAlign="center">{parseBoolean(employee.certificates?.covid)}</Table.Cell>
        </Table.Row>
      );
    });
  };

  if (staffStore.isLoadingStaffList && !staffStore.staffList)
    return (
      <Dimmer active inverted>
        <Loader size="large">Loading Employees</Loader>
      </Dimmer>
    );

  return (
    <div>
      {state.isSuccess && (
        <Message
          success
          header="Employee information saved successfully"
          onDismiss={() => {
            setState((prev) => ({ ...prev, isSuccess: false }));
          }}
        />
      )}
      <div className={style.header}>
        <Column className={style.header_employee_search}>
          <Form>
            <Form.Field>
              <EmployeeFilterDropdown
                search
                clearable
                name="employee"
                placeholder="Search by employee"
                value={employee ? toNum(employee) : undefined}
                onChange={(_, id) => changeDropdown(queryParams.employee, id)}
              />
            </Form.Field>
          </Form>
        </Column>
        <Column width={5} />
        <Column className={style.header_item}>
          <Form>
            <Form.Field>
              <PositionFilterDropdown
                limited
                clearable
                name="position"
                placeholder="Filter by position"
                positionId={position ? toNum(position) : undefined}
                onChange={(_, id) => changeDropdown(queryParams.position, id)}
              />
            </Form.Field>
          </Form>
        </Column>
      </div>
      {staffStore.errorStaffList && <Bulletin error={staffStore.errorStaffList} />}
      <div className={style.tables_container}>
        <div>
          <Table className={style.t_left}>
            <Table.Header>
              <Table.Row textAlign="center">{renderEmployeeTableHeader()}</Table.Row>
            </Table.Header>
            <Table.Body>{renderEmployeeTableBody()}</Table.Body>
          </Table>
        </div>
        <div className={style.table_scrollable_x} ref={tableContainerRef}>
          <Table className={style.t_right} striped>
            <thead ref={tableHeaderRef}>
              <Table.Row textAlign="center">{renderTableHeader()}</Table.Row>
            </thead>
            <Table.Body>{renderTableBody()}</Table.Body>
          </Table>
        </div>
        {state.isModalOpen && state.selectedEmployee && (
          <EmployeeInformationModal
            open={state.isModalOpen}
            onClose={onModalClose}
            employee={state.selectedEmployee}
            department={getDepartmentLabels(state.selectedEmployee.departments.map((d) => d.id))}
            onSubmit={async () => {
              await changePage(page, employee, position, order, sortBy);
              onModalClose();
            }}
            positions={positions}
          />
        )}
      </div>
      <Grid centered>
        <Grid.Row>
          <Pagination
            activePage={page}
            totalPages={totalPages}
            onPageChange={(_, { activePage }) =>
              changePage(activePage as number, employee, position, order, sortBy)
            }
            siblingRange={2}
            boundaryRange={0}
            firstItem={{ content: 'First' }}
            lastItem={{ content: 'Last' }}
            ellipsisItem={null}
            prevItem={null}
            nextItem={null}
          />
        </Grid.Row>
      </Grid>
    </div>
  );
});
