import * as React from 'react';
import { inject, observer } from 'mobx-react';

import FilterDropdown from '@/components/FilterDropdown';
import { EmployeeStore } from '@/store';
import AppContext from '@/AppContext';
import InjectedComponent from '../InjectedComponent';

interface Props {
  name: string;
  placeholder: string;
  value?: number;
  onChange: (name: string, value: number) => void;
  search?: boolean;
  clearable?: boolean;
  limited?: boolean;
}

interface Injected {
  employeeStore: EmployeeStore;
}

@inject('employeeStore')
@observer
class EmployeeFilterDropdown extends InjectedComponent<Props, {}, Injected> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  componentDidMount() {
    const { employeeStore } = this.injected;
    if (!employeeStore.employeeNames?.length) {
      employeeStore.fetchEmployeeNames();
    }
  }

  render() {
    const {
      name,
      placeholder,
      value,
      onChange,
      search,
      clearable,
      limited,
      employeeStore: { dropdownOptions, values: employees, fetchAllPaginated },
    } = this.injected;

    const {
      scope: { departments: limitedDepartments, positions: limitedPositions },
    } = this.context;

    let employeeOptions = dropdownOptions;

    if (limited) {
      const limits = employees.reduce<number[]>((acc, { id, position, departments }) => {
        const deps = departments.map(({ id }) => id);

        if (limitedPositions && !limitedPositions.includes(position.id)) {
          return acc;
        }

        if (limitedDepartments && !limitedDepartments.some((id) => deps.includes(id))) {
          return acc;
        }

        return acc.concat(id);
      }, []);

      employeeOptions = dropdownOptions.filter(({ value }) => limits.includes(value));
    }

    return (
      <FilterDropdown
        name={name || 'employees'}
        placeholder={placeholder || 'Select an employee'}
        value={value}
        options={employeeOptions}
        changeDropdown={onChange}
        search={search}
        clearable={clearable}
      />
    );
  }
}

export default EmployeeFilterDropdown;
