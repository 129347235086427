import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { EmployeeStore, PayrollStore } from '@/store';
import { moment, HasID } from 'sol-data';
import { useAsyncEffect } from '@/hooks';

interface Injected {
  employeeStore: EmployeeStore;
  payrollStore: PayrollStore;
}

interface Props {
  data: CostCalculationErrorData;
}

// prettier-ignore
export type CostCalculationErrorData =
  // missing payroll item
  | { currentMinute: string; applicableCategories: HasID[] }
  // missing employee rate
  | { employee: number; matchingItems: HasID[]; rates: HasID[] }

export const getCostCalcError = (error: any) => {
  const { data } = error;

  if (data && ('applicableCategories' in data || 'matchingItems' in data)) {
    return data as CostCalculationErrorData;
  }
};

const CostCalcError: React.FC<Props & Injected> = ({ data, employeeStore, payrollStore }) => {
  useAsyncEffect(async () => {
    if ('matchingItems' in data) {
      await employeeStore.fetch(data.employee);
    }
  }, []);

  if ('matchingItems' in data) {
    const employeeName =
      employeeStore.has(data.employee) && employeeStore.forceGet(data.employee).user.fullName;

    return (
      <span>
        <p>An error occurred while trying to calculate costs.</p>

        <p>{employeeName} doesn't currently have an applicable rate for a shift.</p>

        <p>
          This employee should have <b>one of</b> the following rates in QuickBooks:
        </p>

        <ul>
          {data.matchingItems &&
            data.matchingItems.map((cat) => (
              <li key={cat.id}>
                {payrollStore.items.has(cat) && payrollStore.items.forceGet(cat).description}
              </li>
            ))}
        </ul>
      </span>
    );
  }

  if ('currentMinute' in data) {
    const currentMinute = moment(data.currentMinute);
    const categoriesDescriptions = data.applicableCategories
      .map((cat) => {
        return (
          payrollStore.categories.has(cat) && payrollStore.categories.forceGet(cat).description
        );
      })
      .filter((v) => !!v) as string[];

    return (
      <span>
        {
          "An error occurred while trying to assign. Currently there isn't a payroll item that includes "
        }
        {currentMinute.format('MMM D HH:mm')}
        {' with '}
        {categoriesDescriptions.join(', ')}
        {categoriesDescriptions.length > 1 ? ' categories' : ' category'}. Please correct the timing
        of payroll items, or enter an item into QuickBooks.
      </span>
    );
  }

  return <span>An error has occurred while trying to calculate assignment costs</span>;
};

export default inject<Injected>(({ employeeStore, payrollStore }) => ({
  employeeStore,
  payrollStore,
}))(observer(CostCalcError));
