import * as React from 'react';
import {
  AreaStore,
  DepartmentStore,
  EmployeeStore,
  PayrollStore,
  PositionStore,
  RequestsStore,
  RotationStore,
  ScheduleStore,
  ShiftPlanStore,
  ShiftStore,
  ShiftSwapStore,
  SickNoticeStore,
  TimeOffStore,
  TimesheetStore,
  UserStore,
  NoticeStore,
  EntityMap,
  EducationalDocumentStore,
  WageStore,
  StaffStore,
  SupplementaryRateStore,
  AlertStore,
} from 'sol-data';

import {
  authService,
  authApi,
  organizationApi,
  schedulingApi,
  schedulingApi2,
  messagingApi,
  messagingApi2,
  payrollApi,
  organizationApi2,
} from '@/api';
import { ScheduleComponentStore } from './schedule-component-store';

export const entityMap = new EntityMap();

const alertStore = new AlertStore(messagingApi2);
const areaStore = new AreaStore(organizationApi);
const departmentStore = new DepartmentStore(organizationApi);
const employeeStore = new EmployeeStore(organizationApi2, authApi);
const payrollStore = new PayrollStore(payrollApi);
const positionStore = new PositionStore(organizationApi, employeeStore);
const rotationStore = new RotationStore(organizationApi);
const scheduleComponentStore = new ScheduleComponentStore();
const scheduleStore = new ScheduleStore(schedulingApi2);
const shiftPlanStore = new ShiftPlanStore(schedulingApi);
const shiftStore = new ShiftStore(schedulingApi2, employeeStore, entityMap);
const shiftSwapStore = new ShiftSwapStore(schedulingApi2, messagingApi, entityMap);
const sickNoticeStore = new SickNoticeStore(schedulingApi2, entityMap);
const timeOffStore = new TimeOffStore(schedulingApi2, entityMap);
const timesheetStore = new TimesheetStore(payrollApi);
const staffStore = new StaffStore(organizationApi);
const userStore = new UserStore(authService, authApi);
const supplementaryRateStore = new SupplementaryRateStore(organizationApi);
const requestsStore = new RequestsStore(
  schedulingApi2,
  sickNoticeStore,
  timeOffStore,
  shiftSwapStore,
);
const noticeStore = new NoticeStore(organizationApi2, messagingApi);
const educationDocumentStore = new EducationalDocumentStore(organizationApi2);
const wageStore = new WageStore(organizationApi);

entityMap.addAll([
  areaStore,
  departmentStore,
  employeeStore,
  employeeStore.userLookup,
  payrollStore.items,
  payrollStore.holidays,
  payrollStore.limits,
  positionStore,
  rotationStore,
  scheduleStore,
  shiftPlanStore,
  shiftStore,
  shiftSwapStore,
  sickNoticeStore,
  timeOffStore,
  timesheetStore,
  noticeStore,
  educationDocumentStore,
  wageStore,
  supplementaryRateStore,
  alertStore,
]);

export const stores = {
  areaStore,
  departmentStore,
  employeeStore,
  payrollStore,
  positionStore,
  rotationStore,
  scheduleComponentStore,
  scheduleStore,
  shiftPlanStore,
  shiftStore,
  shiftSwapStore,
  sickNoticeStore,
  timeOffStore,
  timesheetStore,
  userStore,
  requestsStore,
  noticeStore,
  educationDocumentStore,
  wageStore,
  staffStore,
  supplementaryRateStore,
  alertStore,
};

export {
  AreaStore,
  DepartmentStore,
  EmployeeStore,
  PayrollStore,
  PositionStore,
  RequestsStore,
  RotationStore,
  ScheduleComponentStore,
  ScheduleStore,
  ShiftPlanStore,
  ShiftStore,
  ShiftSwapStore,
  SickNoticeStore,
  TimeOffStore,
  TimesheetStore,
  UserStore,
  NoticeStore,
  EducationalDocumentStore,
  WageStore,
  staffStore,
  SupplementaryRateStore,
  AlertStore,
};

export const StoresContext = React.createContext<typeof stores | null>(null);

export const useStores = () => {
  const stores = React.useContext(StoresContext);

  if (!stores) {
    throw new Error('stores context was not found - StoreProvider was not used');
  }

  return stores;
};

// cheeky addition to `window` for dev, helpful to see state updates easily
if (process.env.NODE_ENV === 'development') {
  (window as any).stores = stores;
}
