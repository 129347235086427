import InjectedComponent from '@/components/InjectedComponent';
import { WageStore, SupplementaryRateStore } from '@/store';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Segment } from '@/semantic-ui/components';
import { withNavigation, WithNavigationProps } from '@/components/Route';
import Breadcrumbs from '@/components/Breadcrumbs';
import ListLayout, { ListColumn } from '@/components/ListLayout';
import ButtonBar from '@/components/ButtonBar';
import AddButton from '@/components/AddButton';
import WageModal from '@/components/WageModal';
import { IWage, UserStore, moment } from 'sol-data';
import { getEssentialsPermissions } from '@/lib/essentials-utils';

interface Props extends WithNavigationProps {}

interface State {
  searchInput: string;
  modalOpen: boolean;
  wagesGrid: IWage[];
}

interface Injected {
  wageStore: WageStore;
  supplementaryRateStore: SupplementaryRateStore;
  userStore: UserStore;
}

@inject('wageStore', 'supplementaryRateStore', 'userStore')
@observer
class Wage extends InjectedComponent<Props, {}, Injected> {
  state: State = { searchInput: '', modalOpen: false, wagesGrid: [] };

  componentDidMount = async () => {
    await this.fetchData();
  };

  async fetchData() {
    const { wageStore, supplementaryRateStore } = this.injected;
    supplementaryRateStore.fetchAll();
    const data = await wageStore.fetchAll();
    this.setState({ wagesGrid: data });
  }

  handleSearch = (value: string) => {
    this.setState({ searchInput: value });
  };

  handleNewWageGrid = async (data: IWage) => {
    const { wageStore } = this.injected;
    await wageStore.create(data);
    await this.fetchData();
    this.setState({ modalOpen: false });
  };

  handleRemoveWageGrid = async (id: number) => {
    const { wageStore } = this.injected;
    await wageStore.delete(id);
    await this.fetchData();
    this.setState({ modalOpen: false });
  };

  closeNewWageGrid = () => {
    this.setState({ modalOpen: false });
  };

  openModalWageGrid = () => {
    this.setState({ modalOpen: true });
  };

  render() {
    const { modalOpen, wagesGrid } = this.state;
    const permissions = getEssentialsPermissions(this.injected.userStore.current);

    const columns: ListColumn[] = [
      {
        name: 'WAGE GRID NAME',
        key: 'name',
        width: { sm: 5, lg: 5 },
      },
      {
        name: 'STEPS',
        key: 'steps',
        width: { sm: 2, lg: 2 },
        textAlign: 'center',
      },
      {
        name: 'CREATED ON',
        key: 'createdAt',
        width: { sm: 4, lg: 4 },
        textAlign: 'center',
      },
      {
        name: 'EFFECTIVE DATE',
        key: 'effectiveDate',
        width: { sm: 5, lg: 5 },
        textAlign: 'center',
      },
    ];

    const { searchLabel, errorMessage, onSearchChange, onDismiss, isLoading } = {
      errorMessage: '',
      onDismiss: () => null,
      onSearchChange: (searchText: string): void => {
        this.handleSearch(searchText);
      },
      searchLabel: 'Search',
      isLoading: false,
    };

    const headerProps = {
      errorMessage,
      onDismiss,
      onSearchChange,
      searchLabel,
      isLoading,
    };

    return (
      <Segment basic padded="very" className="wage-container">
        <Breadcrumbs activeBreadcrumb="Wages Grid" />
        <ListLayout
          columns={columns}
          headerProps={headerProps}
          items={wagesGrid
            .filter((wage) =>
              wage.name.toLowerCase().includes(this.state.searchInput.toLowerCase()),
            )
            .map((wage) => {
              return {
                ...wage,
                createdAt: `${moment(wage.createdAt).format('YYYY-MM-DD')}`,
                effectiveDate: `${moment(wage.startEffectiveDate).format('YYYY-MM-DD')} - ${moment(
                  wage.scalations?.length
                    ? wage.scalations
                        .sort(
                          (escaltionA, escaltionB) => (escaltionA.id || 0) - (escaltionB.id || 0),
                        )
                        .slice(-1)[0].endScalationDate
                    : wage.endEffectiveDate,
                ).format('YYYY-MM-DD')}`,
              };
            })}
          link={({ id }) => `/essentials/wagegrid/${id}`}
        />
        {permissions.write && (
          <ButtonBar>
            <AddButton onAdd={this.openModalWageGrid} addLabel={'New Wage Grid'} />
          </ButtonBar>
        )}
        {modalOpen && (
          <WageModal
            onConfirm={(data: IWage) => this.handleNewWageGrid(data)}
            onCancel={this.closeNewWageGrid}
            label={'Wages'}
            open={modalOpen}
          />
        )}
      </Segment>
    );
  }
}

export default withNavigation(Wage);
