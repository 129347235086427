import { FormikProps } from 'formik';
import { EmployeeActiveStatus, IStaffEmployee } from 'sol-data';
import * as yup from 'yup';

export const employeeCertificatesSchema = yup.object({
  id: yup.number().notRequired(),
  orientation: yup.boolean().required(),
  jobDescription: yup.boolean().required(),
  benefits: yup.boolean().required(),
  newHirePaperwork: yup.boolean().required(),
  criminalCheck: yup.boolean().required(),
  licensePermit: yup.boolean().required(),
  agreementBook: yup.boolean().required(),
  identification: yup.boolean().required(),
  foodSafety: yup.boolean().required(),
  whmis: yup.boolean().required(),
  firstAid: yup.boolean().required(),
  fireSafetyEmergency: yup.boolean().required(),
  fluShot: yup.boolean().required(),
  covid: yup.boolean().required(),
  notes: yup.string(),
});

export type EmployeeCertificatesValues = ReturnType<typeof employeeCertificatesSchema.validateSync>;

function validateValueIfObjectNotEmpty(this: yup.TestContext, value: any) {
  if (!value) {
    if (this.parent && typeof this.parent === 'object') {
      return !Object.values(this.parent).find((itm) => !!itm);
    }
    return false;
  }
  return true;
}

export const employeeEmergencyContactsSchema = yup.object({
  firstName: yup
    .string()
    .test('required', 'Enter the contact first name', validateValueIfObjectNotEmpty),
  lastName: yup
    .string()
    .test('required', 'Enter the contact last name', validateValueIfObjectNotEmpty),
  phone: yup.string().test('required', 'Enter the contact phone', validateValueIfObjectNotEmpty),
  email: yup
    .string()
    .email()
    .test('required', 'Enter the contact email', validateValueIfObjectNotEmpty),
  id: yup.number().notRequired(),
});

export const phoneNumberSchema = yup.object({
  id: yup.number().notRequired(),
  phoneNumber: yup.string().required().label('Phone number'),
  type: yup.mixed().oneOf(['home', 'mobile']).required().label('Type'),
});

export const employeeProfileSchema = yup.object({
  startDate: yup.string().typeError('Date must be in MM/DD/YYYY format').nullable(),
  probationEndAt: yup.string().typeError('Date must be in MM/DD/YYYY format').nullable(),
  lastDay: yup.string().typeError('Date must be in MM/DD/YYYY format').nullable(),
  fte: yup.string(),
  statusChange: yup.string(),
  vacationAccrualPercentage: yup.number().nullable(),
  notes: yup.string().nullable(),
  status: yup.mixed().oneOf(Object.values(EmployeeActiveStatus)),
  phoneNumbers: yup.array(phoneNumberSchema).required(),
});

export type EmployeeProfileValues = ReturnType<typeof employeeProfileSchema.validateSync>;

export const employeeEditSchema = yup.object({
  certificate: employeeCertificatesSchema.required(),
  emergencyContacts: employeeEmergencyContactsSchema.required(),
  profile: employeeProfileSchema.required(),
});

export type EmployeeEditValues = ReturnType<typeof employeeEditSchema.validateSync>;

export interface EmployeeContainerTabProps {
  formProps: FormikProps<EmployeeEditValues>;
  employee: IStaffEmployee;
  readOnly: boolean;
}
