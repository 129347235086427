import * as React from 'react';
import { Button } from 'semantic-ui-react';
import style from '@/components/ButtonBar/index.less';

interface Props {
  onAdd: () => void;
  addLabel: string;
}

const AddButton: React.FC<Props> = ({ onAdd, addLabel }) => {
  return (
    <div className={style.buttons_container}>
      <Button color="blue" type="button" onClick={onAdd} className={style.confirm_button}>
        Add {addLabel}
      </Button>
    </div>
  );
};

export default AddButton;
