/* eslint-disable @typescript-eslint/no-unused-expressions */
import InjectedComponent from '@/components/InjectedComponent';
import { RangeTimesheetDate } from '@/containers/Essentials';
import { getEssentialsPermissions } from '@/lib/essentials-utils';
import { camelCaseToReadable } from '@/lib/utils';
import {
  Button,
  Checkbox,
  Dimmer,
  Form,
  Input,
  Loader,
  Menu,
  Modal,
  Tab,
} from '@/semantic-ui/components';
import * as classNames from 'classnames';
import { Formik } from 'formik';
import { round } from 'lodash';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Link } from 'react-router';
import {
  EmployeeStepSummaryItem,
  EmployeeStore,
  EssentialEmployee,
  EssentialEmployeeData,
  Moment,
  UserStore,
  Wage,
  WageEmployeeUpdateValues,
  WageStore,
  moment,
} from 'sol-data';
import * as yup from 'yup';
import Bulletin from '../Bulletin';
import { SingleDatePicker } from '../SingleDatePicker';
import style from './index.less';

interface Props {
  open: boolean;
  onCancel: () => void;
  employee: EssentialEmployeeData;
  wageGrids: Wage[];
  payPeriod: RangeTimesheetDate;
}

const wageEditSchema = yup.object({
  lsrpRateEnable: yup.bool().default(false),
  personalWage: yup.bool().default(false),
  professionalIncrement: yup.number().min(0).nullable().default(0),
  salaryType: yup.mixed().oneOf(['salary', 'hourly']).default('hourly'),
  wageRate: yup.number().min(0).nullable(),
  wageId: yup.number().nullable().default(null),
  lsrpRate: yup.number().default(0),
  wagePersonalId: yup.number().nullable().default(null),
  stepNumber: yup.number().min(1).required(),
  workedHours: yup.number().min(0).required(),
  stepStartDate: yup.string().required(),
});

/* The yup.InferType is not available in this version and an version upgrade
  is not possible at this moment.
*/
interface WageEditSchemaValues extends WageEmployeeUpdateValues {
  stepNumber: number;
  workedHours: number;
  stepStartDate: string;
}

type WageSetFieldValue = (
  field: keyof WageEditSchemaValues,
  value: any,
  shouldValidate?: boolean,
) => void;

interface Injected {
  employeeStore: EmployeeStore;
  wageStore: WageStore;
  userStore: UserStore;
}

enum SalaryType {
  Hourly = 'hourly',
  Salary = 'salary',
}

interface State {
  isEditing: boolean;
  error?: unknown;
  isLoadingSteps: boolean;
  nextStepChange?: Moment | null;
  stepHistory?: EmployeeStepSummaryItem[];
  wageOptions: {
    key: number;
    text: string;
    value: number;
    data: Wage;
  }[];
}

const salaryTypeOptions = [
  { key: SalaryType.Hourly, text: 'Hourly', value: SalaryType.Hourly },
  { key: SalaryType.Salary, text: 'Salary', value: SalaryType.Salary },
];

interface RenderPanelsProps {
  selectedWage?: Wage;
  values: WageEditSchemaValues;
  handleChange(e: React.ChangeEvent<any>): void;
  setFieldValue: WageSetFieldValue;
  isStepProgressEnabled: boolean;
}

@inject('employeeStore')
@inject('wageStore')
@inject('userStore')
@observer
class EmployeeWageModal extends InjectedComponent<Props, State, Injected> {
  state: State = {
    isEditing: false,
    wageOptions: [],
    isLoadingSteps: false,
  };
  mounted = false;

  componentDidMount(): void {
    this.mounted = true;
    this.setState({
      wageOptions: (this.props.wageGrids || []).map((wage) => {
        return { key: wage?.id || 0, text: wage.name, value: wage?.id, data: wage };
      }),
    });
    const fetchStepHistory = async () => {
      this.setState({
        isLoadingSteps: true,
        error: undefined,
      });
      try {
        const data = await this.injected.employeeStore.fetchStepHistory({
          id: this.props.employee.id,
          endDate: this.props.payPeriod.endDate,
        });
        const stepHistory = data.history.reverse();

        if (this.mounted) {
          this.setState({
            isLoadingSteps: false,
            stepHistory,
            nextStepChange: data.nextStepChange,
          });
        }
      } catch (err) {
        if (this.mounted) {
          this.setState({
            isLoadingSteps: false,
            error: err,
          });
        }
      }
    };
    fetchStepHistory();
  }

  componentWillUnmount(): void {
    this.mounted = false;
  }

  onEditProgress = () => {
    this.setState((state: State) => ({
      isEditing: !state.isEditing,
    }));
  };

  getToggle = (values: WageEditSchemaValues, setFieldValue: WageSetFieldValue) => {
    const { lsrpRateEnable } = values;
    return (
      <Form.Field inline style={{ marginBottom: '4px' }}>
        <p>LSRP Rate</p>
        <Checkbox
          disabled={!this.state.isEditing}
          toggle
          label={lsrpRateEnable ? 'Applied' : 'Not Apply'}
          checked={!!lsrpRateEnable}
          onChange={(e, data) => setFieldValue('lsrpRateEnable', data.checked)}
        />
      </Form.Field>
    );
  };

  getHoursNeeded(stepCount: number) {
    const { position } = this.props.employee;
    if (!position) {
      return 0;
    }
    const stepLimit = position.stepLimit ?? 0;
    if (stepLimit === 0) {
      return 0;
    }
    const stepNumber = this.props.employee.stepNumber ?? 1;
    if (stepNumber >= stepCount) {
      return 0;
    }
    const { nextStepChange } = this.state;
    const { workedHours } = this.props.employee;
    if (nextStepChange) {
      return 0;
    }
    const firstStepLimit = position.firstStepLimit ?? stepLimit;
    if (workedHours < firstStepLimit) {
      return firstStepLimit - workedHours;
    }
    let steps = Math.trunc((workedHours - firstStepLimit) / stepLimit);
    if ((workedHours - firstStepLimit) % stepLimit !== 0) {
      steps++;
    }
    const maxHours = firstStepLimit + steps * stepLimit;
    return maxHours - workedHours;
  }

  getStepOptions(steps: number) {
    const options = [...Array(steps + 1).keys()].slice(1).map((step) => {
      return {
        key: step,
        text: step,
        value: step,
      };
    });
    return options;
  }

  getProgressCircle({ hoursWorked, steps }: { hoursWorked: number; steps: number }) {
    const progress = (hoursWorked / (hoursWorked + this.getHoursNeeded(steps))) * 100;
    const left = 100 - progress;

    return (
      <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="215px" height="215px">
        <defs>
          <linearGradient id="GradientColor">
            <stop offset="0%" stopColor="#0DD7B8" />
            <stop offset="100%" stopColor="#1A818E" />
          </linearGradient>
        </defs>
        <circle
          cx="107.5"
          cy="107.5"
          r="100"
          strokeLinecap="round"
          style={{ strokeDasharray: `${progress} ${left >= 0 ? left : 0}` }}
          pathLength={100}
        />
      </svg>
    );
  }

  renderStepProgress({
    selectedWage,
    values,
    setFieldValue,
    handleChange,
    isStepProgressEnabled,
  }: RenderPanelsProps) {
    const wageSteps = selectedWage?.steps || 1;
    const options = this.getStepOptions(wageSteps);
    const { workedHours, stepNumber } = this.props.employee;
    const hoursLeft = round(this.getHoursNeeded(wageSteps), 2);
    const { isEditing } = this.state;
    const currentStep = this.state.stepHistory?.find(
      ({ props }) => props.stepNumber === stepNumber,
    );

    return (
      <div className={style.wage_modal_container}>
        <div className={style.step_container}>
          {isStepProgressEnabled && (
            <>
              <div className={style.text_description}>
                <p>{`Title: ${name}`}</p>
                <p>{`Step: ${stepNumber || 1} of ${wageSteps}`}</p>
              </div>
              <div className={style.skill}>
                {this.state.isLoadingSteps ? (
                  <Dimmer inverted active>
                    {' '}
                    <Loader size="small" active className={style.modal_loader} />
                  </Dimmer>
                ) : (
                  <>
                    <div className={style.outer}>
                      <div className={style.inner}>
                        <div className={style.statistic_container}>
                          <div className={style.statistic_header}>
                            <h5>Hours Worked</h5>
                            <h2>{workedHours}</h2>
                          </div>
                          <div className={style.statistic_subheader}>
                            <h6>Hours needed</h6>
                            <h4>{hoursLeft >= 0 ? hoursLeft : 0}</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    {this.getProgressCircle({
                      hoursWorked: workedHours,
                      steps: wageSteps,
                    })}
                  </>
                )}
              </div>
              <h3 className={style.edit_title_step}>Edit step progress details</h3>
            </>
          )}
          <Form className={style.form_container}>
            <Form.Input
              className={classNames(
                style.form_item,
                values.workedHours === workedHours ? style.unchanged_form_item : '',
              )}
              label="Hours worked"
              type="number"
              min={0}
              step="0.01"
              value={values.workedHours}
              onChange={handleChange}
              placeholder="Hours Worked"
              name="workedHours"
              disabled={!isEditing}
            />
            {isStepProgressEnabled && (
              <Form.Select
                className={classNames(
                  style.form_item,
                  values.stepNumber === stepNumber ? style.unchanged_form_item : '',
                )}
                label="Current step"
                defaultValue={values.stepNumber}
                onChange={(e, { value }) => setFieldValue('stepNumber', value)}
                options={options}
                placeholder="1"
                name="stepNumber"
                disabled={!isEditing}
              />
            )}
            <Form.Field className={style.form_item}>
              <label>Pay Period End Date</label>
              <SingleDatePicker
                hideKeyboardShortcutsPanel
                noBorder
                disabled={!isEditing}
                block
                openDirection="up"
                id="stepStartDate"
                date={moment(values.stepStartDate)}
                placeholder="MM/DD/YYYY"
                onDateChange={(date) => setFieldValue('stepStartDate', date)}
                isOutsideRange={() => false}
                numberOfMonths={1}
              />
            </Form.Field>
            <Form.Field className={style.form_item}>
              <label>Effectiveness Date</label>
              <SingleDatePicker
                hideKeyboardShortcutsPanel
                noBorder
                disabled
                block
                openDirection="up"
                id="effectivenessDate"
                date={currentStep?.props.startDate ?? null}
                placeholder="MM/DD/YYYY"
                onDateChange={() => {}}
                isOutsideRange={() => false}
                numberOfMonths={1}
              />
            </Form.Field>
          </Form>
        </div>
      </div>
    );
  }

  getWageRate(values: WageEditSchemaValues, selectedWage?: Wage) {
    let wageRate = values.wageRate ?? 0;
    if (values.salaryType === 'hourly') {
      if (selectedWage) {
        const firstScaleWageStep = selectedWage.wageSteps?.find(
          (itm) => itm.scaleIndex === 0 && itm.stepNumber === values.stepNumber,
        );
        if (selectedWage.scalations.length) {
          let wscalation = selectedWage.scalations.find((escalation) =>
            this.props.payPeriod.endDate.isBetween(
              moment(escalation.startScalationDate),
              moment(escalation.endScalationDate),
            ),
          );
          if (!wscalation) {
            selectedWage.scalations.forEach((escalation) => {
              if (this.props.payPeriod.endDate.isAfter(escalation.startScalationDate)) {
                wscalation = escalation;
              }
            });
          }
          wageRate =
            (selectedWage.wageSteps || []).find(
              (item) =>
                item.scaleIndex === wscalation?.id && item.stepNumber === (values.stepNumber || 1),
            )?.stepValue ||
            firstScaleWageStep?.stepValue ||
            0;
        } else {
          wageRate = firstScaleWageStep?.stepValue ?? 0;
        }
        if (values.lsrpRateEnable) {
          wageRate += wageRate * ((selectedWage.lsrpRateValue || 0) / 100);
        }
      }
    }
    return wageRate;
  }

  renderWageForm({ handleChange, setFieldValue, values, selectedWage }: RenderPanelsProps) {
    const { isEditing } = this.state;
    return (
      <div className={style.wage_modal_container}>
        <div>
          <div className={style.wage_modal_seccion}>
            <Form.Select
              className={style.form_item}
              value={values.wageId || undefined}
              label="Linked to wage grid"
              options={this.state.wageOptions}
              placeholder="Select a wage grid"
              name="wageId"
              disabled={!isEditing}
              onChange={(e, { value }) => setFieldValue('wageId', value)}
            />
            <div style={{ width: '47%' }}>
              <Button
                as="a"
                href={`/essentials/wagegrid/${values.wageId}`}
                target="_blank"
                basic
                color="blue"
                content="VIEW WAGE GRID"
                disabled={!values.wageId}
              />
            </div>
          </div>
          <div className={style.wage_modal_seccion}>
            <Form.Select
              className={style.form_item}
              value={values.salaryType}
              onChange={(e, { value }) => setFieldValue('salaryType', value)}
              label="Salary type"
              name="salaryType"
              options={salaryTypeOptions}
              placeholder="Hourly"
              disabled={!isEditing}
            />
            <Form.Field className={style.form_item}>
              {this.getToggle(values, setFieldValue)}
              <Input
                readOnly
                value={`${selectedWage?.lsrpRateValue || 0}%`}
                name="lsrpRate"
                placeholder="2% LSRP rate"
                disabled={!selectedWage?.lsrpRateValue || !isEditing}
              />
            </Form.Field>
          </div>
          <div className={style.wage_modal_seccion}>
            <Form.Input
              className={style.form_item}
              value={this.getWageRate(values, selectedWage)}
              onChange={handleChange}
              label="Rate"
              name="wageRate"
              step="0.01"
              placeholder="$"
              type="number"
              disabled={!isEditing || values.salaryType !== 'salary'}
            />
            <Form.Input
              className={style.form_item}
              name="professionalIncrement"
              value={values.professionalIncrement}
              onChange={handleChange}
              label="Professional Increment"
              type="number"
              step="0.01"
              placeholder="Enter professional increment amount"
              disabled={!isEditing}
            />
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <Modal
        closeIcon
        onClose={this.props.onCancel}
        closeOnDimmerClick={false}
        closeOnDocumentClick={false}
        onOpen={() => null}
        open={this.props.open}
        className={style.modal_parent_container}
        style={{ borderRadius: '20px', maxWidth: '800px' }}
      >
        <Modal.Content style={{ borderRadius: '20px' }}>
          <div className={style.wage_modal_header}>
            <p>Wage grid information</p>
            <h1>{`${this.props.employee.lastName}, ${this.props.employee.firstName}`}</h1>
          </div>
          <Formik
            validationSchema={wageEditSchema}
            initialValues={wageEditSchema.cast(
              {
                ...this.props.employee,
                ...this.props.employee.wage,
                stepNumber: this.props.employee.stepNumber || 1,
                stepStartDate: this.props.payPeriod.endDate.toDate(),
              },
              { stripUnknown: true },
            )}
            onSubmit={async (values, actions) => {
              this.setState({
                error: undefined,
              });
              const { employeeStore, wageStore } = this.injected;
              const { employee } = this.props;
              try {
                const { workedHours, stepNumber, stepStartDate, ...wageData } = values;
                const employeeData = {
                  workedHours,
                  stepNumber,
                  stepStartDate,
                };
                /* 
                  even without data to update, the dashboard needs to be updated 
                  with the latest version of this employee
                */
                let updateEmployeeDashboardPromise: () => Promise<EssentialEmployee>;
                if (
                  employeeData.workedHours !== this.props.employee.workedHours ||
                  employeeData.stepNumber !== this.props.employee.stepNumber
                ) {
                  updateEmployeeDashboardPromise = () =>
                    employeeStore.updateEmployeeStep({
                      id: employee.id,
                      startDate: moment(employeeData.stepStartDate),
                      stepNumber: employeeData.stepNumber,
                      workedHours:
                        employeeData.workedHours !== employee.workedHours
                          ? employeeData.workedHours
                          : undefined,
                      selectedPayPeriodStartDate: this.props.payPeriod.startDate,
                    });
                } else {
                  updateEmployeeDashboardPromise = () =>
                    employeeStore.updateEssentialEmployee({
                      id: employee.id,
                      endDate: this.props.payPeriod.endDate,
                      data: {},
                    });
                }
                const wagePromise = () =>
                  employee.wage
                    ? wageStore.updateWageEmployee(employee.wage.id, wageData)
                    : wageStore.assignWageEmployee({
                        ...wageData,
                        employeeId: employee.id,
                      });
                await wagePromise();
                await updateEmployeeDashboardPromise();
                this.props.onCancel();
              } catch (err) {
                this.setState({ error: err });
                actions.setSubmitting(false);
              }
            }}
            render={({
              values,
              errors,
              handleChange,
              handleSubmit,
              setFieldValue,
              isSubmitting,
            }) => {
              const errorMessages = Object.entries(errors)
                .filter(([k, v]) => v !== undefined)
                .map(([key, value]) => `${camelCaseToReadable(key)}: ${value}`);
              const selectedWage = this.state.wageOptions.find((itm) => itm.key === values.wageId);
              const isStepProgressEnabled = values.salaryType === 'hourly' && !!values.wageId;
              const permissions = getEssentialsPermissions(this.injected.userStore.current);
              const panelsProps = {
                handleChange,
                setFieldValue,
                values,
                selectedWage: selectedWage?.data,
                isStepProgressEnabled,
              };

              return (
                <Form onSubmit={handleSubmit}>
                  <Tab
                    menu={{ text: true }}
                    panes={[
                      {
                        menuItem: (
                          <Menu.Item as={Link} to="" key={1}>
                            WAGE INFORMATION
                          </Menu.Item>
                        ),
                        render: () => this.renderWageForm(panelsProps),
                      },
                      {
                        menuItem: (
                          <Menu.Item as={Link} to="" key={2}>
                            {isStepProgressEnabled ? 'STEP PROGRESS' : 'HOURS WORKED'}
                          </Menu.Item>
                        ),
                        render: () => this.renderStepProgress(panelsProps),
                      },
                    ]}
                    className="tab_menu"
                  />
                  {!!errorMessages.length && <Bulletin errorMessage={errorMessages.join('/n')} />}
                  {this.state.error && <Bulletin error={this.state.error} />}
                  <div className={style.wage_modal_action}>
                    <Button
                      className={style.form_button}
                      color="black"
                      content="CLOSE"
                      onClick={this.props.onCancel}
                    />
                    {permissions.write &&
                      this.props.employee.isActive &&
                      (this.state.isEditing ? (
                        <Button
                          className={style.form_button}
                          color="blue"
                          content="SAVE"
                          type="submit"
                          loading={isSubmitting}
                        />
                      ) : (
                        <Button
                          className={style.form_button}
                          basic
                          color="blue"
                          onClick={(e) => {
                            e.preventDefault();
                            this.onEditProgress();
                          }}
                          content="EDIT"
                        />
                      ))}
                  </div>
                </Form>
              );
            }}
          />
        </Modal.Content>
      </Modal>
    );
  }
}

export default EmployeeWageModal;
