import * as React from 'react';
import {
  Button,
  Checkbox,
  CheckboxProps,
  Form,
  Grid,
  Icon,
  Input,
  Modal,
  Segment,
  Dropdown,
} from '@/semantic-ui/components';
import ModalBase from '@/components/ModalBase';
import InjectedComponent from '@/components/InjectedComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateRangePicker, FocusedInputShape } from 'react-dates';
import { IWage, Moment, moment } from 'sol-data';
import { SupplementaryRateStore } from '@/store';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { validateNumberInput } from '@/lib/utils';
import { inject } from 'mobx-react';
import style from './index.less';
import Bulletin from '../Bulletin';

type BaseProps = {
  onConfirm: (data: IWage) => Promise<void>;
  onCancel: () => void;
  description?: string;
  open: boolean;
  header?: string;
  actionWord?: string;
  isEditable?: boolean;
  wageStorex?: IWage | null;
};

interface Injected {
  supplementaryRateStore: SupplementaryRateStore;
}

type Props = BaseProps &
  (
    | {
        dialog: string;
      }
    | {
        label: string;
      }
  );

interface RangeDate {
  startScalationDate: Moment;
  endScalationDate: Moment;
  id?: number;
}

interface RangeEffectiveDate {
  startDate: Moment;
  endDate: Moment;
}
type FocusedReact = FocusedInputShape | null;

interface ErrorDescriptor {
  code: string;
  message: string;
}

interface State {
  id?: number;
  name: string;
  steps: number | null;
  lsrpRate: boolean;
  lsrpRateValue: number | null;
  lsrpRateStringValue: string | null;
  effectiveDate: RangeEffectiveDate;
  scalations: RangeDate[];
  focusedInput: FocusedInputShape | null;
  dataTest: FocusedReact[];
  percentage: string;
  errorMessage: ErrorDescriptor[];
  error?: unknown;
  supplementaryRateId: number | null;
}

@inject('supplementaryRateStore')
class WageModal extends InjectedComponent<Props, State, Injected> {
  state: State = {
    percentage: '%',
    id: this.props.wageStorex?.id || undefined,
    name: this.props.wageStorex?.name || '',
    steps: this.props.wageStorex?.steps || null,
    lsrpRate: this.props.wageStorex?.lsrpRate || false,
    lsrpRateValue: this.props.wageStorex?.lsrpRateValue || null,
    lsrpRateStringValue: `${this.props.wageStorex?.lsrpRateValue || ''}`,
    supplementaryRateId: this.props.wageStorex?.supplementaryRate?.id ?? null,
    effectiveDate: {
      startDate: this.props.wageStorex?.startEffectiveDate
        ? moment(this.props.wageStorex.startEffectiveDate)
        : moment(),
      endDate: this.props.wageStorex?.endEffectiveDate
        ? moment(this.props.wageStorex.endEffectiveDate)
        : moment().add(1, 'days'),
    },
    scalations:
      this.props.wageStorex?.scalations.map((item) => {
        return {
          startScalationDate: moment(item.startScalationDate) || null,
          endScalationDate: moment(item.endScalationDate) || null,
          id: item.id,
        };
      }) || [],
    focusedInput: null,
    dataTest: [null],
    errorMessage: [],
  };

  componentDidMount = async () => {
    this.setState({
      percentage: '%',
      errorMessage: [],
      lsrpRateStringValue: `${this.props.wageStorex?.lsrpRateValue || ''}`,
    });
  };

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    switch (name) {
      case 'name':
        value.replace(/[^\w\s\d]/gi, '');
        this.setState({ name: value });
        break;
      case 'steps':
        if ((/^\d+$/.test(value) || !value) && +value < 16) this.setState({ steps: +value });
        break;
      case 'lsrpRateValue':
        if ((validateNumberInput(value.trim()) || !value) && +value < 100)
          this.setState({ lsrpRateValue: +value, lsrpRateStringValue: value });
        break;
      default:
        break;
    }
  };

  createWageScalation = (index: number | undefined) => {
    if (
      this.state.effectiveDate.startDate?.isValid &&
      this.state.effectiveDate.endDate?.isValid()
    ) {
      this.setState((state) => {
        if (typeof index !== 'undefined' && state.scalations[index].endScalationDate) {
          return {
            scalations: [
              ...state.scalations,
              this.props.isEditable
                ? {
                    startScalationDate: moment(state.scalations[index].endScalationDate).add(
                      1,
                      'days',
                    ),
                    endScalationDate: moment(state.scalations[index].endScalationDate).add(
                      2,
                      'days',
                    ),
                    id: 0,
                  }
                : {
                    startScalationDate: moment(state.scalations[index].endScalationDate).add(
                      1,
                      'days',
                    ),
                    endScalationDate: moment(state.scalations[index].endScalationDate).add(
                      2,
                      'days',
                    ),
                  },
            ],
          };
        }
        return {
          scalations: [
            ...state.scalations,
            this.props.isEditable
              ? {
                  startScalationDate: moment(state.effectiveDate.endDate).add(1, 'days'),
                  endScalationDate: moment(state.effectiveDate.endDate).add(2, 'days'),
                  id: 0,
                }
              : {
                  startScalationDate: moment(state.effectiveDate.endDate).add(1, 'days'),
                  endScalationDate: moment(state.effectiveDate.endDate).add(2, 'days'),
                },
          ],
        };
      });
    }
  };

  addWageScalation = (index: number) => {
    if (this.state.errorMessage.length) {
      return this.setState((state) => {
        return {
          errorMessage: [
            ...state.errorMessage.filter((item) => item.code !== 'scalation'),
            { code: 'scalation', message: 'Date Conflict. Please select another date' },
          ],
        };
      });
    }
    if (this.state.scalations[index].endScalationDate) {
      this.createWageScalation(index);
    }
  };

  removeScalation = (scalationIndex: number) => {
    this.setState((state) => {
      return {
        scalations: state.scalations.filter((item, index) => index !== scalationIndex),
        dataTest: state.dataTest.filter((item, index) => index !== scalationIndex),
        errorMessage: [],
      };
    });
  };

  handleScalationChange = (index: number, dataRange: RangeDate) => {
    let isInTheMiddle = false;
    let isNoGap = false;
    const { startScalationDate, endScalationDate, id } = dataRange;
    if (index === 0) {
      const effectiveDate = { ...this.state.effectiveDate };
      isInTheMiddle =
        startScalationDate?.isBetween(
          this.state.effectiveDate.startDate,
          this.state.effectiveDate.endDate,
        ) || false;
      isNoGap =
        startScalationDate?.isSame(moment(`${effectiveDate.endDate}`).add(1, 'd'), 'day') || false;
    } else {
      const scalationDate = { ...this.state.scalations[index - 1] };
      isInTheMiddle =
        startScalationDate?.isBetween(
          this.state.scalations[index - 1].startScalationDate,
          this.state.scalations[index - 1].endScalationDate,
        ) || false;
      isNoGap =
        startScalationDate?.isSame(
          moment(`${scalationDate.endScalationDate}`).add(1, 'd'),
          'day',
        ) || false;
    }
    if (isInTheMiddle || !isNoGap) {
      this.setState((state) => {
        return {
          errorMessage: [
            ...state.errorMessage.filter((item) => item.code !== 'scalation'),
            { code: 'scalation', message: 'Date Conflict. Please select another date' },
          ],
        };
      });
    } else {
      this.setState((state) => {
        return {
          errorMessage: [...state.errorMessage.filter((item) => item.code !== 'scalation')],
        };
      });
    }

    this.setState((state) => {
      return {
        scalations: state.scalations
          .sort((a, b) => (a.endScalationDate.isBefore(b.startScalationDate) ? -1 : 1))
          .map((item, idx) => {
            if (index === idx) {
              return this.props.isEditable
                ? { startScalationDate, endScalationDate, id: id || 0 }
                : { startScalationDate, endScalationDate };
            }
            return item;
          }),
      };
    });
  };

  checkboxChangeHandler = (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
    this.setState({ lsrpRate: data.checked || false });
  };

  onFocusChange = (index: number, focusedInput: FocusedReact) => {
    if (index >= this.state.dataTest.length) {
      this.setState((state) => {
        return {
          dataTest: [...state.dataTest, focusedInput],
        };
      });
    } else {
      this.setState((state) => {
        return {
          dataTest: state.dataTest.map((item, idx) => {
            if (index === idx) {
              return focusedInput;
            }
            return item;
          }),
        };
      });
    }
  };

  getDateClassContainer = (index: number) => {
    if (index === this.state.scalations.length - 1 && this.state.errorMessage.length >= 1)
      return style.calendar_container_error;
    return style.calendar_container;
  };

  isInvalidData = () => {
    if (this.state.errorMessage.length) return true;
    if (!this.state.steps || this.state.steps <= 0) return true;
    if (!this.state.name) return true;
    if (this.state.lsrpRate && (!this.state.lsrpRateValue || this.state.lsrpRateValue <= 0))
      return true;
    return false;
  };

  render() {
    const { onConfirm, onCancel, open, header, actionWord } = this.props;
    const {
      id,
      name,
      steps,
      lsrpRate,
      lsrpRateValue,
      scalations,
      effectiveDate,
      lsrpRateStringValue,
      supplementaryRateId,
    } = this.state;

    return (
      <Modal open={open} onClose={onCancel} size="small" className="wage_modal_container">
        <Modal.Content scrolling className={style.modal_scrolling_content}>
          <ModalBase
            header={header || `Create a wage grid table`}
            onAccept={async () => {
              this.setState({
                error: undefined,
              });
              try {
                await onConfirm({
                  id,
                  name,
                  steps: steps || 0,
                  lsrpRate,
                  lsrpRateValue: lsrpRateValue || 0,
                  startEffectiveDate: effectiveDate?.startDate,
                  endEffectiveDate: effectiveDate?.endDate,
                  scalations,
                  supplementaryRate: supplementaryRateId
                    ? this.injected.supplementaryRateStore.values.find(
                        (itm) => itm.id === supplementaryRateId,
                      ) ?? null
                    : null,
                });
              } catch (err) {
                this.setState({
                  error: err,
                });
              }
            }}
            onCancel={onCancel}
            cancelWord={'Discard'}
            actionWord={actionWord || 'Create'}
          >
            <Form>
              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    <Form.Field>
                      <p className={style.form_headers}>Enter wage grid name</p>
                      <Input
                        placeholder="name"
                        name="name"
                        value={name || ''}
                        onChange={this.handleChange}
                      />
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                  <Grid.Column>
                    <Form.Field>
                      <p className={style.form_headers}>Number of steps</p>
                      <Input
                        placeholder=""
                        name="steps"
                        value={steps || ''}
                        onChange={this.handleChange}
                      />
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Field>
                      <p className={style.form_headers}>Supplementary Rate Table</p>
                      <Dropdown
                        fluid
                        selection
                        clearable
                        placeholder="Select a Supplementary Rate Table"
                        options={this.injected.supplementaryRateStore.dropdownOptions}
                        name="supplementaryRateId"
                        value={supplementaryRateId || undefined}
                        onChange={(_, { value }) => {
                          this.setState({
                            supplementaryRateId: value && typeof value === 'number' ? value : null,
                          });
                        }}
                        className={style.dropdowns}
                      />
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={8} verticalAlign="middle">
                    <Form.Field inline>
                      <p>LSRP Rate</p>
                      <Checkbox
                        toggle
                        label={lsrpRate ? 'Applied' : 'Not Apply'}
                        checked={lsrpRate}
                        onChange={this.checkboxChangeHandler}
                      />
                    </Form.Field>
                  </Grid.Column>
                  {lsrpRate ? (
                    <Grid.Column width={8} verticalAlign="middle" className={style.lsrp_input}>
                      <Input
                        placeholder="Enter LSRP rate"
                        name="lsrpRateValue"
                        value={
                          lsrpRateStringValue
                            ? `${lsrpRateStringValue}${this.state.percentage}`
                            : ''
                        }
                        onChange={this.handleChange}
                        onFocus={() => this.setState({ percentage: '' })}
                        onBlur={() => this.setState({ percentage: '%' })}
                      />
                    </Grid.Column>
                  ) : (
                    <></>
                  )}
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={16}>
                    <p className={style.form_headers}>Effective date</p>
                  </Grid.Column>
                  <Grid.Column width={13}>
                    <div className={style.calendar_container} id="calendar_id">
                      <DateRangePicker
                        isOutsideRange={() => false}
                        startDate={effectiveDate.startDate}
                        openDirection="up"
                        endDate={effectiveDate.endDate}
                        startDateId="your_unique_start_date_id"
                        endDateId="your_unique_end_date_id"
                        onDatesChange={({ startDate, endDate }) => {
                          if (this.state.scalations.length) return;

                          if (startDate !== null) {
                            if (!startDate.isBefore(endDate!)) return;
                            this.setState({
                              effectiveDate: { startDate, endDate: endDate || moment() },
                            });
                          }
                          if (startDate !== null && endDate !== null) {
                            if (!endDate.isAfter(startDate)) return;
                            this.setState({ effectiveDate: { startDate, endDate } });
                          }
                        }}
                        regular
                        noBorder
                        focusedInput={this.state.focusedInput}
                        onFocusChange={(focusedInput) => {
                          this.setState({ focusedInput });
                        }}
                        customArrowIcon={'-'}
                      />
                      <Segment
                        onClick={() => {
                          this.setState({ focusedInput: 'startDate' });
                        }}
                        className={style.icon_calendar}
                      >
                        <Icon name="calendar alternate outline" color="grey" />
                      </Segment>
                    </div>
                  </Grid.Column>
                  <Grid.Column width={3} className={style.profile_form_remove_number}>
                    <Button
                      circular
                      size="mini"
                      type="button"
                      color="red"
                      icon="minus"
                      onClick={() => null}
                      disabled
                    />

                    <Button
                      circular
                      size="mini"
                      type="button"
                      color="blue"
                      className={style.profile_delete_button}
                      icon={<FontAwesomeIcon icon={['far', 'plus']} />}
                      onClick={() => this.createWageScalation(undefined)}
                      disabled={!!this.state.scalations?.length}
                    />
                  </Grid.Column>
                </Grid.Row>
                {scalations
                  .sort((a, b) => (a.endScalationDate.isBefore(b.startScalationDate) ? -1 : 1))
                  .map((scalation, index, scale) => {
                    return (
                      <Grid.Row key={`${`${scalation || 'new'}${index}`}`}>
                        <Grid.Column width={16}>
                          <p className={style.form_headers}>
                            Wage escalation
                            {` ${index + 1}`}
                          </p>
                        </Grid.Column>
                        <Grid.Column
                          width={13}
                          onClick={() => {
                            this.onFocusChange(index, 'endDate');
                          }}
                        >
                          <div className={this.getDateClassContainer(index)} id="calendar_id">
                            <DateRangePicker
                              isOutsideRange={() => false}
                              key={this.state.scalations.length}
                              startDate={scalation.startScalationDate}
                              startDateId={`start_date_id_${index}`}
                              endDate={scalation.endScalationDate}
                              openDirection="up"
                              endDateId={`end_date_id_${index}`}
                              onDatesChange={({ startDate, endDate }) => {
                                if (
                                  index === scale.length - 1 ||
                                  !!this.state.errorMessage.length
                                ) {
                                  if (startDate !== null) {
                                    this.handleScalationChange(index, {
                                      startScalationDate: startDate,
                                      endScalationDate: endDate || startDate.add(1, 'days'),
                                      id: scalation.id,
                                    });
                                  }
                                  if (startDate !== null && endDate !== null) {
                                    this.handleScalationChange(index, {
                                      startScalationDate: startDate,
                                      endScalationDate: endDate,
                                      id: scalation.id,
                                    });
                                  }
                                }
                              }}
                              noBorder
                              regular
                              focusedInput={this.state.dataTest[index]}
                              onFocusChange={(fdInput) => {
                                this.onFocusChange(index, fdInput);
                              }}
                              customArrowIcon={'-'}
                            />

                            <Icon name="calendar alternate outline" color="grey" />
                          </div>
                        </Grid.Column>
                        <Grid.Column width={3} className={style.profile_form_remove_number}>
                          <Button
                            circular
                            size="mini"
                            type="button"
                            color="red"
                            icon="minus"
                            onClick={() => this.removeScalation(index)}
                            disabled={index !== this.state.scalations.length - 1}
                          />

                          <Button
                            circular
                            size="mini"
                            type="button"
                            color="blue"
                            className={style.profile_delete_button}
                            icon={<FontAwesomeIcon icon={['far', 'plus']} />}
                            onClick={() => this.addWageScalation(index)}
                            disabled={index !== this.state.scalations.length - 1}
                          />
                        </Grid.Column>
                      </Grid.Row>
                    );
                  })}
                <Grid.Row className={style.alert_message}>
                  <Grid.Column width={16}>
                    {this.state.errorMessage?.length >= 1 ? (
                      <p>
                        {
                          // eslint-disable-next-line react/jsx-one-expression-per-line
                          this.state.errorMessage.slice(-1)[0].message
                        }
                      </p>
                    ) : (
                      <></>
                    )}
                  </Grid.Column>
                </Grid.Row>
                {this.state.error && (
                  <Grid.Row>
                    <Bulletin error={this.state.error} />
                  </Grid.Row>
                )}
              </Grid>
            </Form>
          </ModalBase>
        </Modal.Content>
      </Modal>
    );
  }
}

export default WageModal;
