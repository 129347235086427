import AppContext from '@/AppContext';
import style from '@/containers/Timesheets/index.less';
import { displayHours } from '@/lib/utils';
import { useStores } from '@/store';
import { EntityLookup } from '@lcdev/store';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useWindowSize } from 'react-use';
import { Button, Card, Divider, Grid, Icon, Popup } from 'semantic-ui-react';
import { AssignmentCost, PayrollStore, Timesheet, TimesheetEntry, toDollar } from 'sol-data';

const { Row, Column } = Grid;

interface TotalsCardProps {
  cost?: AssignmentCost<TimesheetEntry>;
  timesheet: Timesheet;
  onApprove: () => void;
  disabledApproval: boolean;
}

interface Injected {
  payrollStore: PayrollStore;
}

function sortedPayrollEntities<T extends { id: number; description: string }>(
  keys: string[],
  lookup: EntityLookup<T>,
) {
  return keys
    .map((key) => {
      const found = lookup.get(key);
      const description = found ? found.description : 'Unknown';

      return {
        description,
        id: parseFloat(key),
      };
    })
    .sort(({ description: a }, { description: b }) => b.localeCompare(a));
}

export const TotalsCard = observer(
  ({ cost, timesheet, onApprove, disabledApproval }: TotalsCardProps) => {
    const { payrollStore, timesheetStore, employeeStore } = useStores();
    const { width } = useWindowSize();
    const {
      cost: { breakdownByItem, total },
    } = cost || { cost: { breakdownByItem: {}, total: undefined } };
    const currentTimesheet = timesheetStore.current;
    const currentEmployeeId = currentTimesheet?.props.employee.id;
    const currentEmployee = currentEmployeeId ? employeeStore.get(currentEmployeeId) : undefined;

    // FIXME: this forces observation of payroll store items
    payrollStore.items.values;
    // https://semantic-ui.com/elements/container.html
    const isTabletView = width < 991;

    return (
      <AppContext.Consumer>
        {({ isPayrollAdmin }) => (
          <div
            className={classnames(
              style.totals_card_wide_width,
              isTabletView ? style.totals_card_tablet : style.totals_card_container,
            )}
          >
            <Card fluid>
              <div className={style.totals_card_grid}>
                <Grid className={style.totals_card_grid}>
                  <Row className={style.timesheet_highlight} columns={3}>
                    <Column width={isPayrollAdmin ? 9 : 13} textAlign="left">
                      Payroll Item
                    </Column>
                    <Column width={3} textAlign="right">
                      Hours
                    </Column>
                    {isPayrollAdmin && (
                      <Column width={4} textAlign="right">
                        Cost
                      </Column>
                    )}
                  </Row>

                  {sortedPayrollEntities(Object.keys(breakdownByItem), payrollStore.items).map(
                    ({ id, description }) => {
                      const item = cost?.cost.items.find((item) => item.item.id === id);

                      return (
                        <Row key={id} className={style.totals_card_row} columns={3}>
                          <Column width={isPayrollAdmin ? 9 : 13}>
                            {description}
                            {item?.minutes && item.minutes > 0 && item.total === 0 && (
                              <Popup
                                content={`${currentEmployee?.user?.fullName} is missing a rate for this payroll item. Check the employee's profile for the rate and check to see if it's in Quickbooks.`}
                                trigger={
                                  <Icon
                                    name="warning circle"
                                    style={{ color: 'red', marginLeft: '6px' }}
                                  />
                                }
                                position="top center"
                              />
                            )}
                          </Column>
                          <Column width={3} textAlign="right">
                            {displayHours(breakdownByItem[id].minutes)}
                          </Column>
                          {isPayrollAdmin && (
                            <Column width={4} textAlign="right">
                              {breakdownByItem[id].total || breakdownByItem[id].total === 0
                                ? toDollar(breakdownByItem[id].total!)
                                : 'Unknown'}
                            </Column>
                          )}
                        </Row>
                      );
                    },
                  )}

                  <Divider />

                  <Row className={style.totals_card_row} columns={3}>
                    <Column width={isPayrollAdmin ? 9 : 13}>Total</Column>
                    <Column width={3} textAlign="right">
                      {displayHours(timesheet.get('allMinutes'))}
                    </Column>
                    {isPayrollAdmin && (
                      <Column width={4} textAlign="right">
                        {total ? toDollar(total) : '-'}
                      </Column>
                    )}
                  </Row>

                  {timesheet.get('approvedOn') ? (
                    <Row>
                      <Button
                        fluid
                        disabled
                        color={timesheet.get('syncedToQuickbooks') ? 'blue' : 'grey'}
                      >
                        {timesheet.get('syncedToQuickbooks')
                          ? 'Synced to QuickBooks'
                          : 'Waiting to sync'}
                      </Button>
                    </Row>
                  ) : (
                    <Row>
                      <Button fluid primary disabled={disabledApproval} onClick={onApprove}>
                        Approve
                      </Button>
                    </Row>
                  )}
                </Grid>
              </div>
            </Card>
          </div>
        )}
      </AppContext.Consumer>
    );
  },
);
