import * as React from 'react';
import { inject, observer } from 'mobx-react';
import AppContext from '@/AppContext';
import FilterDropdown from '@/components/FilterDropdown';
import { PositionStore } from '@/store';
import InjectedComponent from '../InjectedComponent';

type BaseProps = {
  onChange: (name: string, value: number) => void;
  placeholder?: string;
  name?: string;
  multiple?: boolean;
  allOptions?: boolean;
  defaultValue?: number[];
  disabled?: boolean;
  clearable?: boolean;
  limited?: boolean;
};

export type Props = BaseProps & ({ positionId: number | undefined } | { positionIds?: number[] });

interface Injected {
  positionStore: PositionStore;
}

@inject('positionStore')
@observer
class PositionFilterDropdown extends InjectedComponent<Props, {}, Injected> {
  static defaultProps: Partial<Props> = {
    multiple: false,
  };

  static contextType = AppContext;

  componentDidMount() {
    const { positionStore } = this.injected;
    if (positionStore.isEmpty) positionStore.fetchAll();
  }

  render() {
    const {
      positionStore,
      onChange,
      placeholder,
      multiple,
      name,
      allOptions,
      defaultValue,
      disabled,
      clearable,
      limited,
    } = this.injected;

    const {
      scope: { positions: limitedPositions },
    } = this.context;

    let positionOptions = positionStore.dropdownOptionsShort;

    if (allOptions) {
      positionOptions = [
        {
          key: 0,
          text: 'All Positions',
          value: 0,
        },
      ].concat(positionStore.dropdownOptions);
    }

    if (limited && limitedPositions) {
      positionOptions = positionOptions.filter(({ value }) => limitedPositions.includes(value));
    }

    const value = 'positionId' in this.props ? this.props.positionId : this.props.positionIds;

    return (
      <FilterDropdown
        name={name || 'positionId'}
        placeholder={placeholder || 'Select a position'}
        value={value}
        options={positionOptions}
        changeDropdown={onChange}
        multiple={multiple}
        defaultValue={defaultValue}
        disabled={disabled}
        clearable={clearable}
      />
    );
  }
}

export default PositionFilterDropdown;
