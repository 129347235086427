import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { ScheduleStore } from '@/store';
import { Schedule, ScheduleStatus, PayrollStore } from 'sol-data';
import { Segment } from '@/semantic-ui/components';
import AppContext from '@/AppContext';
import InjectedComponent from '@/components/InjectedComponent';
import Bulletin from '@/components/Bulletin';
import SchedulesList from '@/components/SchedulesList';
import style from '@/containers/Schedules/index.less';

const content = {
  active: (
    <span>
      Active Schedules are the schedules that have been published, assigned and are currently being
      used
    </span>
  ),
  unassigned: (
    <span>
      Unassigned Schedules are the schedules that have been published but need to be assigned
    </span>
  ),
  upcoming: (
    <span>
      Upcoming Schedules are Published Schedules that are fully assigned and will be used in the
      future
    </span>
  ),
  unpublished: (
    <span>Unpublished Schedules are schedules that have been generated but not yet published</span>
  ),
};

interface Injected {
  scheduleStore: ScheduleStore;
  payrollStore: PayrollStore;
}

interface State {
  schedules?: Schedule[];
}

@inject('scheduleStore', 'payrollStore')
@observer
class AllScheduleLists extends InjectedComponent<{}, State, Injected> {
  static contextType = AppContext;

  constructor(props: {}) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { payrollStore } = this.injected;
    const { isPayrollAdmin } = this.context;
    if (payrollStore.items.isEmpty && isPayrollAdmin) payrollStore.fetchAll();
  }

  render() {
    const { scheduleStore, payrollStore } = this.injected;

    return (
      <Segment basic className={style.schedule_list}>
        <Bulletin
          isLoading={scheduleStore.isLoading || payrollStore.isLoading}
          error={payrollStore.error || scheduleStore.error}
          handleDismiss={() => {
            scheduleStore.clearError();
            payrollStore.clearError();
          }}
        />

        <SchedulesList
          status={ScheduleStatus.Assigned}
          includeHeader
          scheduleHeader="Active Schedules"
          popupContent={content.active}
          active
        />

        <SchedulesList
          status={ScheduleStatus.Published}
          scheduleHeader="Unassigned Schedules"
          popupContent={content.unassigned}
        />

        <SchedulesList
          status={ScheduleStatus.Assigned}
          scheduleHeader="Upcoming Schedules"
          popupContent={content.upcoming}
        />

        <SchedulesList
          status={ScheduleStatus.Pending}
          scheduleHeader="Unpublished Schedules"
          popupContent={content.unpublished}
        />
      </Segment>
    );
  }
}

export default AllScheduleLists;
