import * as React from 'react';
import * as classNames from 'classnames';
import * as moment from 'moment-timezone';
import { isInclusivelyAfterDay, isInclusivelyBeforeDay } from 'react-dates';
import { Dropdown, Input, TextArea } from 'semantic-ui-react';
import { employeeActiveStatuses } from 'sol-data';
import InjectedComponent from '../InjectedComponent';
import { SingleDatePicker as WrapSingleDatePicker } from '../SingleDatePicker';
import style from './index.less';
import {
  EmployeeContainerTabProps,
  EmployeeProfileValues,
} from '../EmployeeInformationModal/utils';
import { EmployeePhoneNumbersContainer } from '../EmployeePhoneNumbersContainer';

export class EmployeeProfileContainer extends InjectedComponent<EmployeeContainerTabProps, {}, {}> {
  render() {
    const {
      formProps: { values: formValues, errors: formErrors, setFieldValue: setFormFieldValue },
      employee,
      readOnly,
    } = this.props;
    const values = formValues.profile;
    const errors = formErrors.profile ?? {};

    const setFieldValue = (name: keyof EmployeeProfileValues, value: any) =>
      setFormFieldValue(`profile.${name}`, value);

    return (
      <div className={style.profile_body}>
        <EmployeePhoneNumbersContainer {...this.props} />
        <div className={style.grid_2_col}>
          <div className={style.grid}>
            <label className={style.form_headers}>Email</label>
            <Input name="email" value={employee?.user.email} disabled />
          </div>
          <div className={classNames(style.grid, style.date_container)}>
            <label className={style.form_headers}>Date of Birth</label>
            <WrapSingleDatePicker
              hideKeyboardShortcutsPanel
              noBorder
              block
              id="dateOfBirth"
              date={employee?.user.dateOfBirth || null}
              placeholder="MM/DD/YYYY"
              onDateChange={(date) => {}}
              isOutsideRange={(day) => !isInclusivelyBeforeDay(day, moment())}
              numberOfMonths={1}
              disabled
            />
          </div>
        </div>
        <div className={style.grid_2_col}>
          <div className={classNames(style.grid, style.date_container)}>
            <label className={style.form_headers}>Start</label>
            <WrapSingleDatePicker
              hideKeyboardShortcutsPanel
              noBorder
              block
              id="startDate"
              date={values.startDate ? moment.utc(values.startDate) : null}
              placeholder="MM/DD/YYYY"
              onDateChange={(date) => setFieldValue('startDate', date)}
              isOutsideRange={() => false}
              numberOfMonths={1}
              disabled={readOnly}
            />
            {errors.startDate && <p className={style.error_class}>{errors.startDate}</p>}
          </div>
          <div className={classNames(style.grid, style.date_container)}>
            <label className={style.form_headers}>Probation End</label>
            <WrapSingleDatePicker
              hideKeyboardShortcutsPanel
              noBorder
              block
              id="probationEndAt"
              date={values.probationEndAt ? moment(values.probationEndAt) : null}
              placeholder="MM/DD/YYYY"
              onDateChange={(date) => setFieldValue('probationEndAt', date)}
              isOutsideRange={(day) =>
                employee.startDate
                  ? !isInclusivelyAfterDay(day, moment(employee.startDate).add(1, 'day'))
                  : false
              }
              numberOfMonths={1}
              disabled={readOnly}
            />
            {errors.probationEndAt && <p className={style.error_class}>{errors.probationEndAt}</p>}
          </div>
        </div>
        <div className={style.grid_4_col}>
          <div className={classNames(style.grid, style.date_container)}>
            <label className={style.form_headers}>Last Day</label>
            <WrapSingleDatePicker
              hideKeyboardShortcutsPanel
              noBorder
              block
              id="lastDay"
              date={values.lastDay ? moment(values.lastDay) : null}
              placeholder="MM/DD/YYYY"
              onDateChange={(date) => setFieldValue('lastDay', date)}
              isOutsideRange={(day) => !isInclusivelyBeforeDay(day, moment())}
              numberOfMonths={1}
              disabled={readOnly}
            />
            {errors.lastDay && <p className={style.error_class}>{errors.lastDay}</p>}
          </div>
          <div className={style.grid}>
            <label className={style.form_headers}>Status</label>
            {employee.deleteDatetime ? (
              <Input name="status" value="Inactive on QuickBooks" disabled />
            ) : (
              <Dropdown
                name="status"
                upward
                disabled={readOnly}
                value={values.status}
                selection
                options={employeeActiveStatuses.map((itm) => ({
                  key: itm.id,
                  value: itm.id,
                  text: itm.name,
                }))}
                onChange={(_, { value }) => setFieldValue('status', value)}
                className={style.dropdowns}
              />
            )}
            {errors.status && <p className={style.error_class}>{errors.status}</p>}
          </div>
          <div className={style.grid}>
            <label className={style.form_headers}>FTE</label>
            <Input
              placeholder="FTE"
              name="fte"
              disabled={readOnly}
              value={values.fte}
              onChange={(val) => setFieldValue('fte', val.currentTarget.value)}
            />
            {errors.fte && <p className={style.error_class}>{errors.fte}</p>}
          </div>
          <div className={style.grid}>
            <label className={style.form_headers}>Vacation Accrual %</label>
            <Input
              placeholder={`Default: ${
                employee.positionVacationAccrualPercentage
                  ? [employee.positionVacationAccrualPercentage, '%'].join('')
                  : 'empty'
              } `}
              name="vacationAccrualPercentage"
              type="number"
              disabled={readOnly}
              value={values.vacationAccrualPercentage}
              onChange={(val) =>
                setFieldValue(
                  'vacationAccrualPercentage',
                  val.currentTarget.value ? Number(val.currentTarget.value) : null,
                )
              }
            />
            {errors.vacationAccrualPercentage && (
              <p className={style.error_class}>{errors.vacationAccrualPercentage}</p>
            )}
          </div>
        </div>
        <div className={style.grid_2_col}>
          <div className={classNames(style.grid, style.date_container)}>
            <label className={style.form_headers}>Status Change</label>
            <TextArea
              name="statusChange"
              placeholder="Status change"
              disabled={readOnly}
              rows={4}
              value={values.statusChange}
              onChange={(val) => setFieldValue('statusChange', val.currentTarget.value)}
            />
            {errors.statusChange && <p className={style.error_class}>{errors.statusChange}</p>}
          </div>
          <div className={classNames(style.grid, style.date_container)}>
            <label className={style.form_headers}>Notes</label>
            <TextArea
              name="notes"
              placeholder="Notes"
              disabled={readOnly}
              rows={4}
              value={values.notes}
              onChange={(val) => setFieldValue('notes', val.currentTarget.value)}
            />
            {errors.notes && <p className={style.error_class}>{errors.notes}</p>}
          </div>
        </div>
      </div>
    );
  }
}
