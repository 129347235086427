/* eslint-disable react/static-property-placement */
import * as React from 'react';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import {
  IShiftGroup,
  IShift,
  Shift,
  ShiftGroup,
  WithID,
  AssignmentCost,
  Employee,
  toDollar,
} from 'sol-data';
import { PositionStore, EmployeeStore, ScheduleStore, PayrollStore, ShiftStore } from '@/store';
import { Radio } from '@/semantic-ui/components';
import { RadioProps, Input, Grid, Header, Modal } from 'semantic-ui-react';
import ListLayout, { ListColumn } from '@/components/ListLayout';
import Bulletin from '@/components/Bulletin';
import ModalBase from '@/components/ModalBase';
import { EntityReference } from '@lcdev/store';
import AppContext from '@/AppContext';
import { orderBy } from 'lodash';
import style from './index.less';
import InjectedComponent from '../InjectedComponent';
import { getCostCalcError } from '../CostCalcError';

interface BaseProps {
  reassign: boolean;
  closeModal: () => void;
  onAssign?: () => void;
  title: string;
}

type Props =
  | (BaseProps & { shift: WithID<IShift> })
  | (BaseProps & { shiftGroup: WithID<IShiftGroup> });

interface State {
  costs?: AssignmentCost<Shift | ShiftGroup>[];
  assignableEmployees?: Employee[];
  selectedEmployee?: number;
  assignmentRows: AssignmentRow[];
}

interface Injected {
  positionStore: PositionStore;
  employeeStore: EmployeeStore;
  payrollStore: PayrollStore;
  scheduleStore: ScheduleStore;
  shiftStore: ShiftStore;
}

// common type between shift & shift group costs
type AssignmentRow = {
  id: number;
  employee: EntityReference<Employee>;
  total?: number;
  costs?: AssignmentCost<any>[];
};

@inject('positionStore', 'employeeStore', 'payrollStore', 'shiftStore', 'scheduleStore')
@observer
class AssignEmployee extends InjectedComponent<Props, State, Injected> {
  static defaultProps: Partial<Props> = {
    reassign: false,
    closeModal: () => '',
  };

  state: State = {
    assignmentRows: [],
  };

  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  constructor(props: Props) {
    super(props);
  }

  componentDidMount = async () => {
    const { payrollStore, scheduleStore, employeeStore } = this.injected;

    const { isPayrollAdmin } = this.context;
    if (!isPayrollAdmin) {
      const signable =
        'shiftGroup' in this.injected
          ? await scheduleStore.fetchAssignableEmployeesForShiftGroup(this.injected.shiftGroup)
          : await scheduleStore.fetchAssignableEmployeesForShift(this.injected.shift);
      const assignableEmployees = await employeeStore.fetchEmployees(signable.map(({ id }) => id));
      this.setState({ assignableEmployees });
    } else {
      await payrollStore.fetchAll();
      await this.fetchCosts();
    }

    // Make sure the state is updated before setting the assignment rows
    setTimeout(() => {
      this.setAssignmentRows();
    }, 1);
  };

  setAssignmentRows(searchTerm?: string) {
    const { costs, assignableEmployees } = this.state;

    let assignmentRows: AssignmentRow[] = [];

    if (costs) {
      assignmentRows = costs.map(({ employee, cost }) => ({
        employee,
        id: employee.id,
        total: cost.total,
      }));
      assignmentRows = orderBy(assignmentRows, 'total', 'desc');
    } else if (assignableEmployees) {
      assignmentRows = assignableEmployees.map((employee) => ({
        id: employee.id,
        employee: new EntityReference(Employee, employee),
      }));
    }
    if (searchTerm) {
      assignmentRows = assignmentRows.filter((row) => {
        const employee = this.injected.employeeStore.get(row.employee.id);
        return (
          employee &&
          (employee.user.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            employee.user.firstName.toLowerCase().includes(searchTerm.toLowerCase()))
        );
      });
    }
    this.setState({ assignmentRows });
  }

  componentWillUnmount() {
    const { scheduleStore } = this.injected;
    scheduleStore.clearError();
  }

  fetchCosts = async (ignoreRates?: boolean) => {
    const { payrollStore } = this.injected;

    if ('shiftGroup' in this.injected) {
      const { shiftGroup } = this.injected;

      const costs = await payrollStore.fetchShiftGroupAssignmentCosts(
        shiftGroup,
        undefined,
        ignoreRates,
      );

      this.setState({ costs });
    } else {
      const { shift } = this.injected;

      const costs = await payrollStore.fetchShiftAssignmentCosts(shift, undefined, ignoreRates);

      this.setState({ costs });
    }
  };

  handleBulletinDismiss = () => {
    const { payrollStore, scheduleStore, shiftStore, closeModal } = this.injected;

    payrollStore.clearError();
    scheduleStore.clearError();
    shiftStore.clearError();
    closeModal();
  };

  assignEmployee = async () => {
    const { selectedEmployee } = this.state;
    const { scheduleStore, shiftStore, closeModal, onAssign } = this.injected;
    if (!selectedEmployee) return;

    if ('shiftGroup' in this.injected) {
      await scheduleStore.assignShiftGroup(this.injected.shiftGroup, selectedEmployee);
    } else {
      await shiftStore.assignToEmployee(this.injected.shift, selectedEmployee);
    }

    if (onAssign) onAssign();

    this.handleBulletinDismiss();
    closeModal();
  };

  setFillEmployee: RadioProps['onChange'] = async (_, { value }) => {
    this.setState({
      selectedEmployee: value as number,
    });
  };

  @computed get columns(): ListColumn<AssignmentRow>[] {
    const { employeeStore, positionStore } = this.injected;
    const { selectedEmployee, costs } = this.state;

    return [
      {
        name: 'EMPLOYEE',
        key: 'employee',
        width: 4,
        verticalAlign: 'middle',
        render: ({ employee }) => (
          <div className={style.shift_cell}>
            {employeeStore.has(employee) ? employeeStore.get(employee)!.user.fullName : 'Unknown'}
          </div>
        ),
      },
      {
        name: 'POSITION',
        key: 'position',
        width: 4,
        verticalAlign: 'middle',
        render: ({ employee }) => {
          if (!employeeStore.has(employee)) return null;
          const { position } = employeeStore.get(employee)!;
          if (!positionStore.has(position)) return null;
          const { label } = positionStore.get(position)!;

          return <div className={style.shift_cell}>{label}</div>;
        },
      },
      ...(costs
        ? [
            {
              name: 'EST COST',
              key: 'cost',
              width: 4,
              verticalAlign: 'middle',
              render: ({ total }: { total?: number }) => (
                <div className={style.shift_cell}>{toDollar(total!)}</div>
              ),
            } as const,
          ]
        : []),
      {
        name: 'SELECT',
        key: 'checked',
        width: 4,
        verticalAlign: 'middle',
        floated: 'right',
        render: ({ employee }) => (
          <Radio
            value={employee.id}
            onChange={this.setFillEmployee}
            checked={selectedEmployee === employee.id}
            className={style.radio_cell}
          />
        ),
      },
    ];
  }

  render() {
    const { reassign, closeModal, title, payrollStore, scheduleStore, shiftStore } = this.injected;

    let negateButton;
    let affirmButton;

    if (reassign) {
      negateButton = 'Reject';
      affirmButton = 'Approve';
    } else {
      negateButton = 'Cancel';
      affirmButton = 'Assign';
    }

    const isLoading =
      payrollStore.isLoading ||
      scheduleStore.isLoading ||
      shiftStore.isLoading ||
      payrollStore.isLoading;

    const error = payrollStore.error || scheduleStore.error || shiftStore.error;

    const errorBulletin = (
      <Bulletin
        isLoading={isLoading}
        error={error}
        handleDismiss={this.handleBulletinDismiss}
        text="Calculating possibilities..."
        isModal
      />
    );

    return (
      <ModalBase
        header={title}
        headerClass="modal_header modal_header_assign"
        cancelWord={negateButton}
        actionWord={affirmButton}
        handleSubmit={!error ? this.assignEmployee : () => {}}
        onCancel={() => {
          this.handleBulletinDismiss();
          closeModal();
        }}
      >
        {errorBulletin}
        <Modal size="mini" open={!!error && !!getCostCalcError(error)}>
          <ModalBase
            header="Ignore Missing Rates"
            onAccept={async () => {
              await this.fetchCosts(true);
              this.setAssignmentRows();
            }}
            onCancel={closeModal}
            actionWord="Continue"
          >
            {errorBulletin}
            This error occurred during cost calculation. Do you wish to ignore any missing rates and
            rerun cost calculation?
          </ModalBase>
        </Modal>
        {!error && (
          <>
            <Grid>
              <Grid.Row columns="2">
                <Grid.Column width="10">
                  <Header size="small" floated="left">
                    Assign To:
                  </Header>
                </Grid.Column>
                <Grid.Column width="6">
                  <Input
                    placeholder="Search Employees"
                    icon="search"
                    onChange={({ target: { value } }) => this.setAssignmentRows(value)}
                    onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                      if (e.keyCode === 13) {
                        e.stopPropagation();
                      }
                    }}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <ListLayout
              columns={this.columns}
              items={this.state.assignmentRows}
              isScrollable
              center
            />
          </>
        )}
      </ModalBase>
    );
  }
}

export default AssignEmployee;
