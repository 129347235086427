import * as React from 'react';
import { inject, observer } from 'mobx-react';
import InjectedComponent from '@/components/InjectedComponent';
import { UserStore } from '@/store';
import { Formik } from 'formik';
import { Grid, Input, Form, Message } from 'semantic-ui-react';
import ButtonBar from '@/components/ButtonBar';
import { object, string } from 'yup';
import Bulletin from '@/components/Bulletin';
import * as classnames from 'classnames';
import * as zxcvbn from 'zxcvbn';
import style from './index.less';

enum Labels {
  Weak = 'Weak',
  Poor = 'Poor',
  Good = 'Good',
  VeryStrong = 'Very Strong',
}

interface StrengthLevel {
  label: string;
  widthPercent: number;
  level: 0 | 1 | 2 | 3 | 4;
}

interface Injected {
  userStore: UserStore;
}

interface State {
  success?: boolean;
}

@inject('userStore')
@observer
class ChangePassword extends InjectedComponent<{}, State, Injected> {
  state: State = {};

  strengthLevels = [
    {
      label: Labels.Weak,
      widthPercent: 50,
      level: 0,
    },
    {
      label: Labels.Weak,
      widthPercent: 50,
      level: 1,
    },
    {
      label: Labels.Poor,
      widthPercent: 60,
      level: 2,
    },
    {
      label: Labels.Good,
      widthPercent: 80,
      level: 3,
    },
    {
      label: Labels.VeryStrong,
      widthPercent: 100,
      level: 4,
    },
  ];

  render() {
    const { userStore } = this.injected;
    const { errorMessage, isLoading } = userStore;
    return (
      <>
        <Bulletin
          errorMessage={errorMessage}
          isLoading={isLoading}
          handleDismiss={userStore.clearError}
        />
        <Formik
          enableReinitialize
          initialValues={{
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
          }}
          validationSchema={object().shape({
            oldPassword: string().required('Please enter your current password'),
            newPassword: string().required('Please enter a new password'),
            confirmPassword: string()
              // eslint-disable-next-line func-names
              .test('password-test', 'Passwords must match', function (value) {
                const { newPassword } = this.parent;
                return newPassword === value;
              })
              .required('Please confirm your password'),
          })}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            if (values.confirmPassword !== values.newPassword) {
              setSubmitting(false);
              return userStore.setError('Passwords do not match');
            }
            await userStore.changePassword(values);
            resetForm();
            this.setState({ success: true });
          }}
          render={({ values, errors, touched, handleSubmit, handleChange }) => {
            const { success } = this.state;

            const showOldError = !!errors.oldPassword && !!touched.oldPassword;
            const showNewError = !!errors.newPassword && !!touched.newPassword;
            const showConfirmError = !!errors.confirmPassword && !!touched.confirmPassword;

            const strengthLevel = this.strengthLevels.find(({ level }) => {
              return level === zxcvbn(values.newPassword).score;
            });

            return (
              <Form>
                {success && (
                  <Message positive onDismiss={() => this.setState({ success: undefined })}>
                    <Message.Header>Success!</Message.Header>
                    <p>Your password has been successfully changed!</p>
                  </Message>
                )}
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={4}>
                      <p className={style.form_headers}>Current Password</p>
                      <Input
                        fluid
                        name="oldPassword"
                        value={values.oldPassword}
                        onChange={handleChange}
                        type="password"
                      />
                      {showOldError && <p className={style.error_class}>{errors.oldPassword}</p>}
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={4}>
                      <p className={style.form_headers}>New Password</p>
                      <Input
                        fluid
                        name="newPassword"
                        value={values.newPassword}
                        onChange={handleChange}
                        type="password"
                      />
                      {showNewError && <p className={style.error_class}>{errors.newPassword}</p>}
                      {values.newPassword && strengthLevel && (
                        <div className={style.strength_meter_container}>
                          <div
                            className={classnames(style.strength_meter, {
                              [style.strength_meter_weak]: strengthLevel.level < 2,
                              [style.strength_meter_poor]: strengthLevel.level === 2,
                              [style.strength_meter_good]: strengthLevel.level === 3,
                              [style.strength_meter_very_strong]: strengthLevel.level === 4,
                            })}
                            style={{ width: `${strengthLevel.widthPercent}%` }}
                          >
                            <p>{strengthLevel.label}</p>
                          </div>
                        </div>
                      )}
                    </Grid.Column>
                    <Grid.Column width={4}>
                      <p className={style.form_headers}>Re-enter Password</p>
                      <Input
                        fluid
                        name="confirmPassword"
                        value={values.confirmPassword}
                        onChange={handleChange}
                        type="password"
                      />
                      {showConfirmError && (
                        <p className={style.error_class}>{errors.confirmPassword}</p>
                      )}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <ButtonBar onSave={handleSubmit} link="/" />
              </Form>
            );
          }}
        />
      </>
    );
  }
}

export default ChangePassword;
