import Breadcrumbs from '@/components/Breadcrumbs';
import Bulletin from '@/components/Bulletin';
import ButtonBar from '@/components/ButtonBar';
import { Dropdown, Form, Grid, Input, Segment } from '@/semantic-ui/components';
import { useStores } from '@/store';
import { Formik } from 'formik';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Area } from 'sol-data';
import { object, string } from 'yup';
import style from './index.less';

const { Field } = Form;
const { Row, Column } = Grid;

interface InitialValues {
  description: string;
  label: string;
  departmentId?: number;
}

export const ModifyAreas = observer(() => {
  const { areaStore, departmentStore } = useStores();
  const [initialValues, setInitialValues] = useState<InitialValues>({
    description: '',
    label: '',
    departmentId: undefined,
  });
  const navigate = useNavigate();
  const params = useParams();
  const areaId = params.areaId!;
  const { errorMessage } = areaStore;
  const { dropdownOptions } = departmentStore;

  useEffect(() => {
    areaStore.fetchAll().then(() => {
      if (areaId !== 'add') {
        areaStore.setCurrent(areaId);
      } else {
        areaStore.addAsCurrent(Area.create());
      }
    });

    departmentStore.fetchAll();

    return () => {
      areaStore.remove(-1);
      areaStore.clearCurrent();
    };
  }, [areaId, areaStore, departmentStore]);

  useEffect(() => {
    if (areaStore.current) {
      setInitialValues(areaStore.current.withoutID());
    }
  }, [areaStore.current]);

  return (
    <Segment basic padded="very">
      <Breadcrumbs
        linkTitle="Areas"
        activeBreadcrumb={initialValues.description || 'New Area'}
        link="/organization/areas"
        title={'Areas'}
      />
      <Formik
        enableReinitialize
        validationSchema={object().shape({
          label: string().required('Name is required'),
          description: string().required('Description is required'),
          departmentId: string()
            .typeError('Department is required')
            .required('Department is required'),
        })}
        initialValues={initialValues}
        onSubmit={async (values, { setSubmitting }) => {
          const area = areaStore.assignCurrent(values);

          if (area.id === -1) {
            await areaStore.create(area.withoutID());
          } else {
            await areaStore.save(area);
          }

          setSubmitting(false);
          navigate('/organization/areas');
        }}
      >
        {({
          values,
          errors,
          handleChange,
          touched,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => {
          const showLabelError = !!touched.label && !!errors.label;
          const showDescriptionError = !!touched.description && !!errors.description;
          const showDepartmentError = !!touched.departmentId && !!errors.departmentId;
          return (
            <Form>
              <Grid>
                <Row>
                  <Bulletin
                    errorMessage={errorMessage}
                    handleDismiss={() => areaStore.clearError()}
                    isLoading={areaStore.isLoading}
                  />
                </Row>
                <Row>
                  <Column width={8}>
                    <Field error={showLabelError} className={style.areas_form_fields}>
                      <p className={style.form_headers}>Name</p>
                      <Input
                        name="label"
                        value={values.label}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={style.areas_form_fields}
                      />
                      {showLabelError && <p className={style.error_class}>{errors.label}</p>}
                    </Field>
                  </Column>
                  <Column width={8}>
                    <Field error={showDescriptionError}>
                      <p className={style.form_headers}>Description</p>
                      <Input
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={style.areas_form_fields}
                      />
                      {showDescriptionError && (
                        <p className={style.error_class}>{errors.description}</p>
                      )}
                    </Field>
                  </Column>
                </Row>
                <Row>
                  <Column width={16}>
                    <Field error={showDepartmentError} className={style.areas_form_fields}>
                      <p className={style.form_headers}>Department</p>
                      <Dropdown
                        fluid
                        search
                        selection
                        placeholder="Select Department"
                        options={dropdownOptions}
                        name="departmentId"
                        value={values.departmentId}
                        onChange={(_, { name, value }) => {
                          setFieldValue(name, value);
                        }}
                        onBlur={handleBlur}
                        className={style.dropdowns}
                      />
                      {showDepartmentError && (
                        <p className={style.error_class}>{errors.departmentId}</p>
                      )}
                    </Field>
                  </Column>
                </Row>
                <Row>
                  <Column width={16}>
                    <ButtonBar
                      onSave={handleSubmit}
                      link="/organization/areas"
                      disabled={isSubmitting}
                    />
                  </Column>
                </Row>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Segment>
  );
});
