import * as React from 'react';
import * as classNames from 'classnames';
import { inject } from 'mobx-react';
import * as moment from 'moment';
import { isInclusivelyAfterDay, isInclusivelyBeforeDay } from 'react-dates';
import { Checkbox, Dropdown, Form, FormButton, Icon, Input, Modal } from 'semantic-ui-react';
import {
  EmployeeStore,
  Moment,
  IStaffEmployee,
  WithID,
  PositionStore,
  IPosition,
  ProbationPeriodType,
} from 'sol-data';
import InjectedComponent from '../InjectedComponent';
import { SingleDatePicker } from '../SingleDatePicker';
import style from './index.less';

interface Props {
  open: boolean;
  onClose: () => void;
  employee: WithID<IStaffEmployee>;
  department: string;
  onSubmit: () => void;
}

const probationPeriodTypeOptions = [
  {
    key: ProbationPeriodType.Hours,
    value: ProbationPeriodType.Hours,
    text: 'Hours',
  },
  {
    key: ProbationPeriodType.Days,
    value: ProbationPeriodType.Days,
    text: 'Days',
  },
];

type EmployeeInfoUpdate = {
  startDate?: Moment | null;
  probationEndAt?: Moment | null;
  hoursWorked?: number;
  probationPeriod?: number | null;
  probationPeriodType?: ProbationPeriodType | null;
  isProbationEndDateActive?: boolean;
  isProbationPeriodActive?: boolean;
};

interface State {
  employee: WithID<IStaffEmployee>;
  employeeUpdate: EmployeeInfoUpdate;
  isSaving: boolean;
  position?: WithID<IPosition>;
}

interface Injected {
  employeeStore: EmployeeStore;
  positionStore: PositionStore;
}

@inject('employeeStore')
@inject('positionStore')
class EmployeeProbationModal extends InjectedComponent<Props, State, Injected> {
  state: State = {
    employee: this.props.employee,
    employeeUpdate: {
      startDate: this.props.employee.startDate,
      isProbationEndDateActive: !!this.props.employee.isProbationEndDateActive,
      isProbationPeriodActive: !!this.props.employee.isProbationPeriodActive,
      probationEndAt: this.props.employee.probationEndAt,
      probationPeriod: this.props.employee.probationPeriod,
      probationPeriodType: this.props.employee.probationPeriodType,
    },

    isSaving: false,
  };

  componentDidMount(): void {
    const position = this.injected.positionStore.get(this.props.employee.position.id);
    this.setState((p) => ({
      ...p,
      position,
    }));
  }

  componentDidUpdate(): void {
    if (this.state.employee?.id !== this.props.employee.id) {
      this.setState((prev) => ({ ...prev, employee: this.props.employee }));
    }
  }

  changeEmployeeUpdate(key: keyof EmployeeInfoUpdate, value: any) {
    this.setState((prev) => ({
      ...prev,
      employeeUpdate: {
        ...prev.employeeUpdate,
        [key]: value,
      },
    }));
  }

  handleSubmit = async () => {
    this.setState((prev) => ({ ...prev, isSaving: true }));

    const {
      isProbationEndDateActive,
      isProbationPeriodActive,
      probationEndAt,
      probationPeriod,
      probationPeriodType,
      startDate,
    } = this.state.employeeUpdate;

    const probationPeriodValue =
      !probationPeriod || isNaN(Number(probationPeriod)) ? null : Number(probationPeriod);

    await this.injected.employeeStore.update(this.state.employee.id, {
      isProbationEndDateActive,
      isProbationPeriodActive,
      probationEndAt,
      probationPeriod: probationPeriodValue,
      probationPeriodType,
      startDate,
    });

    this.setState((prev) => ({ ...prev, isSaving: false }));
    this.props.onSubmit();
  };

  renderBody() {
    const { employee, employeeUpdate } = this.state;

    const { isProbationEndDateActive, isProbationPeriodActive } = employeeUpdate;

    return (
      <div className={style.body}>
        {/*   start / probation end    */}
        <div className={style.grid_2_col}>
          <div className={classNames(style.grid, style.date_container)}>
            <label className={style.form_headers}>Start Date</label>
            <SingleDatePicker
              hideKeyboardShortcutsPanel
              noBorder
              block
              id="startDate"
              date={employeeUpdate.startDate || employee?.startDate || null}
              placeholder="MM/DD/YYYY"
              onDateChange={(date) => {
                this.changeEmployeeUpdate('startDate', date);
              }}
              isOutsideRange={(day) => !isInclusivelyBeforeDay(day, moment())}
              numberOfMonths={1}
            />
          </div>
          <div className={classNames(style.grid, style.date_container)}>
            <label className={style.form_headers}>{this.getProbationToggle()}</label>
            <SingleDatePicker
              disabled={!isProbationEndDateActive || isProbationPeriodActive}
              hideKeyboardShortcutsPanel
              noBorder
              block
              id="probationEndAt"
              date={employeeUpdate.probationEndAt || employee?.probationEndAt || null}
              placeholder="MM/DD/YYYY"
              onDateChange={(date) => {
                this.changeEmployeeUpdate('probationEndAt', date);
              }}
              isOutsideRange={(day) =>
                employee.startDate
                  ? !isInclusivelyAfterDay(day, moment(employee.startDate).add(1, 'day'))
                  : false
              }
              numberOfMonths={1}
            />
          </div>
        </div>
        {/*   last day / status    */}

        {/*   status change / fte    */}
        <div className={style.grid_2_col}>
          <div className={classNames(style.grid, style.date_container)}>
            <label className={style.form_headers}>Hours Worked</label>

            <Input
              placeholder="Hours Worked"
              name="hoursWorked"
              type="number"
              disabled
              value={employee.hoursWorked}
              onChange={(val) => this.changeEmployeeUpdate('hoursWorked', val.currentTarget.value)}
            />
          </div>
          <div>
            <label className={style.form_headers}>{this.getHoursRequiredToggle()}</label>
            <div className={style.period_container}>
              <Input
                disabled={!isProbationPeriodActive || isProbationEndDateActive}
                placeholder={
                  this.state.position?.probationPeriod
                    ? `Default: ${this.state.position?.probationPeriod}`
                    : 'Hours Required'
                }
                name="hoursRequired"
                value={employeeUpdate.probationPeriod}
                onChange={(val) =>
                  this.changeEmployeeUpdate('probationPeriod', val.currentTarget.value)
                }
              />
              <Dropdown
                name="Period Type"
                value={
                  (employeeUpdate.probationPeriod
                    ? employeeUpdate.probationPeriodType
                    : this.state.position?.probationPeriodType) ?? ProbationPeriodType.Hours
                }
                selection
                disabled={
                  !employeeUpdate.isProbationPeriodActive || !employeeUpdate.probationPeriod
                }
                options={probationPeriodTypeOptions}
                onChange={(_, { value }) => {
                  this.changeEmployeeUpdate('probationPeriodType', value);
                }}
                className={style.dropdowns}
              />
            </div>
          </div>
        </div>
        <div className={style.action_container}>
          <FormButton onClick={this.props.onClose} className={style.btn_default}>
            CANCEL
          </FormButton>
          <FormButton
            loading={this.state.isSaving}
            onClick={() => this.handleSubmit()}
            className={style.btn_primary}
            disabled={this.state.isSaving}
          >
            SAVE
          </FormButton>
        </div>
      </div>
    );
  }

  getProbationToggle = () => {
    const { isProbationEndDateActive, isProbationPeriodActive } = this.state.employeeUpdate;

    return (
      <Form.Field className={style.toggle_label} inline>
        <p>End of Probation</p>
        <Checkbox
          disabled={isProbationPeriodActive}
          className={style.checkbox}
          toggle
          label={isProbationEndDateActive ? 'Applied' : 'Not Apply'}
          checked={isProbationEndDateActive}
          onChange={(_, { checked }) =>
            this.changeEmployeeUpdate('isProbationEndDateActive', checked)
          }
        />
      </Form.Field>
    );
  };

  getHoursRequiredToggle = () => {
    const { isProbationEndDateActive, isProbationPeriodActive } = this.state.employeeUpdate;

    return (
      <Form.Field className={style.toggle_label} inline>
        <p>Period Required</p>
        <Checkbox
          disabled={isProbationEndDateActive}
          className={style.checkbox}
          toggle
          label={isProbationPeriodActive ? 'Applied' : 'Not Apply'}
          checked={isProbationPeriodActive}
          onChange={(_, { checked }) =>
            this.changeEmployeeUpdate('isProbationPeriodActive', checked)
          }
        />
      </Form.Field>
    );
  };

  render() {
    const { open, onClose, employee, department } = this.props;
    return (
      <Modal
        className={style.main_modal}
        open={open}
        onClose={onClose}
        size="small"
        closeIcon={<Icon name="close" size="tiny" color="black" />}
      >
        <Modal.Header className={style.content}>
          <section className={style.modal_header}>
            <p>Employee Information</p>
            <h3>
              {employee.user.lastName},{employee.user.firstName}
            </h3>
            <p>{department}, Job Type</p>
          </section>
        </Modal.Header>
        <Modal.Content className={style.content}>{this.renderBody()}</Modal.Content>
      </Modal>
    );
  }
}

export default EmployeeProbationModal;
