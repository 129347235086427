import { Button, Form } from '@/semantic-ui/components';
import { useStores } from '@/store';
import * as Sentry from '@sentry/react';
import { Formik } from 'formik';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useNavigate, useSearchParams } from 'react-router';
import { object, string } from 'yup';
import { Pane } from '..';
import style from '../index.less';

const { Field, Input } = Form;

interface LoginComponentProps {
  changePane: (pane: Pane) => void;
}

export const Login = observer(({ changePane }: LoginComponentProps) => {
  const { userStore } = useStores();
  const [searchParams] = useSearchParams();
  const redirectPath = searchParams.get('redirect');
  const navigate = useNavigate();

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
      }}
      validationSchema={object().shape({
        email: string().required('Please enter your email'),
        password: string().required('Please enter your password'),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        const { email, password } = values;

        try {
          const user = await userStore.login(email, password);

          if (user) {
            Sentry.setUser({ id: user.id, email: user.email });
          }

          if (redirectPath) {
            navigate(redirectPath);
          } else {
            navigate('/');
          }
        } catch (e) {
          setSubmitting(false);
        } finally {
          setSubmitting(false);
        }
      }}
      render={({
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => {
        const showEmailError = !!touched.email && !!errors.email;
        const showPasswordError = !!touched.password && !!errors.password;
        const { errorMessage } = userStore;

        return (
          <Form className={style.login_form} onSubmit={handleSubmit}>
            <p className={style.error_message}>{errorMessage}</p>

            <Field error={showEmailError}>
              <Input
                type="text"
                name="email"
                placeholder="Email Address"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
              />
              {showEmailError && <p className={style.error_message}>{errors.email}</p>}
            </Field>
            <Field error={showPasswordError}>
              <Input
                type="password"
                name="password"
                placeholder="Password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
              />
              {showPasswordError && <p className={style.error_message}>{errors.password}</p>}
            </Field>
            <Button loading={isSubmitting} primary width="100%" type="submit">
              Login
            </Button>
            <button type="button" onClick={() => changePane(Pane.Reset)}>
              Forgot password?
            </button>
          </Form>
        );
      }}
    />
  );
});
