import AppContext from '@/AppContext';
import { useAsyncEffect } from '@/hooks';
import { Column } from '@/semantic-ui/components';
import { useStores } from '@/store';
import { isNaN } from 'lodash';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useContext, useState } from 'react';
import { useSearchParams } from 'react-router';
import { Dimmer, Form, Grid, Loader, Pagination, Table } from 'semantic-ui-react';
import {
  Authority,
  Department,
  ID,
  ProbationOrderByField,
  ProbationPeriodType,
  Staff,
  isProbationOrderByField,
  toNum,
} from 'sol-data';
import Bulletin from '../Bulletin';
import EmployeeFilterDropdown from '../EmployeeFilterDropdown';
import EmployeeProbationModal from '../EmployeeProbationModal';
import PositionFilterDropdown from '../PositionFilterDropdown';
import ProbationPeriodOverviewModal from '../ProbationPeriodOverviewModal';
import style from './index.less';

interface Props {
  departments: Department[];
}

interface State {
  selectedEmployee?: Staff;
  isEditModalOpen: boolean;
  isProbationModalOpen: boolean;
}

const queryParams = {
  page: 'probationPage',
  employee: 'probationEmployee',
  position: 'probationPosition',
  order: 'probationOrder',
  sortBy: 'probationSortBy',
};

export const ProbationList = observer(({ departments }: Props) => {
  const { employeeStore, staffStore, userStore } = useStores();
  const appContext = useContext(AppContext);
  const [state, setState] = useState<State>({
    selectedEmployee: undefined,
    isEditModalOpen: false,
    isProbationModalOpen: false,
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get(queryParams.page) ? Number(searchParams.get(queryParams.page)) : 1;
  const employee = searchParams.get(queryParams.employee)
    ? Number(searchParams.get(queryParams.employee))
    : undefined;
  const position = searchParams.get(queryParams.position)
    ? Number(searchParams.get(queryParams.position))
    : undefined;
  const order = searchParams.get(queryParams.order) ?? undefined;
  const sortBy = searchParams.get(queryParams.sortBy) ?? undefined;

  useAsyncEffect(async () => {
    await changePage(page, employee, position, order, sortBy);
  }, [page, employee, position, order, sortBy]);

  const changePage = async (
    pageNumber: number,
    employeeId?: ID,
    positionId?: ID,
    sortOrder?: string,
    sortByField?: string,
  ) => {
    if (!pageNumber || isNaN(pageNumber)) {
      return;
    }

    searchParams.set(queryParams.page, pageNumber.toString());
    setSearchParams(searchParams);

    return fetchData(pageNumber, employeeId, positionId, sortOrder, sortByField);
  };

  const changeDropdown = async (type: string, id?: ID) => {
    const value = id ? id.toString() : undefined;

    switch (type) {
      case queryParams.employee:
        if (value) {
          searchParams.set(queryParams.employee, value);
          searchParams.delete(queryParams.position);
          return changePage(page, Number(value), undefined, order, sortBy);
        }

        searchParams.delete(type);
        setSearchParams(searchParams);
        return changePage(page, undefined, position, order, sortBy);
      case queryParams.position:
        if (value) {
          searchParams.set(queryParams.position, value);
          searchParams.delete(queryParams.employee);
          return changePage(page, undefined, Number(value), order, sortBy);
        }

        searchParams.delete(type);
        setSearchParams(searchParams);
        return changePage(page, employee, undefined, order, sortBy);
      default:
        return changePage(1, employee, position, order, sortBy);
    }
  };

  const fetchData = async (
    pageNumber?: number,
    employeeId?: ID,
    positionId?: ID,
    sortOrder?: string,
    sortByField?: string,
  ) => {
    const {
      scope: { positions },
    } = appContext;

    return staffStore.fetchProbationPage({
      pageOptions: { page: pageNumber ?? 1 },
      query: {
        employee: employeeId,
        positions: positionId ? [positionId] : positions || undefined,
        order: sortOrder === 'desc' ? 'desc' : 'asc',
        sortBy: sortByField && isProbationOrderByField(sortByField) ? sortByField : undefined,
      },
    });
  };

  const handleSortChange = async (value: ProbationOrderByField) => {
    searchParams.append(queryParams.order, 'asc');
    searchParams.append(queryParams.sortBy, value);

    if (sortBy === value) {
      const newOrder = order === 'asc' ? 'desc' : 'asc';
      searchParams.append(queryParams.order, newOrder);
    }

    setSearchParams(searchParams);

    return changePage(page, employee, position, order, value);
  };

  const renderTableHeader = () => {
    const currentUser = userStore.current;
    const [authority] = (currentUser?.access && currentUser.access.authority) || [
      Authority.ReadOnly,
    ];
    const isSuperAdmin = authority >= Authority.Super;
    const headerColumns = [
      <Table.HeaderCell className={`markBackTable ${style.table_header_fixed}`}>
        <div
          aria-hidden="true"
          className={style.sort_action}
          onClick={() => handleSortChange('description')}
        >
          EMPLOYEE NAME
        </div>
      </Table.HeaderCell>,
      <Table.HeaderCell className={`markBackTable ${style.table_header_fixed}`}>
        <div
          aria-hidden="true"
          className={style.sort_action}
          onClick={() => handleSortChange('department')}
        >
          DEPARTMENT
        </div>
      </Table.HeaderCell>,
      <Table.HeaderCell className={`markBackTable ${style.table_header_fixed}`}>
        <div
          aria-hidden="true"
          className={style.sort_action}
          onClick={() => handleSortChange('startDate')}
        >
          START DATE
        </div>
      </Table.HeaderCell>,
      <Table.HeaderCell className={`markBackTable ${style.table_header_fixed}`}>
        <div
          aria-hidden="true"
          className={style.sort_action}
          onClick={() => handleSortChange('probationEnd')}
        >
          END OF PROBATION
        </div>
      </Table.HeaderCell>,
      <Table.HeaderCell className={`markBackTable ${style.table_header_fixed}`}>
        <div
          aria-hidden="true"
          className={style.sort_action}
          onClick={() => handleSortChange('hoursWorked')}
        >
          HOURS WORKED
        </div>
      </Table.HeaderCell>,
      <Table.HeaderCell className={`markBackTable ${style.table_header_fixed}`}>
        <div
          aria-hidden="true"
          className={style.sort_action}
          onClick={() => handleSortChange('probationPeriod')}
        >
          PERIOD REQUIRED
        </div>
      </Table.HeaderCell>,
    ];
    if (isSuperAdmin) {
      headerColumns.push(
        <Table.HeaderCell className={`markBackTable ${style.table_header_fixed}`}>
          {' '}
        </Table.HeaderCell>,
      );
    }
    return headerColumns;
  };

  const renderTableBody = (sortedTableData: Staff[]) => {
    return sortedTableData.map((staffEmployee) => {
      const { props: employee } = staffEmployee;
      return (
        <Table.Row
          key={employee.id}
          className={employee.isProbationEnded && style.notification_board}
        >
          <Table.Cell textAlign="center" className={style.medium_column}>
            {`${employee.user.lastName},${employee.user.firstName}`}
          </Table.Cell>
          <Table.Cell textAlign="center">
            {getDepartmentLabels(employee.departments.map((d) => d.id))}
          </Table.Cell>
          <Table.Cell textAlign="center">{employee.startDate?.format('L')}</Table.Cell>
          <Table.Cell textAlign="center">
            {employee.probationEndAt && employee.isProbationEndDateActive
              ? employee.probationEndAt.format('L')
              : 'N/A'}
          </Table.Cell>
          <Table.Cell textAlign="center">{employee.hoursWorked?.toFixed(2)}</Table.Cell>

          <Table.Cell textAlign="center">
            {employee.finalProbationPeriod && employee.isProbationPeriodActive
              ? `${employee.finalProbationPeriod} ${
                  employee.finalProbationPeriodType === ProbationPeriodType.Days ? 'd' : 'h'
                }`
              : 'N/A'}
          </Table.Cell>
          {getActionButton(staffEmployee)}
        </Table.Row>
      );
    });
  };

  const getActionButton = (employee: Staff) => {
    const currentUser = userStore.current;
    const [authority] = (currentUser?.access && currentUser.access.authority) || [
      Authority.ReadOnly,
    ];
    const isSuperAdmin = authority >= Authority.Super;
    if (!isSuperAdmin) {
      return null;
    }

    return !employee.props.isProbationEnded ? (
      <Table.Cell textAlign="center">
        <div onClick={() => openEditModal(employee)} className={style.iconBtn}>
          {getIcon()}
        </div>
      </Table.Cell>
    ) : (
      <Table.Cell textAlign="center">
        <div onClick={() => openProbationModal(employee)} className={style.iconBtn}>
          {getIcon()}
        </div>
      </Table.Cell>
    );
  };

  const openEditModal = (employee: Staff) => {
    setState((prev) => ({ ...prev, selectedEmployee: employee, isEditModalOpen: true }));
  };

  const openProbationModal = (employee: Staff) => {
    setState((prev) => ({ ...prev, selectedEmployee: employee, isProbationModalOpen: true }));
  };

  const onModalEditClose = () => {
    setState((prev) => ({ ...prev, isEditModalOpen: false, selectedEmployee: undefined }));
  };

  const onModalProbationClose = () => {
    setState((prev) => ({
      ...prev,
      isProbationModalOpen: false,
      selectedEmployee: undefined,
    }));
  };

  const getDepartmentLabels = (deptIds: number[]) => {
    const deptArr = departments.filter((dept) => deptIds.includes(dept.id));
    return !deptArr.length ? 'N/A' : deptArr.map((d) => d.label).join(', ');
  };

  const getIcon = () => {
    return (
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.8005 12.6667C13.8005 13.0333 13.6699 13.3473 13.4085 13.6087C13.1476 13.8696 12.8339 14 12.4672 14H3.13385C2.76719 14 2.45319 13.8696 2.19185 13.6087C1.93097 13.3473 1.80052 13.0333 1.80052 12.6667L1.80052 6.71667L3.13385 8.05L3.13385 12.6667H12.4672V3.33333H7.83385L6.50052 2H12.4672C12.8339 2 13.1476 2.13044 13.4085 2.39133C13.6699 2.65267 13.8005 2.96667 13.8005 3.33333V12.6667ZM2.18385 5.21667L3.11719 4.26667L7.51719 8.66667H8.46719V7.73333L4.05052 3.31667L4.98385 2.36667L9.80052 7.16667V10H6.96719L2.18385 5.21667ZM4.98385 2.36667L2.18385 5.21667L0.517188 3.55C0.250521 3.28333 0.117188 2.96378 0.117188 2.59133C0.117188 2.21933 0.250521 1.90556 0.517188 1.65L1.46719 0.716667C1.72274 0.461111 2.03385 0.333333 2.40052 0.333333C2.76719 0.333333 3.0783 0.461111 3.33385 0.716667L4.98385 2.36667Z"
          fill="black"
        />
      </svg>
    );
  };

  if (staffStore.isLoadingProbation && !staffStore.probation)
    return (
      <Dimmer active inverted>
        <Loader size="large">Loading Probation</Loader>
      </Dimmer>
    );

  const totalPages = staffStore.probation?.meta.totalPages ?? 0;

  return (
    <div>
      <div className={style.header}>
        <Column className={style.header_employee_search}>
          <Form>
            <Form.Field>
              <EmployeeFilterDropdown
                search
                clearable
                name="employee"
                placeholder="Search by employee"
                value={employee ? toNum(employee) : undefined}
                onChange={(_, id) => changeDropdown(queryParams.employee, id)}
              />
            </Form.Field>
          </Form>
        </Column>
        <Column width={5} />
        <Column className={style.header_item}>
          <Form>
            <Form.Field>
              <PositionFilterDropdown
                limited
                clearable
                name="position"
                placeholder="Filter by position"
                positionId={position ? toNum(position) : undefined}
                onChange={(_, id) => changeDropdown(queryParams.position, id)}
              />
            </Form.Field>
          </Form>
        </Column>
      </div>
      {staffStore.errorProbation && <Bulletin error={staffStore.errorProbation} />}
      <div className={style.tables_container}>
        <div>
          <Table className={style.table} striped>
            <Table.Header>
              <Table.Row textAlign="center">{renderTableHeader()}</Table.Row>
            </Table.Header>
            <Table.Body>{renderTableBody(staffStore.probation?.data || [])}</Table.Body>
          </Table>
        </div>

        {state.isEditModalOpen && state.selectedEmployee && (
          <EmployeeProbationModal
            open={state.isEditModalOpen}
            onClose={onModalProbationClose}
            employee={state.selectedEmployee.props}
            department={getDepartmentLabels(
              state.selectedEmployee.props.departments.map((d) => d.id),
            )}
            onSubmit={async () => {
              await changePage(page, employee, position, order, sortBy);
              onModalEditClose();
            }}
          />
        )}

        {state.isProbationModalOpen && state.selectedEmployee && (
          <ProbationPeriodOverviewModal
            open={state.isProbationModalOpen}
            employee={state.selectedEmployee.props}
            openEditModal={() => {
              setState((prev) => ({
                ...prev,
                isEditModalOpen: true,
                isProbationModalOpen: false,
              }));
            }}
            onSubmit={async () => {
              await employeeStore.endProbation(state.selectedEmployee!.id);
              await changePage(page, employee, position, order, sortBy);
            }}
            onCancel={onModalProbationClose}
          />
        )}
      </div>
      <Grid centered>
        <Grid.Row>
          <Pagination
            activePage={page}
            totalPages={totalPages}
            onPageChange={(_, { activePage }) =>
              changePage(activePage as number, employee, position, order, sortBy)
            }
            siblingRange={2}
            boundaryRange={0}
            firstItem={{ content: 'First' }}
            lastItem={{ content: 'Last' }}
            ellipsisItem={null}
            prevItem={null}
            nextItem={null}
          />
        </Grid.Row>
      </Grid>
    </div>
  );
});
