import { Authority, User } from 'sol-data';

export const getEssentialsPermissions = (
  user: User | undefined,
): {
  read: boolean;
  write: boolean;
} => {
  const [authority] = (user?.access && user.access.authority) || [Authority.ReadOnly];
  if (authority >= Authority.Super) {
    return {
      read: true,
      write: true,
    };
  }
  if (authority >= Authority.Admin) {
    return {
      read: true,
      write: false,
    };
  }
  return {
    read: false,
    write: false,
  };
};
