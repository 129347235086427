import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { ScheduleComponentStore, PositionStore, ScheduleStore } from '@/store';
import { Button, Modal, Grid, Form } from '@/semantic-ui/components';
import classnames from 'classnames';
import InjectedComponent from '@/components/InjectedComponent';
import ModalBase from '@/components/ModalBase';
import ScheduleControls from '@/components/Schedule/ScheduleControls';
import { rowCellRatio } from '@/components/Schedule/utils';
import style from '../index.less';

const { Row, Column } = Grid;
const { Dropdown } = Form;

type ControlProps = {
  onLeftPress: () => void;
  onCenterPress: () => void;
  onRightPress: () => void;
};

export interface Props {
  controlProps: ControlProps;
}

interface State {
  modalOpen: boolean;
  positionId?: number;
}

interface Injected {
  scheduleComponentStore: ScheduleComponentStore;
  positionStore: PositionStore;
  scheduleStore: ScheduleStore;
}

@inject('scheduleComponentStore', 'positionStore', 'scheduleStore')
@observer
class LegendLayout extends InjectedComponent<Props, State, Injected> {
  state = {
    modalOpen: false,
    positionId: undefined,
  };

  openModal = () => this.setState({ modalOpen: true });

  changePosition = (positionId: number) => {
    this.setState({ positionId });
  };

  onAdd = async () => {
    const { positionId } = this.state;
    const { scheduleStore } = this.injected;
    const schedule = scheduleStore.forceGetCurrent();
    if (!positionId) return;

    try {
      await scheduleStore.createShiftGroup({
        schedule,
        position: positionId!, // ! because of ts bug
      });
    } finally {
      this.setState({ modalOpen: false });
    }
  };

  onCancel = () => {
    this.setState({ modalOpen: false });
  };

  render() {
    const {
      scheduleComponentStore: {
        headerHeight,
        actionHeight,
        sidebarWidth,
        isAreaView,
        showStickyHeader,
        cellDimens: { height: heightDimen },
      },
      positionStore: { dropdownOptions: positionOptions },
      controlProps,
    } = this.injected;

    const { children } = this.props;

    const { positionId, modalOpen } = this.state;

    return (
      <div className={style.schedule_side_bar} style={{ width: sidebarWidth }}>
        <div
          className={classnames(style.schedule_legend_row, {
            [style.schedule_legend_row_fixed]: showStickyHeader,
          })}
          style={showStickyHeader ? { width: `calc(3em + ${sidebarWidth})` } : undefined}
        >
          <div className={style.schedule_control_wrapper} style={{ height: headerHeight }}>
            <ScheduleControls {...controlProps} />
          </div>
          <div className={style.schedule_add_button} style={{ height: actionHeight }}>
            {!isAreaView && (
              <Modal
                size="tiny"
                open={modalOpen}
                onClose={this.onCancel}
                trigger={<Button circular icon="plus" color="blue" onClick={this.openModal} />}
              >
                <ModalBase
                  header="Add new shift line"
                  actionWord="Add"
                  onAccept={this.onAdd}
                  onCancel={this.onCancel}
                >
                  <Grid>
                    <Row>
                      <Column width={16}>
                        <Dropdown
                          fluid
                          selection
                          name="positionId"
                          placeholder="Select a position"
                          options={positionOptions}
                          value={positionId}
                          className={`${style.shift_modal_dropdown} field`}
                          onChange={(_, { value }) => this.changePosition(value as number)}
                        />
                      </Column>
                    </Row>
                  </Grid>
                </ModalBase>
              </Modal>
            )}
          </div>
        </div>
        <div style={showStickyHeader ? { height: `${heightDimen * rowCellRatio}px` } : undefined} />
        {children}
      </div>
    );
  }
}

export default LegendLayout;
