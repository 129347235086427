import * as React from 'react';
import { Link } from 'react-router';
import InjectedComponent from '../InjectedComponent';
import style from './index.less';

interface Props {
  items: { path: string; item: string }[];
  selected: string;
  onTabChange?: (arg: string) => void;
}

class Tabs extends InjectedComponent<Props, {}, {}> {
  render() {
    return (
      <div className={style.tabs_container}>
        {this.props.items.map(({ item, path }) => (
          <Link
            key={item}
            className={this.props.selected === item ? style.tab_selected : undefined}
            to={path}
            onClick={(e) => {
              if (this.props.onTabChange) {
                e.preventDefault();
                this.props.onTabChange(item);
              }
            }}
          >
            {item}
          </Link>
        ))}
      </div>
    );
  }
}

export default Tabs;
