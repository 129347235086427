import AppContext from '@/AppContext';
import Bulletin from '@/components/Bulletin';
import { ListColumn, ObservingListLayout } from '@/components/ListLayout';
import PositionFilterDropdown from '@/components/PositionFilterDropdown';
import SaveButtons from '@/components/SaveButtons';
import { SingleDatePicker } from '@/components/SingleDatePicker';
import { useAsyncEffect } from '@/hooks';
import defaultUserImage from '@/images/default-user-image.png';
import {
  Button,
  Checkbox,
  Dropdown,
  Form,
  Grid,
  Image,
  Input,
  Radio,
} from '@/semantic-ui/components';
import { useStores } from '@/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import * as Cleave from 'cleave.js/react';
import { FieldArray, Formik } from 'formik';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useMemo, useState } from 'react';
import { isInclusivelyBeforeDay } from 'react-dates';
import { useParams } from 'react-router';
import { EmployeeRate, HasID, moment, Moment, PhoneNumber } from 'sol-data';
import { array, number, object, string } from 'yup';
import style from './index.less';

const phoneTypes = [
  {
    key: 'mobile',
    text: 'Mobile',
    value: 'mobile',
  },
  {
    key: 'home',
    text: 'Home',
    value: 'home',
  },
];

const { Field } = Form;

export interface InitialValues {
  firstName: string;
  lastName: string;
  dateOfBirth: Moment;
  email: string;
  phoneNumbers: PhoneNumber[];
  qbEmployeeId: string | null;
  startDate: Moment | null;
  positionId: number;
  departments: HasID[];
  areas: HasID[];
  neverOvertime: boolean;
  isSalary: boolean;
}

export const EmployeeProfile = observer(() => {
  const params = useParams();
  const employeeId = Number(params.employeeId);
  const { employeeStore, departmentStore, areaStore, positionStore, payrollStore, userStore } =
    useStores();
  const [profileImageUrl, setProfileImageUrl] = useState<string | undefined>(undefined);

  useAsyncEffect(async () => {
    await payrollStore.fetchAll();

    const userId = employeeStore.currentDetail?.user?.id;

    if (userId) {
      const imageUrl = await userStore.getProfileImageUrl(userId);
      setProfileImageUrl(imageUrl);
    }
  }, [employeeId]);

  const handleDismiss = () => {
    employeeStore.clearError();
  };

  const columns = useMemo<ListColumn<EmployeeRate>[]>(() => {
    const { items } = payrollStore;

    return [
      {
        name: 'ID',
        key: 'id',
        width: 2,
        render: ({ payrollItem }) => <span>{payrollItem.id.toString().padStart(4, '0')}</span>,
      },
      {
        name: 'ITEM',
        key: 'item',
        width: 10,
        render: ({ payrollItem }) => (
          <span>{items.has(payrollItem) ? items.get(payrollItem)!.description : 'Unknown'}</span>
        ),
      },
      {
        name: 'RATE',
        key: 'rate',
        width: 4,
        render: ({ rate }) => <span>{rate.toFixed(2)}</span>,
      },
    ];
  }, [payrollStore]);

  const { currentDetail: employee, errorMessage, showLoading } = employeeStore;
  const { values: departmentList } = departmentStore;
  const { values: areaList } = areaStore;

  if (!employee) return null;

  const trimmedNumbers =
    employee.user.phoneNumbers &&
    employee.user.phoneNumbers.filter(({ phoneNumber }) => phoneNumber.length);

  const initialValues: InitialValues = {
    firstName: employee.user.firstName,
    lastName: employee.user.lastName,
    dateOfBirth: employee.user.dateOfBirth || moment(),
    email: employee.user.email || 'Hidden',
    phoneNumbers: trimmedNumbers || [],
    qbEmployeeId: employee.qbEmployeeId || null,
    startDate: employee.startDate || null,
    positionId: employee.position.id,
    departments: employee.departments || [],
    areas: employee.areas || [],
    neverOvertime: employee.neverOvertime || false,
    isSalary: employee.isSalary || false,
  };

  return (
    <AppContext.Consumer>
      {({ isPayrollAdmin, isAdmin }) => (
        <Formik
          validationSchema={object().shape({
            firstName: string(),
            lastName: string(),
            email: string(),
            dateOfBirth: string(),
            phoneNumbers: array().of(
              object().shape({
                type: string().required('Type is required'),
                phoneNumber: string()
                  .matches(
                    /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im,
                    'Please enter a valid phone number',
                  )
                  .required('Phone number is required'),
              }),
            ),
            startDate: string().typeError('Date must be in MM/DD/YYYY').nullable(),
            positionId: number()
              .typeError('Please select a position.')
              .required('Position is required'),
            departments: array(),
            areas: array(),
          })}
          initialValues={initialValues}
          onSubmit={async (values, { setSubmitting }) => {
            employee.user.assign({
              phoneNumbers: values.phoneNumbers,
            });

            employee.assign({
              startDate: values.startDate,
              position: { id: values.positionId },
              departments: values.departments,
              areas: values.areas,
              neverOvertime: values.neverOvertime,
              isSalary: values.isSalary,
            });

            await employeeStore.save(employee);

            setSubmitting(false);
          }}
          render={({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
          }) => {
            const showStartDateError = !!touched.startDate && !!errors.startDate;
            const showPositionError = !!touched.positionId && !!errors.positionId;

            const arrayChangeHandler =
              <I extends { label: string }>(arrName: string, items: I[], selected: I[]) =>
              (_: React.SyntheticEvent, { name, checked }: { name: string; checked: boolean }) => {
                if (!checked) {
                  const filteredItems = selected.filter((item) => item.label !== name);
                  setFieldValue(arrName, filteredItems);
                  if (arrName === 'departments') {
                    const deptId = departmentList.find((item) => item.label === name)!.id;
                    setFieldValue(
                      'areas',
                      values.areas.filter(({ id }) => areaStore.get(id)!.departmentId !== deptId),
                    );
                  }
                } else {
                  const exists = selected.some((item) => item.label === name);
                  if (!exists) {
                    const foundItem = items.find((item) => item.label === name);

                    if (foundItem) {
                      setFieldValue(arrName, [...selected, foundItem]);
                    }
                  }
                }
              };

            const handleDepartmentChange = arrayChangeHandler(
              'departments',
              departmentList,
              values.departments.map((id) => departmentStore.get(id)!),
            );

            const handleAreaChange = arrayChangeHandler(
              'areas',
              areaList,
              values.areas.map((id) => areaStore.get(id)!),
            );

            const mappedDepartments = departmentList.map((department) => ({
              ...department,
              checked:
                !!values.departments && values.departments.some(({ id }) => id === department.id),
            }));

            const mappedAreas = areaList
              .map((area) => ({
                ...area,
                checked: !!values.areas && values.areas.some(({ id }) => id === area.id),
              }))
              .filter(
                (area) =>
                  mappedDepartments.find(
                    (department) => department.id === area.departmentId && department.checked,
                  ) !== undefined,
              );

            return (
              <Form className={style.profile_form} onSubmit={handleSubmit}>
                <Bulletin
                  isLoading={showLoading}
                  errorMessage={errorMessage}
                  handleDismiss={handleDismiss}
                />
                <Grid className={style.tab_spacer}>
                  <Grid.Row columns={2}>
                    <Grid.Column computer={11} tablet={13}>
                      <Grid>
                        <Grid.Row columns={2}>
                          <Grid.Column>
                            <Form.Field>
                              <p className={style.form_headers}>First Name</p>
                              <Input
                                disabled
                                name="firstName"
                                value={values.firstName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </Form.Field>
                          </Grid.Column>
                          <Grid.Column>
                            <Form.Field>
                              <p className={style.form_headers}>Last Name</p>
                              <Input
                                disabled
                                name="lastName"
                                value={values.lastName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </Form.Field>
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={2}>
                          <Grid.Column>
                            <Form.Field>
                              <p className={style.form_headers}>Email</p>
                              <Input
                                disabled
                                name="email"
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </Form.Field>
                          </Grid.Column>
                          <Grid.Column>
                            <p className={classnames(style.form_headers, style.disabled_header)}>
                              Date of Birth
                            </p>
                            <Form.Field className={style.profile_form_dob}>
                              <SingleDatePicker
                                disabled
                                hideKeyboardShortcutsPanel
                                noBorder
                                block
                                id="date_of_birth"
                                date={values.dateOfBirth}
                                placeholder="MM/DD/YYYY"
                                onDateChange={(date) => setFieldValue('dateOfBirth', date)}
                                isOutsideRange={(day) => !isInclusivelyBeforeDay(day, moment())}
                                numberOfMonths={1}
                              />
                            </Form.Field>
                          </Grid.Column>
                        </Grid.Row>
                        {isAdmin && (
                          <>
                            <div className={style.profile_phone_header}>
                              <p className={style.form_headers}>Phone Numbers</p>
                              {!values.phoneNumbers.length && (
                                <Button
                                  circular
                                  size="mini"
                                  type="button"
                                  color="blue"
                                  icon={<FontAwesomeIcon icon={['far', 'plus']} />}
                                  onClick={() =>
                                    setFieldValue('phoneNumbers', [
                                      ...values.phoneNumbers,
                                      {
                                        type: phoneTypes[0].value,
                                        phoneNumber: '',
                                      },
                                    ])
                                  }
                                />
                              )}
                            </div>

                            {values.phoneNumbers.map(({ type, phoneNumber }, i) => {
                              let typeError;
                              let numberError;

                              if (errors.phoneNumbers && errors.phoneNumbers[i]) {
                                typeError = errors.phoneNumbers[i]!.type;
                                numberError = errors.phoneNumbers[i]!.phoneNumber;
                              }

                              return (
                                <Grid.Row key={i}>
                                  <Grid.Column width={6}>
                                    <Dropdown
                                      name="phoneNumbers"
                                      value={type}
                                      selection
                                      options={phoneTypes}
                                      error={!!typeError}
                                      onChange={(_, { value }) => {
                                        setFieldValue(
                                          'phoneNumbers',
                                          values.phoneNumbers.map((phone, idx) => {
                                            if (i === idx) {
                                              return { ...phone, type: value };
                                            }
                                            return phone;
                                          }),
                                        );
                                      }}
                                      className={style.dropdowns}
                                    />
                                    {typeError && <p className={style.error_class}>{typeError}</p>}
                                  </Grid.Column>
                                  <Grid.Column computer={8} tablet={7}>
                                    <Cleave
                                      options={{
                                        numericOnly: true,
                                        blocks: [0, 3, 3, 4],
                                        delimiters: ['(', ') ', '-'],
                                      }}
                                      name="phoneNumbers"
                                      value={phoneNumber}
                                      placeholder="(123) 456-7890"
                                      className={style.inputs}
                                      onChange={({ target: { value } }) => {
                                        setFieldValue(
                                          'phoneNumbers',
                                          values.phoneNumbers.map((phone, idx) => {
                                            if (i === idx) {
                                              return { ...phone, phoneNumber: value };
                                            }
                                            return phone;
                                          }),
                                        );
                                      }}
                                      onBlur={handleBlur}
                                    />
                                    {!!numberError && (
                                      <p className={style.error_class}>{numberError}</p>
                                    )}
                                  </Grid.Column>
                                  <Grid.Column
                                    computer={2}
                                    tablet={3}
                                    className={style.profile_form_remove_number}
                                  >
                                    <Button
                                      circular
                                      size="mini"
                                      type="button"
                                      color="red"
                                      icon="minus"
                                      onClick={() => {
                                        setFieldValue(
                                          'phoneNumbers',
                                          values.phoneNumbers.filter((_, idx) => i !== idx),
                                        );
                                      }}
                                    />
                                    {i === values.phoneNumbers.length - 1 && (
                                      <Button
                                        circular
                                        size="mini"
                                        type="button"
                                        color="blue"
                                        className={style.profile_delete_button}
                                        icon={<FontAwesomeIcon icon={['far', 'plus']} />}
                                        onClick={() =>
                                          setFieldValue('phoneNumbers', [
                                            ...values.phoneNumbers,
                                            {
                                              type: phoneTypes[0].value,
                                              phoneNumber: '',
                                            },
                                          ])
                                        }
                                      />
                                    )}
                                  </Grid.Column>
                                </Grid.Row>
                              );
                            })}
                          </>
                        )}

                        <Grid.Row columns={2}>
                          <Grid.Column>
                            <Field className={style.flex_group_field} error={showPositionError}>
                              <p className={style.form_headers}>Position</p>
                              <PositionFilterDropdown
                                name="positionId"
                                onChange={(_, value) => setFieldValue('positionId', value)}
                                positionId={values.positionId}
                                disabled={!isAdmin}
                              />
                              {showPositionError && (
                                <p className={style.error_class}>{errors.positionId}</p>
                              )}
                            </Field>
                          </Grid.Column>
                          <Grid.Column>
                            <Field
                              className={`${style.start_date} ${style.flex_group_field}`}
                              error={showStartDateError}
                            >
                              <p className={style.form_headers}>Start Date</p>
                              <SingleDatePicker
                                hideKeyboardShortcutsPanel
                                noBorder
                                block
                                id="start_date"
                                date={values.startDate || null}
                                placeholder="MM/DD/YYYY"
                                onDateChange={(date) => setFieldValue('startDate', date)}
                                isOutsideRange={() => false}
                                numberOfMonths={1}
                              />
                              {showStartDateError && (
                                <p className={style.error_class}>{errors.startDate}</p>
                              )}
                            </Field>
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Column width={16}>
                            <p className={style.form_headers}>Departments</p>
                            <FieldArray
                              name="departments"
                              render={() => (
                                <div className={style.position_wrapper_checkboxes}>
                                  {mappedDepartments.map((department) => (
                                    <Field
                                      id={`department_${department.id}`}
                                      key={department.id}
                                      label={department.description}
                                      name={department.label}
                                      control={Checkbox}
                                      checked={department.checked}
                                      onChange={handleDepartmentChange}
                                      className={style.checkboxes}
                                      disabled={!isAdmin}
                                    />
                                  ))}
                                </div>
                              )}
                            />
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Column width={16}>
                            <p className={style.form_headers}>Can Be Assigned To:</p>
                            <FieldArray
                              name="departments"
                              render={() => (
                                <div className={style.position_wrapper_checkboxes}>
                                  {mappedAreas.map((area) => (
                                    <Field
                                      id={`area_${area.id}`}
                                      key={area.id}
                                      label={area.label}
                                      name={area.label}
                                      control={Checkbox}
                                      checked={area.checked}
                                      onChange={handleAreaChange}
                                      className={style.checkboxes}
                                      disabled={!isAdmin}
                                    />
                                  ))}
                                </div>
                              )}
                            />
                          </Grid.Column>
                        </Grid.Row>
                        {isPayrollAdmin && (
                          <Grid.Row>
                            <Grid.Column width={16}>
                              <div className={style.flex_between}>
                                <p className={style.form_headers}>Rates</p>
                                <div className={style.flex_between}>
                                  <p>Employee is salaried</p>
                                  <Radio
                                    toggle
                                    className={style.radio}
                                    checked={values.isSalary}
                                    onChange={() => setFieldValue('isSalary', !values.isSalary)}
                                  />
                                </div>
                                <div className={style.flex_between}>
                                  <p>Employee will never qualify for overtime</p>
                                  <Radio
                                    toggle
                                    className={style.radio}
                                    checked={values.neverOvertime}
                                    onChange={() =>
                                      setFieldValue('neverOvertime', !values.neverOvertime)
                                    }
                                  />
                                </div>
                              </div>
                              <ObservingListLayout columns={columns} items={employee.rates || []} />
                            </Grid.Column>
                          </Grid.Row>
                        )}
                      </Grid>
                    </Grid.Column>
                    <Grid.Column computer={5} tablet={3}>
                      <Image
                        rounded
                        size="medium"
                        src={profileImageUrl ?? defaultUserImage}
                        centered
                      />
                      {isAdmin && (
                        <Grid.Row>
                          <Grid.Column width={16} className={style.position_right}>
                            <SaveButtons
                              onClick={handleSubmit}
                              link="/employees"
                              disabled={isSubmitting}
                            />
                          </Grid.Column>
                        </Grid.Row>
                      )}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form>
            );
          }}
        />
      )}
    </AppContext.Consumer>
  );
});
