import RequestsList from '@/components/RequestsList';
import { useAsyncEffect } from '@/hooks';
import { fetchMissingEmployees } from '@/lib/utils';
import { useStores } from '@/store';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useParams, useSearchParams } from 'react-router';
import { Grid, Pagination } from 'semantic-ui-react';
import { HasID } from 'sol-data';

export const EmployeeRequests = observer(() => {
  const { requestsStore, employeeStore, areaStore, shiftStore } = useStores();
  const params = useParams<{ employeeId: string }>();
  const employeeId = Number(params.employeeId);
  const [searchParams, setSearchParams] = useSearchParams();

  const fetchData = async () => {
    const page = searchParams.get('page') ? Number(searchParams.get('page')) : 1;

    // Show all the pending requests
    const pendingRequests = await requestsStore.fetchPage(
      { page: 1, count: 1000 },
      { employee: employeeId, pending: true },
    );

    // Show all the actioned requests for the selected page
    const actionedRequests = await requestsStore.fetchPage(
      { page, count: 10 },
      { employee: employeeId, pending: false },
    );

    const allRequests = pendingRequests.concat(actionedRequests);

    await Promise.all<any>([
      employeeStore.isEmpty && employeeStore.fetchAll(),
      areaStore.isEmpty && areaStore.fetchAll(),
    ]);

    fetchMissingEmployees(employeeStore, allRequests);

    const shifts = allRequests.reduce<HasID[]>((acc, req) => {
      if ('shift' in req.$) {
        acc.push(req.$.shift);
      } else if ('shifts' in req.$) {
        acc.push(...(req.$.shifts || []));
      } else if ('shiftFrom' in req.$) {
        acc.push(req.$.shiftFrom, req.$.shiftTo);
      }

      return acc;
    }, []);

    await shiftStore.fetchShifts(shifts);
  };

  const changePage = async (page: number) => {
    if (!page || Number.isNaN(page)) {
      return;
    }

    requestsStore.pastView.setFilters({ page });

    searchParams.set('page', page.toString());
    setSearchParams(searchParams);

    await fetchData();
  };

  useAsyncEffect(async () => {
    const page = searchParams.get('page');

    await changePage(page ? parseFloat(page) : 1);

    return () => {
      requestsStore.clearFilters();
    };
  }, [requestsStore]);

  const { totalPages } = requestsStore;

  return (
    <>
      <h4>Upcoming</h4>
      <RequestsList requests={requestsStore.pendingView.filtered} fetchData={fetchData} />
      <h4>Past</h4>
      <RequestsList requests={requestsStore.pastView.filtered} />
      {totalPages > 1 && (
        <Grid centered>
          <Grid.Row>
            <Pagination
              activePage={requestsStore.pastView.filters.page}
              totalPages={totalPages}
              onPageChange={(_, { activePage }) => changePage(activePage as number)}
              siblingRange={2}
              boundaryRange={0}
              firstItem={{ content: 'First' }}
              lastItem={{ content: 'Last' }}
              ellipsisItem={null}
              prevItem={null}
              nextItem={null}
            />
          </Grid.Row>
        </Grid>
      )}
    </>
  );
});
