import * as React from 'react';
import { Formik } from 'formik';
import { inject, observer } from 'mobx-react';
import { ScheduleStore, AreaStore } from '@/store';
import { Grid, Row, Column, Form, Segment, Radio } from '@/semantic-ui/components';
import { SingleDatePicker } from 'react-dates';
import isAfterDay from 'react-dates/lib/utils/isAfterDay';
import isBeforeDay from 'react-dates/lib/utils/isBeforeDay';
import { object, string, number } from 'yup';
import {
  moment,
  Moment,
  EntityReference,
  Shift,
  IShift,
  Area,
  Position,
  Time,
  PositionScheduleShiftGroup,
  IShiftGroup,
  ShiftGroup,
} from 'sol-data';
import InjectedComponent from '@/components/InjectedComponent';
import ModalBase from '@/components/ModalBase';
import Bulletin from '@/components/Bulletin';
import TimePicker from '@/components/TimePicker';
import style from './index.less';

const { Dropdown, Field } = Form;

const defaultValues = {
  date: null,
  areaId: undefined,
  startTime: undefined,
  endTime: undefined,
  label: undefined,
  overrideOvertime: false,
};

interface Props {
  shift: Shift['WithID'];
  shiftGroup: IShiftGroup | PositionScheduleShiftGroup | ShiftGroup;
  onSubmit: (shift: IShift, overrideOvertime: boolean) => void;
  closeModal: () => void;
}

interface Injected {
  areaStore: AreaStore;
  scheduleStore: ScheduleStore;
}

interface State {
  isFocused: boolean;
}

interface InitialValues {
  id?: number;
  date: Moment | null;
  startTime: string | undefined;
  endTime: string | undefined;
  areaId: number | undefined;
  overrideOvertime: boolean;
}

interface SubmittedValues extends InitialValues {
  date: Moment;
  startTime: string;
  endTime: string;
  areaId: number;
}

@inject('areaStore', 'scheduleStore')
@observer
class EditShiftModal extends InjectedComponent<Props, State, Injected> {
  static defaultProps = {
    shift: undefined,
  };

  state = {
    isFocused: false,
    overrideOvertime: false,
  };

  async componentDidMount() {
    const { areaStore } = this.injected;

    await areaStore.fetchAll();
  }

  componentWillUnmount() {
    const { scheduleStore } = this.injected;
    scheduleStore.clearError();
  }

  handleFocus = () =>
    this.setState((state) => ({
      ...state,
      isFocused: !state.isFocused,
    }));

  render() {
    const { onSubmit, shiftGroup, shift, closeModal, areaStore, scheduleStore } = this.injected;

    const { isLoading } = scheduleStore;

    const schedule = scheduleStore.forceGetCurrent();

    const areas = areaStore.getAreasForDepartment(schedule.department.id);
    const areaOptions = areas.map((area) => ({
      text: area.label,
      value: area.id,
    }));

    const { isFocused } = this.state;

    const initialValues: InitialValues = !shift
      ? defaultValues
      : {
          id: shift.id,
          date: shift.startDate,
          startTime: shift.startDate.format('HH:mm'),
          endTime: shift.endDate.format('HH:mm'),
          areaId: shift.area.id,
          overrideOvertime: false,
        };

    const shiftDates = (shiftGroup.shifts || [])
      .flat()
      .filter((s) => !!s)
      .map(({ $: { startDate } }) => startDate.format('MM DD YYYY'));

    return (
      <>
        <Segment>
          <Bulletin
            isLoading={isLoading}
            errorMessage={scheduleStore.errorMessage}
            handleDismiss={() => scheduleStore.clearError()}
          />
        </Segment>
        <Formik
          validationSchema={object().shape({
            id: number(),
            date: string().typeError('Please select a date').required(),
            areaId: number().typeError('Please select an area').required('Please select an area'),
            startTime: string()
              .matches(Time.regex, 'Please enter a valid time')
              .typeError('Please select a start time')
              .required('Please select a start time'),
            endTime: string()
              .matches(Time.regex, 'Please enter a valid time')
              .typeError('Please select an end time')
              .required('Please select an end time'),
          })}
          initialValues={initialValues}
          onSubmit={(submitted) => {
            const { date, areaId, startTime, endTime, overrideOvertime } =
              submitted as SubmittedValues;
            const [, startHour, startMinute] = startTime.match(Time.regex)!;
            const [, endHour, endMinute] = endTime.match(Time.regex)!;

            const startDate = moment(date).set({
              hour: parseFloat(startHour),
              minute: parseFloat(startMinute),
            });

            const endDate = moment(date).set({
              hour: parseFloat(endHour),
              minute: parseFloat(endMinute),
            });

            if (endDate.isBefore(startDate)) {
              endDate.add(1, 'day');
            }

            const updatedShift: IShift = {
              startDate,
              endDate,
              area: new EntityReference(Area, areaId),
              position: new EntityReference(Position, shiftGroup.position),
            };

            onSubmit(updatedShift, overrideOvertime);
          }}
          render={({ values, errors, touched, handleSubmit, setFieldValue }) => {
            const changeDropdown = (name: string, value: string) => setFieldValue(name, value);
            const changeAreaDropdown = (areaId: number) => {
              setFieldValue('areaId', areaId);
              setFieldValue('label', areaStore.forceGet(areaId).label);
            };

            const showStartError = !!errors.startTime && !!touched.startTime;
            const showEndError = !!errors.endTime && !!touched.endTime;
            const showDateError = !!errors.date && !!touched.date;
            const showAreaError = !!errors.areaId && !!touched.areaId;

            return (
              <ModalBase
                header={!shift ? 'Create a New Shift' : 'Edit Shift'}
                actionWord={!shift ? 'create' : 'edit'}
                onCancel={closeModal}
                handleSubmit={handleSubmit}
              >
                <Grid>
                  <Row columns={2}>
                    <Column>
                      <Field>
                        <h4 className={style.modal_form_field_headers}>Date</h4>
                        <SingleDatePicker
                          hideKeyboardShortcutsPanel
                          noBorder
                          block
                          id="shift_date"
                          initialVisibleMonth={() => schedule.startDate}
                          date={values.date}
                          placeholder="Date From"
                          onDateChange={(date) => setFieldValue('date', date)}
                          focused={isFocused}
                          onFocusChange={this.handleFocus}
                          isOutsideRange={(day) =>
                            isBeforeDay(day, schedule.startDate) ||
                            isAfterDay(day, schedule.endDate.clone().subtract(1, 'day')) ||
                            shiftDates.includes(moment(day).format('MM DD YYYY'))
                          }
                          numberOfMonths={1}
                        />
                      </Field>
                      {showDateError && <span className={style.error_class}>{errors.date}</span>}
                    </Column>
                    <Column>
                      <Field>
                        <h4 className={style.modal_form_field_headers}>Location</h4>
                        <Dropdown
                          fluid
                          icon="map marker alternate"
                          selection
                          name="areaId"
                          options={areaOptions}
                          value={values.areaId}
                          placeholder="Area"
                          onChange={(_, { value }) => changeAreaDropdown(value as number)}
                        />
                        {showAreaError && (
                          <span className={style.error_class}>{errors.areaId}</span>
                        )}
                      </Field>
                    </Column>
                  </Row>
                  <h4>Time</h4>
                  <Row columns={2}>
                    <Column>
                      <TimePicker
                        name="startTime"
                        onChange={(name, value) => changeDropdown(name, value)}
                        value={values.startTime}
                        error={errors.startTime}
                        placeholder="From (24 hour)"
                        showError={showStartError}
                      />
                    </Column>
                    <Column>
                      <TimePicker
                        name="endTime"
                        onChange={(name, value) => changeDropdown(name, value)}
                        value={values.endTime}
                        error={errors.endTime}
                        placeholder="To (24 hour)"
                        showError={showEndError}
                      />
                    </Column>
                  </Row>
                  {!shift && (
                    <Row>
                      <Radio
                        toggle
                        className={style.radio}
                        checked={values.overrideOvertime}
                        onChange={() => setFieldValue('overrideOvertime', !values.overrideOvertime)}
                        label="Ignore overtime rules"
                      />
                    </Row>
                  )}
                </Grid>
              </ModalBase>
            );
          }}
        />
      </>
    );
  }
}

export default EditShiftModal;
