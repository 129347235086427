import style from '@/containers/Schedules/index.less';
import { Column, Row } from '@/semantic-ui/components';
import { useStores } from '@/store';
import * as classnames from 'classnames';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useLocation, useNavigate } from 'react-router';
import { Button, Grid, Icon, Input, Modal } from 'semantic-ui-react';
import { ScheduleStatus } from 'sol-data';
import ModalBase from '../ModalBase';
import { ScheduleCreateModal } from '../ScheduleCreateModal';

const DATE_FORMAT = 'MMM D';

export const ScheduleInfoHeader = observer(() => {
  const { areaStore, employeeStore, positionStore, scheduleStore, scheduleComponentStore } =
    useStores();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isTitleActive, setIsTitleActive] = useState(false);
  const [scheduleTitle, setScheduleTitle] = useState('');
  const [copyModalOpen, setCopyModalOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [csvData, setCsvData] = useState<[string[], (string | null)[]]>([[], []]);

  const checkScheduleType = () => {
    if (pathname.includes('area')) {
      areaStore.clearCurrent();
      positionStore.clearCurrent();
      scheduleComponentStore.setIsAreaView(true);
    }

    if (pathname.includes('position')) {
      scheduleComponentStore.setIsAreaView(false);
    }
  };

  useEffect(() => {
    const { current } = scheduleStore;

    if (current && current.status === ScheduleStatus.Pending) {
      navigate(`/scheduling/unpublished/${current.id}/position`);
    }

    setScheduleTitle(current?.description || 'Untitled Schedule');
    checkScheduleType();
  }, [scheduleStore.current]);

  useEffect(() => {
    checkScheduleType();
  }, [pathname]);

  const getCSVData = () => {
    const { current: schedule } = scheduleStore;
    const { filter: employeeFilter } = employeeStore;
    const { currentID: currentPositionId } = positionStore;

    const positionSchedule = schedule!.filteredPositionSchedule(
      currentPositionId,
      employeeFilter,
      (id) => employeeStore.get(id),
    );

    const errorMessage = 'An error occurred attempting to retrieve CSV Data';

    if (!positionSchedule) {
      scheduleStore.setError(errorMessage);
      return;
    }

    const data = scheduleStore.getScheduleCsvData(positionSchedule, employeeStore);

    if (data) {
      return data;
    }
    return scheduleStore.setError(errorMessage);
  };

  const toggleTitleActive = () => setIsTitleActive((prev) => !prev);

  const changeTitle = () => {
    const { current } = scheduleStore;
    const description = current?.description;

    if (description !== scheduleTitle && current) {
      scheduleStore.save({ description: scheduleTitle }, current.id);
    }

    toggleTitleActive();
  };

  const { current: schedule } = scheduleStore;

  if (!schedule) return null;

  const description = schedule?.description || 'Untitled Schedule';
  const csvTitle = `${description} - ${schedule.startDate.format(
    'MMM D',
  )} - ${schedule.endDate.format('MMM D')}`;

  return (
    <>
      <Grid>
        <Row columns="equal">
          <Column>
            <h1 className={classnames('u-margin-top-medium', style.page_header)}>
              {schedule.startDate.format(DATE_FORMAT)} -{schedule.endDate.format(DATE_FORMAT)}
              {!isTitleActive ? (
                <>
                  {': '}
                  {scheduleTitle}
                  <Icon
                    name="pencil"
                    size="tiny"
                    className={style.edit_icon}
                    onClick={toggleTitleActive}
                  />
                </>
              ) : (
                <Input
                  className={style.title_input}
                  onBlur={changeTitle}
                  onChange={({ target: { value } }) => setScheduleTitle(value)}
                  value={scheduleTitle}
                  focus
                />
              )}
            </h1>
          </Column>
          <Column>
            <Button
              content="Copy Schedule"
              floated="right"
              onClick={() => setCopyModalOpen(true)}
            />
            {schedule.status !== ScheduleStatus.Completed &&
              schedule.status !== ScheduleStatus.Pending && (
                <Button
                  content="Archive Schedule"
                  floated="right"
                  onClick={() => setConfirmModalOpen(true)}
                />
              )}
            {pathname.includes('/position') && (
              <Button
                content={
                  <CSVLink
                    data={csvData}
                    asyncOnClick
                    onClick={(event, done) => {
                      const data = getCSVData();
                      if (scheduleStore.error || !data) {
                        return false;
                      }
                      setCsvData(data);
                      done();
                    }}
                    target="_blank"
                    filename={`${csvTitle}.csv`}
                    className={style.csv_button}
                  >
                    Export to CSV
                  </CSVLink>
                }
                floated="right"
              />
            )}
          </Column>
        </Row>
      </Grid>
      <ScheduleCreateModal
        open={copyModalOpen}
        onCancel={() => setCopyModalOpen(false)}
        scheduleToCopy={schedule}
      />
      <Modal open={confirmModalOpen} size="mini" onClose={() => setConfirmModalOpen(false)}>
        <ModalBase
          header="Archive Schedule?"
          onAccept={() => {
            scheduleStore.complete(schedule).then(() => setConfirmModalOpen(false));
          }}
          onCancel={() => setConfirmModalOpen(false)}
          actionWord="archive"
        >
          <Grid>Are you sure you wish archive this schedule?</Grid>
        </ModalBase>
      </Modal>
    </>
  );
});
