import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Header, Modal } from '@/semantic-ui/components';
import ListLayout, { ListColumn } from '@/components/ListLayout';
import DayPicker from '@/components/DayPicker';
import CategoryCheckmark from '@/components/CategoryCheckmark';
import { IPayrollItem } from 'sol-data';
import { PayrollStore } from '@/store';
import InjectedComponent from '@/components/InjectedComponent';
import style from '@/containers/Payroll/index.less';
import cn from 'classnames';
import PayrollModal from './Modal';

interface State {
  id?: number;
  modalOpen: boolean;
}

interface Injected {
  payrollStore: PayrollStore;
}

@inject('payrollStore')
@observer
class PayrollItems extends InjectedComponent<{}, State, Injected> {
  state = {
    id: undefined,
    modalOpen: false,
  };

  componentWillUnmount() {
    const { payrollStore } = this.injected;
    payrollStore.items.clearFilter();
  }

  handleDismiss = () => {
    const { payrollStore } = this.injected;
    payrollStore.clearError();
  };

  openModal = (id: number) => {
    const { payrollStore } = this.injected;
    payrollStore.items.setCurrent(id);

    this.setState({
      id,
      modalOpen: true,
    });
  };

  closeModal = () => {
    this.setState({
      modalOpen: false,
      id: undefined,
    });
  };

  submitModal = async (values: IPayrollItem) => {
    const { payrollStore } = this.injected;
    const item = payrollStore.items.assignCurrent(values);

    await payrollStore.saveItem(item);
    this.closeModal();
  };

  render() {
    const { payrollStore } = this.injected;
    const { modalOpen, id } = this.state;

    const {
      categories: { values: payrollCategories },
      isLoading,
      errorMessage,
    } = payrollStore;

    const { filtered: filteredItems } = payrollStore.items;

    const sortedItems = filteredItems.slice().sort((a, b) => {
      return a.description.localeCompare(b.description);
    });

    const columns: ListColumn[] = [
      {
        name: 'ID',
        key: 'id',
        width: 1,
        render: ({ id: rateId }) => <span>{rateId.toString().padStart(4, '0')}</span>,
      },
      {
        name: 'Name',
        key: 'name',
        render: (item) => (
          <Header as="h4" color="blue" className={style.payroll_table_row_header}>
            {item.description}
          </Header>
        ),
      },
      {
        name: 'Start/End Time',
        key: 'start',
        render: (item) =>
          item.startTime ? (
            <span>{`${item.startTime} - ${item.endTime}`}</span>
          ) : (
            <span>No Time Data</span>
          ),
      },
      {
        name: 'Applicable Days',
        key: 'days',
        width: 4,
        render: (item) => <DayPicker values={item.days || []} disabled length={1} />,
      },
      {
        customHeader: (
          <div className={cn(style.payroll_table_row_header, style.payroll_categories_row_header)}>
            {payrollCategories.map(({ label }) => (
              <span key={label} className={style.payroll_category_header}>
                {label}
              </span>
            ))}
          </div>
        ),
        key: 'customHeader',
        width: 7,
        textAlign: 'center',
        render: ({ categories }) => {
          return (
            <div className={style.payroll_checkmark_container}>
              {payrollCategories.map(({ id }) => {
                if (categories.includes(id)) {
                  return <CategoryCheckmark key={id} className={style.payroll_checkmark} />;
                }

                return <div key={id} className={style.payroll_checkmark} />;
              })}
            </div>
          );
        },
      },
    ];

    const headerProps = {
      errorMessage,
      isLoading,
      onSearchChange: payrollStore.items.setFilter,
      searchLabel: 'Search Payroll items',
      onDismiss: payrollStore.clearError,
    };

    return (
      <>
        <ListLayout
          columns={columns}
          items={sortedItems}
          headerProps={headerProps}
          onClick={(item) => this.openModal(item.id)}
        />
        <Modal size="mini" open={modalOpen} onClose={this.closeModal}>
          <PayrollModal id={id} onCancel={this.closeModal} onSubmit={this.submitModal} />
        </Modal>
      </>
    );
  }
}

export default PayrollItems;
