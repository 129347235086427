import { Component, PureComponent } from 'react';

// for context, see https://github.com/mobxjs/mobx-react/issues/256

abstract class InjectedComponent<Props, State, Inject> extends Component<Props, State> {
  get injected(): Inject & Props {
    return this.props as any;
  }
}

abstract class InjectedPureComponent<Props, State, Inject> extends PureComponent<Props, State> {
  get injected(): Inject & Props {
    return this.props as any;
  }
}

export default InjectedComponent;
export { InjectedComponent, InjectedPureComponent };
