import O from '@/images/SOL_O.png';
import SL from '@/images/SOL_SL.png';
import { useStores } from '@/store';
import { IconName, IconPrefix } from '@fortawesome/fontawesome-common-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Sentry from '@sentry/react';
import React from 'react';
import { Link, useLocation } from 'react-router';
import { Image, Menu, Sidebar, Visibility } from 'semantic-ui-react';
import { Authority } from 'sol-data';
import { SubMenu } from '../SubMenu';
import style from './index.less';

type Route = {
  path: string;
  name: string;
  icon: [IconPrefix, IconName];
  exact?: true;
  section: string;
  access: Authority;
  subItems?: {
    path: string;
    name: string;
  }[];
};

const routes: Route[] = [
  {
    path: '/',
    name: 'Home',
    icon: ['fal', 'home'],
    section: 'home',
    access: Authority.ReadOnly,
  },
  {
    path: '/employees',
    name: 'Employees',
    icon: ['fal', 'user'],
    exact: true,
    section: '/employees',
    access: Authority.ReadOnly,
  },
  {
    path: '/organization/departments',
    name: 'Organization',
    icon: ['fal', 'building'],
    section: '/organization',
    access: Authority.ReadOnly,
  },
  {
    path: '/shift-planning',
    name: 'Planning',
    icon: ['fal', 'clock'],
    section: '/shift-planning',
    access: Authority.Admin,
  },
  {
    path: '/scheduling/all',
    name: 'Schedules',
    icon: ['fal', 'calendar-alt'],
    section: '/scheduling',
    access: Authority.ReadOnly,
  },
  {
    path: '/requests/open',
    name: 'Requests',
    icon: ['fal', 'calendar-check'],
    section: '/requests',
    access: Authority.Approver,
  },
  {
    path: '/payroll/items',
    name: 'Payroll',
    icon: ['fal', 'money-check-alt'],
    section: '/payroll',
    access: Authority.PayrollAdmin,
  },
  {
    path: '/timesheets/pending',
    name: 'Timesheets',
    icon: ['fal', 'stopwatch'],
    section: '/timesheets',
    access: Authority.Admin,
  },
  {
    path: '/education',
    name: 'Education',
    icon: ['fal', 'books'],
    section: '/education',
    access: Authority.AdminApprover,
  },
  {
    path: '/notices/all',
    name: 'Notices',
    icon: ['fal', 'bell'],
    section: '/notice',
    access: Authority.Admin,
  },
  {
    path: '/essentials/dashboard',
    name: 'Essentials',
    icon: ['far', 'plus'],
    section: 'essentials',
    access: Authority.Admin,
    subItems: [
      {
        name: 'Employee Dashboard',
        path: '/essentials/dashboard',
      },
      {
        name: 'Master Staff Database',
        path: '/essentials/database/staff-list',
      },
      {
        name: 'Wage Grid',
        path: '/essentials/wage',
      },
    ],
  },
  {
    path: '/admin/api-key',
    name: 'Admin',
    icon: ['fal', 'user-cog'],
    section: '/admin',
    access: Authority.PayrollAdmin,
  },
];

const NavLink = (props: any) => {
  const location = useLocation();
  const { to, section } = props;
  const isActive = location.pathname === to || location.pathname.startsWith(section);

  return (
    <Link
      {...props}
      style={
        isActive
          ? {
              backgroundColor: '#d7ab73',
            }
          : undefined
      }
    />
  );
};

interface SideNavigationProps {
  collapsed: boolean;
  setCollapsed: (collapsed: boolean) => void;
  authority: Authority;
}

const getMenuItem = (authority: Authority, route: Route, collapsed: boolean) => {
  return route.subItems && !collapsed ? (
    <React.Fragment key={route.name}>
      {authority >= route.access && (
        <SubMenu
          title={route.name}
          icon={route.icon}
          subItems={route.subItems}
          section={route.section}
        />
      )}
    </React.Fragment>
  ) : (
    <React.Fragment key={route.name}>
      {authority >= route.access && (
        <Menu.Item
          key={route.path}
          as={NavLink}
          to={route.path}
          section={route.section}
          title={route.name}
        >
          <div className="icon-wrap">
            <FontAwesomeIcon icon={route.icon} />
          </div>
          <span>{route.name}</span>
        </Menu.Item>
      )}
    </React.Fragment>
  );
};

export const SideNavigation = ({ collapsed, setCollapsed, authority }: SideNavigationProps) => {
  const { userStore } = useStores();

  const logout = async () => {
    Sentry.setUser(null);

    await userStore.logout();
  };

  return (
    <Visibility
      onUpdate={(_, { calculations: { width } }) => {
        if (width < 991 && !collapsed) {
          setCollapsed(true);
        }
      }}
    >
      <Sidebar animation="push" visible vertical as={Menu} width={collapsed ? 'thin' : undefined}>
        <div>
          <div className={style.menu_logo}>
            <Image className="sol-logo-sl" src={SL} />
            <Image src={O} />
          </div>
          {routes.map((route) => getMenuItem(authority, route, collapsed))}
          <Menu.Item onClick={logout} title="Sign Out">
            <div className="icon-wrap">
              <FontAwesomeIcon icon="sign-out-alt" />
            </div>
            <span>Sign Out</span>
          </Menu.Item>
        </div>
        <Menu.Item onClick={() => setCollapsed(!collapsed)} title="Toggle Collapse">
          <div className="icon-wrap">
            <FontAwesomeIcon icon={['far', 'chevron-double-right']} className="reveal-icon" />
            <FontAwesomeIcon icon={['far', 'chevron-double-left']} className="collapse-icon" />
          </div>
          <span>Collapse Sidebar</span>
        </Menu.Item>
      </Sidebar>
    </Visibility>
  );
};
