import { AuthService, Auth, fetchApi, serviceApi } from 'sol-data';

const authStorage: Auth.Storage = {
  async save(key, value) {
    if (value === undefined) {
      window.localStorage.removeItem(key);
    } else {
      window.localStorage.setItem(key, value);
    }
  },
  async read(key) {
    return window.localStorage.getItem(key) || undefined;
  },
};

const hooks: Auth.Hooks = {
  unauthorized() {
    const { pathname } = window.location;

    if (pathname === '/') {
      window.location.href = '/login';
    } else if (pathname !== '/login') {
      window.location.href = `/login/unauthorized?redirect=${pathname}`;
    }
  },
  error() {
    const { pathname } = window.location;

    if (pathname === '/login') {
      window.location.href = '/login/network-failure';
      return;
    }

    window.location.href = `/login/network-failure?redirect=${pathname}`;
  },
  logout() {
    window.location.href = '/login';
  },
};

const authApiWithoutAuth = serviceApi(`${process.env.AUTH_API_URL}/api`);
export const authService = new AuthService(authStorage, authApiWithoutAuth, hooks);
export const organizationApi = fetchApi(`${process.env.ORGANIZATION_API_URL}/api`).withAuth(
  authService,
);
export const schedulingApi = fetchApi(`${process.env.SCHEDULING_API_URL}/api`).withAuth(
  authService,
);
export const payrollApi = fetchApi(`${process.env.PAYROLL_API_URL}/api`).withAuth(authService);
export const { authApi } = authService;

export const schedulingApi2 = serviceApi(`${process.env.SCHEDULING_API_URL}/api`, authService);
export const messagingApi = serviceApi(`${process.env.MESSAGING_API_URL}/api`, authService);
export const quickbooksApi = serviceApi(`${process.env.QUICKBOOKS_API_URL}/api`, authService);
export const organizationApi2 = serviceApi(`${process.env.ORGANIZATION_API_URL}/api`, authService);
export const messagingApi2 = fetchApi(`${process.env.MESSAGING_API_URL}/api`).withAuth(authService);
