import AddButton from '@/components/AddButton';
import EmployeeFilterDropdown from '@/components/EmployeeFilterDropdown';
import RequestsFilterModal, {
  Values as FiltersModalValues,
} from '@/components/RequestsFilterModal';
import RequestsList from '@/components/RequestsList';
import NewRequestModal from '@/components/Schedule/NewRequestModal';
import { useAsyncEffect } from '@/hooks';
import style from '@/less/main.less';
import { fetchMissingEmployees } from '@/lib/utils';
import { useStores } from '@/store';
import { toNum } from '@lcdev/store';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useState } from 'react';
import { useSearchParams } from 'react-router';
import { Button, Form, Grid, Menu, Modal, Pagination, Popup } from 'semantic-ui-react';
import { HasID, RequestType } from 'sol-data';
import ButtonBar from '../ButtonBar';

const { Row, Column } = Grid;

interface RequestsViewProps {
  pending: boolean;
}

export const RequestsView = observer(({ pending }: RequestsViewProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page') ? toNum(searchParams.get('page')!) : undefined;
  const employee = searchParams.get('employee') ? toNum(searchParams.get('employee')!) : undefined;
  const department = searchParams.get('department')
    ? toNum(searchParams.get('department')!)
    : undefined;
  const position = searchParams.get('position') ? toNum(searchParams.get('position')!) : undefined;
  const type = searchParams.get('type') as RequestType | undefined;
  const { requestsStore, shiftStore, employeeStore } = useStores();
  const [state, setState] = useState({
    startFocused: false,
    endFocused: false,
    filtersModalOpen: false,
    requestModalType: undefined as RequestType | undefined,
    popupOpen: false as boolean | undefined,
  });
  const view = pending ? requestsStore.pendingView : requestsStore.pastView;

  useAsyncEffect(async () => {
    await changePage(1, employee, department, position, type);

    return () => {
      requestsStore.clearFilters();
    };
  }, []);

  const fetchData = async (
    pageNumber: number,
    employeeId?: number,
    departmentId?: number,
    positionId?: number,
    requestType?: RequestType,
  ) => {
    if (pageNumber) {
      if (pending) {
        requestsStore.pendingView.setFilters({ page: pageNumber });
      } else {
        requestsStore.pastView.setFilters({ page: pageNumber });
      }
    }

    const requests = await requestsStore.fetchPage(
      { page: pageNumber },
      {
        employee: employeeId,
        pending,
        type: requestType,
        departments: departmentId ? [departmentId] : undefined,
        position: positionId || undefined,
        order: pending ? 'asc' : 'desc',
      },
    );

    const shifts = requests.reduce<HasID[]>((acc, req) => {
      if ('shift' in req.$) {
        acc.push(req.$.shift);
      } else if ('shifts' in req.$) {
        acc.push(...(req.$.shifts || []));
      } else if ('shiftFrom' in req.$) {
        acc.push(req.$.shiftFrom, req.$.shiftTo);
      }
      return acc;
    }, []);

    try {
      await Promise.all([
        shiftStore.fetchShifts(shifts),
        employeeStore.isEmpty ? employeeStore.fetchAll() : Promise.resolve(),
      ]);
    } catch (err) {
      console.error('Error on loading shifts and employees.', err);
    }

    fetchMissingEmployees(employeeStore, requests);
  };

  const changePage = async (
    pageNumber: number,
    employeeId?: number,
    departmentId?: number,
    positionId?: number,
    requestType?: RequestType,
  ) => {
    if (!pageNumber || Number.isNaN(pageNumber)) {
      return;
    }

    if (pending) {
      requestsStore.pendingView.setFilters({ page: pageNumber });
    } else {
      requestsStore.pastView.setFilters({ page: pageNumber });
    }

    await fetchData(pageNumber, employeeId, departmentId, positionId, requestType);
  };

  const toggleModal = () =>
    setState((state) => ({
      ...state,
      filtersModalOpen: !state.filtersModalOpen,
    }));

  const applyModalFilters = async (values: FiltersModalValues) => {
    if (values.type) {
      searchParams.append('type', values.type.toString());
    } else {
      searchParams.delete('type');
    }

    if (values.position) {
      searchParams.append('position', values.position.toString());
    } else {
      searchParams.delete('position');
    }

    if (values.department) {
      searchParams.append('department', values.department.toString());
    } else {
      searchParams.delete('department');
    }

    setSearchParams(searchParams, { preventScrollReset: true });
    setState((state) => ({ ...state, filtersModalOpen: false }));

    await fetchData(page ?? 1, employee, values.department, values.position, values.type);
  };

  const openNewModal = (requestType: RequestType) => {
    setState((state) => ({
      ...state,
      requestModalType: requestType,
      popupOpen: false,
    }));
  };

  const requestMenu = () => (
    <Menu size="tiny" vertical className={style.popup_menu}>
      <Menu.Item name="New Time Off" onClick={() => openNewModal(RequestType.TimeOff)} />
      <Menu.Item name="New Sick Notice" onClick={() => openNewModal(RequestType.SickNotice)} />
    </Menu>
  );

  return (
    <>
      <Form>
        <Grid>
          <Row columns={4}>
            <Column>
              <Form.Field>
                <EmployeeFilterDropdown
                  search
                  clearable
                  name="employee"
                  placeholder="Filter by employee"
                  value={employee ? toNum(employee) : undefined}
                  onChange={async (_, id) => {
                    searchParams.append('employee', id ? id.toString() : '');
                    setSearchParams(searchParams, { preventScrollReset: true });

                    await fetchData(page ?? 1, id, department, position, type);
                  }}
                />
              </Form.Field>
            </Column>
            <Column floated="right">
              <div className={style.filter_button}>
                <Button primary fluid onClick={toggleModal}>
                  Filters
                </Button>
              </div>
            </Column>
          </Row>
        </Grid>
      </Form>

      <RequestsList
        requests={view.filtered}
        fetchData={pending ? () => fetchData(page ?? 1, employee, department, position) : undefined}
      />
      <Grid centered>
        <Grid.Row>
          <Pagination
            activePage={view.filters.page}
            totalPages={
              pending ? requestsStore.pendingPages.totalPages : requestsStore.pastPages.totalPages
            }
            onPageChange={(_, { activePage }) =>
              changePage(activePage as number, employee, department, position, type)
            }
            siblingRange={2}
            boundaryRange={0}
            firstItem={{ content: 'First' }}
            lastItem={{ content: 'Last' }}
            ellipsisItem={null}
            prevItem={null}
            nextItem={null}
          />
          <Popup
            open={state.popupOpen}
            content={requestMenu}
            onClose={() => setState({ popupOpen: false })}
            on="click"
            trigger={
              <ButtonBar>
                <AddButton onAdd={() => setState({ popupOpen: true })} addLabel="new request" />
              </ButtonBar>
            }
          />
        </Grid.Row>
      </Grid>

      {state.filtersModalOpen && (
        <RequestsFilterModal onSubmit={applyModalFilters} onCancel={toggleModal} />
      )}

      {!!state.requestModalType && (
        <Modal open closeIcon size="mini" onClose={() => setState({ requestModalType: undefined })}>
          <NewRequestModal
            type={state.requestModalType}
            closeModal={() => setState({ requestModalType: undefined })}
            closeAndFetch={async () => {
              setState({ requestModalType: undefined });
              await fetchData(page ?? 1, employee, department, position);
            }}
          />
        </Modal>
      )}
    </>
  );
});
