import * as React from 'react';
import { Tab, Segment } from '@/semantic-ui/components';
import Breadcrumbs from '@/components/Breadcrumbs';
import { UserStore } from '@/store';
import { observer, inject } from 'mobx-react';
import { Authority } from 'sol-data';
import style from './index.less';

export type TabLayoutPane = {
  menuItem: React.ReactNode;
  render: () => React.ReactNode;
  route: string;
  access?: Authority;
};

interface Props {
  linkTitle?: string;
  link?: string;
  path: string;
  activeBreadcrumb: string;
  panes: TabLayoutPane[];
  onTabChange?: (activeIndex: number) => void;
}

interface Injected {
  userStore: UserStore;
}

const TabLayout: React.FC<Props & Injected> = ({
  link,
  path,
  panes,
  linkTitle,
  activeBreadcrumb,
  userStore,
  onTabChange,
}) => {
  const { current } = userStore;
  const activePane = panes.findIndex((pane) => path.includes(pane.route));

  const filteredPanes = panes.filter(({ access }) =>
    // We're only assuming that one authority level is present
    access ? current!.access!.authority[0] >= access : !access,
  );

  return (
    <Segment className={style.body_container} padded="very">
      <Breadcrumbs linkTitle={linkTitle} activeBreadcrumb={activeBreadcrumb} link={link} tabbed />
      <Tab
        menu={{ text: true }}
        panes={filteredPanes}
        activeIndex={activePane}
        onTabChange={onTabChange ? () => onTabChange(activePane) : undefined}
        className="tab_menu"
      />
    </Segment>
  );
};

export default inject<Injected>(({ userStore }) => ({ userStore }))(observer(TabLayout));
