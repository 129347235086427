import Bulletin from '@/components/Bulletin';
import ModalBase from '@/components/ModalBase';
import TimePicker from '@/components/TimePicker';
import { Checkbox, Column, Form, Grid, Row, Segment } from '@/semantic-ui/components';
import { useStores } from '@/store';
import { Formik } from 'formik';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { SingleDatePicker } from 'react-dates';
import isAfterDay from 'react-dates/lib/utils/isAfterDay';
import isBeforeDay from 'react-dates/lib/utils/isBeforeDay';
import {
  Area,
  EntityReference,
  IShift,
  IShiftGroup,
  moment,
  Moment,
  Position,
  PositionScheduleShiftGroup,
  Shift,
  ShiftGroup,
  Time,
} from 'sol-data';
import { number, object, string } from 'yup';
import style from './copy-shift-modal.less';

const { Dropdown, Field } = Form;

interface CopyShiftModalProps {
  shift: Shift['Props'];
  shiftGroup: IShiftGroup | PositionScheduleShiftGroup | ShiftGroup;
  onSubmit: (shift: IShift, override: boolean) => void;
  closeModal: () => void;
}

interface InitialValues {
  id?: number;
  date: Moment | null;
  startTime: string | undefined;
  endTime: string | undefined;
  areaId: number | undefined;
  override: boolean;
}

interface SubmittedValues extends InitialValues {
  date: Moment;
  startTime: string;
  endTime: string;
  areaId: number;
}

export const CopyShiftModal = observer(
  ({ shift, shiftGroup, onSubmit, closeModal }: CopyShiftModalProps) => {
    const { areaStore, scheduleStore } = useStores();
    const [isFocused, setIsFocused] = useState(false);
    const { isLoading } = scheduleStore;
    const schedule = scheduleStore.forceGetCurrent();
    const areas = areaStore.getAreasForDepartment(schedule.department.id);
    const areaOptions = areas.map((area) => ({
      text: area.label,
      value: area.id,
    }));
    const initialValues: InitialValues = {
      id: shift.id,
      date: null,
      startTime: shift.startDate.format('HH:mm'),
      endTime: shift.endDate.format('HH:mm'),
      areaId: shift.area.id,
      override: false,
    };
    const shiftDates = (shiftGroup.shifts || [])
      .flat()
      .filter((s) => !!s)
      .map(({ $: { startDate } }) => startDate.format('MM DD YYYY'));

    useEffect(() => {
      const fetchData = async () => {
        await areaStore.fetchAll();
      };

      fetchData();

      return () => {
        scheduleStore.clearError();
      };
    }, [areaStore, scheduleStore]);

    const handleFocus = () => setIsFocused(!isFocused);

    return (
      <>
        <Segment>
          <Bulletin
            isLoading={isLoading}
            errorMessage={scheduleStore.errorMessage}
            handleDismiss={() => scheduleStore.clearError()}
          />
        </Segment>
        <Formik
          validationSchema={object().shape({
            id: number(),
            date: string().typeError('Please select a date').required(),
            areaId: number().typeError('Please select an area').required('Please select an area'),
            startTime: string()
              .matches(Time.regex, 'Please enter a valid time')
              .typeError('Please select a start time')
              .required('Please select a start time'),
            endTime: string()
              .matches(Time.regex, 'Please enter a valid time')
              .typeError('Please select an end time')
              .required('Please select an end time'),
          })}
          initialValues={initialValues}
          onSubmit={(submitted) => {
            const { date, areaId, startTime, endTime, override } = submitted as SubmittedValues;
            const [, startHour, startMinute] = startTime.match(Time.regex)!;
            const [, endHour, endMinute] = endTime.match(Time.regex)!;

            const startDate = moment(date).set({
              hour: parseFloat(startHour),
              minute: parseFloat(startMinute),
            });

            const endDate = moment(date).set({
              hour: parseFloat(endHour),
              minute: parseFloat(endMinute),
            });

            if (endDate.isBefore(startDate)) {
              endDate.add(1, 'day');
            }

            const updatedShift: IShift = {
              startDate,
              endDate,
              area: new EntityReference(Area, areaId),
              position: new EntityReference(Position, shiftGroup.position),
            };

            onSubmit(updatedShift, override);
          }}
        >
          {({ values, errors, touched, handleSubmit, setFieldValue }) => {
            const changeDropdown = (name: string, value: string) => setFieldValue(name, value);
            const changeAreaDropdown = (areaId: number) => {
              setFieldValue('areaId', areaId);
              setFieldValue('label', areaStore.forceGet(areaId).label);
            };

            const showStartError = !!errors.startTime && !!touched.startTime;
            const showEndError = !!errors.endTime && !!touched.endTime;
            const showDateError = !!errors.date && !!touched.date;
            const showAreaError = !!errors.areaId && !!touched.areaId;

            return (
              <ModalBase
                header="Copy Shift"
                actionWord="copy"
                onCancel={closeModal}
                handleSubmit={handleSubmit}
              >
                <Grid>
                  <Row columns={2}>
                    <Column>
                      <Field>
                        <h4 className={style.modal_form_field_headers}>Date</h4>
                        <SingleDatePicker
                          hideKeyboardShortcutsPanel
                          noBorder
                          block
                          id="shift_date"
                          initialVisibleMonth={() => schedule.startDate}
                          date={values.date}
                          placeholder="Date From"
                          onDateChange={(date) => setFieldValue('date', date)}
                          focused={isFocused}
                          onFocusChange={handleFocus}
                          isOutsideRange={(day) =>
                            isBeforeDay(day, schedule.startDate) ||
                            isAfterDay(day, schedule.endDate.clone().subtract(1, 'day')) ||
                            shiftDates.includes(moment(day).format('MM DD YYYY'))
                          }
                          numberOfMonths={1}
                        />
                      </Field>
                      {showDateError && <span className={style.error_class}>{errors.date}</span>}
                    </Column>
                    <Column>
                      <Field>
                        <h4 className={style.modal_form_field_headers}>Location</h4>
                        <Dropdown
                          fluid
                          icon="map marker alternate"
                          selection
                          name="areaId"
                          options={areaOptions}
                          value={values.areaId}
                          placeholder="Area"
                          onChange={(_, { value }) => changeAreaDropdown(value as number)}
                        />
                        {showAreaError && (
                          <span className={style.error_class}>{errors.areaId}</span>
                        )}
                      </Field>
                    </Column>
                  </Row>
                  <h4>Time</h4>
                  <Row columns={2}>
                    <Column>
                      <TimePicker
                        name="startTime"
                        onChange={(name, value) => changeDropdown(name, value)}
                        value={values.startTime}
                        error={errors.startTime}
                        placeholder="From (24 hour)"
                        showError={showStartError}
                      />
                    </Column>
                    <Column>
                      <TimePicker
                        name="endTime"
                        onChange={(name, value) => changeDropdown(name, value)}
                        value={values.endTime}
                        error={errors.endTime}
                        placeholder="To (24 hour)"
                        showError={showEndError}
                      />
                    </Column>
                  </Row>
                  <Row>
                    <Checkbox
                      toggle
                      className={style.checkbox}
                      checked={values.override}
                      onChange={() => setFieldValue('override', !values.override)}
                      label="Override assignment"
                    />
                  </Row>
                </Grid>
              </ModalBase>
            );
          }}
        </Formik>
      </>
    );
  },
);
