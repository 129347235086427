import * as React from 'react';
import { InjectedComponent } from '@/components/InjectedComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Link } from 'react-router';
import { Menu } from '@/semantic-ui/components';
import style from './index.less';

interface Injected {}

interface State {
  isCollapsed: boolean;
  currentPath?: string;
}

interface Props {
  icon: IconProp;
  title: string;
  section: string;
  subItems: {
    name: string;
    path: string;
  }[];
}

export class SubMenu extends InjectedComponent<Props, State, Injected> {
  state: State = {
    isCollapsed: true,
  };

  toggle = () => {
    this.setState((prev) => ({ ...prev, isCollapsed: !prev.isCollapsed }));
  };

  change = (path: string) => {
    this.setState((prev) => ({ ...prev, currentPath: path }));
  };

  componentDidMount(): void {
    const paths = location.pathname.split('/');
    this.setState({
      currentPath: location.pathname,
      isCollapsed: !paths.includes(this.props.section),
    });
  }

  render() {
    return (
      <div className={style.submenu_wrapper}>
        <div
          onClick={this.toggle}
          className={`${style.submenu_item} ${style.submenu_header} ${
            !this.state.isCollapsed ? style.opened : ''
          }`}
        >
          <div className="icon-wrap">
            <FontAwesomeIcon icon={this.props.icon} />
          </div>
          <span>{this.props.title}</span>
        </div>
        <div
          className={`${style.submenu_items_wrapper} ${
            this.state.isCollapsed && style.submenu_items_collapsed
          }`}
        >
          {this.props.subItems.map((si) => (
            <div
              className={`${style.submenu_item} ${style.submenu_child} ${
                this.state.currentPath === si.path ? style.submenu_child_active : ''
              }`}
              key={si.path}
            >
              <Link onClick={() => this.change(si.path)} to={si.path}>
                {si.name}
              </Link>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
