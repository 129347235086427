import * as React from 'react';
import { sortBy } from 'lodash';
import { Moment, AreaScheduleShift } from 'sol-data';
import { useStores } from '@/store';
import { Modal, Header } from 'semantic-ui-react';
import ModalBase from '@/components/ModalBase';
import style from '../index.less';
import NoContentMessage from '../NoContentMessage';

interface Props {
  day: Moment;
}

const DayBreakdown = ({ selectedDay }: { selectedDay: Moment }) => {
  const { areaStore, scheduleStore, employeeStore } = useStores();
  const { current } = scheduleStore;
  let areaSchedule;
  if (current) areaSchedule = current.areaSchedule;
  const totalShifts: AreaScheduleShift[] = [];
  const dayBreakdown = [];

  if (areaSchedule) {
    for (const area of areaSchedule) {
      const todaysShifts: AreaScheduleShift[] = sortBy(
        area.shifts.filter(
          ({
            shift: {
              $: { startDate },
            },
          }) => startDate.isSame(selectedDay, 'day'),
        ),
        'shift.$.startDate',
      );
      totalShifts.push(...todaysShifts);

      dayBreakdown.push(
        <React.Fragment key={area.area.id}>
          <Header>
            {!!todaysShifts.length &&
              areaStore.has(area.area.id) &&
              areaStore.get(area.area.id)!.description}
          </Header>
          {todaysShifts.map(({ shift, employee, shiftGroup }) => (
            <React.Fragment key={shift.id}>
              <div className={`${style.shift_block_item} ${style.shift_swap_item}`}>
                <span>
                  {employee && employeeStore.has(employee.id)
                    ? employeeStore.get(employee.id)!.user.fullName
                    : shiftGroup.label}
                </span>
                <span>
                  {shift.$.startDate.format('H:mm')} -{shift.$.endDate.format('H:mm')}
                </span>
              </div>
            </React.Fragment>
          ))}
        </React.Fragment>,
      );
    }
  } else return null;

  if (totalShifts.length) return <>{dayBreakdown}</>;
  return <NoContentMessage type="shifts" />;
};

const DayBlock = ({ day }: Props) => {
  const { scheduleComponentStore } = useStores();
  const [selectedDay, setSelectedDay] = React.useState();
  const { cellWidth: width, actionHeight: height } = scheduleComponentStore;

  return (
    <>
      <div
        className={style.schedule_day_block}
        style={{ width, height }}
        onClick={() => {
          setSelectedDay(day.clone());
        }}
      >
        <span>{day.format('ddd')}</span>
        <span>
          <b>{day.format('D')}</b>
        </span>
      </div>
      <Modal
        size="tiny"
        open={!!selectedDay}
        onClose={() => {
          setSelectedDay(undefined);
        }}
      >
        <ModalBase header={day.format('MMM D')} headerClass="modal_header_day_schedule" noButtons>
          <DayBreakdown selectedDay={selectedDay} />
        </ModalBase>
      </Modal>
    </>
  );
};

export default DayBlock;
