import * as React from 'react';
import * as Cleave from 'cleave.js/react';
import { Props as CleaveProps } from 'cleave.js/react/props';
import { padEnd } from 'lodash';
import style from '@/less/main.less';

export const TIME_FORMAT = 'HH:mm';

interface Props<Name extends string> {
  name: Name;
  label?: string;
  value: string;
  placeholder?: string;
  showError?: boolean;
  error?: string;
  disabled?: boolean;
  onChange?: (name: Name, value: string) => void;
}

const TimePicker = <Name extends string>({
  name,
  onChange,
  label,
  value,
  placeholder,
  showError,
  error,
  disabled,
}: Props<Name>) => {
  const handleChange: CleaveProps['onChange'] = ({ target: { value } }) => {
    if (onChange) {
      onChange(name, value);
    }
  };

  const handleBlur: CleaveProps['onBlur'] = ({ target: { value } }) => {
    if (value.length > 1 && value.length < 5 && onChange) {
      onChange(name, padEnd(value, 5, '0'));
    }
  };

  return (
    <>
      {label && <p>{label}</p>}
      <Cleave
        disabled={disabled}
        options={{ time: true, timePattern: ['h', 'm'] }}
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      {showError && <p className={`${style.error_class} ${style.error_time_picker}`}>{error}</p>}
    </>
  );
};

TimePicker.defaultProps = {
  placeholder: '00:00 (24 hour)',
  showError: false,
  disabled: false,
  value: '',
};

export default TimePicker;
