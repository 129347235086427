import InjectedComponent from '@/components/InjectedComponent';
import { RangeTimesheetDate } from '@/containers/Essentials';
import { Button, Dimmer, Grid, Icon, Loader, Modal, Table } from '@/semantic-ui/components';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import {
  EssentialEmployeeData,
  Moment,
  EmployeeStore,
  EmployeeStepSummaryItemData,
  UserStore,
} from 'sol-data';
import { getEssentialsPermissions } from '@/lib/essentials-utils';
import style from './index.less';
import Bulletin from '../Bulletin';

interface Injected {
  employeeStore: EmployeeStore;
  userStore: UserStore;
}

type StepItemPeriod = EmployeeStepSummaryItemData & { endDate: Moment };

type State = {
  error?: unknown;
  loadingState?: 'saving' | 'fetching';
  data?: {
    currentStep: StepItemPeriod;
    nextStep: StepItemPeriod;
    nextStepChange: Moment;
  };
  approvedStepEmployee?: EssentialEmployeeData;
};

type BaseProps = {
  onClose: () => void;
  payPeriod: RangeTimesheetDate;
  employee: EssentialEmployeeData;
  onApproveChange: React.Dispatch<EssentialEmployeeData>;
};

@inject('employeeStore')
@inject('userStore')
@observer
class StepIncreaseOverviewModal extends InjectedComponent<BaseProps, State, Injected> {
  state: State = {};

  constructor(props: BaseProps) {
    super(props);
    this.approveStepChange = this.approveStepChange.bind(this);
  }

  async componentDidMount() {
    this.setState({
      loadingState: 'fetching',
      error: undefined,
    });
    try {
      const data = await this.injected.employeeStore.fetchStepHistory({
        id: this.props.employee.id,
        endDate: this.props.payPeriod.endDate,
        simulateStepChangeHours: true,
      });
      const items = data.history.map((itm) => itm.props).reverse();
      if (
        items.length < 2 ||
        items[0].stepNumber <= (this.props.employee.stepNumber ?? 1) ||
        !data.nextStepChange
      ) {
        throw new Error('The employee is not apt to advance to the next step!');
      }
      this.setState({
        loadingState: undefined,
        data: {
          currentStep: {
            ...items[1],
            endDate: items[0].startDate.clone().subtract({ days: 1 }),
          },
          nextStep: {
            ...items[0],
            endDate: this.props.payPeriod.endDate,
          },
          nextStepChange: data.nextStepChange,
        },
      });
    } catch (err) {
      this.setState({
        loadingState: undefined,
        error: err,
      });
    }
  }

  async approveStepChange() {
    const { data } = this.state;
    if (!data) {
      return;
    }
    const { nextStep, nextStepChange } = data;
    this.setState({
      error: undefined,
      loadingState: 'saving',
    });
    try {
      const { stepNumber } = nextStep;
      const updatedData = await this.injected.employeeStore.updateEmployeeStep({
        id: this.props.employee.id,
        startDate: nextStepChange,
        stepNumber,
        resetStepHours: true,
        selectedPayPeriodStartDate: this.props.payPeriod.startDate,
      });
      this.setState({
        loadingState: undefined,
        approvedStepEmployee: updatedData.props,
      });
    } catch (err) {
      this.setState({
        error: err,
        loadingState: undefined,
      });
    }
  }

  renderStepApproved(approvedEmployee: EssentialEmployeeData) {
    return (
      <Grid.Column width={5} className={style.right_content}>
        <Grid.Row>
          <div>
            <h3>Step Approved!</h3>
            <p>Proceed to the rates table or go back.</p>
          </div>
        </Grid.Row>
        <Grid.Row className={style.button_container}>
          <Button primary onClick={this.props.onClose}>
            Close
          </Button>
          <Button color="blue" onClick={() => this.props.onApproveChange(approvedEmployee)}>
            Proceed
          </Button>
        </Grid.Row>
      </Grid.Column>
    );
  }

  renderApproveStepChange() {
    const isSaving = this.state.loadingState === 'saving';
    const permissions = getEssentialsPermissions(this.injected.userStore.current);
    if (!permissions.write) {
      return null;
    }
    return (
      <Grid.Column width={5} className={style.right_content}>
        <Grid.Row>
          <div>
            <h3>Approve Step Change</h3>
            <p>
              The next step would start on
              <strong>{this.state.data?.nextStepChange.format('LL')}</strong>. Are you sure you want
              to approve this change?{' '}
            </p>
            <br />
          </div>
        </Grid.Row>
        <Grid.Row className={style.button_container}>
          <Button color="grey" onClick={this.props.onClose} disabled={isSaving}>
            Close
          </Button>
          <Button
            loading={isSaving}
            disabled={!this.state.data}
            color="blue"
            onClick={this.approveStepChange}
          >
            Approve
          </Button>
        </Grid.Row>
      </Grid.Column>
    );
  }

  render() {
    const { employee, payPeriod } = this.props;
    const { error, data, loadingState } = this.state;

    return (
      <Modal
        open
        onClose={this.state.loadingState === 'saving' ? () => {} : this.props.onClose}
        closeOnDimmerClick={false}
        size="large"
        className={style.modal}
        closeIcon={<Icon name="close" size="tiny" color="black" />}
      >
        <Modal.Content className={style.modal_content}>
          <Grid>
            <Grid.Row columns={1} className={style.header}>
              <Grid.Column width={12}>
                <div>
                  <p>Step Increase Overview</p>
                  <h3 className={style.header}>{`${employee.lastName}, ${employee.firstName}`}</h3>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2} className={style.row}>
              <Grid.Column width={10}>
                <Grid.Row className={[style.row, style.pay_period].join(' ')}>
                  <p>
                    Pay Period <strong>{payPeriod.startDate.format('LL')} </strong>
                    {' - '}
                    <strong>{payPeriod.endDate.format('LL')} </strong>
                  </p>
                </Grid.Row>
                <Grid.Row className={style.row}>
                  <div className={style.list_layout_container}>
                    {loadingState === 'fetching' && (
                      <Dimmer inverted active>
                        {' '}
                        <Loader size="small" active className={style.modal_loader} />
                      </Dimmer>
                    )}
                    {data && (
                      <Table className={style.table}>
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell>Step Period</Table.HeaderCell>
                            <Table.HeaderCell>Step</Table.HeaderCell>
                            <Table.HeaderCell>Hours Worked</Table.HeaderCell>
                            <Table.HeaderCell>Rate</Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {[data.nextStep, data.currentStep].map((item, index) => (
                            <Table.Row
                              key={item.id}
                              className={index === 0 ? style.row_highlighted : undefined}
                            >
                              <Table.Cell>
                                {`${item.startDate.format('MMM DD, YYYY')} - ${item.endDate.format(
                                  'MMM DD, YYYY',
                                )}`}
                              </Table.Cell>
                              <Table.Cell>{item.stepNumber}</Table.Cell>
                              <Table.Cell>{item.hoursWorked.toFixed(2)}</Table.Cell>
                              <Table.Cell>{`$ ${item.wageRate.toFixed(2)}`}</Table.Cell>
                            </Table.Row>
                          ))}
                        </Table.Body>
                      </Table>
                    )}
                    {error && <Bulletin error={this.state.error} />}
                  </div>
                </Grid.Row>
              </Grid.Column>
              <Grid.Column width={1} />
              {this.state.approvedStepEmployee
                ? this.renderStepApproved(this.state.approvedStepEmployee)
                : this.renderApproveStepChange()}
            </Grid.Row>
          </Grid>
        </Modal.Content>
      </Modal>
    );
  }
}

export default StepIncreaseOverviewModal;
