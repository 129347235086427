import * as React from 'react';
import { Department, EmployeeStatus } from 'sol-data';
import { Column, Label, Grid, Row, Pagination } from '@/semantic-ui/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { paginate } from '@/lib/utils';
import style from './index.less';
import ListLayout, { ListColumn } from '../ListLayout';
import { CompletedEmployee } from '../DistributionGroup';
import { EmployeeStatusArray, EmployeeStatusColor } from './utils';
import { useStores } from '@/store';
import { uniqBy } from 'lodash';

const RESULTS_PER_PAGE = 10;
const NUM_OF_EMPLOYEES_LABELS = 8;

interface IDepartmentEmployeesProps {
  departments: Department[];
  completedEmployees: CompletedEmployee[];
}

const DepartmentEmployeesList = ({
  departments,
  completedEmployees = [],
}: IDepartmentEmployeesProps) => {
  const [showAllEmployees, setShowAllEmployees] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);

  const selectedEmployees = departments.reduce(
    (current, { employeeCount }) => employeeCount + current,
    0,
  );

  const employeeDepartments = uniqBy(
    departments
      .map((department) =>
        department.departmentEmployees.map((employee) => {
          const completed = completedEmployees.find(({ employeeId }) => employeeId === employee.id);
          return {
            id: employee.id,
            name: `${employee.user.firstName} ${employee.user.lastName}`,
            department: department.description,
            completion: completed ? completed.completionDate : '--',
            status: completed ? EmployeeStatus.COMPLETE : EmployeeStatus.INCOMPLETE,
          };
        }),
      )
      .flat(),
    'id',
  ).sort((a, b) => EmployeeStatusArray.indexOf(a.status) - EmployeeStatusArray.indexOf(b.status));

  const columns: ListColumn[] = [
    {
      name: 'Name',
      key: 'name',
      width: 4,
    },
    {
      name: 'Department',
      key: 'department',
      width: 4,
    },
    {
      name: 'Completion Date',
      key: 'completion',
      width: 4,
      textAlign: 'center',
      render: (item) => <div style={{ textAlign: 'center' }}>{item.completion}</div>,
    },
    {
      name: 'Status',
      key: 'status',
      textAlign: 'center',
      width: 4,
      render: (item) => (
        <div
          style={{
            textAlign: 'center',
            fontWeight: 700,
            color:
              item.status === EmployeeStatus.COMPLETE
                ? EmployeeStatusColor.Complete.color
                : EmployeeStatusColor.Incomplete.color,
          }}
        >
          {item.status}
        </div>
      ),
    },
  ];

  const handleClick = () => {
    setShowAllEmployees(!showAllEmployees);
  };

  return (
    <Row>
      <Column style={{ paddingLeft: 0, paddingRight: 0 }}>
        {showAllEmployees ? (
          <div>
            <div style={{ cursor: 'pointer', marginBottom: '1rem' }} onClick={handleClick}>
              <p className={style.displayEmployeesText}>
                See shortened list <FontAwesomeIcon size="xs" icon={['far', 'chevron-right']} />
              </p>
            </div>
            <ListLayout
              columns={columns}
              items={paginate(employeeDepartments, RESULTS_PER_PAGE, currentPage)}
            />
            <Grid centered>
              <Grid.Row>
                <Pagination
                  activePage={currentPage}
                  totalPages={Math.ceil(employeeDepartments.length / RESULTS_PER_PAGE)}
                  onPageChange={(_, { activePage }) => {
                    setCurrentPage(activePage as number);
                  }}
                  siblingRange={2}
                  boundaryRange={0}
                  firstItem={{ content: 'First' }}
                  lastItem={{ content: 'Last' }}
                  ellipsisItem={null}
                  prevItem={null}
                  nextItem={null}
                />
              </Grid.Row>
            </Grid>
          </div>
        ) : (
          <div>
            <div className={style.list_layout_sticky_header}>
              <Grid className="list_layout list_layout_header">
                <Grid.Row
                  className={style.list_layout_header_row}
                  style={{ padding: '1rem', textTransform: 'uppercase' }}
                >
                  TOTAL {selectedEmployees} Employees Selected
                </Grid.Row>
              </Grid>
            </div>
            <Grid className="list_layout list_layout_body">
              {departments.map((department, index) => (
                <Column width={16} style={{ padding: '1rem' }} key={index}>
                  <Grid.Row style={{ margin: '1rem 0' }}>
                    <p style={{ fontWeight: 600, fontSize: '14px', color: '#979797' }}>
                      {department.label} - #Employees:
                      {department.employeeCount}
                    </p>
                  </Grid.Row>
                  <div>
                    {department.departmentEmployees.slice(0, NUM_OF_EMPLOYEES_LABELS).map((e) => (
                      <Label
                        key={e.id}
                        style={{
                          backgroundColor: '#F9F3ED',
                          borderRadius: 8,
                          marginTop: '0.25rem',
                          marginBottom: '0.25rem',
                        }}
                        className={style.employeeTag}
                      >
                        {e.user.firstName} {e.user.lastName}
                      </Label>
                    ))}
                    {department.employeeCount - NUM_OF_EMPLOYEES_LABELS > 0 && (
                      <Label
                        style={{
                          backgroundColor: '#F9F3ED',
                          borderRadius: 8,
                        }}
                        className={style.employeeTag}
                      >
                        {department.employeeCount - NUM_OF_EMPLOYEES_LABELS} more employees...
                      </Label>
                    )}
                  </div>
                </Column>
              ))}
              {departments.length > 0 && (
                <div
                  style={{ padding: '0 1rem', cursor: 'pointer', marginBottom: '1rem' }}
                  onClick={handleClick}
                >
                  <p className={style.displayEmployeesText}>
                    See full list <FontAwesomeIcon size="xs" icon={['far', 'chevron-right']} />
                  </p>
                </div>
              )}
            </Grid>
          </div>
        )}
      </Column>
    </Row>
  );
};

export default DepartmentEmployeesList;
