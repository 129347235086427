import * as React from 'react';
import { Row, Column, Form, Grid, Radio } from '@/semantic-ui/components';
import { SingleDatePicker } from 'react-dates';
import isBeforeDay from 'react-dates/lib/utils/isBeforeDay';
import isAfterDay from 'react-dates/lib/utils/isAfterDay';
import { inject, observer } from 'mobx-react';
import { Formik } from 'formik';
import { object, string, number } from 'yup';
import { EntityReference } from '@lcdev/store';
import {
  moment,
  Moment,
  TimesheetStore,
  PayrollStore,
  PositionStore,
  Timesheet,
  TimesheetEntry,
  Employee,
  Department,
  Area,
  Time,
  IBaseTimesheetEntry,
  regularPayrollCategory,
  EmployeeStore,
} from 'sol-data';
import ModalBase from '@/components/ModalBase';
import InjectedComponent from '@/components/InjectedComponent';
import Bulletin from '@/components/Bulletin';
import TimePicker from '@/components/TimePicker';
import EmployeeFilterDropdown from '@/components/EmployeeFilterDropdown';
import DepartmentFilterDropdown from '@/components/DepartmentFilterDropdown';
import AppContext from '@/AppContext';
import AreaFilterDropdown from '@/components/AreaFilterDropdown';
import style from '@/less/main.less';
import { isSameDay } from 'date-fns';

type BaseProps = {
  onCancel: () => void;
};

type Props = BaseProps &
  (
    | {
        entry?: TimesheetEntry['Props'];
        employee: Employee;
        timesheet: Timesheet['Props'];
        onSubmit: (values: IBaseTimesheetEntry) => void;
      }
    | {
        onSubmit: (values: IBaseTimesheetEntry, employeeId: number) => void;
      }
  );

interface Injected {
  timesheetStore: TimesheetStore;
  payrollStore: PayrollStore;
  positionStore: PositionStore;
  employeeStore: EmployeeStore;
}

interface State {
  startDateFocused: boolean;
  endDateFocused: boolean;
  employeeName: string;
}

interface Values {
  employeeId?: number;
  startDate: Moment;
  endDate: Moment;
  startTime: string;
  endTime: string;
  breakStart?: string;
  breakEnd?: string;
  entryCategory: number;
  areaId?: number;
  departmentId?: number;
  isNotOvertime: boolean;
}

@inject('timesheetStore', 'payrollStore', 'positionStore', 'employeeStore')
@observer
class TimesheetEntryModal extends InjectedComponent<Props, State, Injected> {
  state: State = {
    startDateFocused: false,
    endDateFocused: false,
    employeeName: 'Unknown',
  };

  static contextType = AppContext;

  async componentDidMount() {
    const { positionStore, payrollStore } = this.injected;

    if ('employee' in this.injected) {
      const {
        employee: { position },
      } = this.injected;

      if (!positionStore.has(position)) {
        positionStore.fetch(position);
      }
    }
    await payrollStore.fetchAll();
    const employee = 'employee' in this.injected ? this.injected.employee : undefined;
    if (employee) {
      this.setState({ employeeName: employee.user.fullName });
    }
  }

  toggleStartDateFocus = () => {
    this.setState(({ startDateFocused }) => ({ startDateFocused: !startDateFocused }));
  };

  toggleEndDateFocus = () => {
    this.setState(({ endDateFocused }) => ({ endDateFocused: !endDateFocused }));
  };

  getEmployeeNameById = async (id: number) => {
    const { employeeStore } = this.injected;
    if (this.state.employeeName === employeeStore.get(id)?.user.fullName) return;
    if (!employeeStore.get(id)) {
      await employeeStore.fetchEmployees([id]);
    }
    const foundEmployee = employeeStore.get(id);
    if (foundEmployee) this.setState({ employeeName: foundEmployee.user.fullName });
  };

  render() {
    const { onCancel, payrollStore, timesheetStore, positionStore, employeeStore } = this.injected;
    const { isPayrollAdmin } = this.context;

    const { startDateFocused, endDateFocused } = this.state;

    const employee = 'employee' in this.injected ? this.injected.employee : undefined;
    const entry = 'entry' in this.injected ? this.injected.entry : undefined;
    const timesheet = 'timesheet' in this.injected ? this.injected.timesheet : undefined;

    const { userVisibleCategories } = payrollStore;
    const { isLoading, error } = timesheetStore;

    const position = employee && positionStore.get(employee.position);

    const normalBreakTimes = position ? position.normalBreakTimes : [new Time(11, 0)];
    const startDate = entry
      ? entry.startDate
      : timesheet?.startDate.clone().set({ hour: 7, minute: 0 }) ||
        moment().set({ hour: 7, minute: 0 });
    const endDate = entry
      ? entry.endDate
      : timesheet?.startDate.clone().set({ hour: 15, minute: 0 }) ||
        moment().set({ hour: 15, minute: 0 });

    const findBreakStart = (start: Time, end: Time) => {
      const found = normalBreakTimes.find((entry) => {
        return entry.isBetween(start, end, '[]');
      });

      return (
        found ||
        start.add(
          // halfway point between start & end
          end.subtract(start.minutesSinceMidnight, 'minutes').minutesSinceMidnight / 2,
          'minutes',
        )
      );
    };

    const breakStart = entry
      ? entry.breakStart
        ? Time.fromMoment(entry.breakStart)
        : undefined
      : findBreakStart(Time.fromMoment(startDate), Time.fromMoment(endDate));
    const breakEnd = entry
      ? entry.breakEnd
        ? Time.fromMoment(entry.breakEnd)
        : undefined
      : breakStart!.add(30, 'minutes');

    const categoryOptions = userVisibleCategories.map(({ description, id }) => ({
      text: description,
      value: id,
      key: id,
    }));

    const initialValues: Values = {
      startDate,
      endDate,
      employeeId: employee?.id,
      startTime: startDate.format('HH:mm'),
      endTime: endDate.format('HH:mm'),
      breakStart: breakStart?.toString(),
      breakEnd: breakEnd?.toString(),
      entryCategory: entry?.categories.length ? entry.categories[0] : regularPayrollCategory.id,
      areaId: entry?.area?.id || undefined,
      departmentId: entry?.department?.id || undefined,
      isNotOvertime: entry?.isNotOvertime || false,
    };

    return (
      <Formik
        validationSchema={object().shape({
          employeeId: number().required('Please select an employee'),
          startTime: string().required('Start time is required'),
          endTime: string().required('End time is required'),
          startDate: string()
            .typeError('Date must be in MM/DD/YYYY format')
            .required('Start date is required'),
          endDate: string()
            .typeError('Date must be in MM/DD/YYYY format')
            .required('Start date is required'),
          breakStart: string(),
          breakEnd: string(),
          areaId: number().notRequired(),
          departmentId: number().notRequired(),
        })}
        initialValues={initialValues}
        onSubmit={(values) => {
          const startTime = Time.parse(values.startTime);
          const endTime = Time.parse(values.endTime);
          const startDate = startTime.momentValueOnDay(values.startDate);
          const endDate = endTime.momentValueOnDay(values.endDate);

          const breakOnDay = (value?: string) => {
            if (!value) return null;

            const timeValue = Time.parse(value);
            const momentValue = timeValue.momentValueOnDay(startDate);

            // if the entry is 11-7, break is on next day
            if (momentValue < startDate) {
              momentValue.add(1, 'day');
            } else if (momentValue > endDate) {
              momentValue.subtract(1, 'day');
            }

            return momentValue;
          };
          const entry = {
            startDate,
            endDate,
            breakStart: breakOnDay(values.breakStart),
            breakEnd: breakOnDay(values.breakEnd),
            categories: [values.entryCategory],
            department: values.departmentId
              ? new EntityReference(Department, values.departmentId)
              : null,
            area: values.areaId ? new EntityReference(Area, values.areaId) : null,
            isNotOvertime: values.isNotOvertime,
          };

          if ('employee' in this.injected) {
            this.injected.onSubmit(entry);
          } else {
            this.injected.onSubmit(entry, values.employeeId!);
          }
        }}
        render={({ values, errors, touched, handleSubmit, setFieldValue }) => {
          const showStartError = !!errors.startTime && !!touched.startTime;
          const showEndError = !!errors.endTime && !!touched.endTime;
          const showStartDateError = !!errors.startDate && !!touched.startDate;
          const showEndDateError = !!errors.endDate && !!touched.endDate;

          if (values.startDate > values.endDate) {
            setFieldValue('endDate', values.startDate);
          }

          const entryAction = entry ? 'Edit' : 'New Entry';
          if (values.employeeId) {
            this.getEmployeeNameById(values.employeeId);
          }
          const headerDate = entry
            ? ` - ${entry.startDate.format('MMM D')}`
            : ` - ${values.startDate ? values.startDate.format('MMM D') : 'Unknown'}`;

          return (
            <ModalBase
              header={`${entryAction} - ${
                employeeStore.isFetchingEmployees ? 'Loading...' : this.state.employeeName
              }${headerDate}`}
              actionWord="Save"
              onCancel={onCancel}
              handleSubmit={handleSubmit}
            >
              <Bulletin
                isLoading={isLoading}
                error={error}
                handleDismiss={() => timesheetStore.clearError()}
              />
              <Grid>
                {!employee && (
                  <>
                    <h4>Employee</h4>
                    <Row>
                      <Column>
                        <Form.Field>
                          <EmployeeFilterDropdown
                            search
                            name="employeeId"
                            placeholder="Employee"
                            value={values.employeeId}
                            onChange={(_, value) => setFieldValue('employeeId', value)}
                          />
                        </Form.Field>
                      </Column>
                    </Row>
                  </>
                )}
                <h4>Shift Timing</h4>
                <Row columns={2}>
                  <Column>
                    <TimePicker
                      name="startTime"
                      placeholder="From (24 hour)"
                      value={values.startTime}
                      onChange={(name, value) => {
                        setFieldValue('startTime', value);
                      }}
                      showError={showStartError}
                    />
                  </Column>
                  <Column>
                    <TimePicker
                      name="endTime"
                      key="endTime"
                      placeholder="To (24 hour)"
                      value={values.endTime}
                      onChange={(_, value) => {
                        setFieldValue('endTime', value);
                      }}
                      showError={showEndError}
                    />
                  </Column>
                </Row>
                <h4>Break Timing</h4>
                <Row columns={2}>
                  <Column>
                    <TimePicker
                      name="breakStart"
                      value={values.breakStart}
                      onChange={(_, value) => {
                        setFieldValue('breakStart', value);

                        if (Time.isValid(value)) {
                          setFieldValue(
                            'breakEnd',
                            Time.parse(value).add(30, 'minutes').toString(),
                          );
                        }
                      }}
                    />
                  </Column>
                  <Column>
                    <TimePicker name="breakEnd" value={values.breakEnd} onChange={setFieldValue} />
                  </Column>
                </Row>
                <Row columns={2}>
                  <Column>
                    <SingleDatePicker
                      id="timesheet_modal_start"
                      date={values.startDate}
                      placeholder="MM/DD/YYYY"
                      onDateChange={(date) => setFieldValue('startDate', date)}
                      focused={startDateFocused}
                      onFocusChange={this.toggleStartDateFocus}
                      isOutsideRange={
                        timesheet
                          ? (day) =>
                              isBeforeDay(day, timesheet.startDate) ||
                              isAfterDay(day, timesheet.endDate) ||
                              isSameDay((day as Moment).toDate(), timesheet.endDate.toDate())
                          : () => false
                      }
                      numberOfMonths={1}
                      hideKeyboardShortcutsPanel
                      noBorder
                      block
                    />
                    {showStartDateError && <p className={style.error_class}>{errors.startDate}</p>}
                  </Column>
                  <Column>
                    <SingleDatePicker
                      id="timesheet_modal_end"
                      date={values.endDate}
                      placeholder="MM/DD/YYYY"
                      onDateChange={(date) => setFieldValue('endDate', date)}
                      focused={endDateFocused}
                      onFocusChange={this.toggleEndDateFocus}
                      numberOfMonths={1}
                      isOutsideRange={
                        timesheet
                          ? (day) =>
                              isBeforeDay(day, values.startDate) ||
                              isAfterDay(day, timesheet.endDate)
                          : () => false
                      }
                      hideKeyboardShortcutsPanel
                      noBorder
                      block
                    />
                    {showEndDateError && <p className={style.error_class}>{errors.endDate}</p>}
                  </Column>
                </Row>
                <h4>Location</h4>
                <Row columns={2}>
                  <Column>
                    <DepartmentFilterDropdown
                      limited
                      clearable
                      name="departmentId"
                      placeholder="Department"
                      departmentId={values.departmentId}
                      onChange={(_, value) => setFieldValue('departmentId', value)}
                    />
                  </Column>
                  <Column>
                    <AreaFilterDropdown
                      limited
                      clearable
                      name="areaId"
                      placeholder="Area"
                      value={values.areaId}
                      onChange={(_, value) => setFieldValue('areaId', value)}
                    />
                  </Column>
                </Row>
                <h4>Category</h4>
                <Row>
                  <Column>
                    <Form.Dropdown
                      selection
                      name="entryCategory"
                      value={values.entryCategory}
                      options={categoryOptions}
                      onChange={(_, { name, value }) => setFieldValue(name, value)}
                    />
                  </Column>
                </Row>
                {isPayrollAdmin && (
                  <Row>
                    <Column>
                      <div className={style.flex_between}>
                        <p>Entry should not include overtime</p>
                        <Radio
                          toggle
                          className={style.radio}
                          checked={values.isNotOvertime}
                          onChange={() => setFieldValue('isNotOvertime', !values.isNotOvertime)}
                        />
                      </div>
                    </Column>
                  </Row>
                )}
              </Grid>
            </ModalBase>
          );
        }}
      />
    );
  }
}

export default TimesheetEntryModal;
