import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { IPayrollItem, Time } from 'sol-data';
import { PayrollStore } from '@/store';
import { Grid, Row, Column, Input, Radio } from '@/semantic-ui/components';
import { Formik } from 'formik';
import { object, string, array, number } from 'yup';
import ModalBase from '@/components/ModalBase';
import TimePicker from '@/components/TimePicker';
import DayPicker from '@/components/DayPicker';
import PayrollSelect from '@/components/PayrollSelect';
import InjectedComponent from '@/components/InjectedComponent';
import style from '@/containers/Payroll/index.less';

interface Props {
  id?: number;
  onCancel: () => void;
  onSubmit: (values: IPayrollItem) => void;
}

interface Injected {
  payrollStore: PayrollStore;
}

interface InitialValues {
  description: string;
  start?: string;
  end?: string;
  days: number[];
  categories: number[];
  doesNotRequireRate: boolean;
  dontResolveAsMostMinutes: boolean;
}

@inject('payrollStore')
@observer
class PayrollItemsModal extends InjectedComponent<Props, {}, Injected> {
  componentDidMount() {
    const { payrollStore, id } = this.injected;
    payrollStore.items.setCurrent(id);
  }

  render() {
    const { payrollStore, onCancel, onSubmit } = this.injected;
    const { items } = payrollStore;
    const currentItem = items.forceGetCurrent();

    const initialValues = {
      description: currentItem.description,
      start: currentItem.startTime ? currentItem.startTime.toString() : '',
      end: currentItem.endTime ? currentItem.endTime.toString() : '',
      days: currentItem.days || [],
      categories: currentItem.categories,
      doesNotRequireRate: currentItem.doesNotRequireRate || false,
      dontResolveAsMostMinutes: currentItem.dontResolveAsMostMinutes || false,
    };

    return (
      <Formik
        enableReinitialize
        validationSchema={object().shape({
          description: string().required('Item name is required'),
          start: string(),
          end: string(),
          days: array().of(number()),
          categories: array().of(number()),
        })}
        initialValues={initialValues}
        onSubmit={(values) =>
          onSubmit({
            description: values.description,
            startTime: values.start ? Time.parse(values.start) : null,
            endTime: values.end ? Time.parse(values.end) : null,
            days: values.days,
            categories: values.categories,
            // not currently settable in UI
            isWholeDay: false,
            doesNotRequireRate: values.doesNotRequireRate,
            dontResolveAsMostMinutes: values.dontResolveAsMostMinutes,
          })
        }
        render={({ values, errors, touched, handleSubmit, setFieldValue }) => {
          const showStartErrors = !!errors.start && !!touched.start;
          const showEndErrors = !!errors.end && !!touched.end;

          return (
            <ModalBase
              header="Edit Payroll Item"
              actionWord="submit"
              onAccept={() => handleSubmit()}
              onCancel={onCancel}
            >
              <Grid>
                <Row>
                  <Column>
                    <p>Name</p>
                    <Input fluid name="description" value={values.description} disabled />
                  </Column>
                </Row>
                <Row columns={2}>
                  <Column>
                    <TimePicker
                      name="start"
                      label="Start Time"
                      onChange={setFieldValue}
                      value={values.start}
                      showError={showStartErrors}
                    />
                    {showStartErrors && <p className={style.error_class}>{errors.start}</p>}
                  </Column>
                  <Column>
                    <TimePicker
                      name="end"
                      label="End Time"
                      onChange={setFieldValue}
                      value={values.end}
                      showError={showEndErrors}
                    />
                    {showEndErrors && <p className={style.error_class}>{errors.end}</p>}
                  </Column>
                </Row>
                <Row>
                  <Column>
                    <p>Applicable Days</p>
                    <DayPicker
                      values={values.days}
                      length={1}
                      onChange={(days) => setFieldValue('days', days)}
                      height={49}
                      error={errors.days as unknown as string}
                    />
                  </Column>
                </Row>
                <Row>
                  <Column>
                    <p>Type</p>
                    <PayrollSelect
                      onChange={(options) => setFieldValue('categories', options)}
                      values={values.categories}
                    />
                    {errors.categories && touched.categories && (
                      <p className={style.error_class}>{errors.categories}</p>
                    )}
                  </Column>
                </Row>
                <Row columns={2}>
                  <Column width={12}>
                    <p>Requires an employee rate</p>
                  </Column>
                  <Column width={4}>
                    <Radio
                      toggle
                      className={style.radio}
                      checked={!values.doesNotRequireRate}
                      onChange={() =>
                        setFieldValue('doesNotRequireRate', !values.doesNotRequireRate)
                      }
                    />
                  </Column>
                </Row>
                <Row columns={2}>
                  <Column width={12}>
                    <p>Ignore majority time rule</p>
                  </Column>
                  <Column width={4}>
                    <Radio
                      toggle
                      className={style.radio}
                      checked={values.dontResolveAsMostMinutes}
                      onChange={() =>
                        setFieldValue('dontResolveAsMostMinutes', !values.dontResolveAsMostMinutes)
                      }
                    />
                  </Column>
                </Row>
              </Grid>
            </ModalBase>
          );
        }}
      />
    );
  }
}

export default PayrollItemsModal;
