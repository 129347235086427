import Areas from '@/components/Areas';
import Departments from '@/components/Departments';
import Positions from '@/components/Positions';
import Rotations from '@/components/Rotations';
import TabLayout, { TabLayoutPane } from '@/components/TabLayout';
import { Menu } from '@/semantic-ui/components';
import React from 'react';
import { Link, useLocation } from 'react-router';

const panes: TabLayoutPane[] = [
  {
    route: 'departments',
    menuItem: (
      <Menu.Item as={Link} to="/organization/departments" key="departments">
        Departments
      </Menu.Item>
    ),
    render: () => <Departments />,
  },
  {
    route: 'areas',
    menuItem: (
      <Menu.Item as={Link} to="/organization/areas" key="areas">
        Areas
      </Menu.Item>
    ),
    render: () => <Areas />,
  },
  {
    route: 'positions',
    menuItem: (
      <Menu.Item as={Link} to="/organization/positions" key="positions">
        Positions
      </Menu.Item>
    ),
    render: () => <Positions />,
  },
  {
    route: 'rotations',
    menuItem: (
      <Menu.Item as={Link} to="/organization/rotations" key="rotations">
        Rotations
      </Menu.Item>
    ),
    render: () => <Rotations />,
  },
];

export const Organization = () => {
  const { pathname } = useLocation();

  return <TabLayout path={pathname} panes={panes} activeBreadcrumb="Organization Central" />;
};
