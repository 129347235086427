import InjectedComponent from '@/components/InjectedComponent';
import Holidays from '@/components/PayrollHolidays';
import Items from '@/components/PayrollItems';
import Overtime from '@/components/PayrollOvertime';
import QbLogs from '@/components/QbLogs';
import { QBReconciler } from '@/components/QbReconciler';
import { withNavigation, WithNavigationProps } from '@/components/Route';
import TabLayout, { TabLayoutPane } from '@/components/TabLayout';
import { Menu } from '@/semantic-ui/components';
import { PayrollStore } from '@/store';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Link } from 'react-router';
import { Authority } from 'sol-data';

const panes: TabLayoutPane[] = [
  {
    route: 'items',
    menuItem: (
      <Menu.Item as={Link} to="/payroll/items" key="items">
        Items
      </Menu.Item>
    ),
    render: () => <Items />,
  },
  {
    route: 'holidays',
    menuItem: (
      <Menu.Item as={Link} to="/payroll/holidays" key="holidays">
        Holidays
      </Menu.Item>
    ),
    render: () => <Holidays />,
  },
  {
    route: 'overtime',
    menuItem: (
      <Menu.Item as={Link} to="/payroll/overtime" key="overtime">
        Overtime
      </Menu.Item>
    ),
    render: () => <Overtime />,
  },
  {
    route: 'qb-logs',
    menuItem: (
      <Menu.Item as={Link} to="/payroll/qb-logs" key="qbLogs">
        QuickBooks Logs
      </Menu.Item>
    ),
    access: Authority.PayrollAdmin,
    render: () => <QbLogs />,
  },
  {
    route: 'qb-reconciler',
    menuItem: (
      <Menu.Item as={Link} to="/payroll/qb-reconciler" key="qbReconciler">
        QuickBooks Reconciler
      </Menu.Item>
    ),
    access: Authority.PayrollAdmin,
    render: () => <QBReconciler />,
  },
];

interface Props extends WithNavigationProps {}

interface Injected {
  payrollStore: PayrollStore;
}

@inject('payrollStore')
@observer
class Payroll extends InjectedComponent<Props, {}, Injected> {
  async componentDidMount() {
    const { payrollStore } = this.injected;
    payrollStore.fetchAll();
  }

  render() {
    const { path } = this.props;

    return <TabLayout path={path} panes={panes} activeBreadcrumb="Payroll Management" />;
  }
}

export default withNavigation(Payroll);
