import AppContext from '@/AppContext';
import SaveButtons from '@/components/SaveButtons';
import classnames from 'classnames';
import * as React from 'react';
import { PropsWithChildren } from 'react';
import style from './index.less';

interface ButtonBarProps {
  link?: string;
  onSave?: () => void;
  withoutSave?: boolean;
  disabled?: boolean;
  saveText?: string;
  hideSave?: boolean;
}

const ButtonBar = ({
  children,
  link,
  onSave,
  disabled,
  saveText,
  hideSave,
}: PropsWithChildren<ButtonBarProps>) => (
  <AppContext.Consumer>
    {({ collapsed }) => (
      <>
        <div
          className={classnames(
            style.add_button_container,
            collapsed ? style.add_button_container_slim : style.add_button_container_wide,
          )}
        >
          {children}
        </div>
        {onSave && (
          <div className={style.save_button_container}>
            <SaveButtons
              onClick={onSave}
              link={link}
              disabled={disabled}
              saveText={saveText}
              hideSave={hideSave}
            />
          </div>
        )}
      </>
    )}
  </AppContext.Consumer>
);

export default ButtonBar;
