import * as React from 'react';
import { Image } from '@/semantic-ui/components';
import style from './index.less';

interface Props {
  src: string;
  alt: string;
}

const CircleImage: React.FC<Props> = ({ src, alt }) => (
  <div className={style.circle}>
    <Image src={src} alt={alt} />
  </div>
);

export default CircleImage;
