import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { ScheduleStore, EmployeeStore } from '@/store';
import ScheduleLayout from '../ScheduleLayout';
import AreaRow from '../AreaRow';
import NoContentMessage from '../NoContentMessage';

interface Props {
  areaId?: number;
  employeeFilter?: string;
}

interface Injected {
  scheduleStore: ScheduleStore;
  employeeStore: EmployeeStore;
}

const AreaSchedule: React.SFC<Props & Injected> = ({
  areaId,
  employeeFilter,
  scheduleStore,
  employeeStore,
}) => {
  const areaSchedule = scheduleStore
    .forceGetCurrent()
    .filteredAreaSchedule(areaId, employeeFilter, (id) => employeeStore.get(id));

  return (
    <ScheduleLayout>
      {areaSchedule && areaSchedule.length > 0 ? (
        areaSchedule.map((areaSchedule) => (
          <AreaRow key={areaSchedule.area.id} areaSchedule={areaSchedule} />
        ))
      ) : (
        <NoContentMessage absolute />
      )}
    </ScheduleLayout>
  );
};

export default inject<Injected>(({ scheduleStore, employeeStore }) => ({
  scheduleStore,
  employeeStore,
}))(observer(AreaSchedule));
