import * as React from 'react';
import { Grid } from '@/semantic-ui/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SemanticWIDTHS, SemanticVERTICALALIGNMENTS, Button } from 'semantic-ui-react';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import { Link } from 'react-router';
import style from './index.less';
import SearchHeader, { Props as SearchHeaderProps } from '../SearchHeader';

export type Item = { key?: any; id?: any; [key: string]: any };

export interface ListColumn<I extends Item = Item> {
  key: any;
  name?: string;
  customHeader?: React.ReactNode;
  className?: string;
  width?: SemanticWIDTHS | { sm: SemanticWIDTHS; lg: SemanticWIDTHS };
  textAlign?: 'left' | 'right' | 'center' | 'justified';
  verticalAlign?: SemanticVERTICALALIGNMENTS;
  floated?: 'left' | 'right';
  template?: (item: I) => React.ReactNode;
  render?: (item: I, error?: string) => React.ReactNode;
}

interface Props<I extends Item> {
  items: I[];
  columns: ListColumn<I>[];
  headerProps?: SearchHeaderProps & { searchHeaderContent?: React.ReactNode };
  errors?: string[];
  center?: boolean;
  keyProperty?: string;
  renderFooter?: (item: I) => React.ReactNode;
  onGetIsRowHighlighted?: (item: I) => boolean;
  onClick?: (item: I) => void;
  onRemove?: (item: I) => void;
  /* default is on */
  stickyHeader?: boolean;
  deleteTopOffset?: boolean;
  isScrollable?: boolean;
  link?: (item: I) => string;
}

const remover =
  <I extends Item>(handler: (i: I) => void, item: I) =>
  (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    handler(item);
  };

const renderer = <I extends Item>(column: ListColumn<I>, item: I, error?: string) => {
  if (column.render) {
    return column.render(item, error);
  }

  if (column.template) {
    return column.template(item[column.key]);
  }

  return item[column.key];
};

const ListLayout = function <I extends Item>({
  items,
  columns,
  headerProps,
  onRemove,
  errors,
  center,
  onClick,
  renderFooter,
  keyProperty,
  stickyHeader = true,
  deleteTopOffset,
  isScrollable,
  onGetIsRowHighlighted,
  link,
}: Props<I>) {
  return (
    <>
      {headerProps && (
        <SearchHeader {...headerProps}>{headerProps.searchHeaderContent}</SearchHeader>
      )}
      <div className={isScrollable ? style.list_layout_container : undefined}>
        <div className={stickyHeader ? style.list_layout_sticky_header : ''}>
          <Grid className="list_layout list_layout_header">
            <Grid.Row className={style.list_layout_header_row}>
              {columns.map(({ width, key, textAlign, name, floated, customHeader, className }) => (
                <Grid.Column
                  key={key}
                  tablet={typeof width === 'object' ? width.sm : width || 2}
                  computer={typeof width === 'object' ? width.lg : width || 2}
                  textAlign={center ? 'center' : textAlign || 'left'}
                  className={className || style.list_layout_headers}
                  floated={floated}
                >
                  {customHeader || name}
                </Grid.Column>
              ))}
              {onRemove && <Grid.Column width={1} />}
            </Grid.Row>
          </Grid>
        </div>
        <Grid className="list_layout list_layout_body">
          {items.map((item, idx) => {
            const isRowHighlighted = onGetIsRowHighlighted ? onGetIsRowHighlighted(item) : false;
            const rowClassName = isRowHighlighted
              ? style.highlighted_row
              : onClick
              ? style.clickable
              : '';
            return (
              <Grid.Row
                key={keyProperty ? item[keyProperty] : item.key || item.id}
                className={rowClassName}
                onClick={onClick && (() => onClick(item))}
                as={link && Link}
                to={link?.(item)}
              >
                {columns.map((column) => {
                  const { verticalAlign, width, key, className, floated } = column;
                  return (
                    <Grid.Column
                      verticalAlign={verticalAlign}
                      key={`${key}_${item.id || item.key}`}
                      tablet={typeof width === 'object' ? width.sm : width || 2}
                      computer={typeof width === 'object' ? width.lg : width || 2}
                      floated={floated}
                      className={className}
                    >
                      {renderer(column, item, errors?.[idx])}
                    </Grid.Column>
                  );
                })}
                {onRemove && (
                  <Grid.Column
                    className={classnames('list_layout_delete_column', {
                      list_layout_delete_column_top_offset: !deleteTopOffset,
                    })}
                    width={1}
                  >
                    <div
                      role="button"
                      tabIndex={-1}
                      onClick={remover(onRemove, item)}
                      onKeyPress={remover(onRemove, item)}
                    >
                      <FontAwesomeIcon icon={['far', 'trash']} color="#415167" />
                    </div>
                  </Grid.Column>
                )}
                {renderFooter && <div className="list_layout_footer">{renderFooter(item)}</div>}
              </Grid.Row>
            );
          })}
        </Grid>
      </div>
    </>
  );
};

export default ListLayout;
export const ObservingListLayout = observer(ListLayout);
