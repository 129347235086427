import { paginate } from '@/lib/utils';
import { Column } from '@/semantic-ui/components';
import { useStores } from '@/store';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router';
import { Form, Grid, Pagination } from 'semantic-ui-react';
import { EmployeeStatus, Moment, Nullable, moment } from 'sol-data';
import { EmployeeStatusColor } from '../DepartmentEmployeesList/utils';
import { EntityList } from '../EntityList';
import FilterDropdown from '../FilterDropdown';
import { Item, ListColumn } from '../ListLayout';

const RESULTS_PER_PAGE = 10;

interface EducationDocumentItem extends Item {
  id: number;
  title: string;
  postedBy: string;
  postedOn: string;
  signedAt: Nullable<Moment>;
  status: EmployeeStatus;
}

export const EmployeeEducationDocuments = observer(() => {
  const [currentPage, setCurrentPage] = useState(1);
  const { educationDocumentStore } = useStores();
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { employeeId, status } = params;

  const paramToStatusEnum = () => {
    if (status === 'Complete') return EmployeeStatus.COMPLETE;
    if (status === 'Incomplete') return EmployeeStatus.INCOMPLETE;
    return undefined;
  };

  const paramToNum = () => {
    if (status === 'Complete') return 1;
    if (status === 'Incomplete') return 2;
    return undefined;
  };

  const updateFilters = () => {
    const newStatus = paramToStatusEnum();
    if (newStatus) {
      educationDocumentStore.setFilters({ status: newStatus });
    } else {
      educationDocumentStore.clearFilters();
    }
  };

  const fetchData = async () => {
    await educationDocumentStore.fetchAllForEmployee(employeeId!);
    updateFilters();
  };

  useEffect(() => {
    fetchData();
    return () => {
      educationDocumentStore.clearFilters();
      educationDocumentStore.clear();
    };
  }, [employeeId]);

  const educationDocuments = useMemo(() => {
    return educationDocumentStore.filtered.map<EducationDocumentItem>(
      ({ id, title, postedBy, postedOn, educationalDocumentEmployees }) => ({
        id,
        title,
        postedBy,
        postedOn,
        signedAt: educationalDocumentEmployees?.length
          ? educationalDocumentEmployees[0].signedAt
          : null,
        status:
          educationalDocumentEmployees?.length && educationalDocumentEmployees[0]?.isSigned
            ? EmployeeStatus.COMPLETE
            : EmployeeStatus.INCOMPLETE,
      }),
    );
  }, [educationDocumentStore.filtered]);

  const columns: ListColumn[] = [
    {
      name: 'Title',
      key: 'title',
      width: { sm: 7, lg: 7 },
      render: (item) => <span>{item.title}</span>,
    },
    {
      name: 'Created By',
      key: 'postedBy',
      textAlign: 'center',
      width: { sm: 2, lg: 2 },
      render: ({ postedBy }) => <div style={{ textAlign: 'center' }}>{postedBy}</div>,
    },
    {
      name: 'Posted On',
      key: 'postedOn',
      textAlign: 'center',
      width: { sm: 2, lg: 2 },
      render: ({ postedOn }) => (
        <div style={{ textAlign: 'center' }}>{moment(postedOn).format('L')}</div>
      ),
    },
    {
      name: 'Signed At',
      key: 'signedAt',
      textAlign: 'center',
      width: { sm: 3, lg: 3 },
      render: ({ signedAt }) => (
        <div style={{ textAlign: 'center' }}>
          {signedAt ? signedAt.format('MM/DD/YYYY - h:mm:ss a') : null}
        </div>
      ),
    },
    {
      name: 'Status',
      key: 'status',
      textAlign: 'center',
      width: { sm: 2, lg: 2 },
      render: ({ status }) => (
        <div
          style={{
            textAlign: 'center',
            fontWeight: 700,
            color:
              status === EmployeeStatus.COMPLETE
                ? EmployeeStatusColor.Complete.color
                : EmployeeStatusColor.Incomplete.color,
          }}
        >
          {status}
        </div>
      ),
    },
  ];

  const handleStatusChange = (value?: number) => {
    const employeeStatus = numToValue(value);
    educationDocumentStore.setFilters({
      status: employeeStatus,
    });

    if (employeeStatus) {
      searchParams.set('status', employeeStatus.toString());
    } else {
      searchParams.delete('status');
    }

    setSearchParams(searchParams);
  };

  const numToValue = (value?: number) => {
    if (value === 1) return EmployeeStatus.COMPLETE;
    if (value === 2) return EmployeeStatus.INCOMPLETE;
    return undefined;
  };

  return (
    <>
      <EntityList<EducationDocumentItem>
        title="Educational Documents"
        searchLabel="Search by document name"
        columns={columns}
        items={paginate(educationDocuments, RESULTS_PER_PAGE, currentPage)}
        titleProp="title"
        onSearchChange={(filter) => educationDocumentStore.setFilter(filter)}
        onDismiss={() => educationDocumentStore.clearError()}
        onRefresh={fetchData}
        errorMessage={educationDocumentStore.errorMessage}
        isLoading={educationDocumentStore.isLoading}
        noNewButton
        searchHeaderContent={
          <>
            <Column>
              <Form.Field>
                <FilterDropdown
                  name={'Filters'}
                  placeholder={'Filters'}
                  clearable
                  value={paramToNum()}
                  changeDropdown={(_, value) => handleStatusChange(value)}
                  options={[
                    {
                      text: 'Completed',
                      value: 1,
                    },
                    {
                      text: 'Incompleted',
                      value: 2,
                    },
                  ]}
                />
              </Form.Field>
            </Column>
          </>
        }
      />
      <Grid centered>
        <Grid.Row>
          <Pagination
            activePage={currentPage}
            totalPages={Math.ceil(educationDocuments.length / RESULTS_PER_PAGE)}
            onPageChange={(_, { activePage }) => setCurrentPage(activePage as number)}
            siblingRange={2}
            boundaryRange={0}
            firstItem={{ content: 'First' }}
            lastItem={{ content: 'Last' }}
            ellipsisItem={null}
            prevItem={null}
            nextItem={null}
          />
        </Grid.Row>
      </Grid>
    </>
  );
});
