import AppContext from '@/AppContext';
import { useAsyncEffect } from '@/hooks';
import { Column, Row } from '@/semantic-ui/components';
import { useStores } from '@/store';
import { isNaN } from 'lodash';
import { observer } from 'mobx-react';
import React, { useContext, useState } from 'react';
import { useSearchParams } from 'react-router';
import { Checkbox, Dimmer, Form, Grid, Loader, Pagination, Popup, Table } from 'semantic-ui-react';
import {
  Authority,
  Department,
  ID,
  IStaffEmployee,
  SeniorityOrderByField,
  Staff,
  isSeniorityOrderByField,
  toNum,
} from 'sol-data';
import Bulletin from '../Bulletin';
import EmployeeFilterDropdown from '../EmployeeFilterDropdown';
import EmployeeSeniorityModal from '../EmployeeSeniorityModal';
import PositionFilterDropdown from '../PositionFilterDropdown';
import style from './index.less';

const queryParams = {
  page: 'seniorityPage',
  employee: 'seniorityEmployee',
  position: 'seniorityPosition',
  order: 'seniorityOrder',
  sortBy: 'senioritySortBy',
  showHiddenEmployees: 'showHiddenEmployees',
};

interface SeniorityListProps {
  departments: Department[];
}

export const SeniorityList = observer(({ departments }: SeniorityListProps) => {
  const context = useContext(AppContext);
  const { staffStore, userStore, positionStore } = useStores();
  const [selectedEmployee, setSelectedEmployee] = useState<IStaffEmployee | undefined>();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get(queryParams.page) ? Number(searchParams.get(queryParams.page)) : 1;
  const employee = searchParams.get(queryParams.employee)
    ? Number(searchParams.get(queryParams.employee))
    : undefined;
  const position = searchParams.get(queryParams.position)
    ? Number(searchParams.get(queryParams.position))
    : undefined;
  const order = searchParams.get(queryParams.order) ?? undefined;
  const sortBy = searchParams.get(queryParams.sortBy) ?? undefined;
  const showHiddenEmployees = searchParams.get(queryParams.showHiddenEmployees) ?? undefined;

  const fetchData = (
    pageNumber?: number,
    employeeId?: ID,
    positionId?: ID,
    sortOrder?: string,
    sortByField?: string,
  ) => {
    const {
      scope: { positions },
    } = context;

    return staffStore.fetchSeniorityPage({
      pageOptions: { page: pageNumber ?? 1 },
      query: {
        employee: employeeId,
        showHiddenEmployees: showHiddenEmployees === 'true',
        positions: positionId ? [positionId] : positions || undefined,
        order: sortOrder === 'desc' ? 'desc' : 'asc',
        sortBy: sortByField && isSeniorityOrderByField(sortByField) ? sortByField : undefined,
      },
    });
  };

  useAsyncEffect(async () => {
    await changePage(page, employee, position, order, sortBy);

    if (!position) {
      searchParams.append(queryParams.position, positionStore.values[0].id.toString());
      setSearchParams(searchParams);
    }
  }, [page, employee, position, order, sortBy]);

  const changePage = async (
    pageNumber: number,
    employeeId?: ID,
    positionId?: ID,
    sortOrder?: string,
    sortByField?: string,
  ) => {
    if (!pageNumber || isNaN(pageNumber)) {
      return;
    }

    searchParams.append(queryParams.page, pageNumber.toString());
    setSearchParams(searchParams);

    return fetchData(pageNumber, employeeId, positionId, sortOrder, sortByField);
  };

  const changeDropdown = async (type: string, id?: ID) => {
    const value = id ? id.toString() : undefined;

    if (value) {
      switch (type) {
        case queryParams.employee:
          searchParams.set(queryParams.employee, value);
          searchParams.delete(queryParams.position);

          return changePage(page, value ? Number(value) : undefined, undefined, order, sortBy);
        case queryParams.position:
          searchParams.set(queryParams.position, value);
          searchParams.delete(queryParams.employee);

          return changePage(page, undefined, value ? Number(value) : undefined, order, sortBy);
        default:
          break;
      }
    } else {
      searchParams.delete(type);
      setSearchParams(searchParams);
    }

    return changePage(page, undefined, undefined, order, sortBy);
  };

  const changeHiddenEmployees = async (value: boolean) => {
    if (value) {
      searchParams.append(queryParams.showHiddenEmployees, 'true');
    } else {
      searchParams.delete(queryParams.showHiddenEmployees);
    }

    setSearchParams(searchParams);

    changePage(1);

    return changePage(page, undefined, undefined, order, sortBy);
  };

  const handleSortChange = async (value: SeniorityOrderByField) => {
    searchParams.append(queryParams.order, 'asc');
    searchParams.append(queryParams.sortBy, value);

    if (sortBy === value) {
      const newOrder = order === 'asc' ? 'desc' : 'asc';
      searchParams.append(queryParams.order, newOrder);
    }

    setSearchParams(searchParams);

    return changePage(page, undefined, undefined, order, sortBy);
  };

  const renderTableHeader = () => {
    const { current: currentUser } = userStore;
    const [authority] = (currentUser?.access && currentUser.access.authority) || [
      Authority.ReadOnly,
    ];
    const isSuperAdmin = authority >= Authority.Super;
    const headerColumns = [
      <Table.HeaderCell className={`markBackTable ${style.table_header_fixed}`}>
        <div
          aria-hidden="true"
          className={style.sort_action}
          onClick={() => handleSortChange('description')}
        >
          EMPLOYEE NAME
        </div>
      </Table.HeaderCell>,
      <Table.HeaderCell className={`markBackTable ${style.table_header_fixed}`}>
        <div
          aria-hidden="true"
          className={style.sort_action}
          onClick={() => handleSortChange('department')}
        >
          DEPARTMENT
        </div>
      </Table.HeaderCell>,
      <Table.HeaderCell className={`markBackTable ${style.table_header_fixed}`}>
        <div
          aria-hidden="true"
          className={style.sort_action}
          onClick={() => handleSortChange('fte')}
        >
          FTE
        </div>
      </Table.HeaderCell>,
      <Table.HeaderCell className={`markBackTable ${style.table_header_fixed}`}>
        <div
          aria-hidden="true"
          className={style.sort_action}
          onClick={() => handleSortChange('startDate')}
        >
          START DATE
        </div>
      </Table.HeaderCell>,
      <Table.HeaderCell className={`markBackTable ${style.table_header_fixed}`}>
        <div
          aria-hidden="true"
          className={style.sort_action}
          onClick={() => handleSortChange('seniority')}
        >
          SENIORITY
        </div>
      </Table.HeaderCell>,
      <Table.HeaderCell className={`markBackTable ${style.table_header_fixed}`}>
        <div
          aria-hidden="true"
          className={style.sort_action}
          onClick={() => handleSortChange('lineNumber')}
        >
          LINE #
        </div>
      </Table.HeaderCell>,
      <Table.HeaderCell className={`markBackTable ${style.table_header_fixed}`}>
        <div
          aria-hidden="true"
          className={style.sort_action}
          onClick={() => handleSortChange('notes')}
        >
          NOTES
        </div>
      </Table.HeaderCell>,
    ];
    if (isSuperAdmin) {
      headerColumns.push(
        <Table.HeaderCell className={`markBackTable ${style.table_header_fixed}`}>
          {' '}
        </Table.HeaderCell>,
      );
    }
    return headerColumns;
  };

  const renderTableBody = (sortedTableData: Staff[]) => {
    const { current: currentUser } = userStore;
    const [authority] = (currentUser?.access && currentUser.access.authority) || [
      Authority.ReadOnly,
    ];
    const isSuperAdmin = authority >= Authority.Super;
    return sortedTableData.map(({ props: employee }) => {
      return (
        <Table.Row
          key={employee.id}
          className={employee.isHiddenOnSeniorityList ? style.hidden_employee : undefined}
        >
          <Table.Cell textAlign="center" className={style.medium_column}>
            {employee.user.lastName},{employee.user.firstName}
          </Table.Cell>
          <Table.Cell textAlign="center">
            {getDepartmentLabels(employee.departments.map((d) => d.id))}
          </Table.Cell>
          <Table.Cell textAlign="center">{employee.fte || 'N/A'}</Table.Cell>
          <Table.Cell textAlign="center">{employee.startDate?.format('L')}</Table.Cell>
          <Table.Cell textAlign="center">
            {employee.seniorityLock && employee.seniority ? (
              <div className={style.center_align}>
                <Popup
                  on="hover"
                  trigger={<p className={style.seniority_highlight}>{employee.seniority}</p>}
                  content={'Seniority manually set'}
                />
              </div>
            ) : (
              employee.seniority || 'N/A'
            )}{' '}
          </Table.Cell>
          <Table.Cell textAlign="center">{employee.line || 'N/A'}</Table.Cell>
          <Table.Cell textAlign="center" className={style.table_cell}>
            {employee.notes || 'N/A'}
          </Table.Cell>

          {isSuperAdmin && (
            <Table.Cell textAlign="center">
              <div onClick={() => openEditModal(employee)} className={style.iconBtn}>
                {getIcon()}
              </div>
            </Table.Cell>
          )}
        </Table.Row>
      );
    });
  };

  const openEditModal = (employee: IStaffEmployee) => {
    setSelectedEmployee(employee);
    setIsEditModalOpen(true);
  };

  const onModalEditClose = () => {
    setIsEditModalOpen(false);
    setSelectedEmployee(undefined);
  };

  const getDepartmentLabels = (deptIds: number[]) => {
    const deptArr = departments.filter((dept) => deptIds.includes(dept.id));
    return !deptArr.length ? 'N/A' : deptArr.map((d) => d.label).join(', ');
  };

  const getIcon = () => {
    return (
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.8005 12.6667C13.8005 13.0333 13.6699 13.3473 13.4085 13.6087C13.1476 13.8696 12.8339 14 12.4672 14H3.13385C2.76719 14 2.45319 13.8696 2.19185 13.6087C1.93097 13.3473 1.80052 13.0333 1.80052 12.6667L1.80052 6.71667L3.13385 8.05L3.13385 12.6667H12.4672V3.33333H7.83385L6.50052 2H12.4672C12.8339 2 13.1476 2.13044 13.4085 2.39133C13.6699 2.65267 13.8005 2.96667 13.8005 3.33333V12.6667ZM2.18385 5.21667L3.11719 4.26667L7.51719 8.66667H8.46719V7.73333L4.05052 3.31667L4.98385 2.36667L9.80052 7.16667V10H6.96719L2.18385 5.21667ZM4.98385 2.36667L2.18385 5.21667L0.517188 3.55C0.250521 3.28333 0.117188 2.96378 0.117188 2.59133C0.117188 2.21933 0.250521 1.90556 0.517188 1.65L1.46719 0.716667C1.72274 0.461111 2.03385 0.333333 2.40052 0.333333C2.76719 0.333333 3.0783 0.461111 3.33385 0.716667L4.98385 2.36667Z"
          fill="black"
        />
      </svg>
    );
  };

  if (staffStore.isLoadingSeniority && !staffStore.seniority)
    return (
      <Dimmer active inverted>
        <Loader size="large">Loading Seniority</Loader>
      </Dimmer>
    );

  const totalPages = staffStore.seniority?.meta.totalPages ?? 0;

  return (
    <div>
      <div className={style.header}>
        <Row className={style.employee_search_container}>
          <Column className={style.header_employee_search}>
            <Form>
              <Form.Field>
                <EmployeeFilterDropdown
                  search
                  clearable
                  name="employee"
                  placeholder="Search by employee"
                  value={employee ? toNum(employee) : undefined}
                  onChange={(_, id) => changeDropdown(queryParams.employee, id)}
                />
              </Form.Field>
            </Form>
          </Column>
          <Checkbox
            className={style.checkbox}
            label="Show hidden employees"
            checked={showHiddenEmployees === 'true'}
            onChange={(_, { checked }) => changeHiddenEmployees(!!checked)}
          />
        </Row>
        <Column width={5} />
        <Column className={style.header_item}>
          <Form>
            <Form.Field>
              <PositionFilterDropdown
                limited
                name="position"
                placeholder="Filter by position"
                positionId={position ? toNum(position) : undefined}
                onChange={(_, id) => changeDropdown(queryParams.position, id)}
              />
            </Form.Field>
          </Form>
        </Column>
      </div>
      {staffStore.errorSeniority && <Bulletin error={staffStore.errorSeniority} />}
      <div className={style.tables_container}>
        <div>
          <Table className={style.table} striped>
            <Table.Header>
              <Table.Row textAlign="center">{renderTableHeader()}</Table.Row>
            </Table.Header>
            <Table.Body>{renderTableBody(staffStore.seniority?.data || [])}</Table.Body>
          </Table>
        </div>
        {isEditModalOpen && selectedEmployee && (
          <EmployeeSeniorityModal
            open={isEditModalOpen}
            onClose={onModalEditClose}
            employee={selectedEmployee}
            department={getDepartmentLabels(selectedEmployee.departments.map((d) => d.id))}
            onSubmit={async () => {
              await changePage(page, employee, position, order, sortBy);
              onModalEditClose();
            }}
          />
        )}
      </div>
      <Grid centered>
        <Grid.Row>
          <Pagination
            activePage={page}
            totalPages={totalPages}
            onPageChange={(_, { activePage }) =>
              changePage(activePage as number, employee, position, order, sortBy)
            }
            siblingRange={2}
            boundaryRange={0}
            firstItem={{ content: 'First' }}
            lastItem={{ content: 'Last' }}
            ellipsisItem={null}
            prevItem={null}
            nextItem={null}
          />
        </Grid.Row>
      </Grid>
    </div>
  );
});
