import * as React from 'react';
import { Grid, Icon, Label } from 'semantic-ui-react';
import { Employee, Position } from 'sol-data';
import style from './index.less';

interface Props {
  positions: Position[];
  employees: Employee[];
  fullList: boolean;
  onFullList: () => void;
}

const WageEmployeePanel: React.FC<Props> = ({ fullList, positions, employees, onFullList }) => {
  const getNumberOfEmployees = (id: number) => {
    if (!employees.length) return 0;
    const filterEmployee = employees.filter(({ position }) => position.id === id);
    return filterEmployee?.length;
  };

  const getFullNumberOfEmployees = () => {
    return positions.reduce(
      (totalEmployee, position) => totalEmployee + getNumberOfEmployees(position.id),
      0,
    );
  };

  return (
    <Grid className={style.employee_panel_container}>
      <Grid.Row className={style.employee_panel_header}>
        <Grid.Column className={style.employee_panel_header}>
          {`TOTAL ${getFullNumberOfEmployees()}  EMPLOYEES SELECTED`}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row className={style.employee_panel_body}>
        <Grid.Column width={16}>
          {positions.map((position) => (
            <Grid.Row key={position.id}>
              <Grid.Column width={16} className={style.employee_row_title}>
                {`${position.description} (${position.label})--# Employees:${getNumberOfEmployees(
                  position.id,
                )} `}
              </Grid.Column>
              <Grid.Column width={16} className={style.employee_row_list}>
                {employees
                  .filter((employee) => employee.position.id === position.id)
                  .map((employee, index, arrayEmployee) => {
                    if (fullList || index <= 4) {
                      return (
                        <Label key={employee.id} className={style.employee_label}>
                          {' '}
                          {`${employee.user.firstName} ${employee.user.lastName}`}
                        </Label>
                      );
                    }
                    if (index === 5) {
                      return (
                        <Label
                          key={`${position.id}-${employee.id}`}
                          className={style.employee_label}
                        >
                          {`${arrayEmployee.length - 5} more employees...`}
                        </Label>
                      );
                    }
                    return <div key={`${position.id}-${employee.id}`} />;
                  })}
              </Grid.Column>
            </Grid.Row>
          ))}
        </Grid.Column>
        <Grid.Column
          onClick={() => {
            onFullList();
          }}
          className={style.employee_row_footer}
          width={'16'}
        >
          {!fullList ? (
            <>
              See Full list
              <Icon name="chevron right" />
            </>
          ) : (
            <>
              Hide list
              <Icon name="chevron up" />
            </>
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default WageEmployeePanel;
