import * as React from 'react';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import {
  AreaStore,
  EmployeeStore,
  FilteredRequest,
  RequestStatus,
  RequestType,
  RequestsStore,
  ShiftStore,
  ShiftSwapStore,
  SickNoticeStore,
  TimeOffStore,
  moment,
} from 'sol-data';
import InjectedComponent from '@/components/InjectedComponent';
import ListLayout, { ListColumn } from '@/components/ListLayout';
import classnames from 'classnames';
import { Modal } from 'semantic-ui-react';
import NoticeModal from '@/components/Schedule/NoticeModal';
import ShiftSwapModal from '@/components/ShiftSwapModal';
import style from './index.less';

const requestTypeLabel = {
  [RequestType.SickNotice]: 'Sick Notice',
  [RequestType.TimeOff]: 'Time Off',
  [RequestType.ShiftSwap]: 'Shift Swap',
};

interface Props {
  requests?: FilteredRequest[];
  fetchData?: () => void;
}

interface Injected {
  requestsStore: RequestsStore;
  sickNoticeStore: SickNoticeStore;
  shiftSwapStore: ShiftSwapStore;
  timeOffStore: TimeOffStore;
  shiftStore: ShiftStore;
  employeeStore: EmployeeStore;
  areaStore: AreaStore;
}

interface State {
  modalOpen: boolean;
  selectedRequest?: FilteredRequest;
}

@inject(
  'requestsStore',
  'sickNoticeStore',
  'shiftSwapStore',
  'timeOffStore',
  'employeeStore',
  'shiftStore',
  'areaStore',
)
@observer
class RequestsList extends InjectedComponent<Props, State, Injected> {
  state: State = {
    modalOpen: false,
  };

  toggleModal = (request?: FilteredRequest) => {
    this.setState((state) => ({ modalOpen: !!request, selectedRequest: request }));
  };

  @computed get columns(): ListColumn<FilteredRequest>[] {
    return [
      {
        name: 'Request Type',
        key: 'request_type',
        render: ({ type }) => <div>{requestTypeLabel[type]}</div>,
      },
      {
        name: 'Start Date',
        key: 'startDate',
        render: ({ startDate }) => <div>{startDate.format('MMM D YYYY')}</div>,
      },
      {
        name: 'End Date',
        key: 'endDate',
        render: ({ endDate }) => <div>{endDate.format('MMM D YYYY')}</div>,
      },
      {
        name: 'Employee',
        key: 'employee',
        render: ({ employee }) => <div>{employee.user.fullName}</div>,
      },
      {
        name: 'Message',
        key: 'message',
        width: 4,
        render: ({ message }) => <div>{message}</div>,
      },
      {
        name: 'Submitted On',
        key: 'submitted-on',
        render: ({ submittedOn }) => <div>{submittedOn.format('YYYY-MM-DD')}</div>,
      },
      {
        name: 'Status',
        key: 'status',
        render: ({ status }) => (
          <div
            className={classnames(style.requests_status, {
              [style.requests_status_denied]: status === RequestStatus.Denied,
            })}
          >
            {status}
          </div>
        ),
      },
    ];
  }

  render() {
    const { requestsStore, shiftStore } = this.injected;
    const { requests = requestsStore.view.filtered, fetchData } = this.props;
    const { modalOpen, selectedRequest } = this.state;

    const isLoading = requestsStore.showLoading || shiftStore.showLoading;
    const errorMessage = requestsStore.errorMessage || shiftStore.errorMessage;
    const isComplete = selectedRequest?.endDate.isBefore(moment());

    return (
      <>
        <ListLayout
          headerProps={{
            isLoading,
            errorMessage,
            onDismiss: () => {
              if (requestsStore.error) requestsStore.clearError();
              else if (shiftStore.error) shiftStore.clearError();
            },
          }}
          items={requests
            // id is not unique because there are multiple request types
            .map((req) => Object.assign(req, { key: req.type + req.id + req.employee.id }))}
          columns={this.columns}
          onClick={({ id, type }) =>
            this.toggleModal(requests.find((req) => req.id === id && req.type === type))
          }
        />
        {modalOpen && selectedRequest && (
          <Modal open={modalOpen} size="mini" onClose={() => this.toggleModal()} closeIcon>
            {selectedRequest.type === RequestType.ShiftSwap ? (
              <ShiftSwapModal request={selectedRequest} closeModal={() => this.toggleModal()} />
            ) : (
              <NoticeModal
                request={selectedRequest}
                type={selectedRequest.type}
                closeModal={() => {
                  this.toggleModal();
                  fetchData?.();
                }}
                isComplete={isComplete}
              />
            )}
          </Modal>
        )}
      </>
    );
  }
}

export default RequestsList;
