import Breadcrumbs from '@/components/Breadcrumbs';
import Bulletin from '@/components/Bulletin';
import IncrementalButton from '@/components/IncrementalButton';
import { withNavigation } from '@/components/Route';
import style from '@/containers/Organization/index.less';
import { useAsyncEffect } from '@/hooks';
import { Button, Dropdown, Form, Grid, Input, Segment } from '@/semantic-ui/components';
import { useStores } from '@/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik } from 'formik';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { DropdownProps } from 'semantic-ui-react';
import { Rotation, RotationRule } from 'sol-data';
import { array, number, object, string } from 'yup';
import ButtonBar from '../ButtonBar';

const { Field } = Form;
const { Column, Row } = Grid;

interface InitialValues {
  description: string;
  departmentId?: number;
  positionId?: number;
  frequency: number;
  rules: RotationRule[];
}

export const ModifyRotations = observer(() => {
  const { departmentStore, areaStore, positionStore, rotationStore } = useStores();
  const [initialValues, setInitialValues] = useState<InitialValues>({
    description: '',
    departmentId: undefined,
    positionId: undefined,
    frequency: 2,
    rules: [],
  });
  const { dropdownOptions: departmentOptions } = departmentStore;
  const { values: areas } = areaStore;
  const { dropdownOptions: positionOptions } = positionStore;
  const { current: currentRotation, errorMessage, isLoading } = rotationStore;
  const navigate = useNavigate();
  const params = useParams();
  const rotationId = params.rotationId!;

  useAsyncEffect(async () => {
    await rotationStore.fetchAll();

    if (rotationId !== 'add') {
      rotationStore.setCurrent(rotationId);
    } else {
      rotationStore.addAsCurrent(Rotation.create());
    }

    await Promise.all([departmentStore.fetchAll(), areaStore.fetchAll(), positionStore.fetchAll()]);

    return () => {
      rotationStore.remove(-1);
      rotationStore.clearCurrent();
    };
  }, [rotationId, departmentStore, areaStore, positionStore, rotationStore]);

  useEffect(() => {
    if (currentRotation) {
      setInitialValues(currentRotation.withoutID());
    }
  }, [currentRotation]);

  return (
    <Segment basic padded="very">
      <Breadcrumbs
        linkTitle="Rotations"
        activeBreadcrumb={initialValues.description || 'New Rotation'}
        link="/organization/rotations"
        title={'Rotations'}
      />
      <Formik
        enableReinitialize
        validationSchema={object().shape({
          description: string().required('Name is required'),
          departmentId: number()
            .min(1, 'Please select a department')
            .typeError('Please select a department')
            .required('You must select a department'),
          positionId: number()
            .min(1, 'Please select a position')
            .typeError('Please select a position')
            .required('You must select a position'),
          frequency: number().min(1, 'Must be at least one week').required('Frequency is required'),
          rules: array()
            .of(
              object().shape({
                from: number().required('Area required'),
              }),
            )
            .min(1, 'A rotation requires at least 1 area'),
        })}
        initialValues={initialValues}
        onSubmit={async (values, { setSubmitting }) => {
          const rotation = rotationStore.assignCurrent(values);

          if (rotation.id === -1) {
            await rotationStore.create(rotation.withoutID());
          } else {
            await rotationStore.save(rotation);
          }

          setSubmitting(false);
          if (!errorMessage) {
            navigate('/organization/rotations');
          }
        }}
        render={({
          values,
          errors,
          handleChange,
          touched,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => {
          const showDescriptionError = !!touched.description && !!errors.description;
          const showDepartmentError = !!touched.departmentId && !!errors.departmentId;
          const showPositionError = !!touched.positionId && !!errors.positionId;
          const showFrequencyError = !!touched.frequency && !!errors.frequency;
          const showRulesError = !!touched.rules && !!errors.rules && !Array.isArray(errors.rules);

          const hasAreaError = (idx: number) =>
            errors.rules && errors.rules[idx] && errors.rules[idx]!.from;

          const { rules } = values;

          const addRule = () => {
            setFieldValue('rules', [...rules, {}]);
          };

          const handleRuleChange = (idx: number, value: number) => {
            setFieldValue(
              'rules',
              rules.map((rule, i) =>
                idx !== i
                  ? rule
                  : {
                      ...rule,
                      from: value,
                    },
              ),
            );
          };

          const removeRule = (removedIndex: number) => {
            setFieldValue(
              'rules',
              rules.filter((_, idx) => idx !== removedIndex),
            );
          };

          const filteredAreas = areas
            .filter(({ departmentId }) => departmentId === values.departmentId)
            .map(({ id, description }) => ({
              text: description,
              value: id,
              disabled: rules.some(({ from }) => from === id),
            }));

          const handleDropdown: DropdownProps['onChange'] = (_, { name, value }) =>
            setFieldValue(name, value);

          return (
            <Form onSubmit={handleSubmit}>
              <Grid>
                <Row>
                  <Bulletin
                    isLoading={isLoading}
                    errorMessage={errorMessage}
                    handleDismiss={() => rotationStore.clearError()}
                  />
                </Row>
                <Row>
                  <Column width={16}>
                    <Field>
                      <p className={style.form_headers}>Description</p>
                      <Input
                        fluid
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                      />
                      {showDescriptionError && (
                        <p className={style.error_class}>{errors.description}</p>
                      )}
                    </Field>
                  </Column>
                </Row>
                <Row>
                  <Column width={8}>
                    <Field>
                      <p className={style.form_headers}>Department</p>
                      <Dropdown
                        fluid
                        selection
                        placeholder="Select a department"
                        options={departmentOptions}
                        name="departmentId"
                        value={values.departmentId}
                        onChange={handleDropdown}
                        className={style.dropdowns}
                      />
                      {showDepartmentError && (
                        <p className={style.error_class}>{errors.departmentId}</p>
                      )}
                    </Field>
                  </Column>
                  <Column width={8}>
                    <Field>
                      <p className={style.form_headers}>Position</p>
                      <Dropdown
                        fluid
                        selection
                        placeholder="Select a position"
                        options={positionOptions}
                        name="positionId"
                        value={values.positionId}
                        onChange={handleDropdown}
                        className={style.dropdowns}
                      />
                      {showPositionError && (
                        <p className={style.error_class}>{errors.positionId}</p>
                      )}
                    </Field>
                  </Column>
                </Row>
                <Row>
                  <Column width={8}>
                    <div className={style.rotations_arrow_wrapper}>
                      {rules.length > 1 && <div className={style.rotations_side_border} />}
                      {rules.map(({ id, from }, index) => {
                        const hasError = hasAreaError(index);

                        return (
                          <div key={id}>
                            <Field className={style.rotations_form_dropdown_wrapper}>
                              <Dropdown
                                search
                                fluid
                                selection
                                placeholder="Choose an area..."
                                options={filteredAreas}
                                value={from}
                                onChange={(_, { value }) => {
                                  handleRuleChange(index, value as number);
                                }}
                                error={!!hasError}
                                onBlur={handleBlur}
                                className={style.rotations_form_dropdown}
                              />
                              {rules.length > 1 && (
                                <FontAwesomeIcon
                                  icon="times"
                                  className={style.rotations_form_dropdown_x}
                                  onClick={() => removeRule(index)}
                                />
                              )}
                              {rules.length > 1 && index === 0 && (
                                <FontAwesomeIcon icon={['fal', 'long-arrow-left']} size="2x" />
                              )}
                            </Field>
                            <div className={style.rotations_form_dropdown_arrow}>
                              {rules.length > 1 && index !== rules.length - 1 && (
                                <FontAwesomeIcon icon={['fal', 'long-arrow-down']} size="2x" />
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    {showRulesError && <p className={style.error_class}>{errors.rules}</p>}
                    <Button basic color="blue" type="button" onClick={() => addRule()}>
                      Add Area
                    </Button>
                  </Column>
                  <Column width={8}>
                    <Field error={showFrequencyError}>
                      <p className={style.form_headers}>Frequency</p>
                      <div className={style.rotations_frequency_row}>
                        <span>Every</span>
                        <IncrementalButton
                          name="frequency"
                          value={values.frequency}
                          onChange={(_, value) => setFieldValue('frequency', value)}
                          onBlur={handleBlur}
                          min={1}
                        />
                        <span>Weeks</span>
                      </div>
                      {showFrequencyError && (
                        <p className={style.error_class}>{errors.frequency}</p>
                      )}
                    </Field>
                  </Column>
                </Row>
                <Row>
                  <Column width={16}>
                    <ButtonBar
                      onSave={handleSubmit}
                      link="/organization/rotations"
                      disabled={isSubmitting}
                    />
                  </Column>
                </Row>
              </Grid>
            </Form>
          );
        }}
      />
    </Segment>
  );
});

export default withNavigation<{ rotationId: string }>(ModifyRotations);
