import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ConflictType, conflictOptions } from '../utils';
import styling from './index.less';

interface Props {
  type: ConflictType;
  title: string;
  onChange?: React.DOMAttributes<any>['onClick'];
}

const ConflictButton: React.FC<Props> = ({ type, title, onChange }) => {
  const { color, icon } = conflictOptions[type];
  const style = {
    backgroundColor: color,
    color: '#fafafa',
  };

  return (
    <div className={styling.conflict_button}>
      <button type="button" name={type} onClick={onChange} style={style}>
        <FontAwesomeIcon icon={icon} size="lg" />
      </button>
      <span>{title}</span>
    </div>
  );
};

export default ConflictButton;
