import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import style from './index.less';

const CategoryCheckmark = ({ className }: { className?: string }) => (
  <div className={cn(style.checkmark, className)}>
    <FontAwesomeIcon icon={['fal', 'check']} size="2x" />
  </div>
);

export default CategoryCheckmark;
