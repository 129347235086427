import App from '@/App';
import { authService } from '@/api';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router';
import { moment } from 'sol-data';
// add all semantic-ui styles globally
import './semantic-ui/index';

moment.tz.setDefault(process.env.TIMEZONE);

// we have to wait until localstorage is loaded, or every component would need a defensive check
authService.isReady().then(() => {
  const container = document.getElementById('app');

  if (!container) throw new Error('Failed to find the root element');

  const root = createRoot(container);

  root.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>,
  );
});
