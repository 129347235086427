import * as React from 'react';
import { moment } from 'sol-data';
import { Dropdown } from '@/semantic-ui/components';
import { DropdownProps } from 'semantic-ui-react';
import { DropdownProps as DatePickerProps } from '@/components/DatePicker';

const yearOptions = (() => {
  const years: {
    text: string;
    value: number;
  }[] = [];

  for (let i = 0; i <= 20; i += 1) {
    const year = moment().add(i, 'years').year();

    years.push({
      text: year.toString(),
      value: year,
    });
  }

  return years;
})();

interface Props extends DatePickerProps {
  clearDate?: boolean;
  onBlur: (field: string, value: boolean) => void;
}

const YearPicker: React.SFC<Props> = ({ fluid, name, value, placeholder, onChange, clearDate }) => {
  const handleSelect: DropdownProps['onChange'] = (_, { name: propName, value: propValue }) => {
    if (clearDate) {
      onChange('date', undefined);
    }

    onChange(propName, propValue as number);
  };

  return (
    <Dropdown
      selection
      name={name}
      placeholder={placeholder}
      fluid={fluid}
      options={yearOptions}
      onChange={handleSelect}
      value={value}
    />
  );
};

YearPicker.defaultProps = {
  value: undefined,
  fluid: false,
  placeholder: undefined,
  clearDate: false,
};

export default YearPicker;
