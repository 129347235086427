import * as React from 'react';
import { inject, observer } from 'mobx-react';
import classnames from 'classnames';
import { PositionStore, EmployeeStore, ScheduleStore, ScheduleComponentStore } from '@/store';
import { AreaScheduleShift, Shift, IShift, ShiftGroup } from 'sol-data';
import { Modal, Popup } from '@/semantic-ui/components';
import InjectedComponent from '@/components/InjectedComponent';
import AppContext from '@/AppContext';
import AreaShiftMenu from '../AreaShiftMenu';
import EditShiftModal from '../EditShiftModal';
import style from '../index.less';

interface Props {
  key: string;
  day: AreaScheduleShift[];
}

interface Injected {
  positionStore: PositionStore;
  employeeStore: EmployeeStore;
  scheduleComponentStore: ScheduleComponentStore;
  scheduleStore: ScheduleStore;
}

interface SelectedEntry {
  shift: Shift;
  shiftGroup: ShiftGroup;
}

interface State {
  popupOpen: boolean;
  modalOpen: boolean;
  selectedEntry?: SelectedEntry;
}

@inject('positionStore', 'employeeStore', 'scheduleComponentStore', 'scheduleStore')
@observer
class AreaShiftBlock extends InjectedComponent<Props, State, Injected> {
  constructor(props: Props) {
    super(props);

    this.state = {
      popupOpen: false,
      modalOpen: false,
    };
  }

  openModal = (entry?: SelectedEntry) => {
    this.setState({
      modalOpen: true,
      popupOpen: false,
      selectedEntry: entry,
    });
  };

  closeModal = () => {
    this.setState({ modalOpen: false });
  };

  togglePopup = () => {
    this.setState((state) => ({ popupOpen: !state.popupOpen }));
  };

  editShift = async (values: IShift) => {
    const { scheduleStore } = this.injected;
    const { selectedEntry } = this.state;

    if (!selectedEntry) return;

    const { shift } = selectedEntry;
    await scheduleStore.saveShift(shift.assign(values));

    this.closeModal();
  };

  render() {
    const {
      positionStore,
      employeeStore,
      scheduleComponentStore: { weekDisplayCount, cellWidth: width, cellHeight: height },
    } = this.injected;

    const { day } = this.props;
    const { popupOpen, modalOpen, selectedEntry } = this.state;

    if (!day || day.length === 0) {
      return <div style={{ width, height }} />;
    }

    const isWeekend = [0, 6].includes(day[0].shift.$.startDate.day());

    const className = classnames(
      style.shift_block_item,
      isWeekend ? style.shift_block_item_weekend : style.shift_block_item_weekday,
      {
        [style.shift_block_item_small]: weekDisplayCount > 2,
        [style.shift_block_item_big]: weekDisplayCount <= 2,
      },
    );

    // Sorting day to display OPEN shifts first
    const sortedDay = day.slice(0, day.length).sort((a, _) => {
      if (!a.employee) {
        return -1;
      }
      return 0;
    });

    const shortenedDay = sortedDay.slice(0, 2);

    // look at first entry for the position type
    const [{ position }] = day;

    const trigger = (
      <div className={style.shift_block} style={{ width, height }}>
        <div className={className}>
          {shortenedDay.map(({ employee, shift }) => (
            <span
              key={shift.id}
              className={classnames(style.shift_block_item_text, {
                [style.shift_block_item_single]: day.length < 2,
                [style.shift_block_item_open]: !employee,
              })}
            >
              {employee
                ? employeeStore.has(employee)
                  ? employeeStore.get(employee)!.user.firstName
                  : 'Unknown'
                : 'OPEN'}
            </span>
          ))}
          <span
            className={classnames(
              style.shift_block_item_label,
              isWeekend
                ? style.shift_block_item_label_weekend
                : style.shift_block_item_label_weekday,
            )}
          >
            {`${day.length} ${
              positionStore.has(position) ? `${positionStore.get(position)!.label}s` : 'Unknown'
            }`}
          </span>
        </div>
      </div>
    );

    const popupTrigger = (
      <AppContext.Consumer>
        {({ isAdmin }) => (
          <Popup
            trigger={trigger}
            content={<AreaShiftMenu day={day} openModal={this.openModal} />}
            open={popupOpen}
            on="click"
            onOpen={isAdmin ? this.togglePopup : undefined}
            onClose={this.togglePopup}
            position="right center"
          />
        )}
      </AppContext.Consumer>
    );

    return (
      <Modal size="mini" onClose={this.closeModal} open={modalOpen} trigger={popupTrigger}>
        {!!selectedEntry && (
          <EditShiftModal
            closeModal={this.closeModal}
            onSubmit={this.editShift}
            shift={selectedEntry.shift.withID}
            shiftGroup={selectedEntry.shiftGroup}
          />
        )}
      </Modal>
    );
  }
}

export default AreaShiftBlock;
