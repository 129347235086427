import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { ListColumn } from '@/components/ListLayout';
import { EntityList } from '@/components/EntityList';

import { AreaStore, DepartmentStore } from '@/store';
import InjectedComponent from '@/components/InjectedComponent';
import AppContext from '@/AppContext';

interface Injected {
  areaStore: AreaStore;
  departmentStore: DepartmentStore;
}

const deleteWarning =
  // eslint-disable-next-line no-multi-str
  'Are you sure you want to delete this area? Doing so will invalidate links to other properties \
  (shifts, schedules, requests, etc...) between this area and anything related to it.';

@inject('areaStore', 'departmentStore')
@observer
class Areas extends InjectedComponent<{}, {}, Injected> {
  async componentDidMount() {
    await this.fetch();
  }

  componentWillUnmount() {
    const { areaStore } = this.injected;
    areaStore.clearFilter();
  }

  fetch = async () => {
    const { departmentStore, areaStore } = this.injected;

    await Promise.all([departmentStore.fetchAll(), areaStore.fetchAll()]);
  };

  render() {
    const { areaStore, departmentStore } = this.injected;
    const { filtered: filteredAreas, errorMessage } = areaStore;

    const columns: ListColumn[] = [
      {
        name: 'ID',
        key: 'id',
        width: { sm: 2, lg: 1 },
        render: ({ id: areaId }) => <span>{areaId.toString().padStart(4, '0')}</span>,
      },
      {
        name: 'Name',
        key: 'label',
        width: { sm: 3, lg: 2 },
        // eslint-disable-next-line prettier/prettier
        render: (item) => <span>{item.label}</span>,
      },
      {
        name: 'Department',
        key: 'department',
        width: { sm: 5, lg: 4 },
        // eslint-disable-next-line prettier/prettier
        render: (item) => {
          const department = departmentStore.get(item.departmentId);
          return <span>{department ? department.description : 'No department found'}</span>;
        },
      },
      {
        name: 'Description',
        key: 'description',
        width: { sm: 6, lg: 4 },
      },
    ];

    return (
      <AppContext.Consumer>
        {({ isAdmin }) => (
          <EntityList
            title="Area"
            searchLabel="Search by area description"
            columns={columns}
            items={filteredAreas}
            newLink={'/organization/areas/add'}
            titleProp="description"
            // eslint-disable-next-line prettier/prettier
            onRemove={isAdmin ? (area) => areaStore.delete(area) : undefined}
            dialog={deleteWarning}
            // eslint-disable-next-line prettier/prettier
            onSearchChange={(filter) => areaStore.setFilter(filter)}
            onDismiss={() => areaStore.clearError()}
            onRefresh={this.fetch}
            errorMessage={errorMessage}
            // eslint-disable-next-line prettier/prettier
            link={isAdmin ? (area) => `/organization/areas/${area.id}` : undefined}
            noNewButton={!isAdmin}
          />
        )}
      </AppContext.Consumer>
    );
  }
}

export default Areas;
