import * as React from 'react';
import { Segment, Grid, Input, Form, Button, ButtonProps, Popup } from '@/semantic-ui/components';
import { Formik } from 'formik';
import { object, string } from 'yup';
import { authService } from '@/api';
import style from './index.less';

const { Row, Column } = Grid;
const { Field } = Form;

interface Props {}

interface State {
  apiKey: string;
  copySuccess: boolean;
}

export default class ApiKeys extends React.Component<Props, State> {
  input?: Input | null;

  constructor(props: Props) {
    super(props);

    this.state = {
      apiKey: '',
      copySuccess: false,
    };
  }

  render() {
    return (
      <Segment>
        <Formik
          enableReinitialize
          validationSchema={object().shape({
            keyName: string().required('Please enter a name for the API key.'),
          })}
          initialValues={{
            keyName: '',
            apiKey: '',
          }}
          onSubmit={async ({ keyName }) => {
            const { apiKey } = await authService.getApiKey(keyName);
            this.setState({ apiKey });
          }}
          render={({ values, errors, handleChange, handleBlur, handleSubmit }) => {
            const { apiKey, copySuccess } = this.state;

            // eslint-disable-next-line prettier/prettier
            const copyKey: ButtonProps['onClick'] = (e) => {
              if (apiKey && this.input) {
                this.input.select();
                document.execCommand('copy');
                e.currentTarget.focus();
                this.setState({ copySuccess: true });
                window.setTimeout(() => this.setState({ copySuccess: false }), 2000);
              }
            };

            return (
              <Form onSubmit={handleSubmit} className={style.api_form}>
                <Grid>
                  <Row>
                    <Column>
                      <Field>
                        <p className={style.form_headers}>Enter the name of this key</p>
                        <Input
                          name="keyName"
                          value={values.keyName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Key Name"
                        />
                      </Field>
                      {errors.keyName && <p className={style.error_class}>{errors.keyName}</p>}
                    </Column>
                  </Row>
                  <Row>
                    <Column>
                      <Button type="submit" color="blue" fluid>
                        Get Key
                      </Button>
                    </Column>
                  </Row>
                  <Row>
                    <Column width={14}>
                      <Field>
                        <Input
                          name="apiKey"
                          // eslint-disable-next-line prettier/prettier
                          ref={(input) => {
                            this.input = input;
                          }}
                          value={apiKey}
                          onChange={() => null}
                          placeholder="Your key will appear here"
                        />
                      </Field>
                    </Column>
                    <Column width={2}>
                      <Popup
                        trigger={
                          // eslint-disable-next-line react/jsx-wrap-multilines
                          <Button
                            type="button"
                            onClick={copyKey}
                            className={style.api_copy}
                            color="blue"
                            fluid
                          >
                            Copy
                          </Button>
                        }
                        content={<p className={style.api_success_msg}>Copied to the clipboard!</p>}
                        open={copySuccess}
                        position="left center"
                      />
                    </Column>
                  </Row>
                </Grid>
              </Form>
            );
          }}
        />
      </Segment>
    );
  }
}
