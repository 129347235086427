import Breadcrumbs from '@/components/Breadcrumbs';
import Bulletin from '@/components/Bulletin';
import InjectedComponent from '@/components/InjectedComponent';
import { WithNavigationProps, withNavigation } from '@/components/Route';
import { Schedule } from '@/components/Schedule';
import { Segment } from '@/semantic-ui/components';
import { AreaStore, PositionStore, ScheduleComponentStore, ScheduleStore } from '@/store';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { ScheduleInfoHeader } from '../ScheduleInfoHeader';

interface Props extends WithNavigationProps {}

interface Injected {
  scheduleStore: ScheduleStore;
  scheduleComponentStore: ScheduleComponentStore;
  positionStore: PositionStore;
  areaStore: AreaStore;
}

interface State {
  isTitleActive: boolean;
  scheduleTitle: string;
  confirmModalOpen: boolean;
  copyModalOpen: boolean;
}

@inject('scheduleStore', 'scheduleComponentStore', 'positionStore', 'areaStore')
@observer
class PublishedSchedule extends InjectedComponent<Props, State, Injected> {
  async componentDidMount() {
    const { scheduleStore } = this.injected;
    const { params } = this.props;
    const { scheduleId } = params;

    await scheduleStore.fetch(scheduleId!);

    scheduleStore.setCurrent(scheduleId);
  }

  render() {
    const { scheduleStore } = this.injected;
    const { current: schedule, isLoading, errorMessage } = scheduleStore;

    const config = {
      hasConflictFilters: true,
      hasEmployeeSearch: true,
      shift: {
        canHaveRequests: true,
      },
      group: {
        canAssign: true,
      },
    };

    return (
      <Segment basic padded="very">
        <Bulletin
          isLoading={isLoading}
          errorMessage={errorMessage}
          handleDismiss={scheduleStore.clearError}
        />

        <Breadcrumbs
          activeBreadcrumb="Published Schedule"
          link="/scheduling/all"
          linkTitle="Schedules"
        />

        {schedule && (
          <>
            <ScheduleInfoHeader />
            <br />
            <Schedule config={config} withFilterRow />
          </>
        )}
      </Segment>
    );
  }
}

export default withNavigation<{}>(PublishedSchedule);
