import * as React from 'react';
import { Grid } from '@/semantic-ui/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import style from './index.less';

interface IFileDisplayForm {
  removeFile?: (field: string, value: File) => void;
  handlePreviewClick: (file: File) => void;
  file: File;
}

const FileDisplayForm = ({ removeFile, handlePreviewClick, file }: IFileDisplayForm) => {
  return (
    <Grid className={style.fileDisplayRoot} columns={3} container>
      <Grid.Row>
        <Grid.Column width={1} className={style.center}>
          <FontAwesomeIcon icon={['fal', 'file-pdf']} size="2x" color="#979797" />
        </Grid.Column>
        <Grid.Column width={9} className={style.center}>
          <p className={style.fileName}>{file.name}</p>
        </Grid.Column>
        {removeFile && (
          <Grid.Column width={2} className={style.center}>
            <FontAwesomeIcon icon={['far', 'check']} color="#56A86C" />
          </Grid.Column>
        )}
        <Grid.Column floated="right" width={1} className={style.center}>
          <div
            role="button"
            tabIndex={-1}
            onClick={() => handlePreviewClick(file)}
            className={style.iconBtn}
          >
            <FontAwesomeIcon icon={['far', 'eye']} color="#D7AB73" />
          </div>
        </Grid.Column>
        {removeFile && (
          <Grid.Column width={1} className={style.center}>
            <div
              role="button"
              tabIndex={-1}
              onClick={() => {
                removeFile('file', new File([], ''));
              }}
              className={style.iconBtn}
            >
              <FontAwesomeIcon icon={['far', 'trash']} color="#D7AB73" />
            </div>
          </Grid.Column>
        )}
      </Grid.Row>
    </Grid>
  );
};

export default FileDisplayForm;
