import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { WithID, IShift, IShiftGroup, HasID, ShiftStore } from 'sol-data';
import { ScheduleStore, EmployeeStore } from '@/store';
import { Dropdown, Segment, Form, Message } from 'semantic-ui-react';
import { Formik, ErrorMessage } from 'formik';
import { object, number } from 'yup';
import { sortBy } from 'lodash';
import InjectedComponent from '@/components/InjectedComponent';
import ModalBase from '@/components/ModalBase';
import Bulletin from '@/components/Bulletin';
import style from '@/less/main.less';

interface Injected {
  scheduleStore: ScheduleStore;
  employeeStore: EmployeeStore;
  shiftStore: ShiftStore;
}

type BaseProps = {
  onDismiss(): void;
};

type Props =
  | ({ shift: WithID<IShift> } & BaseProps)
  | ({ shiftGroup: WithID<IShiftGroup> } & BaseProps);

@inject('scheduleStore', 'employeeStore', 'shiftStore')
@observer
class AssignOverrideModal extends InjectedComponent<Props, {}, Injected> {
  async assign(employee: HasID) {
    const { onDismiss, shiftStore, scheduleStore } = this.injected;

    if ('shift' in this.props) {
      const { shift } = this.props;
      await shiftStore.assignToEmployee(shift, employee, true);
    } else {
      const { shiftGroup } = this.props;
      await scheduleStore.assignShiftGroup(shiftGroup, employee, true);
    }

    onDismiss();
  }

  componentDidMount = async () => {
    const { employeeStore } = this.injected;
    if ('shift' in this.props) {
      const { shift } = this.props;
      await employeeStore.fetchAllPaginated({ positionId: shift.position?.id, page: 1 });
    } else {
      await employeeStore.fetchAll();
    }
  };

  render() {
    const { onDismiss: onCancel, scheduleStore, employeeStore } = this.injected;

    const { isLoading, errorMessage, clearError } = scheduleStore;
    const { isFetching } = employeeStore;
    return (
      <>
        <Segment>
          <Bulletin
            isLoading={isLoading || isFetching}
            errorMessage={errorMessage}
            handleDismiss={clearError}
          />
          <Message warning>
            <Message.Header>Warning</Message.Header>
            <p>
              By overriding assignments we cannot guarantee that schedule rules or payroll items
              will be enforced correctly.
            </p>
          </Message>
        </Segment>
        <Formik
          validationSchema={object().shape({
            employee: number().required('Employee is required'),
          })}
          initialValues={{
            employee: undefined,
          }}
          onSubmit={(submitted) => {
            this.assign(submitted.employee!);
          }}
          render={({ handleSubmit, setFieldValue }) => {
            const { values: employees } = employeeStore;

            let overridePosition: number | undefined;

            if ('shift' in this.props) {
              const { shift } = this.props;
              overridePosition = shift.position && shift.position.id;
            } else {
              const { shiftGroup } = this.props;
              overridePosition = shiftGroup.position.id;
            }

            const options = sortBy(employees, ({ user: { lastName } }) => lastName)
              .filter(({ position }) => position.id === overridePosition)
              .map(({ id, user }) => ({
                value: id,
                text: user.fullName,
              }));

            return (
              <ModalBase
                header="Assignment Override"
                actionWord="override"
                onCancel={onCancel}
                handleSubmit={handleSubmit}
              >
                <Form.Field>
                  <Dropdown
                    fluid
                    selection
                    search
                    placeholder="Assign To"
                    options={options}
                    onChange={(_, { value }) => setFieldValue('employee', value)}
                  />
                </Form.Field>
                <ErrorMessage
                  name="employee"
                  render={(err) => <span className={style.error_class}>{err}</span>}
                />
              </ModalBase>
            );
          }}
        />
      </>
    );
  }
}

export default AssignOverrideModal;
