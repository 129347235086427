import * as React from 'react';
import ModalBase from '@/components/ModalBase';
import { Grid, Modal, Dropdown } from 'semantic-ui-react';
import { Row, Column, Form } from '@/semantic-ui/components';
import { Formik } from 'formik';
import { RequestType, RequestsStore } from 'sol-data';
import DepartmentFilterDropdown from '@/components/DepartmentFilterDropdown';
import PositionFilterDropdown from '@/components/PositionFilterDropdown';
import { inject, observer } from 'mobx-react';
import { getQueryParams } from '@/lib/utils';
import { toNum } from '@lcdev/store';

const { Field } = Form;

interface Props {
  onSubmit: (values: Values) => void;
  onCancel: () => void;
}

interface Injected {
  requestsStore: RequestsStore;
}

export type Values = {
  type?: RequestType;
  department?: number;
  position?: number;
};

const typeOptions = [
  {
    text: 'Sick Request',
    value: RequestType.SickNotice,
  },
  {
    text: 'Time Off Request',
    value: RequestType.TimeOff,
  },
  {
    text: 'Shift Swap',
    value: RequestType.ShiftSwap,
  },
];

const RequestsFilterModal: React.FC<Props & Injected> = ({ onSubmit, onCancel, requestsStore }) => {
  const [type, department, position] = getQueryParams('type', 'department', 'position');

  const initialValues: Values = {
    type: type ? (type as RequestType) : undefined,
    department: department ? toNum(department) : undefined,
    position: position ? toNum(position) : undefined,
  };

  return (
    <Modal open size="mini" onClose={onCancel}>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ values, handleSubmit, setFieldValue }) => (
          <ModalBase
            header="Filters"
            onAccept={() => handleSubmit()}
            onCancel={onCancel}
            actionWord="Apply"
          >
            <Grid>
              <h4>Request Type</h4>
              <Row>
                <Column>
                  <Field>
                    <Dropdown
                      fluid
                      selection
                      clearable
                      name="type"
                      placeholder="Select all that apply"
                      options={typeOptions}
                      value={values.type}
                      onChange={(_, { value }) => setFieldValue('type', value)}
                    />
                  </Field>
                </Column>
              </Row>
              <h4>Departments</h4>
              <Row>
                <Column>
                  <Field>
                    <DepartmentFilterDropdown
                      name="department"
                      placeholder="Select all that apply"
                      departmentId={values.department || undefined}
                      onChange={(_, value) => setFieldValue('department', value)}
                      clearable
                    />
                  </Field>
                </Column>
              </Row>
              <h4>Positions</h4>
              <Row>
                <Column>
                  <Field>
                    <PositionFilterDropdown
                      name="position"
                      placeholder="Select all that apply"
                      positionId={values.position || undefined}
                      onChange={(_, value) => setFieldValue('position', value)}
                      clearable
                    />
                  </Field>
                </Column>
              </Row>
            </Grid>
          </ModalBase>
        )}
      />
    </Modal>
  );
};

export default inject<Injected>(({ requestsStore }) => ({ requestsStore }))(
  observer(RequestsFilterModal),
);
