import * as React from 'react';
import * as Cleave from 'cleave.js/react';
import style from './index.less';

interface Props<Name extends string> {
  name: Name;
  value?: number | string;
  id?: string;
  style?: {};
  min?: number;
  max?: number;
  step?: number;
  onChange: (name: Name, value: number) => void;
  onBlur?: React.InputHTMLAttributes<HTMLInputElement>['onBlur'];
  placeholder?: string;
}

const IncrementalButton = <Name extends string>({
  name,
  value,
  id,
  style: objStyle,
  min,
  max,
  step,
  onChange,
  onBlur,
  placeholder,
}: Props<Name>) => {
  return (
    <Cleave
      id={id}
      name={name}
      value={value}
      options={{ numeral: true }}
      type="number"
      min={min}
      max={max}
      step={step}
      className={style.cleave_inputs}
      onChange={(e) => onChange(name, parseFloat(e.target.value))}
      onBlur={onBlur}
      style={objStyle}
      placeholder={placeholder}
    />
  );
};

IncrementalButton.defaultProps = {
  value: 0,
  style: {},
  id: '',
  min: 0,
  step: 1,
  max: Infinity,
};

export default IncrementalButton;
