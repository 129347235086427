import * as React from 'react';
import { Button, ButtonProps } from '@/semantic-ui/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import style from '../index.less';

interface Props {
  onLeftPress: ButtonProps['onClick'];
  onCenterPress: ButtonProps['onClick'];
  onRightPress: ButtonProps['onClick'];
}

const ScheduleControls: React.FC<Props> = ({ onLeftPress, onCenterPress, onRightPress }) => (
  <div className={style.schedule_controls}>
    <Button onClick={onLeftPress} icon basic size="tiny" className="controls_button">
      <FontAwesomeIcon icon={['fal', 'chevron-left']} />
    </Button>
    <Button onClick={onCenterPress} icon basic size="tiny" className="controls_button">
      <FontAwesomeIcon icon={['fas', 'diamond']} />
    </Button>
    <Button onClick={onRightPress} icon basic size="tiny" className="controls_button">
      <FontAwesomeIcon icon={['fal', 'chevron-right']} />
    </Button>
  </div>
);

export default ScheduleControls;
