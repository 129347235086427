import logo from '@/images/SOL_Light.png';
import { Column, Container, Divider, Grid, Image, Row } from '@/semantic-ui/components';
import { oneLine } from 'common-tags';
import React, { useState } from 'react';
import { useLocation } from 'react-router';
import { ChangePassword } from './ChangePassword';
import ForgotPassword from './ForgotPassword';
import style from './index.less';
import { Login } from './Login';
import ResetSuccess from './ResetSuccess';

const titles: { [key in Pane]: { title: string; subtitle: string } } = {
  login: {
    title: 'Login',
    subtitle: oneLine`Access to Masterpiece Portal
      by entering your email and password below`,
  },
  reset: {
    title: 'Reset Password',
    subtitle: oneLine`Please enter your email
      address to reset your password`,
  },
  finishReset: {
    title: 'Change Password',
    subtitle: 'Enter a new password below',
  },
  success: {
    title: 'Success!',
    subtitle: oneLine`An email has been sent to the email address
      provided containing a link to reset your password`,
  },
  resetSuccess: {
    title: 'Success!',
    subtitle: 'Your password has successfully been reset',
  },
};

export enum Pane {
  Login = 'login',
  Reset = 'reset',
  FinishReset = 'finishReset',
  Success = 'success',
  ResetSuccess = 'resetSuccess',
}

interface Props {
  initialPane: Pane;
}

export const LoginForm = ({ initialPane }: Props) => {
  const [pane, setPane] = useState<Pane>(initialPane);
  const { title, subtitle } = titles[pane];
  const location = useLocation();

  const isNetworkFailure = location.pathname.includes('network-failure');
  const isUnauthorized = location.pathname.includes('unauthorized');

  return (
    <Container fluid className={style.login}>
      <Grid stretched>
        <Column computer={5} tablet={3} mobile={2} verticalAlign="bottom">
          <div className={style.login_copyright}>
            <span>&copy; Masterpiece Retirement</span>
            <a href="https://www.servalldev.com/privacy-policy/sol">Privacy</a>
          </div>
        </Column>
        <Column computer={6} tablet={10} mobile={12}>
          <Row>
            <Image centered src={logo} height={96} />
          </Row>
          <Row>
            <Divider className={style.login_divider} />
            <h1>{title}</h1>
            <Divider className={style.login_divider} />
          </Row>
          <Row>
            <h3>{subtitle}</h3>
          </Row>
          {isUnauthorized && (
            <p className={style.error_message}>
              You were redirected here because your login has expired. Login again to be taken to
              the page you were visiting.
            </p>
          )}
          {isNetworkFailure && (
            <p className={style.error_message}>
              Network Error! Something went wrong when retrieving your login - please try logging in
              again.
            </p>
          )}

          {pane === Pane.Login && <Login changePane={setPane} />}
          {pane === Pane.Reset && <ForgotPassword changePane={setPane} />}
          {pane === Pane.FinishReset && <ChangePassword changePane={setPane} />}
          {pane === Pane.Success && <ResetSuccess changePane={setPane} />}
          {pane === Pane.ResetSuccess && <ResetSuccess changePane={setPane} />}
          <Row className={style.login_support_items}>
            <p>For Support Contact:</p>
            <a href={`mailto:${process.env.MAIN_CONTACT_EMAIL}`}>
              {process.env.MAIN_CONTACT_EMAIL}
            </a>
          </Row>
        </Column>
        <Column computer={5} tablet={3} mobile={2} />
      </Grid>
    </Container>
  );
};
