import * as React from 'react';
import { sortBy } from 'lodash';
import { Grid, Column, Message, Button } from '@/semantic-ui/components';
import ModalBase from '@/components/ModalBase';
import { isMoment, HasID, RequestType, RequestStatus, Shift, SickNotice, TimeOff } from 'sol-data';
import {
  ShiftStore,
  AreaStore,
  SickNoticeStore,
  TimeOffStore,
  ScheduleStore,
  UserStore,
  EmployeeStore,
} from '@/store';
import InjectedComponent from '@/components/InjectedComponent';
import { inject, observer } from 'mobx-react';
import Bulletin from '@/components/Bulletin';
import classnames from 'classnames';
import DeleteModal from '@/components/DeleteModal';
import style from './index.less';

const { Row } = Grid;

interface Props {
  request: HasID;
  type: RequestType.SickNotice | RequestType.TimeOff;
  closeModal: () => void;
  isComplete?: boolean;
}

interface Injected {
  shiftStore: ShiftStore;
  areaStore: AreaStore;
  sickNoticeStore: SickNoticeStore;
  timeOffStore: TimeOffStore;
  scheduleStore: ScheduleStore;
  userStore: UserStore;
  employeeStore: EmployeeStore;
}

interface State {
  modalOpen: boolean;
  request?: SickNotice['WithID'] | TimeOff['WithID'];
}

@inject(
  'shiftStore',
  'areaStore',
  'sickNoticeStore',
  'timeOffStore',
  'scheduleStore',
  'userStore',
  'employeeStore',
)
@observer
class NoticeModal extends InjectedComponent<Props, {}, Injected> {
  state: State = {
    modalOpen: false,
  };

  componentDidMount() {
    this.fetchData();
  }

  componentWillUnmount() {
    this.clearErrors();
  }

  clearErrors = () => {
    const { scheduleStore, timeOffStore, sickNoticeStore } = this.injected;

    scheduleStore.clearError();
    timeOffStore.clearError();
    sickNoticeStore.clearError();
  };

  fetchData = async () => {
    const { type, request: requestId, sickNoticeStore, timeOffStore, shiftStore } = this.injected;
    const store = type === RequestType.SickNotice ? sickNoticeStore : timeOffStore;

    const request = await store.fetch(requestId);

    this.setState({ request: request.withID });

    await shiftStore.fetchShifts(
      request.type === RequestType.TimeOff ? request.get('shifts') || [] : [request.get('shift')],
    );
  };

  approveRequest = async (shifts: (Shift | undefined)[]) => {
    const { type, request, closeModal, userStore, timeOffStore, sickNoticeStore, scheduleStore } =
      this.injected;

    if (type === RequestType.TimeOff) {
      await timeOffStore.approve(request, userStore.current!);
    } else {
      await sickNoticeStore.approve(request, userStore.current!);
    }

    if (scheduleStore.current) scheduleStore.fetch(scheduleStore.current);

    closeModal();
  };

  rejectRequest = async () => {
    const { type, request, closeModal, userStore, timeOffStore, sickNoticeStore, scheduleStore } =
      this.injected;

    if (type === RequestType.TimeOff) {
      await timeOffStore.reject(request, userStore.current!);
    } else {
      await sickNoticeStore.reject(request, userStore.current!);
    }

    if (scheduleStore.current) scheduleStore.fetch(scheduleStore.current);

    closeModal();
  };

  openModal = () => {
    this.setState({ modalOpen: true });
  };

  cancelTimeoff = async (requestType: RequestType) => {
    const { timeOffStore, sickNoticeStore, request, closeModal } = this.injected;
    if (requestType === RequestType.SickNotice) {
      await sickNoticeStore.delete(request.id);
    } else if (requestType === RequestType.TimeOff) {
      await timeOffStore.cancel(request.id);
    }

    this.setState({ modalOpen: false }, () => closeModal());
  };

  render() {
    const {
      shiftStore,
      areaStore,
      scheduleStore,
      sickNoticeStore,
      timeOffStore,
      employeeStore,
      type,
      isComplete,
    } = this.injected;

    const { request, modalOpen } = this.state;

    if (!request) return null;

    const requestType = {
      [RequestType.SickNotice]: 'Sick Notice',
      [RequestType.TimeOff]: 'Time Off',
      [RequestType.ShiftSwap]: 'Shift Swap',
    }[type];

    let shifts: (Shift | undefined)[];
    let shift: Shift | undefined;

    if (request.type === RequestType.SickNotice) {
      shifts = [shiftStore.get(request.shift)];
      if (shifts[0]) shift = shiftStore.get(shifts[0]!);
    } else {
      shifts = (request.shifts || []).map((shift) => shiftStore.get(shift));
    }

    shifts = sortBy(shifts, 'startDate');

    const employee = employeeStore.get(request.employee);

    const { isLoading } = scheduleStore;

    const startDate =
      request.type === RequestType.SickNotice
        ? shift
          ? shift.$.startDate
          : 'Unknown'
        : request.startDate;

    const endDate =
      request.type === RequestType.SickNotice
        ? shift
          ? shift.$.endDate
          : 'Unknown'
        : request.endDate;

    const displayDate =
      type === RequestType.SickNotice
        ? isMoment(startDate)
          ? startDate.format('ddd MMM D')
          : 'Unknown'
        : isMoment(startDate) && isMoment(endDate)
        ? `${startDate.format('MMM D')} - ${endDate.format('MMM D')}`
        : 'Unknown';

    const errorMessage =
      scheduleStore.errorMessage || timeOffStore.errorMessage || sickNoticeStore.errorMessage;

    return (
      <ModalBase
        header={`${requestType} Details`}
        headerClass={`modal_header_${type}`}
        cancelWord="Reject"
        actionWord="Approve"
        onAccept={() => this.approveRequest(shifts)}
        onCancel={this.rejectRequest}
        noButtons={request.status !== RequestStatus.Pending}
      >
        <Bulletin
          isLoading={isLoading}
          errorMessage={errorMessage}
          handleDismiss={this.clearErrors}
          isModal
        />
        <Grid divided="vertically">
          <Row>
            <h3>
              {employee?.user.fullName} ({displayDate})
            </h3>
          </Row>

          {shifts.length === 0 ? (
            <Row columns={1}>
              <Column>
                <p>No assigned shifts found for this request</p>
              </Column>
            </Row>
          ) : (
            <Row columns={3}>
              {shifts.map((shift) => {
                if (!shift) return null;

                const { startDate } = shift.$;
                const { endDate } = shift.$;
                const area = areaStore.get(shift.$.area);

                return (
                  <Column key={shift.id}>
                    <div className={classnames(style.shift_block_item, style.shift_swap_item)}>
                      <span>{startDate ? startDate.format('ddd MMM DD') : 'Unknown'}</span>
                      <span>{`${startDate.format('HH:mm')} - ${endDate.format('HH:mm')}`}</span>
                      <div
                        className={classnames(
                          style.shift_block_item_label,
                          style.shift_block_item_label_weekday,
                        )}
                      >
                        {area?.label}
                      </div>
                    </div>
                  </Column>
                );
              })}
            </Row>
          )}

          <Row columns={1}>
            <Column>
              <h4>Message</h4>

              <Message>
                <p>{request.message || 'No Message'}</p>
              </Message>
            </Column>
          </Row>

          {request.status !== RequestStatus.Pending && (
            <>
              <Row>
                <div
                  className={classnames(style.request_banner, {
                    [style.request_banner_approved]: request.status === RequestStatus.Approved,
                    [style.request_banner_denied]: request.status === RequestStatus.Denied,
                  })}
                >
                  {request.status === RequestStatus.Approved ? 'Approved' : 'Rejected'}
                </div>
              </Row>
              {request.status === RequestStatus.Approved && !isComplete && (
                <Row>
                  <Button
                    fluid
                    onClick={() => this.setState({ modalOpen: true })}
                    type="submit"
                    color="red"
                    className={style.modal_button}
                  >
                    Delete
                  </Button>
                </Row>
              )}
            </>
          )}
          {modalOpen && (
            <DeleteModal
              onConfirm={() => this.cancelTimeoff(request.type)}
              onCancel={() => this.setState({ modalOpen: false })}
              label="Time-Off"
              description="this approved time-off request"
              open={modalOpen}
            />
          )}
        </Grid>
      </ModalBase>
    );
  }
}

export default NoticeModal;
