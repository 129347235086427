import * as React from 'react';
import { Input, Grid, Column } from '@/semantic-ui/components';
import Bulletin from '@/components/Bulletin';
import style from './index.less';

export interface Props {
  onDismiss: () => void;
  isLoading?: boolean;
  searchLabel?: string;
  errorMessage?: string;
  onSearchChange?: (search: string) => void;
}

const SearchHeader: React.FC<Props> = ({
  onSearchChange,
  searchLabel,
  isLoading,
  errorMessage,
  onDismiss,
  children,
}) => (
  <>
    {(onSearchChange || children) && (
      <Grid>
        {onSearchChange && (
          <Column width={5} className="list_layout_search_column">
            <div className={style.search_header}>
              <Input
                type="search"
                icon="search"
                iconPosition="left"
                placeholder={searchLabel}
                className={style.search_header_input}
                onChange={(_, { value }) => onSearchChange(value)}
              />
            </div>
          </Column>
        )}
        {children && (
          <>
            {Array.isArray(children) ? (
              children
            ) : (
              <Column floated="right" width={5}>
                {children}
              </Column>
            )}
          </>
        )}
      </Grid>
    )}
    <Bulletin isLoading={isLoading} errorMessage={errorMessage} handleDismiss={onDismiss} />
  </>
);

export default SearchHeader;
