import * as React from 'react';
import * as Cleave from 'cleave.js/react';
import { Input } from 'semantic-ui-react';
import InjectedComponent from '../InjectedComponent';
import style from './index.less';
import { EmployeeContainerTabProps } from '../EmployeeInformationModal/utils';

export class EmployeeEmergencyContactsContainer extends InjectedComponent<
  EmployeeContainerTabProps,
  {},
  {}
> {
  render() {
    const {
      formProps: { values: formValues, errors: formErrors, handleChange },
      readOnly,
    } = this.props;
    const values = formValues.emergencyContacts;
    const errors = formErrors.emergencyContacts ?? {};

    return (
      <div className={style.emergency_body}>
        <div className={style.grid_2_col}>
          <div className={style.grid}>
            <label className={style.form_headers}>First Name</label>
            <Input
              placeholder="First Name"
              name="emergencyContacts.firstName"
              disabled={readOnly}
              value={values?.firstName}
              onChange={handleChange}
            />
            {errors.firstName && <p className={style.error_class}>{errors.firstName}</p>}
          </div>
          <div className={style.grid}>
            <label className={style.form_headers}>Last Name</label>
            <Input
              placeholder="Last Name"
              name="emergencyContacts.lastName"
              disabled={readOnly}
              value={values?.lastName}
              onChange={handleChange}
            />
            {errors.lastName && <p className={style.error_class}>{errors.lastName}</p>}
          </div>
        </div>
        <div className={style.grid_2_col}>
          <div className={style.grid}>
            <label className={style.form_headers}>Phone number</label>
            <Cleave
              options={{
                numericOnly: true,
                blocks: [0, 3, 3, 4],
                delimiters: ['(', ') ', '-'],
              }}
              name="emergencyContacts.phone"
              value={values.phone}
              placeholder="(123) 456-7890"
              disabled={readOnly}
              className={style.inputs}
              onChange={handleChange}
            />

            {errors.phone && <p className={style.error_class}>{errors.phone}</p>}
          </div>
          <div className={style.grid}>
            <label className={style.form_headers}>Email</label>
            <Input
              name="emergencyContacts.email"
              placeholder="Email"
              type="email"
              disabled={readOnly}
              value={values.email}
              onChange={handleChange}
            />
            {errors.email && <p className={style.error_class}>{errors.email}</p>}
          </div>
        </div>
      </div>
    );
  }
}
