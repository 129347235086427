import Breadcrumbs from '@/components/Breadcrumbs';
import Bulletin from '@/components/Bulletin';
import CircleImage from '@/components/CircleImage';
import PositionFilterDropdown from '@/components/PositionFilterDropdown';
import { useAsyncEffect } from '@/hooks';
import defaultUserImage from '@/images/default-user-image.png';
import { Card, Grid, Input, Segment } from '@/semantic-ui/components';
import { useStores } from '@/store';
import { toNum } from '@lcdev/store';
import { debounce } from 'lodash';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useCallback, useState } from 'react';
import { Link, useSearchParams } from 'react-router';
import { InputProps, Pagination } from 'semantic-ui-react';
import { Employee } from 'sol-data';
import style from './index.less';

const { Row, Column } = Grid;
const DEBOUNCE_DELAY = 200;

export const Employees = observer(() => {
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [employeeProfilePictureUrls, setEmployeeProfilePictureUrls] = useState(
    new Map<number, string>(),
  );
  const [searchInput, setSearchInput] = useState<string>();
  const { employeeStore, positionStore, userStore } = useStores();
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page') ? Number(searchParams.get('page')) : 1;
  const position = searchParams.get('position') ? Number(searchParams.get('position')) : undefined;
  const employee = searchParams.get('employee') ?? undefined;

  useAsyncEffect(async () => {
    updateFilters();

    await fetchData(1, employee, position);

    return () => {
      employeeStore.clearFilters();
    };
  }, []);

  useAsyncEffect(async () => {
    const profilePictureUrls = new Map<number, string>();

    await Promise.all(
      employees.map(async ({ user }) => {
        const image = await userStore.getProfileImageUrl(user.id);
        profilePictureUrls.set(user.id, image);
      }),
    );

    setEmployeeProfilePictureUrls(profilePictureUrls);
  }, [employees]);

  const fetchData = async (pageNumber: number, searchTerm?: string, positionId?: number) => {
    if (!positionStore.values.length) {
      await positionStore.fetchAll();
    }

    if (searchTerm) {
      const fetchedEmployees = await employeeStore.fetchAllPaginated({
        page: 1,
        searchTerm,
      });

      return setEmployees(fetchedEmployees);
    }

    const fetchedEmployees = await employeeStore.fetchAllPaginated({
      page: pageNumber,
      ...(positionId && { positionId }),
    });

    setEmployees(fetchedEmployees);
  };

  const updateFilters = () => {
    if (position) {
      employeeStore.setFilters({ positionId: position });
    } else {
      employeeStore.clearFilters();
    }

    if (employee) {
      employeeStore.setFilter(employee);
      setSearchInput(employee);
    } else {
      employeeStore.clearFilter();
      setSearchInput('');
    }
  };

  const updateSearch = useCallback(
    debounce(async (value: string) => {
      setSearchParams({
        employee: value || '',
        page: '1',
        position: position?.toString() ?? '',
      });

      await fetchData(page, value, position);
    }, DEBOUNCE_DELAY),
    [page, position],
  );

  const handleSearch: InputProps['onChange'] = ({ target: { value } }) => {
    setSearchInput(value);
    updateSearch(value);
  };

  const handlePositionChange = async (value: number) => {
    employeeStore.setFilters({ positionId: value });

    if (value) {
      setSearchParams({ page: page.toString() ?? '', position: value.toString() });
    } else {
      setSearchParams({ position: '', page: page.toString() ?? '', employee: employee ?? '' });
    }

    await fetchData(page, employee, value);
  };

  const changePage = (pageNumber: number) => {
    employeeStore.setPage(pageNumber);
    setSearchParams({
      page: pageNumber.toString(),
      employee: employee ?? '',
      position: position?.toString() ?? '',
    });
    fetchData(pageNumber, employee, position);
  };

  const { isFetching, errorMessage } = employeeStore;

  return (
    <Segment basic padded="very">
      <Breadcrumbs activeBreadcrumb="Employees Central" />

      <Grid className={style.employees_grid}>
        <Row>
          <Column floated="left" computer={4} tablet={6}>
            <Input
              type="search"
              fluid
              placeholder="Search employee"
              onChange={handleSearch}
              icon="search"
              value={searchInput}
            />
          </Column>

          <Column floated="right" computer={4} tablet={6}>
            <PositionFilterDropdown
              onChange={(_, value) => handlePositionChange(value)}
              placeholder="Filter By Position"
              positionId={position ? toNum(position) : undefined}
              clearable
            />
          </Column>
        </Row>

        <Row>
          <Bulletin
            isLoading={isFetching}
            errorMessage={errorMessage}
            handleDismiss={() => employeeStore.clearError()}
            isModal
          />
        </Row>
      </Grid>
      <section className={style.main_section}>
        <Card.Group itemsPerRow={5} doubling className={style.employees_card_wrapper}>
          {!employees.length && (
            <div className={style.empty_state}>
              <h3>Employees not found</h3>
            </div>
          )}
          {employees.map((employee) => {
            const profilePictureUrl = employeeProfilePictureUrls.get(employee.user.id);

            return (
              <Card
                key={employee.id}
                as={Link}
                to={`/employees/${employee.id}/profile`}
                className={style.employees_card}
              >
                <div className={style.image_container}>
                  <CircleImage
                    src={profilePictureUrl ?? defaultUserImage}
                    alt="employee thumbnail"
                  />
                </div>

                <Card.Content className={`employee_card ${style.employees_card_content}`}>
                  <Card.Header className={style.employees_card_header}>
                    {`${employee.user.firstName} ${employee.user.lastName}`}
                  </Card.Header>

                  <span>
                    {positionStore.has(employee.position)
                      ? positionStore.get(employee.position)!.description
                      : 'Unknown'}
                  </span>
                </Card.Content>
              </Card>
            );
          })}
        </Card.Group>
        {!!employeeStore.allEmployeesCount && (
          <Pagination
            activePage={page}
            totalPages={Math.ceil(employeeStore.allEmployeesCount / 20)}
            onPageChange={(_, { activePage }) => changePage(activePage as number)}
            siblingRange={2}
            boundaryRange={0}
            firstItem={{ content: 'First' }}
            lastItem={{ content: 'Last' }}
            ellipsisItem={null}
            prevItem={null}
            nextItem={null}
          />
        )}
      </section>
    </Segment>
  );
});
