import * as React from 'react';
import InjectedComponent from '@/components/InjectedComponent';
import { inject, observer } from 'mobx-react';
import { EducationalDocumentStore } from '@/store';
import { ListColumn } from '@/components/ListLayout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Segment, Grid, Pagination } from '@/semantic-ui/components';
import Breadcrumbs from '@/components/Breadcrumbs';
import AppContext from '@/AppContext';
import { EntityList } from '@/components/EntityList';
import { paginate } from '@/lib/utils';
import style from './index.less';

const RESULTS_PER_PAGE = 10;
interface Injected {
  educationDocumentStore: EducationalDocumentStore;
}

interface State {
  currentPage: number;
}

const deleteWarning = 'Are you sure you want to delete this Educational Document?';
@inject('educationDocumentStore')
@observer
class EducationalDocuments extends InjectedComponent<{}, {}, Injected> {
  state: State = {
    currentPage: 1,
  };

  fetchData = async () => {
    const { educationDocumentStore } = this.injected;
    educationDocumentStore.clearCurrent();
    await educationDocumentStore.fetchAll();
  };

  render() {
    const { educationDocumentStore } = this.injected;
    const { isLoading, errorMessage, filtered } = educationDocumentStore;
    const { currentPage } = this.state;

    const columns: ListColumn[] = [
      {
        name: 'Title',
        key: 'title',
        width: { sm: 6, lg: 6 },
        render: ({ title }) => <span>{title}</span>,
      },
      {
        name: 'Created By',
        key: 'postedBy',
        width: { sm: 2, lg: 2 },
        textAlign: 'center',
        render: ({ postedBy }) => (
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {postedBy}
          </div>
        ),
      },
      {
        name: 'Posted On',
        key: 'postedOn',
        width: { sm: 2, lg: 2 },
        textAlign: 'center',
        render: ({ postedOn }) => (
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {postedOn}
          </div>
        ),
      },
      // TODO: Bring this column back once request is refactored and more efficient. This page takes 1.5 minutes to load.
      // {
      //   name: 'Status',
      //   key: 'status',
      //   width: { sm: 2, lg: 2 },
      //   textAlign: 'center',
      //   render: ({ employeesCount, totalCompletedEmployees }) => (
      //     <div
      //       style={{
      //         color:
      //           totalCompletedEmployees === employeesCount
      //             ? EmployeeStatusColor.Complete.color
      //             : EmployeeStatusColor.Incomplete.color,
      //         textAlign: 'center',
      //       }}
      //     >{`${totalCompletedEmployees}/${employeesCount}`}</div>
      //   ),
      // },
      {
        name: 'File Name',
        key: 'fileName',
        width: { sm: 3, lg: 3 },
        render: ({ fileName }) => (
          <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
            <FontAwesomeIcon icon={['far', 'file']} className="reveal-icon" />
            {fileName}
          </div>
        ),
      },
    ];

    const handleDel = async (id: number) => {
      await educationDocumentStore.delete(id);
    };

    const educationDocuments = filtered.map(
      ({ id, title, postedBy, postedOn, fileName, completedEmployees, employeesCount }) => ({
        id,
        title,
        postedBy,
        postedOn,
        fileName,
        employeesCount,
        totalCompletedEmployees: completedEmployees.length,
      }),
    );

    return (
      <Segment className={style.body_container} padded="very">
        <Breadcrumbs activeBreadcrumb="Education Documents" />
        <AppContext.Consumer>
          {({ isAdmin }) => (
            <>
              <EntityList
                title="Educational Documents"
                searchLabel="Search by document name"
                columns={columns}
                items={paginate(educationDocuments, RESULTS_PER_PAGE, currentPage)}
                newLink="/education/add"
                titleProp="title"
                onRemove={isAdmin ? ({ id }) => handleDel(id) : undefined}
                dialog={deleteWarning}
                onSearchChange={(filter) => educationDocumentStore.setFilter(filter)}
                onDismiss={() => educationDocumentStore.clearError()}
                onRefresh={this.fetchData}
                errorMessage={errorMessage}
                isLoading={isLoading}
                link={isAdmin ? ({ id }) => `/education/${id}` : undefined}
                noNewButton={!isAdmin}
              />
              <Grid centered>
                <Grid.Row>
                  <Pagination
                    activePage={currentPage}
                    totalPages={Math.ceil(educationDocuments.length / RESULTS_PER_PAGE)}
                    onPageChange={(_, { activePage }) => {
                      this.setState({ currentPage: activePage as number });
                    }}
                    siblingRange={2}
                    boundaryRange={0}
                    firstItem={{ content: 'First' }}
                    lastItem={{ content: 'Last' }}
                    ellipsisItem={null}
                    prevItem={null}
                    nextItem={null}
                  />
                </Grid.Row>
              </Grid>
            </>
          )}
        </AppContext.Consumer>
      </Segment>
    );
  }
}

export default EducationalDocuments;
