import TabLayout from '@/components/TabLayout';
import { TimesheetView } from '@/components/TimesheetView';
import * as React from 'react';
import { Link, useLocation } from 'react-router';
import { Menu } from 'semantic-ui-react';
import { TimesheetStatus } from 'sol-data';

const panes = [
  {
    route: 'pending',
    menuItem: (
      <Menu.Item as={Link} to="/timesheets/pending" key="pending">
        Pending
      </Menu.Item>
    ),
    render: () => <TimesheetView key="pending" status={TimesheetStatus.Pending} />,
  },
  {
    route: 'approved',
    menuItem: (
      <Menu.Item as={Link} to="/timesheets/approved" key="approved">
        Approved
      </Menu.Item>
    ),
    render: () => <TimesheetView key="approved" status={TimesheetStatus.Approved} />,
  },
];

export const Timesheets = () => {
  const { pathname } = useLocation();

  return (
    <TabLayout linkTitle="Timesheet" panes={panes} path={pathname} activeBreadcrumb="Timesheet" />
  );
};
