import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { PositionStore } from '@/store';
import { Dropdown, Button, Grid, List } from '@/semantic-ui/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IncrementalButton from '@/components/IncrementalButton';
import { FieldArray, Formik } from 'formik';
import style from './index.less';
import InjectedComponent from '../InjectedComponent';

const { Row, Column } = Grid;

interface Props {
  name: string;
  values: {
    positionId?: number;
    quantity: number;
    key: number;
  }[];
  getError: (e: number) =>
    | {
        positionId?: string;
        quantity?: string;
      }
    | undefined;
  setFieldValue: Formik['setFieldValue'];
}

interface Injected {
  positionStore: PositionStore;
}

@inject('positionStore')
@observer
class PlanningPosition extends InjectedComponent<Props, {}, Injected> {
  static defaultProps = {
    errors: undefined,
  };

  render() {
    const {
      positionStore: { dropdownOptions: positionOptions },
      getError,
      values,
      setFieldValue,
      name,
    } = this.injected;

    return (
      <FieldArray
        name="shiftPlanPositions"
        validateOnChange={false}
        render={(arrayHelpers) => (
          <>
            {values.map((position, idx) => {
              const showAdd = idx === values.length - 1;
              const showRemove = values.length > 1;
              const positionErrors = getError(idx);

              return (
                <React.Fragment key={`${position.positionId}_${idx}`}>
                  <Row>
                    <Column width={8}>
                      <Dropdown
                        fluid
                        selection
                        name={`${name}.${idx}.positionId`}
                        placeholder="Select a Position"
                        value={position.positionId}
                        options={positionOptions}
                        onChange={(_, { value }) =>
                          setFieldValue(`${name}.${idx}.positionId`, value)
                        }
                      />
                    </Column>
                    <Column width={4}>
                      <IncrementalButton
                        name={`${name}.${idx}.quantity`}
                        id="quantity"
                        value={position.quantity}
                        onChange={(_, value) => setFieldValue(`${name}.${idx}.quantity`, value)}
                      />
                    </Column>
                    <Column width={4}>
                      {showRemove && (
                        <Button
                          circular
                          type="button"
                          color="red"
                          className={style.planning_mini_button}
                          onClick={() => arrayHelpers.remove(idx)}
                          icon={<FontAwesomeIcon icon="times" />}
                        />
                      )}
                      {showAdd && (
                        <Button
                          circular
                          type="button"
                          color="blue"
                          className={style.planning_mini_button}
                          onClick={() =>
                            arrayHelpers.push({
                              quantity: 1,
                              key: values.length,
                              positionId: undefined,
                            })
                          }
                          icon={<FontAwesomeIcon icon={['far', 'plus']} />}
                        />
                      )}
                    </Column>
                  </Row>
                  {positionErrors && (
                    <Row>
                      <Column width={16}>
                        <List>
                          {Object.entries(positionErrors).map(([name, error]) => (
                            <List.Item className={style.error_class} key={name}>
                              {error}
                            </List.Item>
                          ))}
                        </List>
                      </Column>
                    </Row>
                  )}
                </React.Fragment>
              );
            })}
          </>
        )}
      />
    );
  }
}

export default PlanningPosition;
