import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { flatten } from 'lodash';
import { moment, ScheduleStore, Schedule, ScheduleStatus, DepartmentStore } from 'sol-data';
import InjectedComponent from '@/components/InjectedComponent';
import FilterDropdown from '@/components/FilterDropdown';
import AppContext from '@/AppContext';

type Props = {
  name?: string;
  placeholder?: string;
  value?: number;
  onChange: (name: string, value: number) => void;
  search?: boolean;
  clearable?: boolean;
};

interface Injected {
  scheduleStore: ScheduleStore;
  departmentStore: DepartmentStore;
}

interface State {
  schedules?: Schedule[];
}

@inject('scheduleStore', 'departmentStore')
@observer
class ScheduleFilterDropdown extends InjectedComponent<Props, State, Injected> {
  state: State = {};

  componentDidMount() {
    const { scheduleStore } = this.injected;

    Promise.all([
      scheduleStore.findSchedules({
        startDate: moment().subtract(2, 'months'),
        endDate: moment().add(2, 'months'),
        status: ScheduleStatus.Assigned,
      }),
      scheduleStore.findSchedules({
        startDate: moment().subtract(3, 'months'),
        endDate: moment().add(3, 'months'),
        status: ScheduleStatus.Published,
      }),
    ]).then((schedules) => this.setState({ schedules: flatten(schedules) }));
  }

  render() {
    const {
      scheduleStore,
      departmentStore,
      name,
      placeholder,
      value,
      onChange,
      search,
      clearable,
    } = this.injected;
    const { schedules } = this.state;

    const options = !schedules
      ? []
      : schedules.map(({ id, department, description, startDate, endDate }) => {
          const name =
            description ||
            (departmentStore.has(department) ? departmentStore.get(department)!.label : 'Unknown');

          return {
            value: id,
            text: `${name} (${startDate.format('MMM D')} - ${endDate.format('MMM D')})`,
          };
        });

    return (
      <FilterDropdown
        name={name || 'scheduleIds'}
        placeholder={placeholder || 'Select a schedule'}
        value={value}
        options={options}
        changeDropdown={onChange}
        search={search}
        clearable={clearable}
      />
    );
  }
}

export default ScheduleFilterDropdown;
