import InjectedComponent from '@/components/InjectedComponent';
import defaultUserImage from '@/images/default-user-image.png';
import style from '@/less/main.less';
import { Breadcrumb, Divider, Image } from '@/semantic-ui/components';
import { UserStore } from '@/store';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Link } from 'react-router';
import TimesheetSyncErrorNotifier from '../TimesheetSyncErrorNotifier';

interface Props {
  link: string;
  linkTitle: string;
  title?: string;
  tabbed: boolean;
  activeBreadcrumb: string;
}

interface Injected {
  userStore: UserStore;
}

interface State {
  profileImageUrl?: string;
}

@inject('userStore')
@observer
class Breadcrumbs extends InjectedComponent<Props, State, Injected> {
  static defaultProps = {
    link: undefined,
    linkTitle: undefined,
    tabbed: false,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      profileImageUrl: undefined,
    };
  }

  async componentDidMount() {
    const { userStore } = this.injected;

    if (userStore.current) {
      const profileImageUrl = await userStore.getProfileImageUrl(userStore.current.id);
      this.setState({ profileImageUrl });
    }
  }

  render() {
    const { link, linkTitle, title, tabbed, activeBreadcrumb } = this.injected;

    return (
      <>
        <div className={style.top_row_container}>
          <Breadcrumb size="huge" className={style.top_row_breadcrumb}>
            {link && (
              <>
                <Breadcrumb.Section active className={style.top_row_breadcrumb_link}>
                  <Link to={link}>{title || linkTitle}</Link>
                </Breadcrumb.Section>
                <Breadcrumb.Divider icon="right chevron" />
              </>
            )}
            <Breadcrumb.Section active color="grey">
              {activeBreadcrumb}
            </Breadcrumb.Section>
          </Breadcrumb>
          <div className={style.top_row_profile_container}>
            <TimesheetSyncErrorNotifier />
            <Image
              circular
              size="mini"
              src={this.state.profileImageUrl ?? defaultUserImage}
              floated="right"
              alt="user-thumbnail"
              style={{ cursor: 'pointer' }}
              as={Link}
              to="/user-profile/change-password"
            />
          </div>
        </div>
        {tabbed || <Divider />}
      </>
    );
  }
}

export default Breadcrumbs;
