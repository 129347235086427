import { EducationalDocumentStore, UserStore } from '@/store';
import { inject, observer } from 'mobx-react';
import InjectedComponent from '@/components/InjectedComponent';
import * as React from 'react';
import { Segment, Form, Grid, Row, Input, Column, TextArea } from '@/semantic-ui/components';
import { EmployeeActiveStatus, ID, IEducationDocument, moment } from 'sol-data';
import { Formik } from 'formik';
import { array, mixed, number, object, string } from 'yup';
import { differenceWith, isEqual } from 'lodash';
import { downloadBlob } from '@/lib/utils';
import Breadcrumbs from '../Breadcrumbs';
import style from './index.less';
import Bulletin from '../Bulletin';
import ButtonBar from '../ButtonBar';
import FileDisplayForm from '../FileDisplayForm';
import DragDrop from '../DragDrop';
import CreateEducationalDocumentModal from '../CreateEducationalDocumentModal';
import DistributionGroup, { CompletedEmployee } from '../DistributionGroup';
import { withNavigation, WithNavigationProps } from '../Route';

const { Field } = Form;

export const MAX_FILE_SIZE = 15000000;

interface State {
  shouldOpenFinishModal: boolean;
  shouldOpenPreviewModal: boolean;
}

interface Injected {
  educationDocumentStore: EducationalDocumentStore;
  userStore: UserStore;
}

interface Props extends WithNavigationProps {
  documentId: string;
}

@inject('educationDocumentStore', 'userStore')
@observer
class CreateEducationalDocument extends InjectedComponent<Props, State, Injected> {
  state: State = {
    shouldOpenFinishModal: false,
    shouldOpenPreviewModal: false,
  };

  async componentDidMount() {
    const { educationDocumentStore } = this.injected;
    const { documentId } = this.props.params;

    educationDocumentStore.clearCurrent();

    if (documentId !== 'add') {
      await this.fetchData(documentId!);
    }
  }

  async fetchData(id: ID) {
    const { educationDocumentStore } = this.injected;
    await educationDocumentStore.fetch(id);
  }

  render() {
    const { educationDocumentStore, userStore } = this.injected;
    const { documentId } = this.props.params;
    const { errorMessage, isLoading, current: educationDocument } = educationDocumentStore;
    const { shouldOpenFinishModal } = this.state;
    let initialValues: IEducationDocument & { file: File } = {
      title: '',
      description: '',
      azureBlobName: '',
      departments: [],
      fileName: '',
      file: new File([], ''),
    };

    const { fullName } = userStore.current!;
    let createdBy = `${fullName} - ${moment().format('YYYY-MM-DD')}`;

    let completedEmployees: CompletedEmployee[] = [];

    if (educationDocument) {
      initialValues = {
        ...initialValues,
        title: educationDocument.title,
        description: educationDocument.description,
        azureBlobName: educationDocument.azureBlobName,
        departments: educationDocument.departments.map(({ id }) => ({
          id,
        })),
        fileName: educationDocument.fileName,
        file: educationDocument.file ?? new File([], ''),
      };
      completedEmployees = educationDocument.completedEmployees.map(
        ({ signedOn, employee: { id } }) => ({
          employeeId: id,
          completionDate: signedOn ?? '--',
        }),
      );
      createdBy = `${educationDocument.postedBy} - ${educationDocument.postedOn}`;
    }

    const handlePreviewClick = async (file: File) => {
      const pdfFile = file;
      downloadBlob(pdfFile, pdfFile.name);
      this.setState({
        shouldOpenPreviewModal: true,
      });
    };

    const closePreview = () => {
      this.setState({
        shouldOpenPreviewModal: false,
      });
    };

    const closeModal = () => {
      this.setState({
        shouldOpenFinishModal: false,
      });
    };

    return (
      <>
        <Segment basic padded="very">
          <Breadcrumbs
            linkTitle="Educational Documents"
            activeBreadcrumb={educationDocument?.title ?? 'New Document'}
            link={'/education'}
            title="Educational Documents"
          />
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={object().shape({
              title: string().required('Title is required'),
              description: string().required('Description is required'),
              file: mixed()
                .test(
                  'fileSize',
                  'A file is required before submitting',
                  (file: File) => file.size > 0,
                )
                .test(
                  'fileSize',
                  'File size must be less than 15mb',
                  (file: File) => file.size <= MAX_FILE_SIZE,
                )
                .test(
                  'fileType',
                  'Unsupported file format. Please select jpg, png, or pdf.',
                  (file?: File) => (file ? ['application/pdf'].includes(file.type) : true),
                )
                .required(),
              fileName: string().required('File name is required').label('File Name'),
              departments: array()
                .of(
                  object().shape({
                    id: number().min(1).required('Department must be selected'),
                  }),
                )
                .min(1, 'You must select at least one department'),
            })}
            onSubmit={async (
              { file, title, description, fileName, departments },
              { setSubmitting },
            ) => {
              if (documentId !== 'add') {
                if (departments.length < initialValues.departments.length) {
                  educationDocumentStore.setError('You cannot remove departments');
                } else {
                  const modifiedDepartments = differenceWith(
                    departments,
                    initialValues.departments,
                    isEqual,
                  );
                  await educationDocumentStore.update({
                    id: documentId,
                    departments: modifiedDepartments,
                    description,
                    title,
                  });
                }
              } else {
                const readyFormData = new FormData();
                readyFormData.append('file', file);
                const upload = await educationDocumentStore.uploadEducationDocumentPDF(
                  readyFormData,
                );
                await educationDocumentStore.create({
                  title,
                  description,
                  azureBlobName: upload.azureBlobName,
                  fileName,
                  departments,
                });
              }
              setSubmitting(false);
              if (educationDocumentStore.errorMessage === undefined) {
                this.setState({
                  shouldOpenFinishModal: true,
                });
              }
            }}
            render={({
              handleSubmit,
              values,
              isSubmitting,
              handleChange,
              setFieldValue,
              touched,
              errors,
            }) => {
              const showTitleError = !!touched.title && !!errors.title;
              const showDescriptionError = !!touched.description && !!errors.description;
              const showDepartmentsError = !!touched.departments && !!errors.departments;
              const showFileError = !!touched.file && !!errors.file;
              const removeFile = documentId === 'add' ? setFieldValue : undefined;
              const totalEmployees = educationDocument?.departments.reduce(
                (current, { employees }) => (employees?.length ?? 0) + current,
                0,
              );

              return (
                <Grid>
                  <div
                    style={{
                      color:
                        educationDocument?.completedEmployees.length === totalEmployees
                          ? 'green'
                          : 'red',
                    }}
                  >
                    {totalEmployees &&
                      `${
                        educationDocument?.completedEmployees.length || 0
                      }/${totalEmployees} signed`}
                  </div>
                  <Row>
                    <Bulletin
                      errorMessage={errorMessage}
                      handleDismiss={() => educationDocumentStore.clearError()}
                      isLoading={isLoading}
                    />
                  </Row>
                  <Row>
                    <Column width={8}>
                      <Field>
                        <p className={style.form_headers}>Title</p>
                        <Input
                          name="title"
                          value={values.title}
                          placeholder="Title"
                          onChange={handleChange}
                          fluid
                        />
                        {showTitleError && <p className="error_class">{errors.title}</p>}
                      </Field>
                    </Column>
                    <Column width={8}>
                      <Field>
                        <p className={style.form_headers}>Created By</p>
                        <Input value={createdBy} placeholder={createdBy} readOnly fluid />
                      </Field>
                    </Column>
                  </Row>
                  <Row>
                    <Column width={8}>
                      <Field>
                        <p className={style.form_headers}>Description</p>
                        <Form>
                          <TextArea
                            name="description"
                            value={values.description}
                            placeholder="Details of document"
                            rows={15}
                            onChange={handleChange}
                            autoHeight
                            className={style.input}
                          />
                        </Form>
                        {showDescriptionError && (
                          <p className="error_class">{errors.description}</p>
                        )}
                      </Field>
                    </Column>
                    <Column width={8}>
                      <Field>
                        <p className={style.form_headers}>Upload Document</p>
                        {values.file.size > 0 ? (
                          <FileDisplayForm
                            removeFile={removeFile}
                            handlePreviewClick={handlePreviewClick}
                            file={values.file}
                          />
                        ) : (
                          <DragDrop
                            text="Drag and drop files"
                            dragText="Drop your PDF document here!"
                            onFileDrop={(files: File[]) => {
                              if (files?.[0]) {
                                setFieldValue('fileName', files[0].name);
                                setFieldValue('file', files[0]);
                              }
                            }}
                          />
                        )}
                        {showFileError && <p className="error_class">{errors.file}</p>}
                      </Field>
                    </Column>
                  </Row>
                  <Row>
                    <Column width={16}>
                      <p className={style.form_headers}>Distribution</p>
                      <p>Select departments/groups below to receive this document</p>
                      <DistributionGroup
                        handleDepartmentClick={setFieldValue}
                        selectedDepartments={values.departments}
                        completedEmployees={completedEmployees}
                      />
                      {showDepartmentsError && <p className="error_class">{errors.departments}</p>}
                    </Column>
                  </Row>
                  <Row>
                    <Column width={16}>
                      <ButtonBar
                        onSave={handleSubmit}
                        link="/education"
                        disabled={isSubmitting}
                        saveText={'Publish'}
                      />
                    </Column>
                  </Row>
                </Grid>
              );
            }}
          />
        </Segment>
        <CreateEducationalDocumentModal open={shouldOpenFinishModal} handleClose={closeModal} />
      </>
    );
  }
}

export default withNavigation<{}>(CreateEducationalDocument);
