import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Grid, Radio, Modal } from '@/semantic-ui/components';
import { Formik } from 'formik';
import { number, object, array, string, boolean } from 'yup';
import { IShiftPlan, Time } from 'sol-data';
import { ShiftPlanStore } from '@/store';
import InjectedComponent from '@/components/InjectedComponent';
import TimePicker from '@/components/TimePicker';
import DayPicker from '@/components/DayPicker';
import PlanningPosition from '@/components/PlanningPosition';
import Bulletin from '@/components/Bulletin';
import ModalBase from '@/components/ModalBase';
import style from '@/containers/ShiftPlanning/index.less';
import { toNum } from '@lcdev/store';

const { Row, Column } = Grid;

interface Props {
  open: boolean;
  onCancel: () => void;
  departmentId: number;
  areaId: number;
  label: string;
  planId?: number;
}

interface Injected {
  shiftPlanStore: ShiftPlanStore;
}

interface InitialValues {
  departmentId: number;
  areaId: number;
  days: number[];
  shiftPlanPositions: { quantity: number; key: number; positionId?: number }[];
  startTime: string;
  endTime: string;
  isWholeDay: boolean;
}

@inject('shiftPlanStore')
@observer
class ShiftPlanModal extends InjectedComponent<Props, {}, Injected> {
  static defaultProps = {
    open: false,
  };

  render() {
    const { open, onCancel, departmentId, areaId, shiftPlanStore, label, planId } = this.injected;

    const { isLoading, errorMessage } = shiftPlanStore;

    let initialValues: InitialValues = {
      departmentId,
      areaId,
      days: [],
      shiftPlanPositions: [{ quantity: 1, key: 0, positionId: undefined }],
      startTime: '',
      endTime: '',
      isWholeDay: false,
    };

    if (planId) {
      const plan = shiftPlanStore.forceGet(planId);

      initialValues = {
        areaId,
        departmentId: plan.department.id,
        days: plan.days,
        shiftPlanPositions: plan.shiftPlanPositions.map(({ quantity, position }, i) => ({
          quantity,
          positionId: toNum(position),
          key: i,
        })),
        startTime: plan.startTime.toString(),
        endTime: plan.endTime.toString(),
        isWholeDay: plan.isWholeDay,
      };
    }

    return (
      <Modal open={open} className="generate_schedule" size="small" onClose={onCancel}>
        <Bulletin
          isLoading={isLoading}
          errorMessage={errorMessage}
          handleDismiss={shiftPlanStore.clearError}
        />
        <Formik
          initialValues={initialValues}
          validateOnChange={false}
          validationSchema={object().shape({
            // The following two properties are automatically set
            // Validation is just a sanity check
            departmentId: number().required(),
            areaId: number().required(),
            days: array().min(1, 'Please select at least one day').of(number()),
            shiftPlanPositions: array().of(
              object().shape({
                positionId: number()
                  .typeError('Please select a position')
                  .required('Please select a position'),
                quantity: number()
                  .typeError('Please enter a number')
                  .min(1, 'Please enter a quantity of at least 1')
                  .required('Please enter a quantity'),
              }),
            ),
            isWholeDay: boolean(),
            startTime: string().when('isWholeDay', {
              is: false,
              then: string().required('Select start time'),
              otherwise: string(),
            }),
            endTime: string().when('isWholeDay', {
              is: false,
              then: string().required('Select end time'),
              otherwise: string(),
            }),
          })}
          onSubmit={async (values) => {
            const {
              startTime,
              endTime,
              days,
              departmentId,
              areaId,
              shiftPlanPositions,
              isWholeDay,
            } = values;

            const submittedValues: IShiftPlan = {
              days,
              isWholeDay,
              shiftPlanPositions: shiftPlanPositions.map((pos) => ({
                position: { id: pos.positionId! },
                quantity: pos.quantity,
              })),
              department: { id: departmentId },
              startTime: isWholeDay ? new Time(0, 0) : Time.parse(startTime),
              endTime: isWholeDay ? new Time(23, 59) : Time.parse(endTime),
              area: { id: areaId },
            };

            if (planId) {
              await shiftPlanStore.update(planId, submittedValues);
            } else {
              await shiftPlanStore.create(submittedValues);
            }

            if (!shiftPlanStore.error) {
              onCancel();
            }
          }}
          render={({ values, errors, setFieldValue, handleSubmit }) => {
            const getPositionErrorObj = (positionIdx: number) => {
              if (
                errors.shiftPlanPositions &&
                errors.shiftPlanPositions.length > 0 &&
                errors.shiftPlanPositions[positionIdx]
              ) {
                return errors.shiftPlanPositions[positionIdx];
              }

              return undefined;
            };

            return (
              <ModalBase
                header={`Add Operating Hours - ${label}`}
                actionWord={planId ? 'Update' : 'Add'}
                onAccept={() => handleSubmit()}
                onCancel={onCancel}
                sideButtons
              >
                <Grid stackable verticalAlign="middle">
                  <Row>
                    <p
                      className={`${style.form_headers} ${style.planning_modal_label}`}
                      style={{ margin: '8px 0 8px 8px' }}
                    >
                      Hours
                    </p>
                    <Column width={4}>
                      <TimePicker
                        name="startTime"
                        placeholder="From (24 hour)"
                        value={values.startTime}
                        onChange={(name, value) => setFieldValue(name, value)}
                        showError={!!errors.startTime}
                        error={errors.startTime}
                        disabled={values.isWholeDay}
                      />
                    </Column>
                    <Column width={4}>
                      <TimePicker
                        name="endTime"
                        placeholder="To (24 hour)"
                        value={values.endTime}
                        onChange={(name, value) => setFieldValue(name, value)}
                        showError={!!errors.endTime}
                        error={errors.endTime}
                        disabled={values.isWholeDay}
                      />
                    </Column>
                    <Column width={4}>
                      <Radio
                        toggle
                        className={style.radio}
                        checked={values.isWholeDay}
                        onChange={() => setFieldValue('isWholeDay', !values.isWholeDay)}
                        label="24 hours"
                      />
                    </Column>
                  </Row>
                  <Row>
                    <Column width={16}>
                      <p className={`${style.form_headers} ${style.planning_modal_label}`}>Days</p>
                      <DayPicker
                        length={1}
                        height={49}
                        values={values.days}
                        onChange={(days) => setFieldValue('days', days)}
                        error={errors.days as unknown as string}
                        isModal
                      />
                    </Column>
                  </Row>
                  <p className={style.form_headers}>Positions</p>
                  <PlanningPosition
                    name="shiftPlanPositions"
                    values={values.shiftPlanPositions}
                    getError={getPositionErrorObj}
                    setFieldValue={setFieldValue}
                  />
                </Grid>
              </ModalBase>
            );
          }}
        />
      </Modal>
    );
  }
}

export default ShiftPlanModal;
