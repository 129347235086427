import ScrollableContent from '@/components/ScrollableContent';
import { useAsyncEffect } from '@/hooks';
import { fetchMissingEmployees } from '@/lib/utils';
import { Button, Dropdown, Form, Input, Segment } from '@/semantic-ui/components';
import {
  AreaStore,
  DepartmentStore,
  EmployeeStore,
  PositionStore,
  ScheduleComponentStore,
  ScheduleStore,
  useStores,
} from '@/store';
import classnames from 'classnames';
import { merge } from 'lodash';
import { observer } from 'mobx-react';
import * as React from 'react';
import { UIEventHandler, useCallback, useRef } from 'react';
import { Link, useLocation } from 'react-router';
import { InputProps } from 'semantic-ui-react';
import { ScheduleStatus } from 'sol-data';
import AreaLegend from './AreaLegend';
import AreaSchedule from './AreaSchedule';
import ConflictButton from './ConflictButton';
import style from './index.less';
import PositionLegend from './PositionLegend';
import PositionSchedule from './PositionSchedule';
import ScheduleContext, { defaultConfig } from './ScheduleContext';
import { ConflictType, weekOptions } from './utils';

interface Props {
  config: {};
  withFilterRow?: boolean;
}

interface Injected {
  scheduleStore: ScheduleStore;
  scheduleComponentStore: ScheduleComponentStore;
  areaStore: AreaStore;
  positionStore: PositionStore;
  employeeStore: EmployeeStore;
  departmentStore: DepartmentStore;
}

export const Header = observer(() => {
  const { scheduleStore, scheduleComponentStore } = useStores();
  const { numberOfWeeks, status } = scheduleStore.forceGetCurrent();
  const { isAreaView, weekDisplayCount } = scheduleComponentStore;
  const isPublished = scheduleStore.current?.status !== ScheduleStatus.Pending;
  const scheduleId = scheduleStore.current?.id;

  return (
    <div className={style.schedule_control_header}>
      <div className={classnames(style.controls_spacer, style.schedule_control_header_text)}>
        {numberOfWeeks} week{status.toLowerCase()} schedule
      </div>
      <div className={style.schedule_control_switcher}>
        <Button
          basic
          toggle
          color="black"
          attached="left"
          active={!isAreaView}
          as={Link}
          to={
            isPublished
              ? `/scheduling/published/${scheduleId}/position`
              : `/scheduling/unpublished/${scheduleId}/position`
          }
        >
          Position View
        </Button>
        <Button
          basic
          toggle
          color="black"
          attached="right"
          active={isAreaView}
          as={Link}
          to={
            isPublished
              ? `/scheduling/published/${scheduleId}/area`
              : `/scheduling/unpublished/${scheduleId}/area`
          }
        >
          Area View
        </Button>
      </div>
      <div className={style.schedule_dropdown_container}>
        <Form>
          <Form.Field>
            <Dropdown
              selection
              placeholder="Number of weeks"
              name="weekDisplayCount"
              options={weekOptions}
              onChange={(_, { value }) =>
                scheduleComponentStore.setWeekDisplayCount(value as number)
              }
              value={weekDisplayCount}
              className={style.schedule_dropdown}
            />
          </Form.Field>
        </Form>
      </div>
    </div>
  );
});

interface EmployeeSearchProps {
  onChange: InputProps['onChange'];
}

const EmployeeSearch = ({ onChange }: EmployeeSearchProps) => (
  <ScheduleContext.Consumer>
    {({ hasEmployeeSearch }) =>
      hasEmployeeSearch && (
        <div className={style.controls_spacer}>
          <Input
            type="search"
            icon="search"
            iconPosition="left"
            placeholder="Search for an employee"
            className={style.schedule_search_input}
            onChange={onChange}
          />
        </div>
      )
    }
  </ScheduleContext.Consumer>
);

const ConflictFilters = () => (
  <div className={style.schedule_conflict_filters}>
    <ConflictButton type={ConflictType.SICK_NOTICE} title="Sick" />
    <ConflictButton type={ConflictType.SHIFT_SWAP} title="Swaps" />
    <ConflictButton type={ConflictType.TIME_OFF} title="Time Off" />
  </div>
);

export const DropdownFilter = observer(() => {
  const { scheduleStore, scheduleComponentStore, areaStore, positionStore } = useStores();
  const { current } = scheduleStore;
  if (!current) throw new Error('Schedule not found');

  const { isAreaView } = scheduleComponentStore;

  let dropdown;
  if (isAreaView) {
    const { currentID, dropdownOptions } = areaStore;

    let areaOptions = dropdownOptions.slice();

    if (current.areasArray) {
      areaOptions = areaOptions.filter(({ value }) => current.areasArray!.includes(value));
    }

    areaOptions.unshift({
      text: 'All Areas',
      value: 0,
      key: 0,
    });

    dropdown = (
      <Dropdown
        selection
        name="areaId"
        placeholder="Select an area"
        value={currentID || 0}
        options={areaOptions}
        onChange={(_, { value }) => areaStore.setCurrent(value as number)}
        className={style.schedule_dropdown_filter}
      />
    );
  } else {
    const { dropdownOptions, currentID } = positionStore;

    // copy to avoid mutating
    let positionOptions = dropdownOptions.slice();

    if (current.positionsArray) {
      positionOptions = positionOptions.filter(({ value }) =>
        current.positionsArray!.includes(value),
      );
    }

    positionOptions.unshift({
      text: 'All Positions',
      value: 0,
      key: 0,
    });

    dropdown = (
      <Dropdown
        selection
        name="positionId"
        placeholder="Select a position"
        value={currentID || 0}
        options={positionOptions}
        onChange={(_, { value }) => positionStore.setCurrent(value as number)}
        className={style.schedule_dropdown_filter}
      />
    );
  }

  return (
    <div className={style.schedule_dropdown_container}>
      <Form>
        <Form.Field>{dropdown}</Form.Field>
      </Form>
    </div>
  );
});

export const Schedule = observer((props: Props) => {
  const {
    departmentStore,
    areaStore,
    positionStore,
    employeeStore,
    scheduleStore,
    scheduleComponentStore,
  } = useStores();
  const scrollToRef = useRef<Function>();
  const scrollAreaRef = useRef<Element>();
  const location = useLocation();

  const fetchData = async () => {
    await Promise.all([
      areaStore.fetchAll(),
      positionStore.fetchAll(),
      departmentStore.fetchAll().then(() => {
        departmentStore.setCurrent(scheduleStore.current && scheduleStore.current.department);
      }),
    ]);

    const shiftGroups = scheduleStore.current && scheduleStore.current.shiftGroups;
    if (shiftGroups) fetchMissingEmployees(employeeStore, shiftGroups);
  };

  const onScrollVertical = useCallback(() => {
    if (!scrollAreaRef.current) return;

    const showStickyHeader = scrollAreaRef.current.getBoundingClientRect().top < 0;
    scheduleComponentStore.setShowStickyHeader(showStickyHeader);
  }, [scheduleComponentStore]);

  useAsyncEffect(async () => {
    const { setIsAreaView } = scheduleComponentStore;

    if (location.pathname.includes('area')) {
      setIsAreaView(true);
    } else {
      setIsAreaView(false);
    }

    await fetchData();

    onScrollVertical();
    window.addEventListener('scroll', onScrollVertical);

    return () => {
      areaStore.clearCurrent();
      positionStore.clearCurrent();
      scheduleStore.clearCurrent();
      employeeStore.clearFilter();
      window.removeEventListener('scroll', onScrollVertical);
    };
  }, [fetchData, onScrollVertical, areaStore, positionStore, scheduleStore, employeeStore]);

  const setEmployeeFilter: InputProps['onChange'] = ({ currentTarget: { value } }) => {
    return employeeStore.setFilter(value);
  };

  const onScrollHorizontal: UIEventHandler = ({ target }) => {
    scheduleComponentStore.setScrollLeft((target as HTMLElement).scrollLeft);
  };

  const scrollToWeek = (increment: number) => () => {
    const {
      cellDimens: { width },
    } = scheduleComponentStore;

    if (!scrollAreaRef.current || !scrollToRef.current) return;

    const weekWidth = Math.floor(width * 7);
    const scrollLeft = Math.floor(scrollAreaRef.current.scrollLeft);
    const remainder = scrollLeft % weekWidth;
    const moveBy = weekWidth * increment;

    scrollToRef.current(scrollLeft + moveBy - remainder);
  };

  const { currentID: currentAreaId } = areaStore;
  const { currentID: currentPositionId } = positionStore;
  const { filter: employeeFilter } = employeeStore;
  const { isAreaView } = scheduleComponentStore;
  const mergedConfig = merge({}, defaultConfig, props.config);

  const controlProps = {
    onLeftPress: scrollToWeek(-1),
    onCenterPress: () => scrollToRef.current?.(0),
    onRightPress: scrollToWeek(1),
  };

  return (
    <ScheduleContext.Provider value={mergedConfig}>
      <Header />

      {props.withFilterRow && (
        <div className={style.schedule_control_header}>
          <EmployeeSearch onChange={setEmployeeFilter} />
          <ConflictFilters />
          <DropdownFilter />
        </div>
      )}

      <Segment className={style.schedule_segment}>
        {isAreaView ? (
          <AreaLegend
            areaId={currentAreaId}
            employeeFilter={employeeFilter}
            controlProps={controlProps}
          />
        ) : (
          <PositionLegend
            positionId={currentPositionId}
            employeeFilter={employeeFilter}
            controlProps={controlProps}
          />
        )}

        <ScrollableContent
          horizontal
          onScroll={onScrollHorizontal}
          onSetWidth={scheduleComponentStore.setWidth}
          onSetScrollTo={(scrollTo) => {
            scrollToRef.current = scrollTo;
          }}
          onSetScrollArea={(scrollArea) => {
            scrollAreaRef.current = scrollArea;
          }}
        >
          <div className={style.schedule_container}>
            {isAreaView ? (
              <AreaSchedule areaId={currentAreaId} employeeFilter={employeeFilter} />
            ) : (
              <PositionSchedule positionId={currentPositionId} employeeFilter={employeeFilter} />
            )}
          </div>
        </ScrollableContent>
      </Segment>
    </ScheduleContext.Provider>
  );
});
