import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Form, Dropdown } from '@/semantic-ui/components';
import { IPayrollCategory } from 'sol-data';
import { PayrollStore } from '@/store';
import InjectedComponent from '../InjectedComponent';

const { Field } = Form;

interface Props {
  onChange: (value: number) => void;
  values: number[];
  categoryType?: keyof IPayrollCategory;
  errors?: boolean;
}

interface Injected {
  payrollStore: PayrollStore;
}

@inject('payrollStore')
@observer
class PayrollSelect extends InjectedComponent<Props, {}, Injected> {
  static defaultProps: Partial<Props> = {
    errors: false,
  };

  render() {
    const {
      payrollStore: {
        categories: { values: payrollCategories },
      },
      onChange,
      values,
      categoryType,
      errors,
    } = this.injected;

    const options = payrollCategories.map((cat) => ({
      key: cat.description,
      text: cat.description,
      value: cat.id,
      disabled: !!categoryType && !cat[categoryType],
    }));

    return (
      <Field error={errors}>
        <Dropdown
          multiple
          selection
          fluid
          placeholder="Type"
          options={options}
          value={values}
          onChange={(e, { value }) => onChange(value as number)}
        />
      </Field>
    );
  }
}

export default PayrollSelect;
