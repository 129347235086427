import * as React from 'react';
import { toJS, computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Moment, Shift, Time } from 'sol-data';
import { ShiftStore, EmployeeStore, AreaStore, PositionStore } from '@/store';
import { ObservingListLayout, ListColumn } from '@/components/ListLayout';
import { Grid, Column, Row, Form, Modal, Icon, Segment } from '@/semantic-ui/components';
import InjectedComponent from '@/components/InjectedComponent';
import ModalBase from '@/components/ModalBase';
import TimePickers from '@/components/TimePickers';
import DepartmentFilterDropdown from '@/components/DepartmentFilterDropdown';
import PositionFilterDropdown from '@/components/PositionFilterDropdown';
import AssignEmployee from '@/components/AssignEmployee';
import FiltersHeader from '@/components/FiltersHeader';
import AssignOverrideModal from '@/components/AssignOverrideModal';
import style from '@/less/main.less';
import NoContentMessage from '@/components/Schedule/NoContentMessage';
import DeleteModal from '@/components/DeleteModal';

interface State {
  startFocused: boolean;
  endFocused: boolean;
  shiftId?: number;
  modalFilters: {
    startTime?: string;
    endTime?: string;
    selectedDepartments: number[];
    selectedPositions: number[];
  };
  willOverride?: boolean;
  modalProperties: {
    modalOpen: boolean;
    type?: ModalTypes;
    shift?: Shift['WithID'];
  };
}

interface Injected {
  shiftStore: ShiftStore;
  employeeStore: EmployeeStore;
  areaStore: AreaStore;
  positionStore: PositionStore;
}

enum ModalTypes {
  Assignment = 'assignment',
  Filters = 'filters',
  Delete = 'delete',
}

const initialModalProps = {
  modalOpen: false,
};

@inject('shiftStore', 'employeeStore', 'areaStore', 'positionStore')
@observer
class OpenShifts extends InjectedComponent<{}, State, Injected> {
  constructor(props: {}) {
    super(props);

    this.state = {
      startFocused: false,
      endFocused: false,
      modalFilters: {
        selectedDepartments: [],
        selectedPositions: [],
      },
      modalProperties: initialModalProps,
    };
  }

  async componentDidMount() {
    const { shiftStore, employeeStore } = this.injected;
    await Promise.all([employeeStore.fetchAll(), shiftStore.fetchOpenShifts()]);
  }

  componentWillUnmount() {
    const { shiftStore } = this.injected;
    shiftStore.clearFilters();
  }

  handleStartChange = (date: Moment | null) => {
    const { shiftStore } = this.injected;
    const checkedDate = date || undefined;

    shiftStore.setFilters({ startDate: checkedDate });
  };

  handleEndChange = (date: Moment | null) => {
    const { shiftStore } = this.injected;
    const checkedDate = date || undefined;

    shiftStore.setFilters({ endDate: checkedDate });
  };

  applyFilters = () => {
    const { shiftStore } = this.injected;
    const {
      modalFilters: { startTime, endTime, selectedDepartments, selectedPositions },
    } = this.state;

    shiftStore.setFilters({
      startTime: startTime ? Time.parse(startTime) : undefined,
      endTime: endTime ? Time.parse(endTime) : undefined,
      positions: selectedPositions,
      departments: selectedDepartments,
    });

    this.closeModal();
  };

  toggleStartFocus = () => this.setState((state) => ({ startFocused: !state.startFocused }));

  toggleEndFocus = () => this.setState((state) => ({ endFocused: !state.endFocused }));

  toggleOverride = () => this.setState((state) => ({ willOverride: !state.willOverride }));

  changeFilter = (name: string, value: string | number) =>
    this.setState((state) => ({ modalFilters: { ...state.modalFilters, [name]: value } } as any));

  closeModal = () => this.setState({ modalProperties: initialModalProps });

  @computed get columns(): ListColumn<Shift['WithID']>[] {
    const { areaStore, positionStore } = this.injected;

    return [
      {
        name: 'Date',
        key: 'startDate',
        width: 4,
        render: ({ startDate }) => <div>{startDate.format('MMM D YYYY')}</div>,
      },
      {
        name: 'Time',
        key: 'time',
        width: 4,
        render: ({ startDate, endDate }) => (
          <div>{`${startDate.format('HH:mm')} - ${endDate.format('HH:mm')}`}</div>
        ),
      },
      {
        name: 'Area',
        key: 'area',
        width: 4,
        render: ({ area }) => (
          <div>{area && areaStore.has(area) ? areaStore.get(area)!.description : 'Unknown'}</div>
        ),
      },
      {
        name: 'Position',
        key: 'position',
        render: ({ position }) => (
          <div>
            {position && positionStore.has(position)
              ? positionStore.get(position)!.description
              : 'Unknown'}
          </div>
        ),
      },
    ];
  }

  render() {
    const {
      startFocused,
      endFocused,
      modalFilters: { startTime, endTime, selectedDepartments, selectedPositions },
      willOverride,
      modalProperties: { type, shift, modalOpen },
    } = this.state;

    const { shiftStore } = this.injected;
    const { filters, openShifts } = shiftStore;

    let selectedShift: Shift['WithID'] | undefined;

    if (shift) {
      const foundShift = shiftStore.get(shift);
      selectedShift = foundShift?.withID;
    }

    return (
      <>
        <FiltersHeader
          filters={filters}
          handleStartChange={this.handleStartChange}
          toggleStartFocus={this.toggleStartFocus}
          startFocused={startFocused}
          handleEndChange={this.handleEndChange}
          toggleEndFocus={this.toggleEndFocus}
          endFocused={endFocused}
          toggleFiltersModal={() =>
            this.setState({
              modalProperties: {
                modalOpen: true,
                type: ModalTypes.Filters,
              },
            })
          }
        />
        <ObservingListLayout
          items={openShifts.filtered}
          columns={this.columns}
          onClick={(shift) =>
            this.setState({
              modalProperties: {
                shift,
                modalOpen: true,
                type: ModalTypes.Assignment,
              },
            })
          }
          keyProperty="id"
          onRemove={(shift) =>
            this.setState({
              modalProperties: {
                shift,
                modalOpen: true,
                type: ModalTypes.Delete,
              },
            })
          }
        />
        {!openShifts.filtered.length && <NoContentMessage />}
        <Modal
          open={modalOpen && type !== ModalTypes.Delete}
          size={type && type !== ModalTypes.Assignment ? 'mini' : 'tiny'}
          onClose={this.closeModal}
        >
          {type === ModalTypes.Filters && (
            <ModalBase
              header="Filters"
              onAccept={this.applyFilters}
              onCancel={this.closeModal}
              actionWord="Apply"
            >
              <TimePickers
                startTime={startTime}
                endTime={endTime}
                onChange={this.changeFilter}
                showStartError={false}
                showEndError={false}
              />
              <Grid>
                <Row>
                  <Column>
                    <Form.Field>
                      <p>Departments</p>
                      <DepartmentFilterDropdown
                        name="selectedDepartments"
                        placeholder="Select all that apply"
                        onChange={this.changeFilter}
                        departmentIds={selectedDepartments}
                        multiple
                      />
                    </Form.Field>
                  </Column>
                </Row>
                <Row>
                  <Column>
                    <Form.Field>
                      <p>Positions</p>
                      <PositionFilterDropdown
                        name="selectedPositions"
                        placeholder="Select all that apply"
                        onChange={this.changeFilter}
                        positionIds={selectedPositions}
                        multiple
                      />
                    </Form.Field>
                  </Column>
                </Row>
              </Grid>
            </ModalBase>
          )}
          {type === ModalTypes.Assignment && selectedShift && (
            <>
              {!willOverride && (
                <AssignEmployee
                  shift={selectedShift}
                  closeModal={this.closeModal}
                  title="Assign Shift"
                />
              )}
              <Segment>
                <Grid>
                  <Row onClick={() => this.toggleOverride()} className={style.chevrons}>
                    <Icon name={willOverride ? 'angle down' : 'angle right'} />
                    <Modal.Header>Override Assignments?</Modal.Header>
                  </Row>
                </Grid>
              </Segment>

              {willOverride && (
                <AssignOverrideModal
                  shift={selectedShift}
                  onDismiss={() =>
                    this.setState({
                      willOverride: false,
                      modalProperties: initialModalProps,
                    })
                  }
                />
              )}
            </>
          )}
        </Modal>
        {type === ModalTypes.Delete && shift && (
          <DeleteModal
            onConfirm={async () => {
              await shiftStore.delete(shift);
              this.closeModal();
            }}
            onCancel={this.closeModal}
            description="this open shift"
            open={modalOpen}
            label="Open Shift"
          />
        )}
      </>
    );
  }
}

export default OpenShifts;
