import * as React from 'react';
import * as classNames from 'classnames';
import { inject } from 'mobx-react';
import * as moment from 'moment';
import { isInclusivelyBeforeDay } from 'react-dates';
import { FormButton, Icon, Input, Modal, TextArea, Checkbox } from 'semantic-ui-react';
import { EmployeeStore, IStaffEmployee, Position, PositionStore, TimesheetStore } from 'sol-data';
import InjectedComponent from '../InjectedComponent';
import { SingleDatePicker } from '../SingleDatePicker';
import style from './index.less';
import Bulletin from '../Bulletin';
import PositionFilterDropdown from '../PositionFilterDropdown';

interface Props {
  open: boolean;
  onClose: () => void;
  employee: IStaffEmployee;
  department: string;
  onSubmit: () => void;
}

type EmployeeInfoUpdate = {
  seniority: number | null;
  line?: string | null;
  notes?: string | null;
  isHiddenOnSeniorityList?: boolean | null;
  seniorityPositionId?: number | null;
};

interface State {
  employee: IStaffEmployee;
  employeeUpdate: EmployeeInfoUpdate;
  isSaving: boolean;
  error?: unknown;
  employeePosition?: Position;
}

interface Injected {
  employeeStore: EmployeeStore;
  timesheetStore: TimesheetStore;
  positionStore: PositionStore;
}

@inject('employeeStore')
@inject('timesheetStore')
@inject('positionStore')
class EmployeeSeniorityModal extends InjectedComponent<Props, State, Injected> {
  state: State = {
    employee: this.props.employee,
    employeePosition: this.injected.positionStore.get(this.props.employee.position.id),
    employeeUpdate: {
      seniority: this.props.employee.seniorityLock ? this.props.employee.seniority ?? null : null,
      line: this.props.employee.line,
      notes: this.props.employee.notes,
      isHiddenOnSeniorityList: this.props.employee.isHiddenOnSeniorityList,
      seniorityPositionId: this.props.employee.seniorityPosition?.id,
    },
    isSaving: false,
  };

  componentDidUpdate(): void {
    if (this.state.employee?.id !== this.props.employee.id) {
      this.setState((prev) => ({ ...prev, employee: this.props.employee }));
    }
  }

  changeEmployeeUpdate(key: keyof EmployeeInfoUpdate, value: any) {
    this.setState((prev) => ({
      ...prev,
      employeeUpdate: {
        ...prev.employeeUpdate,
        [key]: value,
      },
    }));
  }

  handleSubmit = async () => {
    this.setState((prev) => ({ ...prev, isSaving: true, error: undefined }));
    const { seniority, line, notes, isHiddenOnSeniorityList, seniorityPositionId } =
      this.state.employeeUpdate;
    try {
      await this.injected.employeeStore.update(this.state.employee.id, {
        seniority,
        line,
        notes,
        isHiddenOnSeniorityList,
        seniorityPositionId: seniorityPositionId || null,
      });
      this.setState((prev) => ({ ...prev, isSaving: false }));
      this.props.onSubmit();
    } catch (err) {
      this.setState((prev) => ({ ...prev, isSaving: false, error: err }));
    }
  };

  renderBody() {
    const { employee, employeeUpdate } = this.state;

    const { startDate, fte } = employee;

    return (
      <div className={style.body}>
        <div className={style.grid_2_col}>
          <div className={classNames(style.grid, style.date_container)}>
            <label className={style.form_headers}>FTE</label>

            <Input placeholder="FTE" name="fte" type="text" value={fte || 'N/A'} disabled />
          </div>
          <div className={classNames(style.grid, style.date_container)}>
            <label className={style.form_headers}>Start Date</label>
            <SingleDatePicker
              disabled
              hideKeyboardShortcutsPanel
              noBorder
              block
              id="startDate"
              date={startDate || null}
              placeholder="MM/DD/YYYY"
              onDateChange={() => {}}
              isOutsideRange={(day) => !isInclusivelyBeforeDay(day, moment())}
              numberOfMonths={1}
            />
          </div>
        </div>

        <div className={style.grid_2_col}>
          <div className={classNames(style.grid, style.date_container)}>
            <label className={style.form_headers}>Manual Seniority</label>
            <Input
              placeholder="Unset (automatically calculated)"
              name="seniority"
              type="number"
              value={employeeUpdate.seniority}
              onChange={(e, { value }) => {
                const numberValue = value ? Number(value) : null;
                const finalValue =
                  numberValue && !isNaN(numberValue) && numberValue > 0 ? numberValue : null;
                this.changeEmployeeUpdate('seniority', finalValue);
              }}
            />
          </div>

          <div className={classNames(style.grid, style.date_container)}>
            <label className={style.form_headers}>Line #</label>

            <Input
              placeholder="N/A"
              name="line"
              value={employeeUpdate.line}
              onChange={(e, { value }) => {
                this.changeEmployeeUpdate('line', value);
              }}
            />
          </div>
        </div>
        <div className={style.grid_2_col}>
          <div className={classNames(style.grid, style.date_container)}>
            <label className={style.form_headers}>Seniority&apos;s Position</label>
            <PositionFilterDropdown
              name="seniorityPositionId"
              placeholder={`Default: ${this.state.employeePosition?.label}`}
              onChange={(_, value) => this.changeEmployeeUpdate('seniorityPositionId', value)}
              clearable
              positionId={employeeUpdate.seniorityPositionId ?? undefined}
            />
          </div>
          <div className={classNames(style.grid, style.date_container)}>
            <div className={style.checkbox_parent}>
              <Checkbox
                className={style.checkbox}
                label="Hide Employee"
                checked={employeeUpdate.isHiddenOnSeniorityList === true}
                onChange={(_, { checked }) =>
                  this.changeEmployeeUpdate('isHiddenOnSeniorityList', checked)
                }
              />
            </div>
          </div>
        </div>
        <div className={style.grid_1_col}>
          <div className={classNames(style.grid, style.date_container)}>
            <label className={style.form_headers}>Notes</label>

            <TextArea
              name="notes"
              rows={4}
              placeholder="Notes"
              value={employeeUpdate.notes || ''}
              onChange={(val) => this.changeEmployeeUpdate('notes', val.currentTarget.value)}
            />
          </div>
        </div>
        {this.state.error && <Bulletin error={this.state.error} />}
        <div className={style.action_container}>
          <FormButton onClick={this.props.onClose} className={style.btn_default}>
            CANCEL
          </FormButton>
          <FormButton
            loading={this.state.isSaving}
            onClick={() => this.handleSubmit()}
            className={style.btn_primary}
            disabled={this.state.isSaving}
          >
            SAVE
          </FormButton>
        </div>
      </div>
    );
  }

  render() {
    const { open, onClose, employee, department } = this.props;

    return (
      <Modal
        className={style.main_modal}
        open={open}
        onClose={onClose}
        size="small"
        closeIcon={<Icon name="close" size="tiny" color="black" />}
      >
        <Modal.Header>
          <section className={style.modal_header}>
            <p>Employee Information</p>
            <h4>
              {employee.user.lastName},{employee.user.firstName}
            </h4>
            <p>{department}</p>
          </section>
        </Modal.Header>
        <Modal.Content scrolling>{this.renderBody()}</Modal.Content>
      </Modal>
    );
  }
}

export default EmployeeSeniorityModal;
