import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { EntityList } from '@/components/EntityList';
import { ListColumn } from '@/components/ListLayout';
import { DepartmentStore, AreaStore, PositionStore } from '@/store';
import InjectedComponent from '@/components/InjectedComponent';
import AppContext from '@/AppContext';

interface Injected {
  departmentStore: DepartmentStore;
  areaStore: AreaStore;
  positionStore: PositionStore;
}

const deleteWarning =
  'Are you sure you want to delete this position? Doing so will invalidate links to other properties \
  (shifts, schedules, requests, etc...) between this position and anything related to it.';

@inject('departmentStore', 'areaStore', 'positionStore')
@observer
class Positions extends InjectedComponent<{}, {}, Injected> {
  fetch = async () => {
    const { departmentStore, areaStore, positionStore } = this.injected;

    await Promise.all([departmentStore.fetchAll(), areaStore.fetchAll(), positionStore.fetchAll()]);
  };

  componentWillUnmount() {
    const { positionStore } = this.injected;
    positionStore.clearFilter();
  }

  render() {
    const { positionStore } = this.injected;
    const { filtered: filteredPositions, errorMessage, isLoading } = positionStore;

    const columns: ListColumn[] = [
      {
        name: 'ID',
        key: 'id',
        width: { sm: 2, lg: 1 },
        render: ({ id }) => <span>{id.toString().padStart(4, '0')}</span>,
      },
      {
        name: 'Name',
        key: 'label',
        width: { sm: 5, lg: 2 },
        render: (item) => <span>{item.label}</span>,
      },
      {
        name: 'Description',
        key: 'description',
        width: { sm: 6, lg: 3 },
      },
    ];

    return (
      <AppContext.Consumer>
        {({ isAdmin }) => (
          <EntityList
            title="Position"
            searchLabel="Search by position description"
            columns={columns}
            items={filteredPositions}
            newLink="/organization/positions/add"
            titleProp="description"
            onRemove={isAdmin ? (position) => positionStore.delete(position) : undefined}
            dialog={deleteWarning}
            onSearchChange={(filter) => positionStore.setFilter(filter)}
            onDismiss={() => positionStore.clearError()}
            onRefresh={() => this.fetch()}
            errorMessage={errorMessage}
            isLoading={isLoading}
            link={isAdmin ? (pos) => `/organization/positions/${pos.id}` : undefined}
            noNewButton={!isAdmin}
          />
        )}
      </AppContext.Consumer>
    );
  }
}

export default Positions;
