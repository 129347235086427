import InjectedComponent from '@/components/InjectedComponent';
import { Button, Form } from '@/semantic-ui/components';
import { EmployeeStore } from '@/store';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import * as classNames from 'classnames';
import {
  DepartmentStore,
  EssentialEmployeeData,
  Moment,
  StatInLieuFormat,
  UserStore,
} from 'sol-data';
import * as yup from 'yup';
import { Formik } from 'formik';
import { camelCaseToReadable } from '@/lib/utils';
import { getEssentialsPermissions } from '@/lib/essentials-utils';
import Bulletin from '../Bulletin';
import DeptSelect from '../DeptSelect';
import style from './index.less';

interface Props {
  employee: EssentialEmployeeData;
  endDate: Moment;
  onSave: (data: EssentialEmployeeData) => void;
}

const employeeEditSchema = yup.object({
  employeeNumber: yup.number().min(1).nullable().notRequired(),
  workedHours: yup.number().min(0).notRequired(),
  vacationAccrued: yup.number().min(0).notRequired(),
  vacationAccruedInPayPeriod: yup.number().min(0).notRequired(),
  statInLieu: yup.number().min(0).notRequired(),
  statInLieuAccrued: yup.number().min(0).notRequired(),
  statInLieuValue: yup.number().min(0).notRequired(),
  statInLieuValueAccrued: yup.number().min(0).notRequired(),
  sickAccrued: yup.number().min(0).notRequired(),
  vacationValueAccrued: yup.number().min(0).notRequired(),
  vacationValueInPayPeriod: yup.number().min(0).notRequired(),
  sickHours: yup.number().min(0).notRequired(),
  hoursInPayPeriod: yup.number().min(0).notRequired(),
  departmentIds: yup.array(yup.number().required()).notRequired(),
});

interface State {
  error?: unknown;
  isEditing: boolean;
}

interface Injected {
  employeeStore: EmployeeStore;
  departmentStore: DepartmentStore;
  userStore: UserStore;
}

@inject('employeeStore')
@inject('departmentStore')
@inject('userStore')
@observer
class EditEssentialEmployee extends InjectedComponent<Props, {}, Injected> {
  state: State = {
    isEditing: false,
  };

  render() {
    const { employeeStore, departmentStore, userStore } = this.injected;
    const { employee } = this.props;
    const steps = employee.wage?.wage?.steps ?? 1;
    const statInLieuFormatId = employee.position.statInLieuFormatId ?? StatInLieuFormat.Hours;
    const { isEditing } = this.state;
    const permissions = getEssentialsPermissions(userStore.current);
    return (
      <Formik
        validationSchema={employeeEditSchema}
        initialValues={employeeEditSchema.cast(employee, { stripUnknown: true })}
        onSubmit={async (values, actions) => {
          this.setState({
            error: undefined,
          });
          try {
            const result = await employeeStore.updateEssentialEmployee({
              data: values,
              endDate: this.props.endDate,
              id: this.props.employee.id,
            });
            this.props.onSave(result.props);
            this.setState({
              isEditing: false,
            });
          } catch (err) {
            this.setState({ error: err });
          } finally {
            actions.setSubmitting(false);
          }
        }}
        render={({ values, errors, handleChange, handleSubmit, isSubmitting, setFieldValue }) => {
          const errorMessages = Object.entries(errors)
            .filter(([k, v]) => v !== undefined)
            .map(([key, value]) => `${camelCaseToReadable(key)}: ${value}`);
          return (
            <Form className={style.form_container} onSubmit={handleSubmit}>
              <div className={style.form_sub_section}>
                <Form.Input
                  disabled={!isEditing}
                  label="Employee #"
                  name="employeeNumber"
                  onChange={handleChange}
                  placeholder=""
                  value={values.employeeNumber || ''}
                  type="number"
                  width={2}
                  className={style.form_item}
                  error={!!errors.employeeNumber}
                />
                <DeptSelect
                  departments={departmentStore.values}
                  deptString={employee.departments.map((itm) => itm.label).join(', ') || '--'}
                  disabled={!isEditing}
                  onChange={(value) => setFieldValue('departmentIds', value)}
                />
                <Form.Input
                  disabled={!isEditing}
                  label="Hours in Pay Period"
                  name="hoursInPayPeriod"
                  type="number"
                  step="0.1"
                  placeholder="Hours in Pay Period"
                  value={values.hoursInPayPeriod}
                  width={2}
                  className={classNames(style.form_item, style.step_input)}
                  onChange={handleChange}
                  error={!!errors.hoursInPayPeriod}
                />
                <Form.Input
                  disabled={!isEditing}
                  label="Hours Worked"
                  type="number"
                  min={0}
                  step="0.01"
                  name="workedHours"
                  onChange={handleChange}
                  value={values.workedHours}
                  placeholder="Hours Worked"
                  width={2}
                  className={style.form_item}
                  error={!!errors.workedHours}
                />
                {statInLieuFormatId === StatInLieuFormat.Hours && (
                  <>
                    <Form.Input
                      disabled={!isEditing}
                      label="Stat in Lieu Hours"
                      placeholder="Stat in Lieu Hours"
                      name="statInLieu"
                      value={values.statInLieu}
                      onChange={handleChange}
                      width={2}
                      type="number"
                      className={style.form_item}
                      error={!!errors.statInLieu}
                    />
                    <Form.Input
                      disabled={!isEditing}
                      label="Stat In Lieu Hours Accrued"
                      placeholder="Stat In Lieu Hours Accrued"
                      name="statInLieuAccrued"
                      type="number"
                      value={values.statInLieuAccrued}
                      onChange={handleChange}
                      width={2}
                      className={style.form_item}
                      error={!!errors.statInLieuAccrued}
                    />
                  </>
                )}
                {statInLieuFormatId === StatInLieuFormat.Value && (
                  <>
                    <Form.Input
                      disabled={!isEditing}
                      label="Stat in Lieu"
                      placeholder="Stat in Lieu"
                      name="statInLieuValue"
                      value={values.statInLieuValue}
                      onChange={handleChange}
                      icon="dollar"
                      iconPosition="left"
                      width={2}
                      type="number"
                      className={style.form_item}
                      error={!!errors.statInLieuValue}
                    />
                    <Form.Input
                      disabled={!isEditing}
                      label="Stat In Lieu Accrued"
                      placeholder="Stat In Lieu Accrued"
                      name="statInLieuValueAccrued"
                      type="number"
                      icon="dollar"
                      iconPosition="left"
                      value={values.statInLieuValueAccrued}
                      onChange={handleChange}
                      width={2}
                      className={style.form_item}
                      error={!!errors.statInLieuValueAccrued}
                    />
                  </>
                )}
              </div>

              <div className={style.form_sub_section}>
                <Form.Input
                  disabled={!isEditing}
                  label="Vacation Accrued in Pay Period"
                  placeholder="Vacation Accrued in Pay Period"
                  name="vacationAccruedInPayPeriod"
                  type="number"
                  step="0.1"
                  icon="dollar"
                  iconPosition="left"
                  value={values.vacationAccruedInPayPeriod}
                  width={2}
                  className={style.form_item}
                  onChange={handleChange}
                  error={!!errors.vacationAccruedInPayPeriod}
                />
                <Form.Input
                  disabled={!isEditing}
                  label="Vacation Used"
                  placeholder="Vacation Used in Pay Period"
                  name="vacationValueInPayPeriod"
                  icon="dollar"
                  iconPosition="left"
                  type="number"
                  onChange={handleChange}
                  value={values.vacationValueInPayPeriod}
                  width={2}
                  className={style.form_item}
                  error={!!errors.vacationValueInPayPeriod}
                />
                <Form.Input
                  label="Vacation Accrued in Pay Period (Actual)"
                  placeholder="Vacation Accrued in Pay Period (Actual)"
                  type="number"
                  icon="dollar"
                  iconPosition="left"
                  value={employee.vacationAccruedActual}
                  width={2}
                  className={style.form_item}
                  disabled
                />
                <Form.Input
                  disabled={!isEditing}
                  label="Vacation Total Accrued"
                  placeholder="Vacation Total Accrued"
                  name="vacationAccrued"
                  type="number"
                  step="0.1"
                  icon="dollar"
                  iconPosition="left"
                  value={values.vacationAccrued}
                  width={2}
                  className={style.form_item}
                  onChange={handleChange}
                  error={!!errors.vacationAccrued}
                />
                <Form.Input
                  disabled={!isEditing}
                  label="Vacation Used Total"
                  placeholder="Vacation Used Total"
                  name="vacationValueAccrued"
                  icon="dollar"
                  iconPosition="left"
                  type="number"
                  onChange={handleChange}
                  value={values.vacationValueAccrued}
                  width={2}
                  className={style.form_item}
                  error={!!errors.vacationValueAccrued}
                />
                <Form.Input
                  disabled={!isEditing}
                  label="Sick Hours Accrued"
                  placeholder="Sick Hours Accrued"
                  name="sickAccrued"
                  type="number"
                  step="0.1"
                  value={values.sickAccrued}
                  onChange={handleChange}
                  width={2}
                  className={style.form_item}
                  error={!!errors.sickAccrued}
                />
                <Form.Input
                  disabled={!isEditing}
                  label="Sick Hours Used"
                  placeholder="Sick Hours Used"
                  name="sickHours"
                  type="number"
                  onChange={handleChange}
                  value={values.sickHours}
                  width={2}
                  className={style.form_item}
                  error={!!errors.sickHours}
                />
                <Form.Input
                  label="Sick Hours Accrued (Actual)"
                  placeholder="Sick Hours Accrued (Actual)"
                  type="number"
                  step="0.1"
                  value={employee.sickAccruedActual}
                  width={2}
                  className={style.form_item}
                  disabled
                />

                {permissions.write &&
                  this.props.employee.isActive &&
                  (isEditing ? (
                    <Button
                      content="SAVE"
                      type="submit"
                      width={1}
                      className={style.form_button_save}
                      disabled={!!errorMessages.length}
                      loading={isSubmitting}
                    />
                  ) : (
                    <Button
                      type="button"
                      content="EDIT"
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({ isEditing: true, error: undefined });
                      }}
                      width={1}
                      className={style.form_button_edit}
                    />
                  ))}
              </div>

              {!!errorMessages.length && <Bulletin errorMessage={errorMessages.join('/n')} />}
              {this.state.error && <Bulletin error={this.state.error} />}
            </Form>
          );
        }}
      />
    );
  }
}

export default EditEssentialEmployee;
