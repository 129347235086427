import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Modal } from '@/semantic-ui/components';
import { ID, PayrollLimit } from 'sol-data';
import ButtonBar from '@/components/ButtonBar';
import CategoryCheckmark from '@/components/CategoryCheckmark';
import { PayrollStore, PositionStore } from '@/store';
import InjectedComponent from '@/components/InjectedComponent';
import ListLayout, { ListColumn } from '@/components/ListLayout';
import Bulletin from '@/components/Bulletin';
import style from '@/containers/Payroll/index.less';
import OvertimeForm from './OvertimeForm';

interface State {
  showForm: boolean;
}

interface Injected {
  payrollStore: PayrollStore;
  positionStore: PositionStore;
}

@inject('payrollStore', 'positionStore')
@observer
class PayrollOvertime extends InjectedComponent<{}, State, Injected> {
  state = {
    showForm: false,
  };

  componentDidMount() {
    const { positionStore } = this.injected;
    positionStore.fetchAll();
  }

  toggleForm = () => {
    const { payrollStore } = this.injected;
    this.setState((state) => ({ showForm: !state.showForm }));
    payrollStore.clearError();
  };

  handleRemove = async (item: ID) => {
    const { payrollStore } = this.injected;
    await payrollStore.deleteLimit(item);
  };

  render() {
    const { payrollStore, positionStore } = this.injected;

    const {
      limits,
      categories: { values: payrollCategories },
      errorMessage,
      isLoading,
    } = payrollStore;

    const { showForm } = this.state;

    const columns: ListColumn<PayrollLimit>[] = [
      {
        name: 'ID',
        key: 'id',
        width: 1,
        render: ({ id }) => <span>{id.toString().padStart(4, '0')}</span>,
      },
      {
        name: 'Unit',
        key: 'unit',
        render: ({ unit }) => <span className={style.max_unit}>{unit}</span>,
      },
      {
        name: 'Hours',
        key: 'hours',
        render: ({ hours }) => <span className={style.max_unit}>{hours}</span>,
      },
      {
        name: 'Position',
        key: 'position',
        width: 5,
        render: ({ position }) => (
          <span>
            {position
              ? positionStore.has(position)
                ? positionStore.get(position)!.description
                : 'Unknown'
              : '-'}
          </span>
        ),
      },
    ];

    const overtimeCategories = payrollCategories.filter(({ isOvertime }) => isOvertime);

    const checkCols = overtimeCategories.map(({ label, id }): ListColumn => {
      return {
        name: label,
        key: id,
        textAlign: 'center',
        render: ({ categories }) => categories.includes(id) && <CategoryCheckmark />,
      };
    });

    columns.push(...checkCols);

    return (
      <>
        <Bulletin isLoading={isLoading} errorMessage={errorMessage} />
        <ListLayout columns={columns} items={limits.values} onRemove={this.handleRemove} />
        <ButtonBar>
          <Button
            color="blue"
            type="button"
            onClick={this.toggleForm}
            className={style.confirm_button}
          >
            Add Overtime Limit
          </Button>
        </ButtonBar>
        <Modal open={showForm} size="mini" onClose={this.toggleForm}>
          <OvertimeForm onFormToggle={this.toggleForm} />
        </Modal>
      </>
    );
  }
}

export default PayrollOvertime;
