import AllScheduleLists from '@/components/AllScheduleLists';
import InjectedComponent from '@/components/InjectedComponent';
import OpenShifts from '@/components/OpenShifts';
import PastSchedules from '@/components/PastSchedules';
import { withNavigation, WithNavigationProps } from '@/components/Route';
import TabLayout, { TabLayoutPane } from '@/components/TabLayout';
import { Label, Menu } from '@/semantic-ui/components';
import { AreaStore, DepartmentStore, EmployeeStore, PositionStore, ScheduleStore } from '@/store';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Link } from 'react-router';
import { Authority, moment, ShiftStore } from 'sol-data';

interface Props extends WithNavigationProps {}

interface Injected {
  scheduleStore: ScheduleStore;
  departmentStore: DepartmentStore;
  areaStore: AreaStore;
  positionStore: PositionStore;
  employeeStore: EmployeeStore;
  shiftStore: ShiftStore;
}

@inject('scheduleStore', 'departmentStore', 'areaStore', 'positionStore', 'shiftStore')
@observer
class Schedules extends InjectedComponent<Props, {}, Injected> {
  componentDidMount() {
    const { scheduleStore, departmentStore, areaStore, positionStore, shiftStore } = this.injected;

    scheduleStore.findSchedules({
      startDate: moment('2019-01-01'),
      endDate: moment().add(10, 'years'),
    });

    areaStore.fetchAll();
    positionStore.fetchAll();
    departmentStore.fetchAll();
    shiftStore.fetchOpenShifts();
  }

  render() {
    const { path } = this.props;
    const { scheduleStore, shiftStore } = this.injected;

    const panes: TabLayoutPane[] = [
      {
        route: 'all',
        menuItem: (
          <Menu.Item as={Link} to="/scheduling/all" key="all">
            Schedules
          </Menu.Item>
        ),
        render: () => <AllScheduleLists />,
      },
      {
        route: 'past-schedules',
        menuItem: (
          <Menu.Item as={Link} to="/scheduling/past-schedules" key="past-schedules">
            Past Schedules
          </Menu.Item>
        ),
        render: () => <PastSchedules />,
      },
      {
        route: 'open-shifts',
        menuItem: (
          <Menu.Item as={Link} to="/scheduling/open-shifts" key="open-shifts">
            Open Shifts
            {shiftStore.openShifts.filtered.length > 0 && (
              <Label color="yellow" floating circular>
                {shiftStore.openShifts.filtered.length}
              </Label>
            )}
          </Menu.Item>
        ),
        render: () => <OpenShifts />,
        access: Authority.Admin,
      },
    ];

    return (
      <TabLayout
        path={path}
        panes={panes || []}
        activeBreadcrumb="Schedules"
        onTabChange={(activeIndex) => {
          if (activeIndex !== 2) scheduleStore.clearFilters();
        }}
      />
    );
  }
}

export default withNavigation(Schedules);
