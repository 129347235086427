import * as React from 'react';
import { NoticeStore, UserStore } from '@/store';
import { inject, observer } from 'mobx-react';
import { ID, INotice, moment } from 'sol-data';
import { Column, Form, Grid, Input, Row, Segment, TextArea } from '@/semantic-ui/components';
import { Formik } from 'formik';
import { array, number, object, string } from 'yup';
import { differenceWith, isEqual } from 'lodash';
import Breadcrumbs from '../Breadcrumbs';
import InjectedComponent from '../InjectedComponent';
import Bulletin from '../Bulletin';
import style from './index.less';
import ButtonBar from '../ButtonBar';
import DistributionGroup, { CompletedEmployee } from '../DistributionGroup';
import CreateNoticeModal from '../CreateNoticeModal';
import { withNavigation, WithNavigationProps } from '../Route';

const { Field } = Form;

interface Props extends WithNavigationProps {}

interface State {
  shouldOpenFinishModal: boolean;
}
interface Injected {
  noticeStore: NoticeStore;
  userStore: UserStore;
}

@inject('noticeStore', 'userStore')
@observer
class ModifyNotices extends InjectedComponent<Props, State, Injected> {
  state: State = {
    shouldOpenFinishModal: false,
  };

  async componentDidMount() {
    const { noticeStore } = this.injected;
    const { noticeId } = this.props.params;
    noticeStore.clearCurrent();

    if (noticeId !== 'add') {
      await this.fetchData(noticeId!);
    }
  }

  async fetchData(id: ID) {
    const { noticeStore } = this.injected;
    await noticeStore.fetch(id);
  }

  render() {
    const { noticeStore, userStore } = this.injected;
    const { noticeId } = this.props.params;
    const { errorMessage, isLoading, current: notice } = noticeStore;
    const { shouldOpenFinishModal } = this.state;

    let initialValues: INotice = {
      title: '',
      description: '',
      departments: [],
    };

    const { fullName } = userStore.current!;
    let createdBy = `${fullName} - ${moment().format('YYYY-MM-DD')}`;

    let completedEmployees: CompletedEmployee[] = [];

    if (notice) {
      initialValues = {
        ...initialValues,
        title: notice.title,
        description: notice.description,
        departments: notice.departments,
      };
      completedEmployees = notice.completedEmployees.map(({ readOn, employee: { id } }) => ({
        employeeId: id,
        completionDate: readOn,
      }));
      createdBy = `${notice.postedBy} - ${notice.postedOn}`;
    }

    const closeModal = () => {
      this.setState({
        shouldOpenFinishModal: false,
      });
    };

    return (
      <>
        <Segment basic padded="very">
          <Breadcrumbs
            linkTitle="Notice"
            activeBreadcrumb={notice?.title ?? 'New Notice'}
            link="/notices/all"
            title="Notice"
          />
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={object().shape({
              title: string().required('Title is required'),
              description: string().required('Description is required'),
              departments: array()
                .of(
                  object().shape({
                    id: number().min(1).required('Department must be selected'),
                  }),
                )
                .min(1, 'You must select at least one department'),
            })}
            onSubmit={async (values, { setSubmitting }) => {
              if (noticeId !== 'add') {
                if (values.departments.length < initialValues.departments.length) {
                  noticeStore.setError('You cannot remove departments');
                }
                const modifiedDepartments = differenceWith(
                  values.departments,
                  initialValues.departments,
                  isEqual,
                );
                const departmentIds = modifiedDepartments.map((d) => ({
                  id: d.id,
                }));
                await noticeStore.update({
                  id: noticeId!,
                  departments: departmentIds,
                });
                await noticeStore.sendNoticeNotifcations(noticeId!, modifiedDepartments);
              } else {
                const createdNotice = await noticeStore.create(values);
                await noticeStore.sendNoticeNotifcations(
                  createdNotice.id,
                  createdNotice.departments,
                );
              }

              setSubmitting(false);
              if (!errorMessage) {
                this.setState({
                  shouldOpenFinishModal: true,
                });
              }
            }}
            render={({
              handleSubmit,
              values,
              handleChange,
              isSubmitting,
              touched,
              errors,
              setFieldValue,
            }) => {
              const showLabelError = !!touched.title && !!errors.title;
              const showDescriptionError = !!touched.description && !!errors.description;
              const showDepartmentsError = !!touched.departments && !!errors.departments;
              const readOnlyField = noticeId !== 'add';
              return (
                <Grid>
                  <Row>
                    <Bulletin
                      errorMessage={errorMessage}
                      handleDismiss={() => noticeStore.clearError()}
                      isLoading={isLoading}
                    />
                  </Row>
                  <Row>
                    <Column width={8}>
                      <Field>
                        <p className={style.form_headers}>Title</p>
                        <Input
                          name={'title'}
                          value={values.title}
                          placeholder={'Title'}
                          onChange={handleChange}
                          readOnly={readOnlyField}
                          fluid
                        />
                        {showLabelError && <p className="error_class">{errors.title}</p>}
                      </Field>
                    </Column>
                    <Column width={8}>
                      <Field>
                        <p className={style.form_headers}>Created By</p>
                        <Input value={createdBy} placeholder={createdBy} readOnly fluid />
                      </Field>
                    </Column>
                  </Row>
                  <Row>
                    <Column width={16}>
                      <Field>
                        <p className={style.form_headers}>Description</p>
                        <Form>
                          <TextArea
                            name="description"
                            value={values.description}
                            placeholder="Description"
                            rows={15}
                            onChange={handleChange}
                            autoHeight
                            className={style.input}
                            readOnly={readOnlyField}
                          />
                        </Form>
                        {showDescriptionError && (
                          <p className="error_class">{errors.description}</p>
                        )}
                      </Field>
                    </Column>
                  </Row>
                  <Row>
                    <Column width={16}>
                      <p className={style.form_headers}>Distribution</p>
                      <p>Select departments/groups below to receive this document</p>
                      <DistributionGroup
                        handleDepartmentClick={setFieldValue}
                        selectedDepartments={values.departments}
                        completedEmployees={completedEmployees}
                      />
                      {showDepartmentsError && <p className="error_class">{errors.departments}</p>}
                    </Column>
                  </Row>
                  <Row>
                    <Column width={16}>
                      <ButtonBar
                        onSave={handleSubmit}
                        link="/notices"
                        disabled={isSubmitting}
                        saveText={'Post'}
                      />
                    </Column>
                  </Row>
                </Grid>
              );
            }}
          />
        </Segment>
        <CreateNoticeModal open={shouldOpenFinishModal} handleClose={closeModal} />
      </>
    );
  }
}

export default withNavigation<{}>(ModifyNotices);
