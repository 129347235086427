import * as React from 'react';
import { Segment } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { UserStore } from '@/store';
import { InjectedComponent } from '@/components/InjectedComponent';
import style from './index.less';
import '@/App.scss';

interface Injected {
  userStore: UserStore;
}

@inject('userStore')
@observer
class Home extends InjectedComponent<{}, {}, Injected> {
  render() {
    const { userStore } = this.injected;
    const { firstName, lastName } = userStore.current!;

    return (
      <Segment className={style.homepage} padded="very">
        <div className={style.homepage_overlay} />
        <div className={style.homepage_headers}>
          <h1 className={style.header_primary}>{`Welcome, ${firstName} ${lastName}`}</h1>
        </div>
      </Segment>
    );
  }
}

export default Home;
