import { authService } from '@/api';
import AppContext from '@/AppContext';
import { LoginForm, Pane } from '@/components/LoginForm';
import { SideNavigation } from '@/components/SideNavigation';
import { AppContainer } from '@/containers/AppContainer';
import style from '@/containers/AppContainer/index.less';
import { Segment } from '@/semantic-ui/components';
import { useStores } from '@/store';
import * as Sentry from '@sentry/react';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router';
import { Authority } from 'sol-data';

const AppRouter = observer(() => {
  const [collapsed, setCollapsed] = useState(window.innerWidth < 1075);
  const navigate = useNavigate();
  const { userStore } = useStores();
  const [authority] = (userStore.current?.access && userStore.current.access.authority) || [
    Authority.ReadOnly,
  ];
  const scope = (userStore.current?.access && userStore.current.access.scope) || {
    positions: null,
    departments: null,
  };
  const isAdmin = authority >= Authority.Admin;
  const isPayrollAdmin = authority >= Authority.PayrollAdmin;

  useEffect(() => {
    const isAuthenticated = authService.isAuthenticated();

    if (!isAuthenticated) {
      navigate('/login');
    }
  }, [authService.isAuthenticated]);

  const SentryRoutes = Sentry.withSentryReactRouterV7Routing(Routes);

  return (
    <Sentry.ErrorBoundary
      fallback={({ error }) => {
        return (
          <AppContext.Provider value={{ collapsed, isAdmin, isPayrollAdmin, scope }}>
            <SideNavigation
              collapsed={collapsed}
              setCollapsed={setCollapsed}
              authority={authority}
            />

            <Segment
              className={classnames(style.app_content, {
                [style.app_content_thin]: collapsed,
              })}
              style={{ height: '100vh' }}
            >
              <Segment inverted color="red" padded style={{ margin: 20 }}>
                Error! Something went wrong trying to load the page. Please try again.
                {process.env.NODE_ENV === 'development' && (
                  <>
                    <br />
                    <br />
                    Error message: <b>{error.message}</b>
                  </>
                )}
              </Segment>
            </Segment>
          </AppContext.Provider>
        );
      }}
    >
      <SentryRoutes>
        <Route path="/login" element={<LoginForm initialPane={Pane.Login} />} />
        <Route path="/password-reset" element={<LoginForm initialPane={Pane.FinishReset} />} />
        <Route path="*" element={<AppContainer />} />
      </SentryRoutes>
    </Sentry.ErrorBoundary>
  );
});

export default AppRouter;
