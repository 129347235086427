import { Grid } from '@/semantic-ui/components';
import { DepartmentStore } from '@/store';
import { HasID } from '@lcdev/store';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Authority, EmployeeActiveStatus } from 'sol-data';
import DepartmentEmployeesList from '../DepartmentEmployeesList';
import DistributionPill from '../DistributionPill';
import InjectedComponent from '../InjectedComponent';

export interface CompletedEmployee {
  employeeId: number;
  completionDate: string;
}
interface Props {
  handleDepartmentClick: (field: string, value: HasID[]) => void;
  selectedDepartments: HasID[];
  completedEmployees?: CompletedEmployee[];
}

interface Injected {
  departmentStore: DepartmentStore;
}

@inject('departmentStore')
@observer
export default class DistributionGroup extends InjectedComponent<Props, {}, Injected> {
  componentDidMount() {
    this.fetchData();
  }

  async fetchData() {
    const { departmentStore } = this.injected;
    departmentStore.fetchAll();
  }

  render() {
    const { departmentStore } = this.injected;
    const { filtered } = departmentStore;
    const { selectedDepartments, completedEmployees = [] } = this.props;
    const departmentsWithoutInactiveEmployees = filtered.map(({ employees, ...department }) => {
      const activeEmployees = employees?.filter(({ status, user, ...employee }) => {
        return (
          (status as unknown as EmployeeActiveStatus) === EmployeeActiveStatus.Active &&
          // @ts-ignore
          employee.deleteDatetime === null &&
          !user?.access?.authority.includes(Authority.ReadOnly)
        );
      });

      return {
        ...department,
        employeeCount: activeEmployees?.length ?? 0,
        numberOfEmployees: activeEmployees?.length ?? 0,
        departmentEmployees: activeEmployees,
      };
    });

    const handleClick = (id: number) => {
      const { handleDepartmentClick } = this.props;
      const filteredDepartments = selectedDepartments.some((selected) => selected.id === id)
        ? selectedDepartments.filter((selected) => selected.id !== id)
        : [
            ...selectedDepartments,
            {
              id,
            },
          ];
      handleDepartmentClick('departments', filteredDepartments);
    };

    return (
      <Grid>
        {departmentsWithoutInactiveEmployees.map((department, index) => (
          <DistributionPill
            department={department}
            key={index}
            selected={selectedDepartments.some((selected) => selected.id === department.id)}
            handleClick={handleClick}
          />
        ))}
        <DepartmentEmployeesList
          departments={departmentsWithoutInactiveEmployees.filter((f) =>
            selectedDepartments.some((sd) => sd.id === f.id),
          )}
          completedEmployees={completedEmployees}
        />
      </Grid>
    );
  }
}
