import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { PositionScheduleShiftGroup } from 'sol-data';
import { ScheduleComponentStore } from '@/store';
import InjectedComponent from '@/components/InjectedComponent';
import ShiftBlock from '../ShiftBlock';
import { rowCellRatio } from '../utils';
import style from '../index.less';

interface Props {
  group: PositionScheduleShiftGroup;
}

interface Injected {
  scheduleComponentStore: ScheduleComponentStore;
}

@inject('scheduleComponentStore')
@observer
class GroupRow extends InjectedComponent<Props, {}, Injected> {
  render() {
    const {
      group,
      scheduleComponentStore: {
        cellDimens: { height: cellHeight },
      },
    } = this.injected;

    return (
      <div className={style.schedule_row} style={{ height: `${cellHeight * rowCellRatio}px` }}>
        {(group.shifts || []).map((shifts, i) => (
          <ShiftBlock key={i} shifts={shifts} shiftGroup={group} />
        ))}
      </div>
    );
  }
}

export default GroupRow;
