import * as React from 'react';
import { Menu, MenuItemProps } from '@/semantic-ui/components';
import ScheduleContext from '../ScheduleContext';
import style from '../index.less';
import { ModalActions } from '../PositionGroupTitle';

interface Props {
  openModal: (action: ModalActions) => void;
  hasEmployee: boolean;
}

const { Item } = Menu;

const PositionMenu: React.FC<Props> = ({ openModal, hasEmployee }) => {
  const clickHandler: MenuItemProps['onClick'] = (_, { action }) => {
    if (!action) {
      throw new Error('Action required');
    }

    openModal(action);
  };
  return (
    <ScheduleContext.Consumer>
      {(config) => (
        <Menu size="mini" vertical className={style.schedule_position_menu}>
          {config.group.canAddShift && (
            <Item name="new shift" action={ModalActions.NewShift} onClick={clickHandler} />
          )}
          {config.group.canAssign && (
            <Item
              name={hasEmployee ? 'unassign' : 'assign'}
              action={hasEmployee ? ModalActions.Unassign : ModalActions.Assign}
              onClick={clickHandler}
            />
          )}
          {config.group.canAssign && !hasEmployee && (
            <Item
              name="assignment override"
              action={ModalActions.Override}
              onClick={clickHandler}
            />
          )}
          {config.group.canRemove && (
            <Item name="delete" action={ModalActions.Delete} onClick={clickHandler} />
          )}
          {config.group.canUnassignAllShifts && (
            <Item
              name="unassign all shifts"
              action={ModalActions.UnassignAllShifts}
              onClick={clickHandler}
            />
          )}
        </Menu>
      )}
    </ScheduleContext.Consumer>
  );
};

export default PositionMenu;
