import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { AreaScheduleArea } from 'sol-data';
import { ScheduleComponentStore } from '@/store';
import InjectedComponent from '@/components/InjectedComponent';
import { rowCellRatio } from '../utils';
import AreaShiftBlock from '../AreaShiftBlock';
import style from '../index.less';

interface Props {
  areaSchedule: AreaScheduleArea;
}

interface Injected {
  scheduleComponentStore: ScheduleComponentStore;
}

@inject('scheduleComponentStore')
@observer
export default class AreaRow extends InjectedComponent<Props, {}, Injected> {
  render() {
    const {
      areaSchedule: { timeRanges, timeRangeKeys },
      scheduleComponentStore: {
        cellDimens: { height: cellHeight },
      },
    } = this.injected;

    return (
      <div className={style.area_group_row}>
        {timeRangeKeys.map((key) => {
          const { days } = timeRanges[key];

          return (
            <div
              className={style.area_row}
              style={{ height: `${cellHeight * rowCellRatio}px` }}
              key={key}
            >
              {days.map((day, i) => (
                // eslint-disable-next-line
                <AreaShiftBlock day={day} key={`${key}${i}`} />
              ))}
            </div>
          );
        })}
      </div>
    );
  }
}
