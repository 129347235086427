import Breadcrumbs from '@/components/Breadcrumbs';
import ButtonBar from '@/components/ButtonBar';
import { ListColumn } from '@/components/ListLayout';
import WageEmployeePanel from '@/components/WageEmployeePanel';
import WageModal from '@/components/WageModal';
import WagePositionDropdown from '@/components/WagePositionDropdown';
import { useAsyncEffect } from '@/hooks';
import { getEssentialsPermissions } from '@/lib/essentials-utils';
import { validateNumberInput } from '@/lib/utils';
import {
  Button,
  Dimmer,
  Input,
  InputOnChangeData,
  Loader,
  Message,
  Segment,
  Table,
} from '@/semantic-ui/components';
import { useStores } from '@/store';
import * as classnames from 'classnames';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useRef, useState } from 'react';
import { useParams } from 'react-router';
import { Employee, IWage, Position, WageSteps, moment } from 'sol-data';
import style from './index.less';

interface InputValues {
  [id: number]: string;
}

interface DateRangeDetail {
  iniRangeDate: moment.Moment;
  endRangeDate: moment.Moment;
  wageName: string;
  positionDescription: string;
}

interface WagePosition extends IWage {
  positionDescription: string;
}

export const WageGrid = observer(() => {
  const [modalOpen, setModalOpen] = useState(false);
  const [wagesGrid, setWagesGrid] = useState<IWage | null>(null);
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [positions, setPositions] = useState<Position[]>([]);
  const [fullList, setFullList] = useState(false);
  const [error, setError] = useState<string[] | null>(null);
  const [inputValues, setInputValues] = useState<InputValues>({});
  const [success, setSuccess] = useState(false);
  const [readOnly, setReadOnly] = useState(true);
  const { wageStore, employeeStore, positionStore, supplementaryRateStore, userStore } =
    useStores();
  const params = useParams();
  const wageId = Number(params.wageId);

  const timerRef = useRef<ReturnType<typeof setTimeout>>();

  useAsyncEffect(async () => {
    wageStore.clearCurrent();

    setReadOnly(!getEssentialsPermissions(userStore.current).write);

    await Promise.all([fetchData(wageId), supplementaryRateStore.fetchAll()]);

    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, [wageId]);

  const getRangeDate = ({
    startEffectiveDate,
    endEffectiveDate,
    scalations,
    name,
    positionDescription,
  }: WagePosition): DateRangeDetail => {
    const iniRangeDate = startEffectiveDate;
    const endRangeDate = scalations?.length
      ? scalations
          .sort((a, b) => (moment(a.endScalationDate).isBefore(b.startScalationDate) ? -1 : 1))
          .slice(-1)[0].endScalationDate
      : endEffectiveDate;
    return { iniRangeDate, endRangeDate, wageName: name, positionDescription };
  };

  const isDateOverlap = (dateRangeA: DateRangeDetail, dateRangeB: DateRangeDetail) => {
    const isInRangeA = moment(dateRangeB.iniRangeDate).isBetween(
      dateRangeA.iniRangeDate,
      dateRangeA.endRangeDate,
      undefined,
      '[]',
    );
    const isInRangeB = moment(dateRangeB.endRangeDate).isBetween(
      dateRangeA.iniRangeDate,
      dateRangeA.endRangeDate,
      undefined,
      '[]',
    );
    return isInRangeA || isInRangeB;
  };

  const linkToWageGrid = (positionsData: Position[]) => {
    if (wagesGrid) {
      setWagesGrid({ ...wagesGrid, positions: positionsData });
      setError(null);
    }
  };

  const fetchData = async (wageIdNum: number) => {
    const [wagesGridData, employeesData, positionsData] = await Promise.all([
      wageStore.fetch(wageIdNum),
      employeeStore.fetchAll(),
      positionStore.fetchPositionsWage(),
    ]);

    const inputValuesData: InputValues =
      wagesGridData.wageSteps?.reduce(
        (acu, step) => ({ ...acu, [step?.id || 0]: step.stepValue }),
        {},
      ) || {};

    setWagesGrid(toJS(wagesGridData));
    setEmployees(toJS(employeesData));
    setPositions(toJS(positionsData));
    setInputValues(inputValuesData);
  };

  const handleUpdateGrid = async (data: IWage) => {
    if (!data.id) return;
    setError(null);

    if (!data.positions?.length) return saveWageGrid(data);

    const myWageRangeDate = getRangeDate({ ...data, positionDescription: '' });
    const positionsWithWages = data.positions
      .flatMap(
        ({ wages, description, label }) =>
          wages?.map((iwage) => {
            return { ...iwage, positionDescription: `${description} (${label})` };
          }) || [],
      )
      .filter((emptyWages) => emptyWages)
      .map((itemWage) => getRangeDate(itemWage));

    if (positionsWithWages.length <= 0) return saveWageGrid(data);

    const wagePositionOverlap = positionsWithWages
      .filter((posWage) => isDateOverlap(posWage, myWageRangeDate))
      .filter(({ wageName }) => wageName !== data.name);

    const errorMessage = wagePositionOverlap.map(
      (item) => `Employee group: ${item.positionDescription} from wage grid: ${item.wageName}`,
    );

    if (errorMessage.length) {
      setError([
        `WageGrid cannot be associated to the same group(s) with in the same time period:`,
        ...errorMessage,
      ]);
      return;
    }

    return saveWageGrid(data);
  };

  const saveWageGrid = async (data: IWage) => {
    try {
      if (!data.id) return;
      const resUpdate = await wageStore.update(data, data.id);
      await updateWagesRequest(resUpdate.id);
    } catch (error) {
      setError(['Error loading data. Please check your network connection and try again.']);
    }
  };

  const updateWagesRequest = async (id: number) => {
    try {
      const res = await wageStore.fetch(id);
      setWagesGrid(res);
      setModalOpen(false);
      return setSuccessMessage();
    } catch (error) {
      setError(['There was some problem, while updating the Wage', 'Please reload the page']);
    }
  };

  const setSuccessMessage = () => {
    setSuccess(true);
    timerRef.current = setTimeout(() => {
      setSuccess(false);
    }, 5000);
  };

  const handleRemoveWageGrid = async (id: number) => {
    await wageStore.delete(id);
    setModalOpen(false);
  };

  const closeNewWageGrid = () => {
    setModalOpen(false);
  };

  const openModalWageGrid = () => {
    setModalOpen(true);
  };

  const headersWage = (): ListColumn[] => {
    const initial: ListColumn[] = [
      {
        name: 'DATE OF RATIFICATION',
        key: 'name',
        className: style[`layout_${wagesGrid?.steps || 1}col`],
      },
    ];
    if (wagesGrid) {
      for (let i = 1; i <= wagesGrid.steps; i += 1) {
        initial.push({
          name: `STEP ${i}`,
          key: `STEP ${i}`,
          className: style[`layout_${wagesGrid?.steps || 1}col`],
          textAlign: 'center',
        });
      }
    }
    return initial;
  };

  const handleStepInputChange = (itemStep: WageSteps, d: { value: string }): void => {
    if (wagesGrid && validateNumberInput(d.value)) {
      const wageSteps = (wagesGrid?.wageSteps || []).filter((item) => item.id !== itemStep.id);
      wageSteps.push({ ...itemStep, stepValue: +Number(d.value).toFixed(2) });
      setWagesGrid({ ...wagesGrid, wageSteps });
    }
  };

  const handleInputValues = (data: string, item: WageSteps) => {
    if (/^\d*(\.\d{0,2})?$/.test(data)) {
      setInputValues({ ...inputValues, [item.id || 0]: data });
    }
  };

  const getStepsValues = (wSteps: WageSteps[], scaleId: number, lsrpValue: number) => {
    const stepsResults = wSteps
      .filter((item) => item.scaleIndex === scaleId)
      .map((item) => {
        if (lsrpValue) {
          return {
            [`STEP ${item.stepNumber}`]: (
              <div className={style.lsrp_grid}>
                <span>$</span>
                <span>{`${Number(item.stepValue * (1 + lsrpValue / 100)).toFixed(2)}`}</span>
              </div>
            ),
          };
        }
        return {
          [`STEP ${item.stepNumber}`]: (
            <Input
              key={item.stepNumber}
              icon="dollar"
              step=".01"
              pattern="^\d*(\.\d{0,2})?$"
              iconPosition="left"
              disabled={readOnly}
              className={classnames(style.swage_input)}
              labelPosition="right"
              value={inputValues[item.id || 0] || ''}
              onChange={(_event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) =>
                handleInputValues(data.value, item)
              }
              onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleStepInputChange(item, { value: e.target.value });
              }}
              type={'number'}
            />
          ),
        };
      });
    return Object.assign({}, ...stepsResults);
  };

  const getContent = () => {
    if (wagesGrid) {
      const initial = [
        {
          key: 0,
          name: (
            <p>
              Effective date
              <br />
              <span className={style.grid_dates_range}>
                {`${moment(wagesGrid?.startEffectiveDate).format('YYYY-MM-DD')} - ${moment(
                  wagesGrid?.endEffectiveDate,
                ).format('YYYY-MM-DD')}`}
              </span>
            </p>
          ),
          ...getStepsValues(Array.from(wagesGrid?.wageSteps || []), 0, 0),
        },
      ];

      if (wagesGrid.lsrpRate && wagesGrid.lsrpRateValue) {
        initial.push({
          key: initial.length,
          name: `LSRP Rate: ${wagesGrid?.lsrpRateValue}%`,
          ...getStepsValues(Array.from(wagesGrid?.wageSteps || []), 0, wagesGrid.lsrpRateValue),
        });
      }
      if (Array.from(wagesGrid.scalations).length) {
        Array.from(wagesGrid.scalations || [])
          .sort((a, b) => (moment(a.endScalationDate).isBefore(b.startScalationDate) ? -1 : 1))
          .forEach((item, index) => {
            initial.push({
              key: initial.length,
              name: (
                <>
                  <p>
                    Wage escalation date
                    {` ${index + 1}`}
                    <br />
                    <span className={style.grid_dates_range}>
                      {`${moment(item.startScalationDate).format('YYYY-MM-DD')} - ${moment(
                        item.endScalationDate,
                      ).format('YYYY-MM-DD')}`}
                    </span>
                  </p>
                </>
              ),
              ...getStepsValues(Array.from(wagesGrid?.wageSteps || []), item.id || 0, 0),
            });
            if (wagesGrid.lsrpRate && wagesGrid.lsrpRateValue && item.id) {
              initial.push({
                key: initial.length,
                name: `LSRP Rate: ${wagesGrid?.lsrpRateValue}%`,
                ...getStepsValues(
                  Array.from(wagesGrid?.wageSteps || []),
                  item.id,
                  wagesGrid.lsrpRateValue,
                ),
              });
            }
          });
      }
      return initial;
    }
    return [];
  };

  const onFullList = () => {
    setFullList(!fullList);
  };

  const renderTableHeader = () => {
    const headersTable = headersWage();
    return headersTable.map((item) => (
      <Table.HeaderCell className="markBackTable" key={item.key}>
        {item.name}
      </Table.HeaderCell>
    ));
  };

  const renderTableBody = () => {
    const wageList = getContent();
    return wageList.map((wage) => (
      <Table.Row key={wage.key} textAlign="center">
        <Table.Cell textAlign="left">{wage.name}</Table.Cell>
        {Object.keys(wage)
          .filter((wFilter) => wFilter.includes('STEP'))
          .map((wKey, index) => (
            <Table.Cell key={wKey}>{wage[`STEP ${index + 1}`]}</Table.Cell>
          ))}
      </Table.Row>
    ));
  };

  return (
    <Segment basic padded="very" className={style.wage_grid_container}>
      <Breadcrumbs
        linkTitle="Wage grids"
        activeBreadcrumb={wagesGrid?.name || ''}
        link={'/essentials/wage'}
        title="Wage grids"
      />

      <div className={classnames(style.wagegrid_table_title)}>
        <h3>Wage grid Table</h3>
        {!readOnly && (
          <Button
            basic
            color="blue"
            type="button"
            loading={
              employeeStore.isLoading ||
              supplementaryRateStore.isLoading ||
              wageStore.isLoading ||
              positionStore.isLoading
            }
            onClick={openModalWageGrid}
          >
            EDIT TABLE PROPERTIES
          </Button>
        )}
      </div>

      <br />

      <div className={style.wage_table_container}>
        <Table id={style.wage_grid_table}>
          <Table.Header>
            <Table.Row textAlign="center">{renderTableHeader()}</Table.Row>
          </Table.Header>
          <Table.Body>{renderTableBody()}</Table.Body>
        </Table>
      </div>

      {!readOnly && positions.length > 0 && (
        <WagePositionDropdown
          onLinkWages={linkToWageGrid}
          employees={employees}
          positions={positions}
          wagePositions={positions.filter(
            ({ id }) =>
              wagesGrid?.positions?.length &&
              wagesGrid?.positions.findIndex((position) => position.id === id) >= 0,
          )}
        />
      )}

      {wagesGrid?.positions?.length ? (
        <WageEmployeePanel
          employees={employees}
          positions={positions.filter(
            ({ id }) =>
              wagesGrid?.positions?.length &&
              wagesGrid?.positions.findIndex((position) => position.id === id) >= 0,
          )}
          fullList={fullList}
          onFullList={onFullList}
        />
      ) : undefined}

      <ButtonBar
        onSave={() => {
          if (!wagesGrid) return;
          handleUpdateGrid(wagesGrid);
        }}
        disabled={!(wagesGrid?.wageSteps?.every((step) => step.stepValue > 0) || false)}
        hideSave={readOnly}
        link="/essentials/wage"
      />

      {modalOpen && (
        <WageModal
          onConfirm={(data: IWage) => handleUpdateGrid(data)}
          onCancel={closeNewWageGrid}
          label={'Wages'}
          description={'modalTitle'}
          open={modalOpen}
          dialog={''}
          header="Edit wage grid table properties"
          isEditable
          actionWord={'Save'}
          wageStorex={wagesGrid}
        />
      )}

      {error && (
        <Message
          error
          header="Submission Error"
          list={error}
          onDismiss={() => {
            setError(null);
          }}
          className={style.submit_error_container}
        />
      )}

      {success && (
        <Message
          success
          header="Saved successfully"
          onDismiss={() => {
            setSuccess(false);
          }}
        />
      )}

      <Dimmer active={wageStore.isLoading} inverted>
        <Loader size="large">Loading</Loader>
      </Dimmer>
    </Segment>
  );
});
