import Breadcrumbs from '@/components/Breadcrumbs';
import EmployeeFilterDropdown from '@/components/EmployeeFilterDropdown';
import EssentialTab from '@/components/EssentialTab';
import FilterDropdown from '@/components/FilterDropdown';
import InjectedComponent from '@/components/InjectedComponent';
import { withNavigation, WithNavigationProps } from '@/components/Route';
import mainStyle from '@/less/main.less';
import {
  Dropdown,
  DropdownItemProps,
  Loader,
  Menu,
  Row,
  Segment,
  Tab,
} from '@/semantic-ui/components';
import { WageStore } from '@/store';
import { format, isWithinInterval } from 'date-fns';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Link } from 'react-router';
import {
  DepartmentStore,
  EssentialEmployeeStatus,
  Moment,
  Period,
  Position,
  PositionStore,
  TimesheetStore,
  essentialEmployeeStatuses,
} from 'sol-data';
import style from './index.less';

interface Props extends WithNavigationProps {}

export interface RangeTimesheetDate {
  id: number;
  startDate: Moment;
  endDate: Moment;
}

interface State {
  selectedPeriod?: Period;
  selectedEmployeeId?: number;
  employeeStatuses: EssentialEmployeeStatus[];
}

interface Injected {
  wageStore: WageStore;
  timesheetStore: TimesheetStore;
  positionStore: PositionStore;
  departmentStore: DepartmentStore;
}

const essentialEmployeeStatusOptions: DropdownItemProps[] = essentialEmployeeStatuses.map(
  (status) => {
    let text: string = status;
    switch (status) {
      case 'active':
        text = 'Active';
        break;
      case 'permanentlyInactive':
        text = 'Permanently Inactive';
        break;
      case 'temporarilyInactive':
        text = 'Temporarily Inactive';
        break;
      case 'inactiveQB':
        text = 'Inactive on QuickBooks';
        break;
    }
    return {
      value: status,
      text,
    };
  },
);

@inject('wageStore')
@inject('timesheetStore')
@inject('positionStore')
@inject('departmentStore')
@observer
class Essentials extends InjectedComponent<Props, State, Injected> {
  state: State = {
    selectedPeriod: undefined,
    employeeStatuses: [],
  };

  componentDidMount = async () => {
    await this.fetchData();
  };

  async fetchData() {
    const { positionStore, timesheetStore, departmentStore } = this.injected;
    await Promise.all([
      timesheetStore.fetchPayPeriods(),
      positionStore.fetchPositionsWage(),
      departmentStore.fetchAll(),
    ]);
    const selectedPeriod = this.getSelectedPayPeriod(timesheetStore.payPeriods);
    this.setState({ selectedPeriod });
  }

  getSelectedPayPeriod = (payPeriods: Period[]) => {
    let response: Period | undefined;
    const currentDate = new Date();
    payPeriods.forEach((period) => {
      const { startDate, endDate } = period.props;
      if (isWithinInterval(currentDate, { start: startDate.toDate(), end: endDate.toDate() })) {
        response = period;
      }
    });
    return response;
  };

  getAllPayPeriod = () => {
    const { timesheetStore } = this.injected;
    return timesheetStore.payPeriods.map(({ props: itemTime }) => ({
      key: itemTime.id,
      text: `${format(itemTime.startDate.toDate(), 'LLL d')} - ${format(
        itemTime.endDate.toDate(),
        'LLL d, yyyy',
      )}`,
      value: itemTime.id,
    }));
  };

  getPanes = () => {
    const { positionStore, wageStore, timesheetStore } = this.injected;
    const timesheets = timesheetStore.payPeriods;
    const wagesGrid = wageStore.values;
    const panes = positionStore.values.map((item: Position) => {
      return {
        route: '/',
        menuItem: (
          <Menu.Item as={Link} to="" key={item.id}>
            {item.label}
          </Menu.Item>
        ),
        render: () => (
          <div className={style.tab_container}>
            <EssentialTab
              wageGrids={wagesGrid || []}
              selectedPayPeriod={timesheets.find(
                (itemPay) => itemPay.id === this.state?.selectedPeriod?.id,
              )}
              position={item}
              employeeStatuses={this.state.employeeStatuses}
              employeeId={this.state.selectedEmployeeId}
            />
          </div>
        ),
      };
    });
    return [
      ...panes,
      {
        route: '/',
        menuItem: (
          <Menu.Menu position="right" key="pay-period">
            {this.getPayPeriodSelector()}
          </Menu.Menu>
        ),
        render: () => <></>,
      },
    ];
  };

  getPayPeriodSelector() {
    const { timesheetStore } = this.injected;
    const { selectedPeriod } = this.state;
    const selectedPeriodIndex =
      timesheetStore.payPeriods.findIndex((period) => {
        return period.id === selectedPeriod?.id;
      }) + 1;
    if (timesheetStore.isFetching && !timesheetStore.payPeriods.length) {
      return (
        <Segment>
          <Loader className={style.loader} active />
        </Segment>
      );
    }
    return (
      <Menu.Item className={style.dropdown_payperiod}>
        <p>Pay Period</p>
        <FilterDropdown
          name={'payperiodId'}
          placeholder={'Select a pay period'}
          value={selectedPeriodIndex}
          options={this.getAllPayPeriod()}
          changeDropdown={(_name, value) => {
            const index = value - 1 >= 0 ? value - 1 : 0;
            this.setState({ selectedPeriod: timesheetStore.payPeriods[index] });
          }}
        />
      </Menu.Item>
    );
  }

  render() {
    return (
      <Segment basic padded="very" className={style.body_container}>
        <Breadcrumbs
          tabbed
          linkTitle="Essentials"
          activeBreadcrumb={'Employee Dashboard'}
          link={'/essentials/dashboard'}
          title="Essentials"
        />
        <Row className={style.filter_row}>
          <div className={style.filter_container}>
            <EmployeeFilterDropdown
              search
              clearable
              name="employee"
              placeholder="Search by employee"
              value={this.state.selectedEmployeeId}
              onChange={(_, id) => this.setState({ selectedEmployeeId: id || undefined })}
            />
          </div>
          <div className={style.filter_container}>
            <Dropdown
              multiple
              selection
              fluid
              placeholder="Filter by employee status"
              options={essentialEmployeeStatusOptions}
              value={this.state.employeeStatuses}
              className={mainStyle.dropdowns}
              onChange={(e, { value }) =>
                this.setState({ employeeStatuses: value as EssentialEmployeeStatus[] })
              }
            />
          </div>
        </Row>

        <Tab menu={{ text: true }} panes={this.getPanes()} className={style.tab_menu} />
      </Segment>
    );
  }
}

export default withNavigation(Essentials);
