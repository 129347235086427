import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { DepartmentStore } from '@/store';
import InjectedComponent from '@/components/InjectedComponent';
import FilterDropdown from '@/components/FilterDropdown';
import AppContext from '@/AppContext';

type BaseProps = {
  onChange: (name: string, value: number) => void;
  placeholder?: string;
  name?: string;
  multiple?: boolean;
  allOptions?: boolean;
  defaultValue?: number[];
  clearable?: boolean;
  limited?: boolean;
};

type Props = BaseProps & ({ departmentId: number | undefined } | { departmentIds: number[] });

interface Injected {
  departmentStore: DepartmentStore;
}

@inject('departmentStore')
@observer
class DepartmentFilterDropdown extends InjectedComponent<Props, {}, Injected> {
  static defaultProps: Partial<Props> = {
    multiple: false,
  };

  static contextType = AppContext;

  componentDidMount() {
    const { departmentStore } = this.injected;
    if (departmentStore.isEmpty) departmentStore.fetchAll();
  }

  render() {
    const {
      departmentStore,
      onChange,
      placeholder,
      multiple,
      name,
      allOptions,
      defaultValue,
      clearable,
      limited,
    } = this.injected;

    const {
      scope: { departments: limitedDepartments },
    } = this.context;

    let departmentOptions = departmentStore.dropdownOptionsShort;

    if (allOptions) {
      departmentOptions = [
        {
          key: 0,
          text: 'All Departments',
          value: 0,
        },
      ].concat(departmentStore.dropdownOptions);
    }

    const value = 'departmentId' in this.props ? this.props.departmentId : this.props.departmentIds;

    if (limited && limitedDepartments) {
      departmentOptions = departmentOptions.filter(({ value }) =>
        limitedDepartments.includes(value),
      );
    }

    return (
      <FilterDropdown
        name={name || 'departmentId'}
        placeholder={placeholder || 'Select a department'}
        value={value}
        options={departmentOptions}
        changeDropdown={onChange}
        multiple={multiple}
        defaultValue={defaultValue}
        clearable={clearable}
      />
    );
  }
}

export default DepartmentFilterDropdown;
