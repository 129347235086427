import * as React from 'react';
import * as classnames from 'classnames';
import style from '../index.less';

const NoContentMessage = ({ type = 'content', absolute = false }) => (
  <>
    <div
      className={classnames(style.no_content_message, {
        [style.no_content_message_absolute]: absolute,
      })}
    >
      <h3>{`No ${type} to display`}</h3>
    </div>
    {absolute && <div className={style.no_content_spacer} />}
  </>
);

export default NoContentMessage;
