import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { EntityList } from '@/components/EntityList';
import { ListColumn } from '@/components/ListLayout';
import { DepartmentStore } from '@/store';
import InjectedComponent from '@/components/InjectedComponent';
import AppContext from '@/AppContext';
import { oneLine } from 'common-tags';

interface Injected {
  departmentStore: DepartmentStore;
}

const deleteWarning = oneLine`
  Are you sure you want to delete this department? Doing so will invalidate links to other properties
  (shifts, schedules, requests, etc...) between this department and anything related to it.
`;

@inject('departmentStore')
@observer
class Departments extends InjectedComponent<{}, {}, Injected> {
  componentWillUnmount() {
    const { departmentStore } = this.injected;
    departmentStore.clearFilter();
  }

  render() {
    const { departmentStore } = this.injected;
    const { filtered, errorMessage, showLoading } = departmentStore;

    const columns: ListColumn[] = [
      {
        name: 'ID',
        key: 'id',
        width: { sm: 2, lg: 1 },
        render: ({ id }) => <span>{id.toString().padStart(4, '0')}</span>,
      },
      {
        name: 'Name',
        key: 'label',
        width: { sm: 3, lg: 2 },
        render: (item) => <span>{item.label}</span>,
      },
      {
        name: 'Description',
        key: 'description',
        width: { sm: 6, lg: 2 },
      },
      {
        name: '# Employees',
        key: 'numberOfEmployees',
        width: { sm: 3, lg: 3 },
      },
    ];

    return (
      <AppContext.Consumer>
        {({ isAdmin }) => (
          <EntityList
            title="Department"
            searchLabel="Search by department description"
            columns={columns}
            items={filtered}
            newLink="/organization/departments/add"
            titleProp="description"
            onRemove={isAdmin ? (department) => departmentStore.delete(department) : undefined}
            dialog={deleteWarning}
            onSearchChange={(filter) => departmentStore.setFilter(filter)}
            onDismiss={() => departmentStore.clearError()}
            onRefresh={() => departmentStore.fetchAll()}
            errorMessage={errorMessage}
            isLoading={showLoading}
            link={isAdmin ? (dep) => `/organization/departments/${dep.id}` : undefined}
            noNewButton={!isAdmin}
          />
        )}
      </AppContext.Consumer>
    );
  }
}

export default Departments;
