import InjectedComponent from '@/components/InjectedComponent';
import { Button, Grid, Icon, Modal } from '@/semantic-ui/components';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as moment from 'moment';
import * as React from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { WageStore, WithID, IStaffEmployee, ProbationPeriodType } from 'sol-data';
import style from './index.less';

type BaseProps = {
  onCancel: () => void;
  open: boolean;
  employee: WithID<IStaffEmployee>;
  openEditModal: () => void;
  onSubmit: () => void;
};

type Item = { key?: any; id?: any; [key: string]: any };

interface TableColumn<I extends Item = Item> {
  key: any;
  name?: string;
  render?: (item: I, error?: string) => React.ReactNode;
}

interface ListProps<I extends Item> {
  items: I[];
  columns: TableColumn<I>[];
}

interface Injected {
  wageStore: WageStore;
}

type State = {
  isApproved: boolean;
};

@inject('payrollStore')
@inject('wageStore')
@observer
class ProbationPeriodOverviewModal extends InjectedComponent<BaseProps, {}, Injected> {
  state: State = {
    isApproved: false,
  };

  @computed get columns(): TableColumn<any>[] {
    return [
      {
        name: 'Start Date',
        key: 'startDate',
      },
      {
        name: 'End of Probation',
        key: 'probationEndAt',
      },
      {
        name: 'Hours Worked',
        key: 'hoursWorked',
      },
      {
        name: 'Probation Period',
        key: 'probationPeriod',
      },
    ];
  }

  render() {
    const {
      startDate,
      probationEndAt,
      hoursWorked,
      finalProbationPeriod,
      finalProbationPeriodType,
    } = this.props.employee;

    const { isApproved } = this.state;

    const items = [
      {
        id: 1,
        startDate: startDate?.format('L'),
        probationEndAt: probationEndAt?.format('L') ?? 'N/A',
        hoursWorked: hoursWorked ?? 0,
        probationPeriod: finalProbationPeriod
          ? `${finalProbationPeriod} ${
              finalProbationPeriodType === ProbationPeriodType.Days ? 'days' : 'hours'
            }`
          : 'N/A',
        active: true,
      },
    ];

    const Table = function <I extends Item>({ items, columns }: ListProps<I>) {
      return (
        <>
          <div className={style.list_layout_container}>
            <Grid>
              <Grid.Row className={style.list_layout_header_row}>
                {columns.map(({ key, name }) => (
                  <Grid.Column
                    key={key}
                    tablet={2}
                    computer={4}
                    textAlign={'center'}
                    className={style.list_layout_headers}
                  >
                    {name}
                  </Grid.Column>
                ))}
              </Grid.Row>
            </Grid>

            <Grid>
              {items.map((item) => (
                <Grid.Row key={item.key || item.id} className={style.row}>
                  {columns.map((column) => {
                    const { key } = column;
                    return (
                      <Grid.Column
                        className={`${style.list_layout_row} ${
                          item.active && !isApproved && style.list_layout_row_warning
                        }`}
                        key={`${key}_${item.id || item.key}`}
                        tablet={2}
                        computer={4}
                      >
                        <span>{item[column.key]}</span>
                      </Grid.Column>
                    );
                  })}
                </Grid.Row>
              ))}
            </Grid>
          </div>
        </>
      );
    };

    const ApproveProbation = function ({
      onSubmit,
      onEdit,
    }: {
      onSubmit: () => void;
      onEdit: () => void;
    }) {
      return (
        <Grid.Column width={5} className={style.right_content}>
          <Grid.Row>
            <div>
              <h3>Approve Probation Change</h3>
              <p>Are you sure you want to approve this change?</p>
            </div>
          </Grid.Row>
          <Grid.Row className={style.button_container}>
            <Button color="grey" onClick={onEdit}>
              EDIT
            </Button>
            <Button color="blue" onClick={onSubmit}>
              APPROVE
            </Button>
          </Grid.Row>
        </Grid.Column>
      );
    };

    const ProbationApproved = function ({ onCancel }: { onCancel: () => void }) {
      return (
        <Grid.Column width={5} className={style.right_content}>
          <Grid.Row>
            <div>
              <h3>Approved!</h3>
              <p>Proceed or go back.</p>
            </div>
          </Grid.Row>
          <Grid.Row className={style.button_container}>
            <Button primary onClick={onCancel}>
              GO BACK
            </Button>
            <Button color="blue" onClick={onCancel}>
              PROCEED
            </Button>
          </Grid.Row>
        </Grid.Column>
      );
    };

    const { lastName, firstName } = this.props.employee.user;

    return (
      <Modal
        open={this.props.open}
        onClose={this.props.onCancel}
        size="large"
        className={style.modal}
        closeIcon={<Icon name="close" size="tiny" color="black" />}
      >
        <Modal.Content className={style.modal_content}>
          <Grid>
            <Grid.Row columns={1} className={style.header}>
              <Grid.Column width={12}>
                <div>
                  <p>Probation Period Overview</p>
                  <h3 className={style.header}>{`${lastName}, ${firstName}`}</h3>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2} className={style.row}>
              <Grid.Column width={10}>
                <Grid.Row className={[style.row, style.date].join(' ')}>
                  <p>{moment(new Date()).format('LL')}</p>
                </Grid.Row>
                <Grid.Row className={[style.row, style.table].join(' ')}>
                  <Table columns={this.columns} items={items || []} />
                </Grid.Row>
              </Grid.Column>
              <Grid.Column width={1} />
              {!this.state.isApproved && (
                <ApproveProbation
                  onSubmit={() => {
                    this.props.onSubmit();
                    this.setState({ isApproved: true });
                  }}
                  onEdit={this.props.openEditModal}
                />
              )}
              {this.state.isApproved && <ProbationApproved onCancel={this.props.onCancel} />}
            </Grid.Row>
          </Grid>
        </Modal.Content>
      </Modal>
    );
  }
}

export default ProbationPeriodOverviewModal;
