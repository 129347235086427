import AppContext from '@/AppContext';
import ButtonBar from '@/components/ButtonBar';
import ListLayout, { ListColumn } from '@/components/ListLayout';
import TimesheetEntryModal from '@/components/TimesheetEntryModal';
import style from '@/containers/Timesheets/index.less';
import { useAsyncEffect } from '@/hooks';
import { displayHours } from '@/lib/utils';
import { Column, Row } from '@/semantic-ui/components';
import { useStores } from '@/store';
import { chunk } from 'lodash';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useContext, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router';
import { Button, Form, Grid, Modal, Pagination, Popup } from 'semantic-ui-react';
import {
  AssignmentCost,
  IBaseTimesheetEntry,
  ID,
  TimesheetBasic,
  TimesheetEntry,
  TimesheetStatus,
  toDollar,
  toNum,
} from 'sol-data';
import Bulletin from '../Bulletin';
import DepartmentFilterDropdown from '../DepartmentFilterDropdown';
import EmployeeFilterDropdown from '../EmployeeFilterDropdown';
import ScheduleFilterDropdown from '../ScheduleFilterDropdown';

interface TimesheetViewProps {
  status: TimesheetStatus;
}

interface State {
  modalOpen: boolean;
  costs: {
    [timesheetId: number]: AssignmentCost<TimesheetEntry>;
  };
}

export const TimesheetView = observer(({ status }: TimesheetViewProps) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page');
  const employee = searchParams.get('employee') ? toNum(searchParams.get('employee')!) : undefined;
  const schedule = searchParams.get('schedule') ? toNum(searchParams.get('schedule')!) : undefined;
  const department = searchParams.get('department')
    ? toNum(searchParams.get('department')!)
    : undefined;
  const position = searchParams.get('position') ? toNum(searchParams.get('position')!) : undefined;
  const context = useContext(AppContext);
  const {
    isPayrollAdmin,
    scope: { departments, positions },
  } = context;
  const { timesheetStore, employeeStore, payrollStore } = useStores();

  const [state, setState] = useState<State>({
    costs: {},
    modalOpen: false,
  });

  const fetchData = async (
    pageNumber: number,
    employeeId?: number,
    scheduleId?: number,
    departmentId?: number,
    positionId?: number,
  ) => {
    await timesheetStore.fetchPage(
      { page: pageNumber },
      {
        status,
        employee: employeeId,
        schedule: scheduleId,
        departments: departmentId ? [departmentId] : departments || undefined,
        positions: positionId ? [positionId] : positions || undefined,
        order: status === TimesheetStatus.Pending ? 'asc' : 'desc',
      },
    );

    if (isPayrollAdmin) {
      const timesheetData = timesheetStore.paginatedData?.data ?? [];
      // fetch costs of timesheets in batches of 10
      for (const timesheets of chunk(timesheetData, 10)) {
        const promises = timesheets.map(({ id }) => {
          return payrollStore.fetchTimesheetCost(id, department).then((cost) => {
            setState((prev) => ({
              ...prev,
              costs: {
                ...prev.costs,
                [id]: cost,
              },
            }));
          });
        });

        await Promise.all(promises).catch(() => {});
      }
    }
  };

  useAsyncEffect(async () => {
    await changePage(page ? Number(page) : 1, employee, schedule, department, position);

    if (payrollStore.categories.isEmpty && isPayrollAdmin) {
      await payrollStore.fetchAll();
    }

    return () => {
      employeeStore.clearFilter();
    };
  }, []);

  const changePage = async (
    pageNumber: number,
    employeeId?: number,
    scheduleId?: number,
    departmentId?: number,
    positionId?: number,
    dontFetchData?: boolean,
  ) => {
    if (!pageNumber || Number.isNaN(pageNumber)) return;

    searchParams.append('page', pageNumber.toString());
    setSearchParams(searchParams);

    if (!dontFetchData) {
      await fetchData(pageNumber, employeeId, scheduleId, departmentId, positionId);
    }
  };

  const changeDropdown = async (type: 'employee' | 'schedule' | 'department', id?: ID) => {
    const value = id ? id.toString() : undefined;

    switch (type) {
      case 'employee':
        if (value) {
          searchParams.append('employee', value);
          searchParams.delete('page');
        } else {
          searchParams.delete('employee');
        }

        setSearchParams(searchParams);

        return fetchData(1, value ? toNum(value) : undefined, schedule, department, position);
      case 'department':
        if (value) {
          searchParams.append('department', value);
          searchParams.delete('page');
        } else {
          searchParams.delete('department');
        }

        setSearchParams(searchParams);

        return fetchData(1, employee, schedule, value ? toNum(value) : undefined, position);
      case 'schedule':
        if (value) {
          searchParams.append('schedule', value);
          searchParams.delete('page');
        } else {
          searchParams.delete('schedule');
        }

        setSearchParams(searchParams);

        return fetchData(1, employee, value ? toNum(value) : undefined, department, position);
      default:
        break;
    }
  };

  const handleModalSubmit = async (values: IBaseTimesheetEntry, employeeId: ID) => {
    const [, timesheet] = await timesheetStore.create({ ...values, employee: employeeId });
    setState({ ...state, modalOpen: false });
    navigate(`/timesheets/details/${timesheet.id}`);
  };

  const getContent = (id: number, value?: number) => {
    const error = payrollStore.getError(`timesheetCost:${id}`);

    if (error) {
      return '?';
    }
    if (value) {
      return displayHours(value);
    }
    return '-';
  };

  const columns: ListColumn<TimesheetBasic['Props']>[] = [
    {
      name: 'ID',
      key: 'id',
      width: 1,
      textAlign: 'left',
      render: (item) => <span>{item.id.toString().padStart(4, '0')}</span>,
    },
    {
      name: 'Employee',
      key: 'employee',
      width: 3,
      textAlign: 'center',
      render: ({ employeeName }) => {
        return <span className={style.timesheet_subcell}>{employeeName}</span>;
      },
    },
    {
      name: 'Date',
      key: 'date',
      width: 2,
      textAlign: 'center',
      render: (item) => {
        const endDate =
          item.startDate.month() !== item.endDate.month()
            ? item.endDate.format('MMM D YYYY')
            : item.endDate.format('D YYYY');

        return (
          <span className={style.timesheet_subcell}>
            {item.startDate.format('MMM D')} -{endDate}
          </span>
        );
      },
    },
    {
      name: 'Regular',
      key: 'regular',
      width: 2,
      textAlign: 'center',
      render: ({ id }) => {
        const content = getContent(
          id,
          state.costs[id] && payrollStore.categories.values.find(({ isRegular }) => isRegular)
            ? state.costs[id].minutesOfCategory(
                payrollStore.categories.values.find(({ isRegular }) => isRegular)!.id,
                payrollStore.items,
              )
            : undefined,
        );

        return <span className={style.timesheet_subcell}>{content}</span>;
      },
    },
    {
      name: 'Overtime',
      key: 'overtime',
      width: 2,
      textAlign: 'center',
      render: ({ id }) => {
        const content = getContent(
          id,
          state.costs[id] && payrollStore.categories.values.find(({ isOvertime }) => isOvertime)
            ? state.costs[id].minutesOfCategory(
                payrollStore.categories.values.find(({ isOvertime }) => isOvertime)!.id,
                payrollStore.items,
              )
            : undefined,
        );
        return <span className={style.timesheet_subcell}>{content}</span>;
      },
    },
    {
      name: 'Other',
      key: 'other',
      width: 2,
      textAlign: 'center',
      render: ({ id, totalMinutes }) => {
        const otherMinutes =
          state.costs[id] &&
          payrollStore.categories.values.find(({ isRegular }) => isRegular) &&
          payrollStore.categories.values.find(({ isOvertime }) => isOvertime) &&
          totalMinutes -
            state.costs[id].minutesOfCategory(
              payrollStore.categories.values.find(({ isRegular }) => isRegular)!.id,
              payrollStore.items,
            ) -
            state.costs[id].minutesOfCategory(
              payrollStore.categories.values.find(({ isOvertime }) => isOvertime)!.id,
              payrollStore.items,
            );

        const content = getContent(id, otherMinutes !== undefined ? otherMinutes : undefined);
        return <span className={style.timesheet_subcell}>{content}</span>;
      },
    },
    {
      name: 'Total Hours',
      key: 'totalHours',
      width: 2,
      textAlign: 'center',
      render: ({ totalMinutes }) => (
        <span className={style.timesheet_subcell}>{displayHours(totalMinutes)}</span>
      ),
    },
    ...(isPayrollAdmin
      ? [
          {
            name: 'Cost of Labour',
            key: 'cost',
            width: 2,
            textAlign: 'center',
            render: ({ id }: { id: number }) => {
              const error = payrollStore.getError(`timesheetCost:${id}`);

              let content;
              if (error) {
                content = (
                  <Popup
                    wide="very"
                    content={<Bulletin error={error} />}
                    trigger={<span className={style.error_class}>ERROR</span>}
                  />
                );
              } else if (state.costs[id] && state.costs[id].cost.total) {
                content = toDollar(state.costs[id].cost.total!);
              } else {
                content = '-';
              }

              return <span className={style.timesheet_subcell}>{content}</span>;
            },
          } as const,
        ]
      : []),
  ];

  const headerProps = {
    errorMessage: timesheetStore.errorMessage,
    isLoading: timesheetStore.isLoading,
    onDismiss: timesheetStore.clearError,
    searchHeaderContent: [
      <Row key={0}>
        <Column width={4}>
          <Form>
            <Form.Field>
              <EmployeeFilterDropdown
                search
                clearable
                name="employee"
                placeholder="Search by employee"
                value={employee ? toNum(employee) : undefined}
                onChange={(_, id) => changeDropdown('employee', id)}
              />
            </Form.Field>
          </Form>
        </Column>
        <Column width={5} />
        <Column width={4}>
          {status === TimesheetStatus.Pending && (
            <Form>
              <Form.Field>
                <ScheduleFilterDropdown
                  search
                  clearable
                  name="schedule"
                  placeholder="Filter by schedule"
                  value={schedule ? toNum(schedule) : undefined}
                  onChange={(_, id) => changeDropdown('schedule', id)}
                />
              </Form.Field>
            </Form>
          )}
        </Column>
        <Column width={3}>
          <Form>
            <Form.Field>
              <DepartmentFilterDropdown
                onChange={(_, value) => changeDropdown('department', value)}
                departmentId={department ? toNum(department) : undefined}
                limited
                clearable
              />
            </Form.Field>
          </Form>
        </Column>
      </Row>,
    ],
  };

  return (
    <>
      <ListLayout
        columns={columns}
        items={(timesheetStore.paginatedData?.data ?? []).map((itm) => itm.props)}
        headerProps={headerProps}
        link={(item) => `/timesheets/details/${item.id}`}
      />
      <Grid centered>
        <Grid.Row>
          <Pagination
            activePage={page ? parseFloat(page) : 1}
            totalPages={timesheetStore.paginatedData?.meta.totalPages ?? 0}
            onPageChange={(_, { activePage }) =>
              changePage(Number(activePage), employee, schedule, department, position)
            }
            siblingRange={2}
            boundaryRange={0}
            firstItem={{ content: 'First' }}
            lastItem={{ content: 'Last' }}
            ellipsisItem={null}
            prevItem={null}
            nextItem={null}
          />
          {status === TimesheetStatus.Pending && (
            <>
              <ButtonBar>
                <Button
                  color="blue"
                  type="button"
                  onClick={() => setState({ ...state, modalOpen: true })}
                >
                  Add new entry
                </Button>
              </ButtonBar>
              <Modal
                open={state.modalOpen}
                size="mini"
                onClose={() => setState({ ...state, modalOpen: false })}
              >
                <TimesheetEntryModal
                  onSubmit={handleModalSubmit}
                  onCancel={() => setState({ ...state, modalOpen: false })}
                />
              </Modal>
            </>
          )}
        </Grid.Row>
      </Grid>
    </>
  );
});
