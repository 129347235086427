import * as React from 'react';
import { Dropdown } from 'semantic-ui-react';
import { debounce } from 'lodash';
import style from '@/less/main.less';

interface Props {
  name: string;
  placeholder: string;
  value?: number | number[];
  multiple?: boolean;
  options?: {
    key?: string | number;
    text: string;
    value: number;
  }[];
  changeDropdown: (name: string, value: number) => void;
  onSearchChange?: (arg: string) => void;
  searchDebounceTimeMs?: number;
  defaultValue?: number[];
  disabled?: boolean;
  search?: boolean;
  clearable?: boolean;
}

const FilterDropdown: React.FC<Props> = ({
  name,
  placeholder,
  value: valueId,
  options,
  changeDropdown,
  multiple,
  defaultValue,
  disabled,
  search,
  clearable,
  onSearchChange,
  searchDebounceTimeMs,
}) => {
  const updateSearch = debounce(
    (searchValue: string) => {
      onSearchChange?.(searchValue);
    },
    searchDebounceTimeMs || 500,
    {},
  );
  return (
    <Dropdown
      fluid
      selection
      multiple={multiple}
      name={name}
      placeholder={placeholder}
      // The null as any type is required for removing filters to render no selections
      value={valueId || (null as any)}
      options={options}
      defaultValue={defaultValue}
      onChange={(e, { value }) => changeDropdown(name, value as number)}
      className={style.dropdowns}
      disabled={disabled}
      search={search}
      clearable={clearable}
      onSearchChange={(_, { searchQuery }) => {
        updateSearch(searchQuery);
      }}
    />
  );
};

FilterDropdown.defaultProps = {
  value: undefined,
  options: [],
  multiple: false,
};

export default FilterDropdown;
