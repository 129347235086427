import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { EmployeeStore, DepartmentStore, AreaStore, PositionStore } from '@/store';
import { Authority } from 'sol-data';
import { Menu, Segment, Loader } from '@/semantic-ui/components';
import { withNavigation, WithNavigationProps } from '@/components/Route';
import { Link } from 'react-router';
import TabLayout, { TabLayoutPane } from '@/components/TabLayout';
import InjectedComponent from '@/components/InjectedComponent';
import { EmployeeProfile as Profile } from '@/components/EmployeeProfile';
import { EmployeeRequests } from '@/components/EmployeeRequests';
import { EmployeeEducationDocuments } from '@/components/EmployeeEducationDocuments';
import { RequestPasswordReset } from '@/components/RequestPasswordReset';

interface Props extends WithNavigationProps {
  employeeId: string;
}

interface Injected {
  employeeId: string;
  employeeStore: EmployeeStore;
  departmentStore: DepartmentStore;
  areaStore: AreaStore;
  positionStore: PositionStore;
}

@inject('employeeStore', 'departmentStore', 'areaStore', 'positionStore')
@observer
class EmployeeProfile extends InjectedComponent<Props, {}, Injected> {
  componentDidMount() {
    const { employeeStore, departmentStore, areaStore, positionStore } = this.injected;
    const { params } = this.props;
    const { employeeId } = params;

    departmentStore.fetchAll();
    areaStore.fetchAll();
    positionStore.fetchAll();
    employeeStore.fetch(employeeId!).then((employee) => employeeStore.setCurrentDetail(employee));
  }

  componentWillUnmount() {
    const { employeeStore } = this.injected;

    employeeStore.setCurrentDetail();
  }

  render() {
    const {
      employeeStore: { isLoading, currentDetail: employee },
    } = this.injected;
    const { path, params } = this.props;
    const { employeeId } = params;

    if (isLoading || !employee) {
      return (
        <Segment basic>
          <Loader />
        </Segment>
      );
    }

    const fullName = `${employee?.user.firstName} ${employee?.user.lastName}`;

    const panes: TabLayoutPane[] = [
      {
        route: 'profile',
        menuItem: (
          <Menu.Item as={Link} to={`/employees/${employeeId}/profile`} key="profile">
            Profile
          </Menu.Item>
        ),
        render: () => <Profile path="/employees/:employeeId/profile" key="employee_profile" />,
      },
      {
        route: 'requests',
        menuItem: (
          <Menu.Item as={Link} to={`/employees/${employeeId}/requests`} key="requests">
            Requests
          </Menu.Item>
        ),
        render: () => <EmployeeRequests key="employee_request_history" />,
        access: Authority.Approver,
      },
      {
        route: 'education',
        menuItem: (
          <Menu.Item as={Link} to={`/employees/${employeeId}/education`} key="education">
            Education
          </Menu.Item>
        ),
        render: () => <EmployeeEducationDocuments key="employee_education_documents" />,
        access: Authority.Approver,
      },
      {
        route: 'password-reset',
        menuItem: (
          <Menu.Item as={Link} to={`/employees/${employeeId}/password-reset`} key="password-reset">
            Password Reset
          </Menu.Item>
        ),
        access: Authority.Super,
        render: () => <RequestPasswordReset />,
      },
    ];

    return (
      <TabLayout
        link="/employees"
        path={path}
        panes={panes}
        linkTitle="Employee Central"
        activeBreadcrumb={fullName}
      />
    );
  }
}

export default withNavigation<{ employeeId: string }>(EmployeeProfile);
