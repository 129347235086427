import * as React from 'react';
import * as Cleave from 'cleave.js/react';
import { FieldArray } from 'formik';
import { Button, Dropdown, Grid } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InjectedComponent from '../InjectedComponent';
import style from './index.less';
import { EmployeeContainerTabProps } from '../EmployeeInformationModal/utils';

const phoneTypes = [
  {
    key: 'mobile',
    text: 'Mobile',
    value: 'mobile',
  },
  {
    key: 'home',
    text: 'Home',
    value: 'home',
  },
];

export class EmployeePhoneNumbersContainer extends InjectedComponent<
  EmployeeContainerTabProps,
  {},
  {}
> {
  render() {
    const {
      formProps: { values: formValues, errors: formErrors, setFieldValue },
      readOnly,
    } = this.props;
    const values = formValues.profile;
    const errors = formErrors.profile ?? {};

    return (
      <FieldArray name="profile.phoneNumbers">
        {({ remove, push }) => (
          <div className={style.phone_number_container}>
            <div className={style.phone_container}>
              <label className={style.form_headers}>Phone Numbers</label>
              {!values.phoneNumbers.length && (
                <Button
                  circular
                  disabled={readOnly}
                  size="mini"
                  type="button"
                  color="blue"
                  icon={<FontAwesomeIcon icon={['far', 'plus']} />}
                  onClick={() => push({ type: 'home', phoneNumber: '' })}
                />
              )}
            </div>
            <div className={style.phone_body}>
              {values.phoneNumbers.map((phoneObj, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={index} className={style.phone_inputs}>
                  <div className={style.field_item}>
                    <Dropdown
                      name="type"
                      value={phoneObj.type}
                      selection
                      disabled={readOnly}
                      options={phoneTypes}
                      onChange={(_, { value }) =>
                        setFieldValue(`profile.phoneNumbers[${index}].type`, value)
                      }
                      className={style.dropdowns}
                    />
                    {errors.phoneNumbers && errors.phoneNumbers[index]?.type && (
                      <p className={style.error_class}>{errors.phoneNumbers[index]?.type}</p>
                    )}
                  </div>
                  <div className={style.field_item}>
                    <Cleave
                      options={{
                        numericOnly: true,
                        blocks: [0, 3, 3, 4],
                        delimiters: ['(', ') ', '-'],
                      }}
                      name="phoneNumber"
                      disabled={readOnly}
                      value={phoneObj.phoneNumber}
                      placeholder="(123) 456-7890"
                      className={style.inputs}
                      onChange={({ target: { value } }) =>
                        setFieldValue(`profile.phoneNumbers[${index}].phoneNumber`, value)
                      }
                    />
                    {errors.phoneNumbers && errors.phoneNumbers[index]?.phoneNumber && (
                      <p className={style.error_class}>{errors.phoneNumbers[index]?.phoneNumber}</p>
                    )}
                  </div>
                  <Grid.Column computer={2} tablet={3} className={style.profile_form_remove_number}>
                    <Button
                      circular
                      size="mini"
                      type="button"
                      color="red"
                      disabled={readOnly}
                      icon="minus"
                      onClick={() => remove(index)}
                    />
                    {index === values.phoneNumbers.length - 1 && (
                      <Button
                        circular
                        size="mini"
                        type="button"
                        color="blue"
                        disabled={readOnly}
                        className={style.profile_delete_button}
                        icon={<FontAwesomeIcon icon={['far', 'plus']} />}
                        onClick={() => push({ type: 'home', phoneNumber: '' })}
                      />
                    )}
                  </Grid.Column>
                </div>
              ))}
            </div>
          </div>
        )}
      </FieldArray>
    );
  }
}
