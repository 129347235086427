import * as React from 'react';
import InjectedComponent from '@/components/InjectedComponent';
import { inject, observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Segment, Dropdown, Grid, Button } from '@/semantic-ui/components';
import classnames from 'classnames';
import { AreaStore, PositionStore, DepartmentStore, ShiftPlanStore } from '@/store';
import { ShiftPlan, Area, ID, toID, toNum } from 'sol-data';
import ListLayout, { ListColumn } from '@/components/ListLayout';
import Breadcrumbs from '@/components/Breadcrumbs';
import DayPicker from '@/components/DayPicker';
import { ScheduleCreateModal } from '@/components/ScheduleCreateModal';
import ShiftPlanModal from '@/components/ShiftPlanModal';
import NoContentMessage from '@/components/Schedule/NoContentMessage';
import style from './index.less';

const { Column, Row } = Grid;

interface State {
  planModalOpen: boolean;
  generateModalOpen: boolean;
  editModalOpen: boolean;
  selectedAreaId?: ID;
  planId?: ID;
}

interface Injected {
  areaStore: AreaStore;
  positionStore: PositionStore;
  departmentStore: DepartmentStore;
  shiftPlanStore: ShiftPlanStore;
}

@inject('areaStore', 'departmentStore', 'positionStore', 'shiftPlanStore')
@observer
class ShiftPlanning extends InjectedComponent<{}, State, Injected> {
  state: State = {
    planModalOpen: false,
    generateModalOpen: false,
    editModalOpen: false,
  };

  async componentDidMount() {
    const { departmentStore, areaStore, positionStore, shiftPlanStore } = this.injected;

    await Promise.all([
      departmentStore.fetchAll(),
      areaStore.fetchAll(),
      positionStore.fetchAll(),
      shiftPlanStore.fetchAll(),
    ]);

    // select a default department
    if (!departmentStore.isEmpty) {
      const { values } = departmentStore;
      departmentStore.setCurrent(values[0].id);
    }
  }

  changeDepartments = async (id: ID) => {
    const { departmentStore } = this.injected;

    departmentStore.setCurrent(id);
  };

  handleDismiss = () => {
    const { shiftPlanStore, departmentStore } = this.injected;

    shiftPlanStore.clearError();
    departmentStore.clearCurrent();
  };

  handleRemove = (item: ID) => async () => {
    const { shiftPlanStore } = this.injected;

    await shiftPlanStore.delete(item);
  };

  togglePlanModal = (selectedAreaId?: ID, planId?: ID) => () => {
    this.setState(({ planModalOpen }) => ({
      selectedAreaId,
      planId,
      planModalOpen: !planModalOpen,
    }));
  };

  toggleGenerateModal = () => {
    this.setState(({ generateModalOpen }) => ({
      generateModalOpen: !generateModalOpen,
    }));
  };

  renderTimes = (plan: ShiftPlan) => {
    const time = plan.isWholeDay ? '00:00 - 23:59' : `${plan.startTime} - ${plan.endTime}`;

    return (
      <div
        key={plan.id}
        className={classnames(style.planning_modal_inner_row, style.clickable)}
        onClick={this.togglePlanModal(plan.area, plan)}
      >
        <p>{time}</p>
      </div>
    );
  };

  renderPositions = (plan: ShiftPlan) => (
    <div
      key={plan.id}
      className={classnames(style.planning_modal_inner_row, style.clickable)}
      onClick={this.togglePlanModal(plan.area, plan)}
    >
      <p>
        {plan.shiftPlanPositions
          .map(
            ({ quantity, position }) =>
              `${quantity} x ${this.injected.positionStore.forceGet(position).label}`,
          )
          .join('\n')}
      </p>
    </div>
  );

  renderFooter = (area: ID) => (
    <Button basic color="blue" type="button" onClick={this.togglePlanModal(area)} key={toID(area)}>
      Add Operating Hours
    </Button>
  );

  render() {
    const { departmentStore, shiftPlanStore, areaStore } = this.injected;

    const { planModalOpen, generateModalOpen, selectedAreaId, planId } = this.state;

    const { current: department } = departmentStore;

    const { errorMessage, isLoading } = shiftPlanStore;

    let selectedAreaDescription: string | undefined;
    // ! because ts 3.5 is not able to analyze the &&
    if (selectedAreaId && areaStore.has(selectedAreaId)) {
      selectedAreaDescription = areaStore.get(selectedAreaId)!.label;
    }

    const columns: ListColumn<Area>[] = [
      {
        name: 'Area',
        key: 'area',
        width: 2,
        render: (area) => {
          const items = shiftPlansForArea(area);

          return Array.from(Array(Math.max(1, items.length))).map((_, idx) => {
            if (idx === 0) {
              return (
                <div key={idx} className={style.planning_modal_inner_row}>
                  <p className={style.form_headers}>{area.label}</p>
                </div>
              );
            }

            return <div key={idx} className={style.planning_modal_inner_row} />;
          });
        },
      },
      {
        name: 'Hours',
        key: 'hours',
        width: { sm: 3, lg: 3 },
        render: (area) => {
          return shiftPlansForArea(area).map(this.renderTimes);
        },
      },
      {
        name: 'Days',
        key: 'days',
        width: { sm: 8, lg: 7 },
        render: (area) => {
          return shiftPlansForArea(area).map((plan) => (
            <div
              key={plan.id}
              className={classnames(style.planning_modal_inner_row, style.clickable)}
              onClick={this.togglePlanModal(plan.area, plan)}
            >
              <DayPicker disabled length={1} values={plan.days} />
            </div>
          ));
        },
      },
      {
        name: 'Positions',
        key: 'positions',
        width: { sm: 3, lg: 4 },
        render: (area) => {
          return shiftPlansForArea(area).map(this.renderPositions);
        },
      },
      {
        name: '',
        key: 'remove',
        width: 1,
        className: 'list_layout_delete_column',
        render: (area) => {
          return shiftPlansForArea(area).map((plan) => {
            const remove = this.handleRemove(plan);

            return (
              <div
                role="button"
                tabIndex={-1}
                onClick={remove}
                onKeyPress={remove}
                className={style.planning_modal_inner_row}
                key={plan.id}
              >
                <FontAwesomeIcon icon={['far', 'trash']} color="#415167" />
              </div>
            );
          });
        },
      },
    ];

    if (!department) {
      return (
        <Segment basic padded="very">
          <Breadcrumbs title="Shift Planning" activeBreadcrumb="Shift Planning" />

          <ListLayout
            columns={columns}
            items={[]}
            headerProps={{ errorMessage, isLoading, onDismiss: shiftPlanStore.clearError }}
          />
        </Segment>
      );
    }

    const areas = areaStore.getAreasForDepartment(department);
    const shiftPlans = shiftPlanStore.getShiftPlansForDepartment(department);

    const shiftPlansForArea = (area: ID) =>
      shiftPlans.filter(({ area: { id } }) => id === toID(area));

    return (
      <>
        <Segment basic padded="very">
          <Breadcrumbs title="Shift Planning" activeBreadcrumb="Shift Planning" />

          <Grid>
            <Row>
              <Column width={6}>
                <Dropdown
                  fluid
                  selection
                  placeholder="Department:"
                  name="departments"
                  options={departmentStore.dropdownOptions}
                  onChange={(_, { value }) => this.changeDepartments(value as number)}
                  value={department.id}
                />
              </Column>
              <Column computer={7} tablet={5} />
              <Column computer={3} tablet={5}>
                <Button color="yellow" type="button" onClick={this.toggleGenerateModal} fluid>
                  Generate Schedule
                </Button>
              </Column>
            </Row>
          </Grid>
          <ListLayout
            columns={columns}
            headerProps={{ errorMessage, isLoading, onDismiss: shiftPlanStore.clearError }}
            items={areas}
            renderFooter={this.renderFooter}
            deleteTopOffset
          />
          {!areas.length && <NoContentMessage type="areas" />}
        </Segment>

        {selectedAreaId && (
          <ShiftPlanModal
            areaId={toNum(selectedAreaId)}
            departmentId={department.id}
            onCancel={this.togglePlanModal()}
            planId={planId ? toNum(planId) : undefined}
            open={planModalOpen}
            label={selectedAreaDescription || 'Unknown'}
          />
        )}

        <ScheduleCreateModal open={generateModalOpen} onCancel={this.toggleGenerateModal} />
      </>
    );
  }
}

export default ShiftPlanning;
