import * as React from 'react';
import TimePicker from '@/components/TimePicker';
import { Grid } from '@/semantic-ui/components';

export default ({
  startTime,
  endTime,
  onChange,
  showStartError,
  showEndError,
}: {
  startTime?: string;
  endTime?: string;
  onChange: (name: 'startTime' | 'endTime', value: string) => void;
  showStartError: boolean;
  showEndError: boolean;
}) => (
  <Grid>
    <p>Time</p>

    <Grid.Row columns={2}>
      <Grid.Column>
        <TimePicker
          name="startTime"
          value={startTime}
          onChange={onChange}
          showError={showStartError}
        />
      </Grid.Column>

      <Grid.Column>
        <TimePicker name="endTime" value={endTime} onChange={onChange} showError={showEndError} />
      </Grid.Column>
    </Grid.Row>
  </Grid>
);
