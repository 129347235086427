import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { WithID, IShift, IShiftGroup } from 'sol-data';
import { ScheduleStore, EmployeeStore } from '@/store';
import { Dropdown, Segment, Grid, Form } from 'semantic-ui-react';
import { Formik, ErrorMessage } from 'formik';
import { object, number } from 'yup';
import ModalBase from '@/components/ModalBase';
import Bulletin from '@/components/Bulletin';
import style from '@/less/main.less';

interface Props {
  shift: WithID<IShift>;
  shiftGroup?: WithID<IShiftGroup>;
  onSubmit: (shiftGroupId: number) => void;
  onCancel?: () => void;
}

interface Injected {
  scheduleStore: ScheduleStore;
  employeeStore: EmployeeStore;
}

const MoveShiftModal: React.FC<Props & Injected> = ({
  shift,
  shiftGroup,
  onSubmit,
  onCancel,
  scheduleStore,
  employeeStore,
}) => (
  <>
    <Segment>
      <Bulletin
        isLoading={scheduleStore.isLoading}
        errorMessage={scheduleStore.errorMessage}
        handleDismiss={scheduleStore.clearError}
      />
    </Segment>
    <Formik
      validationSchema={object().shape({
        shiftGroup: number().required('Shift Group is required'),
      })}
      initialValues={{
        shiftGroup: shiftGroup?.id,
      }}
      onSubmit={(submitted) => {
        onSubmit(submitted.shiftGroup!);
      }}
      render={({ handleSubmit, setFieldValue }) => {
        const { current: schedule } = scheduleStore;
        if (!schedule || !schedule.shiftGroups) return null;

        const options = schedule.shiftGroups
          .filter(({ position }) => !shiftGroup || position.id === shiftGroup.position.id)
          .map(({ id, employee, label }) => ({
            value: id,
            text:
              employee && employeeStore.has(employee)
                ? employeeStore.get(employee)!.user.fullName
                : label,
          }));

        return (
          <ModalBase
            header="Move Shift"
            actionWord="move"
            onCancel={onCancel}
            handleSubmit={handleSubmit}
          >
            <Form.Field>
              <Dropdown
                fluid
                selection
                search
                placeholder="Move To"
                options={options}
                onChange={(_, { value }) => setFieldValue('shiftGroup', value)}
              />
            </Form.Field>
            <ErrorMessage
              name="shiftGroup"
              render={(err) => <span className={style.error_class}>{err}</span>}
            />
          </ModalBase>
        );
      }}
    />
  </>
);

export default inject<Injected>(({ scheduleStore, employeeStore }) => ({
  scheduleStore,
  employeeStore,
}))(observer(MoveShiftModal));
