import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Moment } from 'sol-data';
import { ScheduleStore, ScheduleComponentStore } from '@/store';
import InjectedComponent from '@/components/InjectedComponent';
import classnames from 'classnames';
import WeekLabel from '../WeekLabel';
import DayBlock from '../DayBlock';
import { rowCellRatio } from '../utils';

import style from '../index.less';

const WeeksList: React.FC<{ weeks: Moment[] }> = ({ weeks }) => (
  <>
    {weeks.map((week, i) => (
      <WeekLabel week={week} key={i} />
    ))}
  </>
);

const DaysList: React.FC<{ days: Moment[] }> = ({ days }) => (
  <>
    {days.map((day, i) => (
      <DayBlock day={day} key={i} />
    ))}
  </>
);

interface Injected {
  scheduleStore: ScheduleStore;
  scheduleComponentStore: ScheduleComponentStore;
}

@inject('scheduleStore', 'scheduleComponentStore')
@observer
class ScheduleLayout extends InjectedComponent<{}, {}, Injected> {
  render() {
    const { children } = this.props;
    const {
      scheduleStore,
      scheduleComponentStore: {
        scrollLeft,
        showStickyHeader,
        cellDimens: { height: heightDimen },
      },
    } = this.injected;
    const { current: schedule } = scheduleStore;

    if (!schedule) return null;

    const { dayDates, weekDates } = schedule;

    return (
      <>
        <div
          className={classnames(style.schedule_time_row, {
            [style.schedule_time_row_fixed]: showStickyHeader,
          })}
          style={showStickyHeader ? { transform: `translateX(${-scrollLeft}px)` } : undefined}
        >
          <div className={style.schedule_label_row}>
            <WeeksList weeks={weekDates} />
          </div>
          <div className={style.schedule_header_row}>
            <DaysList days={dayDates} />
          </div>
        </div>
        <div style={showStickyHeader ? { height: `${heightDimen * rowCellRatio}px` } : undefined} />
        {children}
      </>
    );
  }
}

export default ScheduleLayout;
