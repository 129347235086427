import Breadcrumbs from '@/components/Breadcrumbs';
import { BidHistoryModal } from '@/components/Schedule/BidHistoryModal';
import { useAsyncEffect } from '@/hooks';
import { useStores } from '@/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { Link, useParams } from 'react-router';
import { Card, CardGroup, Segment, Table } from 'semantic-ui-react';
import { Employee, ID, OpenShiftAlert, Schedule, ScheduleStatus, Shift, toNum } from 'sol-data';
import style from './bid-history.less';

const DATE_FORMAT = 'MMM D';

const getScheduleLink = (schedule: Schedule | undefined) => {
  if (!schedule) {
    return undefined;
  }

  const status = schedule.status === ScheduleStatus.Pending ? 'unpublished' : 'published';

  return `/scheduling/${status}/${schedule.id}`;
};

const getScheduleLinkTitle = (schedule: Schedule | undefined) => {
  if (!schedule) {
    return undefined;
  }

  const description = schedule.description ?? 'Untitled Schedule';
  const title = `${description} - ${schedule.startDate.format(
    DATE_FORMAT,
  )} - ${schedule.endDate.format(DATE_FORMAT)}`;

  return title;
};

export const BidHistory = observer(() => {
  const { shiftStore, scheduleStore, areaStore, positionStore, alertStore, employeeStore } =
    useStores();
  const { shiftId: shiftIdValue, scheduleId: scheduleIdValue } = useParams<{
    shiftId: string;
    scheduleId: string;
  }>();
  const shiftId = shiftIdValue ? toNum(shiftIdValue) : undefined;
  const scheduleId = scheduleIdValue ? toNum(scheduleIdValue) : undefined;
  const [shift, setShift] = useState<Shift | undefined>(undefined);
  const [employee, setEmployee] = useState<Employee>(undefined);
  const [openShiftAlerts, setOpenShiftAlerts] = useState<OpenShiftAlert[] | undefined>(undefined);
  const [selectedOpenShiftAlertId, setSelectedOpenShiftAlertId] = useState<
    ID<OpenShiftAlert> | undefined
  >(undefined);
  const schedule = scheduleId ? scheduleStore.get(scheduleId) : undefined;
  const area = shift?.props.area.id ? areaStore.get(shift?.props.area.id) : undefined;
  const position = shift?.props.position?.id
    ? positionStore.get(shift?.props.position.id)
    : undefined;
  const foundOpenShiftAlert = openShiftAlerts?.find(({ id }) => id === selectedOpenShiftAlertId);

  useAsyncEffect(async () => {
    const [shiftValue, _, openShiftAlertValues] = await Promise.all([
      shiftId ? shiftStore.fetchShift(shiftId) : undefined,
      areaStore.fetchAll(),
      shiftId ? alertStore.fetchOpenShiftsByShiftId(shiftId) : undefined,
      scheduleId && scheduleStore.fetch(scheduleId),
      shift?.props.position?.id && positionStore.fetch(shift?.props.position?.id),
    ]);

    const employeeId = shiftValue?.props.employee?.id;

    if (employeeId) {
      const employeeValue = await employeeStore.fetch(employeeId);
      setEmployee(employeeValue);
    }

    setShift(shiftValue);
    setOpenShiftAlerts(openShiftAlertValues);
  }, [shiftId, shift?.props.position?.id, scheduleId]);

  return (
    <>
      <Segment basic padded="very">
        <Breadcrumbs
          activeBreadcrumb={
            schedule && shift
              ? `Bid History For ${shift?.props.startDate.format('MMM DD, YYYY')}`
              : 'Loading...'
          }
          link={getScheduleLink(schedule)}
          linkTitle={getScheduleLinkTitle(schedule)}
        />

        {shift && (
          <CardGroup itemsPerRow={5}>
            <Card>
              <Card.Content>
                <Card.Header>Assigned Employee</Card.Header>
                <Card.Description>
                  <Link
                    // @ts-ignore
                    to={`/employees/${employee?.id}/profile`}
                    style={{ color: '#1E6FA9' }}
                  >
                    {/* @ts-ignore */}
                    {employee?.user?.firstName} {employee?.user?.lastName}
                  </Link>
                </Card.Description>
              </Card.Content>
            </Card>

            <Card>
              <Card.Content>
                <Card.Header>Shift Start Time</Card.Header>
                <Card.Description>
                  {shift.props.startDate.format('MMM DD, YYYY [at] HH:mm')}
                </Card.Description>
              </Card.Content>
            </Card>

            <Card>
              <Card.Content>
                <Card.Header>Shift End Time</Card.Header>
                <Card.Description>
                  {shift.props.endDate.format('MMM DD, YYYY [at] HH:mm')}
                </Card.Description>
              </Card.Content>
            </Card>

            <Card>
              <Card.Content>
                <Card.Header>Area</Card.Header>
                <Card.Description>{area?.label}</Card.Description>
              </Card.Content>
            </Card>

            <Card>
              <Card.Content>
                <Card.Header>Position</Card.Header>
                <Card.Description>{position?.label}</Card.Description>
              </Card.Content>
            </Card>
          </CardGroup>
        )}

        <Table className={style.table}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Bid Creation Time</Table.HeaderCell>
              <Table.HeaderCell>Bid Accepted At</Table.HeaderCell>
              <Table.HeaderCell>Employee Who Won Bid</Table.HeaderCell>
              <Table.HeaderCell>Open to All</Table.HeaderCell>
              <Table.HeaderCell>Overdue</Table.HeaderCell>
              <Table.HeaderCell>Bid History</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {openShiftAlerts?.map(
              ({ props: { insertDatetime, userOpenShiftAlerts, ...openShiftAlert } }) => {
                const wonBid = userOpenShiftAlerts?.find(({ props: { accepted } }) => accepted);

                return (
                  <Table.Row>
                    <Table.Cell>{insertDatetime?.format('MMM DD, YYYY [at] HH:mm')}</Table.Cell>
                    <Table.Cell>
                      {wonBid?.props.accepted
                        ? wonBid?.props.updateDatetime?.format('MMM DD, YYYY [at] HH:mm')
                        : 'N/A'}
                    </Table.Cell>
                    <Table.Cell>
                      {wonBid ? (
                        <Link
                          // @ts-ignore
                          to={`/employees/${wonBid?.props.user?.employee?.id}/profile`}
                          style={{ color: '#1E6FA9' }}
                        >
                          {wonBid?.props.user?.firstName} {wonBid?.props.user?.lastName}
                        </Link>
                      ) : (
                        <>N/A</>
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {openShiftAlert.event?.dueDate.isAfter(moment()) ? 'Yes' : 'No'}
                    </Table.Cell>
                    <Table.Cell>{openShiftAlert.openToAll ? 'Yes' : 'No'}</Table.Cell>
                    <Table.Cell>
                      <div
                        role="button"
                        tabIndex={-1}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setSelectedOpenShiftAlertId(openShiftAlert.id);
                        }}
                        onKeyUp={(e) => {
                          if (e.key === 'Enter') {
                            setSelectedOpenShiftAlertId(openShiftAlert.id);
                          }
                        }}
                      >
                        <FontAwesomeIcon icon={['far', 'eye']} color="#1E6FA9" />
                      </div>
                    </Table.Cell>
                  </Table.Row>
                );
              },
            )}
          </Table.Body>
        </Table>
      </Segment>

      {selectedOpenShiftAlertId && foundOpenShiftAlert && (
        <BidHistoryModal
          open={!!selectedOpenShiftAlertId}
          openShiftAlert={foundOpenShiftAlert}
          onClose={() => setSelectedOpenShiftAlertId(undefined)}
        />
      )}
    </>
  );
});
