import * as React from 'react';
import classnames from 'classnames';
import { inject, observer } from 'mobx-react';
import { Grid, Message } from '@/semantic-ui/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModalBase from '@/components/ModalBase';
import Bulletin from '@/components/Bulletin';
import {
  ScheduleStore,
  ShiftSwapStore,
  HasID,
  ShiftStore,
  AreaStore,
  EmployeeStore,
  PayrollStore,
  RequestStatus,
  UserStore,
  toDollar,
} from 'sol-data';
import AppContext from '@/AppContext';
import InjectedComponent from '../InjectedComponent';
import style from './index.less';

const { Row, Column } = Grid;

interface Props {
  request: HasID;
  closeModal: () => void;
}

interface State {
  shiftFromDifference?: number;
  shiftToDifference?: number;
}

interface Injected {
  scheduleStore: ScheduleStore;
  shiftSwapStore: ShiftSwapStore;
  shiftStore: ShiftStore;
  areaStore: AreaStore;
  employeeStore: EmployeeStore;
  payrollStore: PayrollStore;
  userStore: UserStore;
}

@inject(
  'scheduleStore',
  'shiftSwapStore',
  'shiftStore',
  'areaStore',
  'employeeStore',
  'payrollStore',
  'userStore',
)
@observer
class ShiftSwapModal extends InjectedComponent<Props, State, Injected> {
  state: State = {};

  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  componentDidMount() {
    this.fetchData();
  }

  async fetchData() {
    const { request, shiftSwapStore, shiftStore } = this.injected;

    const swap = await shiftSwapStore.fetch(request);

    const { employeeSwapFrom, employeeSwapTo, shiftFrom, shiftTo } = swap.withoutID;

    const { isPayrollAdmin } = this.context;

    isPayrollAdmin
      ? await Promise.all([
          this.fetchShiftDifference(shiftFrom, employeeSwapFrom, employeeSwapTo, 'shiftFrom'),
          this.fetchShiftDifference(shiftTo, employeeSwapTo, employeeSwapFrom, 'shiftTo'),
          shiftStore.fetchShifts([swap.$.shiftFrom, swap.$.shiftTo]),
        ])
      : await shiftStore.fetchShifts([swap.$.shiftFrom, swap.$.shiftTo]);
  }

  fetchShiftDifference = async (
    shift: HasID,
    employee1: HasID,
    employee2: HasID,
    title: 'shiftFrom' | 'shiftTo',
  ) => {
    const { payrollStore } = this.injected;

    const [[{ cost: a }], [{ cost: b }]] = await Promise.all([
      payrollStore.fetchShiftAssignmentCosts(shift, employee1),
      payrollStore.fetchShiftAssignmentCosts(shift, employee2),
    ]);

    if (!a.total || !b.total) return 'Unknown';

    const difference = a.total - b.total;
    const stateProp = `${title}Difference`;

    this.setState({
      [stateProp]: difference,
    } as any);
  };

  approveSwap = async () => {
    const { request, shiftSwapStore, userStore, closeModal, scheduleStore } = this.injected;

    await shiftSwapStore.approve(request, userStore.current!);

    if (scheduleStore.current) scheduleStore.fetch(scheduleStore.current);
    closeModal();
  };

  rejectSwap = async () => {
    const { request, shiftSwapStore, userStore, closeModal, scheduleStore } = this.injected;

    await shiftSwapStore.reject(request, userStore.current!);

    if (scheduleStore.current) scheduleStore.fetch(scheduleStore.current);
    closeModal();
  };

  render() {
    const { request, shiftSwapStore } = this.injected;

    const { shiftFromDifference, shiftToDifference } = this.state;

    const swap = shiftSwapStore.get(request);

    if (!swap) {
      return (
        <ModalBase header="Shift Swap Details" noButtons>
          <Bulletin
            isLoading={shiftSwapStore.showLoading}
            errorMessage={shiftSwapStore.errorMessage}
            handleDismiss={shiftSwapStore.clearError}
          />
        </ModalBase>
      );
    }

    const ShiftCell = ({ employee, shift }: { employee: HasID; shift: HasID }) => {
      const { shiftStore, areaStore, employeeStore } = this.injected;
      const foundShift = shiftStore.get(shift);
      const foundEmployee = employeeStore.get(employee);

      const name = foundEmployee ? foundEmployee.user.fullName : 'Unknown';

      const startTime = foundShift ? foundShift.$.startDate.format('H:mm') : 'Unknown';
      const endTime = foundShift ? foundShift.$.endDate.format('H:mm') : 'Unknown';

      const area = foundShift && areaStore.get(foundShift.$.area);

      return (
        <div className={`${style.shift_block_item} ${style.shift_swap_item}`}>
          <b className={style.shift_swap_item_employee}>{name}</b>
          <span>{foundShift ? foundShift.$.startDate.format('ddd MMM DD') : 'Unknown'}</span>
          <span>
            {startTime} -{endTime}
          </span>
          <div className={style.shift_swap_item_label}>{area?.label}</div>
        </div>
      );
    };

    const { status, employeeSwapFrom, employeeSwapTo, shiftFrom, shiftTo, message } = swap.withID;

    const { isPayrollAdmin } = this.context;

    return (
      <ModalBase
        header="Shift Swap Details"
        headerClass="modal_header modal_header_swap"
        actionWord="Approve"
        cancelWord="Reject"
        onAccept={() => this.approveSwap()}
        onCancel={() => this.rejectSwap()}
        noButtons={status !== RequestStatus.Pending}
      >
        <Bulletin
          isLoading={shiftSwapStore.showLoading}
          errorMessage={shiftSwapStore.errorMessage}
          handleDismiss={shiftSwapStore.clearError}
          isModal
        />
        <Grid>
          <Row>
            <Column width={7}>
              <ShiftCell employee={employeeSwapFrom} shift={shiftFrom} />
            </Column>
            <Column verticalAlign="middle" textAlign="center" width={2}>
              <FontAwesomeIcon
                icon={['fal', 'exchange']}
                size="2x"
                className={style.swap_centered}
                color="#4a4a4a"
              />
            </Column>
            <Column width={7}>
              <ShiftCell employee={employeeSwapTo} shift={shiftTo} />
            </Column>
          </Row>
          {isPayrollAdmin && shiftFromDifference && shiftToDifference && (
            <Row>
              <div className={style.cost_difference_header}>
                <span>
                  Total Cost Difference:
                  <span> {toDollar(shiftFromDifference + shiftToDifference)}</span>
                </span>
              </div>
            </Row>
          )}

          <Row columns={1}>
            <Column>
              <h4>Message</h4>

              <Message>
                <p>{message || 'No Message'}</p>
              </Message>
            </Column>
          </Row>

          {status !== RequestStatus.Pending && (
            <Row>
              <div
                className={classnames(style.request_banner, {
                  [style.request_banner_approved]: status === RequestStatus.Approved,
                  [style.request_banner_denied]: status === RequestStatus.Denied,
                })}
              >
                {status === RequestStatus.Approved ? 'Approved' : 'Rejected'}
              </div>
            </Row>
          )}
        </Grid>
      </ModalBase>
    );
  }
}

export default ShiftSwapModal;
