import Breadcrumbs from '@/components/Breadcrumbs';
import Bulletin from '@/components/Bulletin';
import IncrementalButton from '@/components/IncrementalButton';
import TimePicker from '@/components/TimePicker';
import style from '@/containers/Organization/index.less';
import { useAsyncEffect } from '@/hooks';
import { Button, Dropdown, Form, Grid, Input, Segment } from '@/semantic-ui/components';
import { useStores } from '@/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FieldArray, Formik } from 'formik';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { dayOffOptions, DayOffRotation, Position, Time } from 'sol-data';
import { array, number, object, string } from 'yup';
import ButtonBar from '../ButtonBar';

const { Field } = Form;
const { Row, Column } = Grid;

interface InitialValues {
  description: string;
  label: string;
  maxHoursPerWeek: number;
  shiftsPerWeek: number;
  hoursBetweenShifts: number;
  maxHoursPerShift: number;
  qbClassName: string;
  dayOffRotation: DayOffRotation;
  normalBreakTimes: string[];
  openShiftLockdownDuration?: number;
  supplementaryRateId: number | null;
}

export const ModifyPositions = observer(() => {
  const { positionStore, supplementaryRateStore } = useStores();
  const {
    isLoading,
    errorMessage,
    employeeClassDropdownOptions,
    current: position,
  } = positionStore;
  const { dropdownOptions: supplementaryRateDropDownOptions } = supplementaryRateStore;
  const navigate = useNavigate();
  const params = useParams();
  const positionId = params.positionId!;
  const [initialValues, setInitialValues] = useState<InitialValues>({
    description: '',
    label: '',
    maxHoursPerWeek: 0,
    shiftsPerWeek: 0,
    hoursBetweenShifts: 0,
    maxHoursPerShift: 0,
    qbClassName: '',
    dayOffRotation: DayOffRotation.TWO_DAYS,
    normalBreakTimes: [],
    supplementaryRateId: null,
  });

  useAsyncEffect(async () => {
    await supplementaryRateStore.fetchAll();
    await positionStore.fetchAll();

    if (positionId === 'add') {
      positionStore.addAsCurrent(Position.create());
    } else {
      positionStore.setCurrent(positionId);
    }

    await positionStore.fetchEmployeeClasses();

    return () => {
      positionStore.remove(-1);
      positionStore.clearCurrent();
    };
  }, [positionId, positionStore, supplementaryRateStore]);

  useEffect(() => {
    if (position) {
      const values = position.withoutID();

      setInitialValues({
        ...values,
        supplementaryRateId: values.supplementaryRate?.id ?? null,
        normalBreakTimes: values.normalBreakTimes.map((v) => v.toString()),
      });
    }
  }, [position]);

  return (
    <Segment basic padded="very">
      <Breadcrumbs
        linkTitle="Positions"
        activeBreadcrumb={initialValues.description || 'New Position'}
        link="/organization/positions"
        title={'Positions'}
      />
      <Formik
        enableReinitialize
        validationSchema={object().shape({
          description: string().required('Position description is required'),
          label: string().required('Position name is required'),
          shiftsPerWeek: number()
            .typeError('Please enter a number')
            .min(0.5, 'Must be greater than 0.5')
            .required('Shifts per week is required'),
          hoursBetweenShifts: number()
            .typeError('Please enter a number')
            .min(0.5, 'Must be greater than 0.5')
            .required('Hours between shifts is required'),
          maxHoursPerShift: number()
            .typeError('Please enter a number')
            .min(0.5, 'Must be greater than 0.5')
            .required('Max hours per shift is required'),
          maxHoursPerWeek: number()
            .typeError('Please enter a number')
            .min(0, 'Must be greater than 0')
            .max(80, 'Must be less than 2.0')
            .required('Max Hours Per Week is required'),
          supplementaryRateId: number().nullable(),
          qbClassName: string().required('Quickbooks class is required'),
          dayOffRotation: string().required('Day Off Rotation is required'),
          normalBreakTimes: array()
            .of(string().matches(Time.regex, 'Please enter a valid time'))
            .min(1, 'At least one break time is required'),
          openShiftLockdownDuration: number()
            .nullable()
            .typeError('Please enter a number')
            .min(0, 'Duration must be a positive number value'),
        })}
        initialValues={initialValues}
        onSubmit={async (values, { setSubmitting }) => {
          const position = positionStore.assignCurrent({
            ...values,
            supplementaryRate: values.supplementaryRateId
              ? supplementaryRateStore.values.find(
                  (itm) => itm.id === values.supplementaryRateId,
                ) ?? null
              : null,
            normalBreakTimes: values.normalBreakTimes.map((v) => Time.parse(v)),
          });
          if (position.id === -1) {
            await positionStore.create(position.toAPI());
          } else {
            await positionStore.save(position);
          }

          setSubmitting(false);
          if (!errorMessage) {
            navigate('/organization/positions');
          }
        }}
        render={({
          values,
          errors,
          handleChange,
          touched,
          handleBlur,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          isSubmitting,
        }) => {
          const showlabelError = !!touched.label && !!errors.label;
          const showPositionDescriptionError = !!touched.description && !!errors.description;
          const showShiftsPerWeekError = !!touched.shiftsPerWeek && !!errors.shiftsPerWeek;
          const showHoursBetweenShiftError =
            !!touched.hoursBetweenShifts && !!errors.hoursBetweenShifts;
          const showMaxHoursPerShiftError = !!touched.maxHoursPerShift && !!errors.maxHoursPerShift;
          const showMaxHoursPerWeekError = !!touched.maxHoursPerWeek && !!errors.maxHoursPerWeek;
          const showClassError = !!touched.qbClassName && !!errors.qbClassName;
          const showDayOffError = !!touched.dayOffRotation && !!errors.dayOffRotation;
          const showBreakError = !!touched.normalBreakTimes && !!errors.normalBreakTimes;
          const showOpenShiftLockdownDurationError =
            !!touched.openShiftLockdownDuration && !!errors.openShiftLockdownDuration;
          const showSupplementaryRateError =
            !!touched.supplementaryRateId && !!errors.supplementaryRateId;

          return (
            <Form>
              <Grid>
                <Row>
                  <Bulletin
                    isLoading={isLoading}
                    errorMessage={errorMessage}
                    handleDismiss={() => positionStore.clearError()}
                  />
                </Row>
                <Row>
                  <Column width={8}>
                    <Field error={showlabelError}>
                      <p className={style.form_headers}>Name</p>
                      <Input
                        name="label"
                        value={values.label}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {showlabelError && <p className={style.error_class}>{errors.label}</p>}
                    </Field>
                  </Column>
                </Row>
                <Row>
                  <Column width={8}>
                    <Field error={showPositionDescriptionError}>
                      <p className={style.form_headers}>Description</p>
                      <Input
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {showPositionDescriptionError && (
                        <p className={style.error_class}>{errors.description}</p>
                      )}
                    </Field>
                  </Column>
                  <Column width={4}>
                    <Field error={showMaxHoursPerShiftError}>
                      <p className={style.form_headers}>Max Hours Per Shift</p>
                      <IncrementalButton
                        name="maxHoursPerShift"
                        id="maxHoursPerShift"
                        value={values.maxHoursPerShift}
                        onChange={setFieldValue}
                        onBlur={handleBlur}
                      />
                      {showMaxHoursPerShiftError && (
                        <p className={style.error_class}>{errors.maxHoursPerShift}</p>
                      )}
                    </Field>
                  </Column>
                </Row>
                <Row>
                  <Column width={4}>
                    <Field error={showShiftsPerWeekError}>
                      <p className={style.form_headers}>Shifts Per Week</p>
                      <IncrementalButton
                        name="shiftsPerWeek"
                        id="shiftsPerWeek"
                        value={values.shiftsPerWeek}
                        onChange={setFieldValue}
                        onBlur={handleBlur}
                      />
                      {showShiftsPerWeekError && (
                        <p className={style.error_class}>{errors.shiftsPerWeek}</p>
                      )}
                    </Field>
                  </Column>
                  <Column width={4}>
                    <Field error={!!showHoursBetweenShiftError}>
                      <p className={style.form_headers}>Hours Between Shifts</p>
                      <IncrementalButton
                        name="hoursBetweenShifts"
                        id="hoursBetweenShifts"
                        value={values.hoursBetweenShifts}
                        onChange={setFieldValue}
                        onBlur={handleBlur}
                      />
                      {showHoursBetweenShiftError && (
                        <p className={style.error_class}>{errors.hoursBetweenShifts}</p>
                      )}
                    </Field>
                  </Column>
                  <Column width={4}>
                    <Field error={!!showMaxHoursPerWeekError}>
                      <p className={style.form_headers}>Optimal FTE</p>
                      <IncrementalButton
                        name="maxHoursPerWeek"
                        id="maxHoursPerWeek"
                        step={0.1}
                        max={2}
                        value={values.maxHoursPerWeek / 40}
                        onChange={(_, val) => setFieldValue('maxHoursPerWeek', val * 40)}
                        onBlur={handleBlur}
                      />
                      {showMaxHoursPerWeekError && (
                        <p className={style.error_class}>{errors.maxHoursPerWeek}</p>
                      )}
                    </Field>
                  </Column>
                </Row>
                <Row>
                  <Column width={4}>
                    <Field error={showClassError}>
                      <p className={style.form_headers}>Quickbooks Employee Class</p>
                      <Dropdown
                        fluid
                        selection
                        search
                        placeholder="Select Quickbooks Class"
                        options={employeeClassDropdownOptions}
                        name="qbClassName"
                        value={values.qbClassName}
                        onChange={(_, { name, value }) => {
                          setFieldValue(name, value);
                        }}
                        onBlur={(_, { name }) => {
                          setFieldTouched(name, true, false);
                        }}
                        className={style.dropdowns}
                      />
                      {showClassError && <p className={style.error_class}>{errors.qbClassName}</p>}
                    </Field>
                  </Column>
                  <Column width={4}>
                    <Field error={showDayOffError}>
                      <p className={style.form_headers}>Day Off Rotation</p>
                      <Dropdown
                        fluid
                        selection
                        search
                        placeholder="Select Day Off Rotation"
                        options={dayOffOptions}
                        name="dayOffRotation"
                        value={values.dayOffRotation}
                        onChange={(_, { name, value }) => {
                          setFieldValue(name, value);
                        }}
                        onBlur={(_, { name }) => {
                          setFieldTouched(name, true, false);
                        }}
                        className={style.dropdowns}
                      />
                      {showDayOffError && (
                        <p className={style.error_class}>{errors.dayOffRotation}</p>
                      )}
                    </Field>
                  </Column>
                  <Column width={4}>
                    <Field error={!!showOpenShiftLockdownDurationError}>
                      <p className={style.form_headers}>
                        Open Shift Lockdown Duration (In Minutes)
                      </p>
                      <IncrementalButton
                        name="openShiftLockdownDuration"
                        id="openShiftLockdownDuration"
                        step={15}
                        max={10000}
                        value={values.openShiftLockdownDuration ?? 'null'}
                        onChange={(_, val) => {
                          if (Number.isNaN(val)) {
                            return setFieldValue('openShiftLockdownDuration', null);
                          }
                          setFieldValue('openShiftLockdownDuration', val);
                        }}
                        onBlur={handleBlur}
                        placeholder="For the default duration, leave blank"
                      />
                      {showOpenShiftLockdownDurationError && (
                        <p className={style.error_class}>{errors.openShiftLockdownDuration}</p>
                      )}
                    </Field>
                  </Column>
                </Row>
                <Row>
                  <Column width={4}>
                    <Field>
                      <p className={style.form_headers}>Supplementary Rate Table</p>
                      <Dropdown
                        fluid
                        selection
                        clearable
                        placeholder="Select a Supplementary Rate Table"
                        options={supplementaryRateDropDownOptions}
                        name="supplementaryRateId"
                        value={values.supplementaryRateId || undefined}
                        onChange={(_, { name, value }) => {
                          setFieldValue(name, value);
                        }}
                        onBlur={(_, { name }) => {
                          setFieldTouched(name, true, false);
                        }}
                        className={style.dropdowns}
                      />
                      {showSupplementaryRateError && (
                        <p className={style.error_class}>{errors.supplementaryRateId}</p>
                      )}
                    </Field>
                  </Column>
                </Row>
                <div className={style.break_buttons_wrapper}>
                  <p className={style.form_headers}>Normal Break Times</p>
                  <div className={style.break_buttons}>
                    <Button
                      circular
                      size="mini"
                      type="button"
                      color="blue"
                      icon={<FontAwesomeIcon icon={['far', 'plus']} />}
                      onClick={() => {
                        setFieldValue('normalBreakTimes', [...values.normalBreakTimes, '']);
                      }}
                    />
                    {values.normalBreakTimes.length > 1 && (
                      <Button
                        circular
                        size="mini"
                        type="button"
                        color="red"
                        icon="minus"
                        onClick={() => {
                          setFieldValue('normalBreakTimes', values.normalBreakTimes.slice(0, -1));
                        }}
                      />
                    )}
                  </div>
                </div>
                <Row columns={4}>
                  <FieldArray
                    name="normalBreakTimes"
                    render={(arrayHelpers) => (
                      <>
                        {values.normalBreakTimes.map((time, index) => (
                          <Column key={`break_${index}`}>
                            <TimePicker
                              name="normalBreakTimes"
                              value={time}
                              onChange={(_, value) => {
                                setFieldValue(`normalBreakTimes[${index}]`, value);
                              }}
                            />
                          </Column>
                        ))}
                      </>
                    )}
                  />
                  <Column width={16}>
                    {showBreakError && (
                      <p className={style.error_class}>{errors.normalBreakTimes}</p>
                    )}
                  </Column>
                </Row>
                <Row>
                  <Column width={16}>
                    <ButtonBar
                      onSave={handleSubmit}
                      link="/organization/positions"
                      disabled={isSubmitting}
                    />
                  </Column>
                </Row>
              </Grid>
            </Form>
          );
        }}
      />
    </Segment>
  );
});
