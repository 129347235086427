import InjectedComponent from '@/components/InjectedComponent';
import { Dimmer, Grid, Loader, Modal } from '@/semantic-ui/components';
import { computed } from 'mobx';
import * as React from 'react';
import { inject, observer } from 'mobx-react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { EssentialEmployeeData, EmployeeSupplementaryRate, Moment } from 'sol-data';
import Decimal from 'decimal.js';
import { EmployeeStore } from '@/store';
import { ListColumn, ObservingListLayout } from '../ListLayout';
import style from './index.less';
import Bulletin from '../Bulletin';

type BaseProps = {
  onCancel: () => void;
  open: boolean;
  selectedEmployee: EssentialEmployeeData;
  endDate: Moment;
};

type State = {
  rates?: EmployeeSupplementaryRate[];
  error?: unknown;
};

type iRow = {
  desc: string;
  id: number;
  rate: number;
};

interface Injected {
  employeeStore: EmployeeStore;
}
@inject('employeeStore')
@observer
class EmployeeRatesModal extends InjectedComponent<BaseProps, State, Injected> {
  state: State = {};

  componentDidMount() {
    const { employeeStore } = this.injected;
    const fetchRates = async () => {
      try {
        const rates = await employeeStore.fetchEmployeeSupplementaryRates({
          id: this.props.selectedEmployee.id,
          endDate: this.props.endDate,
        });
        this.setState({
          rates,
          error: undefined,
        });
      } catch (error) {
        this.setState({
          rates: undefined,
          error,
        });
      }
    };
    fetchRates();
  }

  @computed get columns(): ListColumn<iRow>[] {
    return [
      {
        name: 'ID',
        key: 'id',
        width: 2,
        render: ({ id }) => <span>{id}</span>,
      },
      {
        name: 'ITEM',
        key: 'item',
        width: 10,
        render: ({ desc }) => <span>{desc}</span>,
      },
      {
        name: 'RATE',
        key: 'rate',
        width: 4,
        render: ({ rate }) => <span>{new Decimal(rate).toFixed(2)}</span>,
      },
    ];
  }

  render() {
    const { onCancel, open } = this.props;
    const { lastName, firstName } = this.props.selectedEmployee;
    const { employeeStore } = this.injected;
    const { error, rates } = this.state;

    return (
      <Modal open={open} onClose={onCancel} size="small">
        <Modal.Content>
          <Grid.Row>
            <Grid.Column width={16} className={style.employee_rates_modal_container}>
              <div className={style.flex}>
                <p className={style.rate}>Rates</p>
                <h3 className={style.header}>{`${lastName}, ${firstName}`}</h3>
              </div>
              {employeeStore.isLoadingEmployeeSupplementaryRates ? (
                <Dimmer inverted active>
                  {' '}
                  <Loader size="small" active className={style.modal_loader} />
                </Dimmer>
              ) : error ? (
                <Bulletin error={error} />
              ) : (
                rates && (
                  <ObservingListLayout
                    columns={this.columns}
                    items={rates.map((itm, index) => ({
                      id: index + 1,
                      rate: itm.rate,
                      desc: itm.paymentOccurrence.description,
                    }))}
                  />
                )
              )}
            </Grid.Column>
          </Grid.Row>
        </Modal.Content>
      </Modal>
    );
  }
}

export default EmployeeRatesModal;
