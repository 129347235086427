import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { ScheduleStore } from '@/store';
import {
  moment,
  Moment,
  RequestType,
  HasID,
  EmployeeStore,
  ShiftStore,
  Shift,
  TimeOffStore,
  SickNoticeStore,
  UserStore,
  SickNotice,
} from 'sol-data';
import { Row, Column, Form, TextArea, Grid } from '@/semantic-ui/components';
import { Formik } from 'formik';
import { string, object, StringSchema, number, NumberSchema } from 'yup';
import { SingleDatePicker } from 'react-dates';
import isBeforeDay from 'react-dates/lib/utils/isBeforeDay';
import InjectedComponent from '@/components/InjectedComponent';
import ModalBase from '@/components/ModalBase';
import Bulletin from '@/components/Bulletin';
import { EntityReference } from '@lcdev/store';
import EmployeeFilterDropdown from '@/components/EmployeeFilterDropdown';
import style from '../index.less';

const { Field } = Form;

interface Props {
  type: RequestType;
  closeModal: () => void;
  closeAndFetch?: () => void;
  shift?: HasID;
}

interface State {
  startDateFocused: boolean;
  endDateFocused: boolean;
  requestShift?: Shift;
}

interface Injected {
  scheduleStore: ScheduleStore;
  employeeStore: EmployeeStore;
  shiftStore: ShiftStore;
  timeOffStore: TimeOffStore;
  sickNoticeStore: SickNoticeStore;
  userStore: UserStore;
}

interface InitialValues {
  startDate?: Moment;
  endDate?: Moment;
  message: string;
  employeeId?: number;
}

interface Schema {
  message: StringSchema;
  startDate?: StringSchema;
  endDate?: StringSchema;
  employeeId: NumberSchema;
}

interface TimeOffValues {
  message: string;
  startDate: Moment;
  endDate: Moment;
  employeeId: number;
}

interface SickNoticeValues {
  message: string;
  shiftId: number;
  employeeId: number;
}

@inject('scheduleStore', 'employeeStore', 'shiftStore', 'timeOffStore', 'sickNoticeStore')
@observer
class NewRequestModal extends InjectedComponent<Props, State, Injected> {
  state: State = {
    startDateFocused: false,
    endDateFocused: false,
  };

  componentDidMount() {
    const { shift, shiftStore } = this.injected;
    if (shift) {
      shiftStore.fetchShift(shift).then((shift) => this.setState({ requestShift: shift }));
    }
  }

  componentWillUnmount() {
    this.clearErrors();
  }

  clearErrors() {
    const { shiftStore, sickNoticeStore, timeOffStore } = this.injected;
    shiftStore.clearError();
    sickNoticeStore.clearError();
    timeOffStore.clearError();
  }

  handleFocus = (type: 'startDate' | 'endDate') => {
    const stateField = `${type}Focused`;

    this.setState(
      (state: any) =>
        ({
          [stateField]: !state[stateField],
        } as any),
    );
  };

  render() {
    const {
      type,
      closeModal,
      shiftStore,
      timeOffStore,
      sickNoticeStore,
      scheduleStore,
      closeAndFetch,
    } = this.injected;
    const { startDateFocused, endDateFocused, requestShift } = this.state;

    const isSickNotice = type === RequestType.SickNotice;
    const headerWord = isSickNotice ? 'Sick Notice' : 'Time Off';

    const initialValues: InitialValues = {
      startDate: requestShift?.$.startDate,
      endDate: (requestShift && isSickNotice && requestShift.$.endDate) || undefined,
      message: '',
      employeeId: requestShift?.$.employee?.id || undefined,
    };

    const schema: Schema = {
      startDate: string().required('Start date is required'),
      endDate: string().required('End date is required'),
      message: string(),
      employeeId: number().required('Employee is required'),
    };

    if (!isSickNotice) {
      schema.startDate = string().typeError('Please select a start date').required();
      schema.endDate = string().typeError('Please select an end date').required();
    }

    const errorMessages =
      shiftStore.errorMessage || sickNoticeStore.errorMessage || timeOffStore.errorMessage;

    const isLoading = shiftStore.isLoading || sickNoticeStore.isLoading || timeOffStore.isLoading;

    return (
      <Formik
        enableReinitialize
        validationSchema={object().shape(schema)}
        initialValues={initialValues}
        onSubmit={async ({ employeeId, message, startDate, endDate }) => {
          if (isSickNotice) {
            const [shift] = requestShift
              ? [requestShift]
              : await shiftStore.fetchEmployeesShifts({ id: employeeId! }, startDate, endDate);

            if (shift) {
              const notice = await sickNoticeStore.create({
                message,
                shift,
                employee: shift.$.employee || undefined,
              });

              if (requestShift) {
                const found = scheduleStore.findShiftGroup(shift);
                if (found) {
                  const [foundShift] = found;
                  foundShift.set('sickNotices', [new EntityReference(SickNotice, notice)]);
                }
              }
            } else {
              sickNoticeStore.setError(
                'Sorry, no shift found for that employee on the specified day',
              );

              return;
            }
          } else {
            await timeOffStore.create({
              message,
              employee: employeeId,
              startDate: startDate!,
              endDate: endDate!,
            });

            // refresh shifts that are affected
            await scheduleStore.fetchEmployeeShifts(employeeId!, startDate, endDate);
          }
          if (closeAndFetch) {
            closeAndFetch();
          } else {
            closeModal();
          }
        }}
        render={({ values, errors, touched, handleChange, handleSubmit, setFieldValue }) => {
          const showStartError = !!errors.startDate && !!touched.startDate;
          const showEndError = !!errors.endDate && !!touched.endDate;
          const showEmployeeError = !!errors.employeeId && !!touched.employeeId;

          return (
            <ModalBase
              header={`New ${headerWord} Entry`}
              headerClass={`modal_header_${type} modal_header`}
              actionWord="Add Entry"
              cancelWord="cancel"
              handleSubmit={handleSubmit}
              onCancel={closeModal}
            >
              <Bulletin
                errorMessage={errorMessages}
                isLoading={isLoading}
                handleDismiss={() => this.clearErrors()}
              />
              <Grid>
                {!requestShift && (
                  <>
                    <h4>Employee</h4>
                    <Row>
                      <Column>
                        <Field>
                          <EmployeeFilterDropdown
                            name="employee"
                            search
                            placeholder="Select Employee"
                            onChange={(_, value) => setFieldValue('employeeId', value)}
                            value={values.employeeId}
                          />
                        </Field>
                        {showEmployeeError && (
                          <span className={style.error_class}>{errors.employeeId}</span>
                        )}
                      </Column>
                    </Row>
                  </>
                )}
                <h4>Date</h4>
                <Row columns={isSickNotice ? 1 : 2}>
                  <Column>
                    <Field>
                      <SingleDatePicker
                        hideKeyboardShortcutsPanel
                        noBorder
                        block
                        id="shift_start_date"
                        date={values.startDate || null}
                        placeholder="Date From"
                        initialVisibleMonth={
                          requestShift ? () => requestShift.$.startDate : () => moment()
                        }
                        onDateChange={(value) => {
                          setFieldValue('startDate', value);

                          if (value) {
                            setFieldValue('endDate', value.clone());
                          } else {
                            setFieldValue('endDate', undefined);
                          }
                        }}
                        focused={startDateFocused}
                        onFocusChange={() => this.handleFocus('startDate')}
                        numberOfMonths={1}
                        disabled={!!requestShift && type === RequestType.SickNotice}
                      />
                    </Field>
                    {showStartError && (
                      <span className={style.error_class}>{errors.startDate}</span>
                    )}
                  </Column>
                  {!isSickNotice && (
                    <Column>
                      <Field>
                        <SingleDatePicker
                          hideKeyboardShortcutsPanel
                          noBorder
                          block
                          id="shift_end_date"
                          date={values.endDate || null}
                          placeholder="Date To"
                          initialVisibleMonth={() => values.startDate || moment()}
                          onDateChange={(value) => setFieldValue('endDate', value)}
                          focused={endDateFocused}
                          isOutsideRange={(day) => isBeforeDay(day, values.startDate)}
                          onFocusChange={() => this.handleFocus('endDate')}
                          numberOfMonths={1}
                        />
                      </Field>
                      {showEndError && <span className={style.error_class}>{errors.endDate}</span>}
                    </Column>
                  )}
                </Row>
                <h4>Notes</h4>
                <Row>
                  <Column>
                    <Field>
                      <TextArea
                        name="message"
                        value={values.message}
                        placeholder={`Details of ${headerWord}`}
                        rows={5}
                        onChange={handleChange}
                        autoHeight
                      />
                    </Field>
                  </Column>
                </Row>
              </Grid>
            </ModalBase>
          );
        }}
      />
    );
  }
}

export default NewRequestModal;
