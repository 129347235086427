import ApiKeys from '@/components/ApiKeys';
import TabLayout, { TabLayoutPane } from '@/components/TabLayout';
import UserPermissions from '@/components/UserPermissions';
import { Menu } from '@/semantic-ui/components';
import * as React from 'react';
import { Link, useLocation } from 'react-router';
import { Authority } from 'sol-data';

const panes: TabLayoutPane[] = [
  {
    route: 'api-key',
    menuItem: (
      <Menu.Item as={Link} to="/admin/api-key" key="apikey">
        Api Keys
      </Menu.Item>
    ),
    render: () => <ApiKeys />,
  },
  {
    route: 'users',
    menuItem: (
      <Menu.Item as={Link} to="/admin/users" key="users">
        User Permissions
      </Menu.Item>
    ),
    access: Authority.PayrollAdmin,
    render: () => <UserPermissions />,
  },
];

const Admin = () => {
  const { pathname } = useLocation();

  return <TabLayout path={pathname} panes={panes} activeBreadcrumb="Admin" />;
};

export default Admin;
