import { useAsyncEffect } from '@/hooks';
import { useStores } from '@/store';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { Menu, MenuItemProps } from 'semantic-ui-react';
import { HasID, IShiftGroup, Shift, ShiftType } from 'sol-data';
import ScheduleContext from '../ScheduleContext';
import { Action } from '../ShiftBlock';
import style from '../index.less';

interface ShiftMenuProps {
  shift: Shift;
  openModal: (action: Action) => void;
  shiftGroup: IShiftGroup & HasID;
}

const ShiftMenu = observer(({ shift, openModal, shiftGroup }: ShiftMenuProps) => {
  const { alertStore, scheduleStore } = useStores();
  const [hasBidHistory, setHasBidHistory] = useState(false);
  const navigate = useNavigate();

  useAsyncEffect(async () => {
    if (shift.id) {
      const openShiftAlerts = await alertStore.fetchOpenShiftsByShiftId(shift.id);

      setHasBidHistory(openShiftAlerts.length > 0);
    }
  }, [shift.id]);

  const clickHandler: MenuItemProps['onClick'] = (_, { action }) => {
    if (!action) {
      throw new Error('Action required');
    }

    openModal(action);
  };

  const shiftHasRequests = shift.$.type !== ShiftType.Normal;

  console.log('ShiftMenu', { schedule: scheduleStore.current });

  return (
    <ScheduleContext.Consumer>
      {(config) => (
        <Menu size="tiny" vertical className={style.schedule_position_menu}>
          {config.shift.canHaveRequests && shiftGroup.employee && (
            <>
              <Menu.Item
                action={shiftHasRequests ? Action.Request : Action.NewSickNotice}
                name={shiftHasRequests ? 'Request Details' : Action.NewSickNotice}
                onClick={clickHandler}
              />
              {!shiftHasRequests && (
                <Menu.Item
                  action={Action.NewTimeOff}
                  name={Action.NewTimeOff}
                  onClick={clickHandler}
                />
              )}
            </>
          )}
          {config.shift.canEdit && (
            <Menu.Item action={Action.Edit} name={Action.Edit} onClick={clickHandler} />
          )}
          {config.shift.canMove && (
            <Menu.Item action={Action.Move} name={Action.Move} onClick={clickHandler} />
          )}
          {config.shift.canCopy && (
            <Menu.Item action={Action.Copy} name={Action.Copy} onClick={clickHandler} />
          )}
          {config.shift.canUnassign && (
            <Menu.Item action={Action.Unassign} name={Action.Unassign} onClick={clickHandler} />
          )}
          <Menu.Item
            action={Action.ViewBidHistory}
            name={Action.ViewBidHistory}
            disabled={!hasBidHistory}
            onClick={() =>
              navigate(`/bid-history/shift/${shift.id}/schedule/${scheduleStore.current?.id}`)
            }
          />
          {config.shift.canRemove && (
            <Menu.Item action={Action.Remove} name={Action.Remove} onClick={clickHandler} />
          )}
        </Menu>
      )}
    </ScheduleContext.Consumer>
  );
});

export default ShiftMenu;
