import * as React from 'react';
import { DayOfWeek } from 'sol-data';
import classnames from 'classnames';
import { Button } from '@/semantic-ui/components';
import { days } from '@/lib/utils';
import pickerStyle from './index.less';

interface Props {
  length: number;
  height?: number;
  values: DayOfWeek[];
  onChange?: (values: DayOfWeek[]) => void;
  error?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  isModal?: boolean;
}

const DayPicker: React.FC<Props> = ({
  height,
  values,
  error,
  style,
  disabled,
  isModal,
  onChange,
  length,
}) => {
  const handleDayChange = (value: DayOfWeek) => {
    let dayValues;
    if (values.includes(value)) {
      dayValues = values.filter((v) => v !== value);
    } else {
      dayValues = [...values, value];
    }

    if (onChange) {
      onChange(dayValues);
    }
  };

  const getDays = () => {
    return days.map(({ day, value }) => ({
      value,
      text: day.substring(0, length),
    }));
  };

  const intervals = getDays();

  let styleObj = style;

  if (height) {
    styleObj = {
      ...styleObj,
      height: `${height}px`,
      display: 'flex',
      alignItems: 'center',
    };
  }

  return (
    <div>
      <div style={styleObj}>
        {intervals.map(({ text, value }) => (
          <Button
            toggle
            type="button"
            size="mini"
            key={value}
            active={values.includes(value)}
            onClick={() => handleDayChange(value)}
            disabled={disabled}
            className={classnames({ [pickerStyle.day_picker_big_buttons]: isModal })}
          >
            {text}
          </Button>
        ))}
      </div>
      <p
        className={classnames(pickerStyle.error_class, {
          [pickerStyle.error_day_picker]: !!height,
        })}
      >
        {error}
      </p>
    </div>
  );
};

DayPicker.defaultProps = {
  height: undefined,
  values: [],
  onChange: undefined,
  error: undefined,
  style: {},
  disabled: false,
  isModal: false,
};

export default DayPicker;
