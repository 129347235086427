import * as React from 'react';
import { inject } from 'mobx-react';
import { Menu } from '@/semantic-ui/components';
import { EmployeeStore } from '@/store';
import { AreaScheduleShift } from 'sol-data';
import style from '../index.less';

interface Props {
  openModal: (entry: AreaScheduleShift) => void;
  day: AreaScheduleShift[];
}

interface Injected {
  employeeStore: EmployeeStore;
}

const AreaShiftMenu: React.FC<Props & Injected> = ({ day, openModal, employeeStore }) => (
  <Menu size="tiny" vertical className={style.schedule_position_menu}>
    {day.map((entry) => {
      const clickHandler = () => {
        if (!entry) {
          throw new Error('Shift Required');
        }
        openModal(entry);
      };

      const { employee } = entry;

      return (
        <Menu.Item
          name={employee ? employeeStore.forceGet(employee).user.firstName : 'OPEN'}
          onClick={clickHandler}
        />
      );
    })}
  </Menu>
);

export default inject<Injected>(({ employeeStore }) => ({ employeeStore }))(AreaShiftMenu);
