import Breadcrumbs from '@/components/Breadcrumbs';
import Bulletin from '@/components/Bulletin';
import { withNavigation } from '@/components/Route';
import { useAsyncEffect } from '@/hooks';
import { Form, Grid, Input, Segment } from '@/semantic-ui/components';
import { useStores } from '@/store';
import { Formik } from 'formik';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router';
import { Department } from 'sol-data';
import { object, string } from 'yup';
import ButtonBar from '../ButtonBar';
import style from './index.less';

const { Field } = Form;
const { Column, Row } = Grid;

export const ModifyDepartments = observer(() => {
  const { departmentStore } = useStores();
  const navigate = useNavigate();
  const params = useParams();
  const departmentId = params.departmentId!;

  useAsyncEffect(async () => {
    await departmentStore.fetchAll();

    if (departmentId !== 'add') {
      departmentStore.setCurrent(departmentId);
    } else {
      departmentStore.addAsCurrent(Department.create());
    }

    return () => {
      departmentStore.remove(-1);
      departmentStore.clearCurrent();
    };
  }, [departmentId, departmentStore]);

  const { current: department, errorMessage, isLoading } = departmentStore;

  let initialValues = {
    description: '',
    label: '',
  };

  if (department) {
    initialValues = department.withoutID();
  }

  return (
    <Segment basic padded="very">
      <Breadcrumbs
        linkTitle="Departments"
        activeBreadcrumb={initialValues.description || 'New Department'}
        link="/organization/departments"
        title={'Departments'}
      />
      <Formik
        enableReinitialize
        validationSchema={object().shape({
          label: string().required('Department name is required'),
          description: string().required('Department description is required'),
        })}
        initialValues={initialValues}
        onSubmit={async (values, { setSubmitting }) => {
          const department = departmentStore.assignCurrent(values);

          if (department.id === -1) {
            await departmentStore.create(department.withoutID());
          } else {
            await departmentStore.save(department);
          }

          setSubmitting(false);
          if (!errorMessage) {
            navigate('/organization/departments');
          }
        }}
      >
        {({ values, errors, handleChange, touched, handleBlur, handleSubmit, isSubmitting }) => {
          const showLabelError = !!touched.label && !!errors.label;
          const showDescriptionError = !!touched.description && !!errors.description;

          return (
            <Form onSubmit={handleSubmit}>
              <Grid>
                <Row>
                  <Bulletin
                    errorMessage={errorMessage}
                    handleDismiss={() => departmentStore.clearError()}
                    isLoading={isLoading}
                  />
                </Row>
                <Row>
                  <Column width={16}>
                    <Field error={showLabelError}>
                      <p className={style.form_headers}>Name</p>
                      <Input
                        name="label"
                        value={values.label}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {showLabelError && <p className="error_class">{errors.label}</p>}
                    </Field>
                  </Column>
                </Row>
                <Row>
                  <Column width={16}>
                    <Field error={showDescriptionError}>
                      <p className={style.form_headers}>Description</p>
                      <Input
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {showDescriptionError && <p className="error_class">{errors.description}</p>}
                    </Field>
                  </Column>
                </Row>
                <Row>
                  <Column width={16}>
                    <ButtonBar
                      onSave={handleSubmit}
                      link="/organization/departments"
                      disabled={isSubmitting}
                    />
                  </Column>
                </Row>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Segment>
  );
});

export const ModifyDepartmentsWithNav = withNavigation<{ departmentId: string }>(ModifyDepartments);
