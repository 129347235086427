import * as React from 'react';
import { ScheduleStatus, ScheduleStore, PayrollStore } from 'sol-data';
import { observer, inject } from 'mobx-react';
import SchedulesList from '../SchedulesList';
import Bulletin from '../Bulletin';

interface Injected {
  scheduleStore: ScheduleStore;
  payrollStore: PayrollStore;
}

const PastSchedules: React.FC<{} & Injected> = ({ scheduleStore, payrollStore }) => (
  <>
    <Bulletin
      isLoading={scheduleStore.isLoading || payrollStore.isLoading}
      error={scheduleStore.error || payrollStore.error}
      handleDismiss={() => {
        scheduleStore.clearError();
        payrollStore.clearError();
      }}
    />
    <SchedulesList status={ScheduleStatus.Completed} includeHeader />
  </>
);

export default inject<Injected>(({ scheduleStore, payrollStore }) => ({
  scheduleStore,
  payrollStore,
}))(observer(PastSchedules));
