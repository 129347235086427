import * as React from 'react';
import 'react-dates/initialize';

import AppRouter from '@/AppRouter';
import { stores, StoresContext } from '@/store';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCalendar,
  faCheckCircle,
  faDollarSign,
  faExclamation,
  faPhone,
  faPlusSquare,
  faRss,
  faSave,
  faSignOutAlt,
  faTimes,
  faTimesCircle,
  faUserClock,
} from '@fortawesome/free-solid-svg-icons';
import {
  faBell,
  faBooks,
  faBracketsCurly,
  faBriefcaseMedical,
  faBuilding,
  faCalendarAlt,
  faCalendarCheck,
  faChartBar,
  faCheck,
  faChevronLeft,
  faChevronRight,
  faClipboardListCheck,
  faClock,
  faCloudUpload,
  faCogs,
  faExchange,
  faExchangeAlt,
  faExclamationCircle,
  faFilePdf,
  faHome,
  faLongArrowDown,
  faLongArrowLeft,
  faMoneyCheckAlt,
  faSearch,
  faStopwatch,
  faUser,
  faUserCog,
  faUsersCog,
} from '@fortawesome/pro-light-svg-icons';
import {
  faChartBar as faChartBarRegular,
  faCheck as faCheckRegular,
  faChevronDoubleLeft,
  faChevronDoubleRight,
  faChevronRight as faChevronRightRegular,
  faClipboard,
  faDesktop,
  faExclamationTriangle,
  faEye,
  faFile,
  faInfoCircle,
  faPlus,
  faTrash,
} from '@fortawesome/pro-regular-svg-icons';
import { faDiamond } from '@fortawesome/pro-solid-svg-icons';
import * as Sentry from '@sentry/react';
import { Provider } from 'mobx-react';
import DefaultTheme from 'react-dates/lib/theme/DefaultTheme';
import { Route, Routes, useLocation } from 'react-router';
import { useWindowSize } from 'react-use';
import aphroditeInterface from 'react-with-styles-interface-aphrodite';
import ThemedStyleSheet from 'react-with-styles/lib/ThemedStyleSheet';
import { LoginForm, Pane } from './components/LoginForm';
import ScreenSizeMessage from './components/ScreenSizeMessage';
import './sentry';

library.add(
  faBracketsCurly,
  faBriefcaseMedical,
  faBuilding,
  faCalendar,
  faCalendarAlt,
  faCalendarCheck,
  faCheck,
  faChevronDoubleLeft,
  faChevronDoubleRight,
  faChevronLeft,
  faChevronRight,
  faClipboard,
  faClipboardListCheck,
  faClock,
  faCogs,
  faDesktop,
  faDiamond,
  faDollarSign,
  faExchange,
  faExchangeAlt,
  faExclamation,
  faExclamationCircle,
  faExclamationTriangle,
  faHome,
  faInfoCircle,
  faLongArrowDown,
  faLongArrowLeft,
  faMoneyCheckAlt,
  faPhone,
  faPlus,
  faPlusSquare,
  faRss,
  faSave,
  faSearch,
  faSignOutAlt,
  faStopwatch,
  faTimes,
  faTimesCircle,
  faTrash,
  faUser,
  faUserClock,
  faUserCog,
  faUsersCog,
  faBooks,
  faFile,
  faCloudUpload,
  faFilePdf,
  faCheckRegular,
  faEye,
  faCheckCircle,
  faChevronRightRegular,
  faBell,
  faCheckCircle,
  faChartBarRegular,
  faChartBar,
);

ThemedStyleSheet.registerInterface(aphroditeInterface);
ThemedStyleSheet.registerTheme({
  reactDates: {
    ...DefaultTheme.reactDates,
    zIndex: 2,
    border: {
      ...DefaultTheme.reactDates.border,
      input: {
        ...DefaultTheme.reactDates.border.input,
        border: '1px solid rgba(34,36,38,.15)',
        borderTop: '1px solid rgba(34,36,38,.15)',
        borderRight: '1px solid rgba(34,36,38,.15)',
        borderBottom: '1px solid rgba(34,36,38,.15)',
        borderLeft: '1px solid rgba(34,36,38,.15)',
        borderFocused: '1px solid #85b7d9',
        borderTopFocused: '1px solid #85b7d9',
        borderLeftFocused: '1px solid #85b7d9',
        borderBottomFocused: '1px solid #85b7d9',
        borderRightFocused: '1px solid #85b7d9',
        borderRadius: '4px',
      },
    },
    font: {
      ...DefaultTheme.reactDates.font,
      input: {
        ...DefaultTheme.reactDates.font.input,
        size: '0.9rem',
      },
    },
    color: {
      ...DefaultTheme.reactDates.color,
      selected: {
        ...DefaultTheme.reactDates.color.selected,
        backgroundColor: '#D7AB73',
        backgroundColor_active: '#D7AB73',
        backgroundColor_hover: '#D7AB73',
        borderColor: '#D7AB73',
        borderColor_active: '#D7AB73',
        borderColor_hover: '#D7AB73',
      },
      disabled: '#ddd',
    },
    spacing: {
      ...DefaultTheme.reactDates.spacing,
      captionPaddingBottom: 42,
    },
  },
});

const App = () => {
  const { pathname } = useLocation();
  const { width } = useWindowSize();

  const SentryRoutes = Sentry.withSentryReactRouterV7Routing(Routes);

  return (
    <StoresContext.Provider value={stores}>
      <Provider {...stores}>
        {/* eslint-disable-next-line no-nested-ternary */}
        {width > 768 ? (
          <AppRouter />
        ) : pathname.includes('login') || pathname.includes('password') ? (
          <SentryRoutes>
            <Route path="/login" element={<LoginForm initialPane={Pane.Login} />} />
            <Route path="/password-reset" element={<LoginForm initialPane={Pane.FinishReset} />} />
          </SentryRoutes>
        ) : (
          <ScreenSizeMessage />
        )}
      </Provider>
    </StoresContext.Provider>
  );
};

export default App;
