import * as React from 'react';
import { Link } from 'react-router';
import { Button } from '@/semantic-ui/components';
import style from '@/less/main.less';

interface Props {
  link?: string;
  disabled?: boolean;
  onClick: () => void;
  saveText?: string;
  hideSave?: boolean;
}

const SaveButtons: React.FC<Props> = ({ link, disabled, onClick, hideSave, saveText = 'Save' }) => {
  const button = (
    <Button type="button" color="grey" className={style.confirm_button}>
      Cancel
    </Button>
  );

  return (
    <div className={style.confirm_wrapper}>
      {link && <Link to={link}>{button}</Link>}
      {!link && button}
      {!hideSave && (
        <Button
          type="submit"
          onClick={onClick}
          className={style.confirm_button}
          color="yellow"
          disabled={disabled}
        >
          {saveText}
        </Button>
      )}
    </div>
  );
};

SaveButtons.defaultProps = {
  disabled: false,
  link: undefined,
};

export default SaveButtons;
