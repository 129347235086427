import * as React from 'react';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import AppContext from '@/AppContext';
import InjectedComponent from '@/components/InjectedComponent';
import { ScheduleStore } from '@/store';
import { moment, Schedule, DepartmentStore, ScheduleStatus, PayrollStore, HasID } from 'sol-data';
import { ListColumn, ObservingListLayout } from '@/components/ListLayout';
import { Link } from 'react-router';
import { Segment, Popup, Button } from 'semantic-ui-react';
import Bulletin from '@/components/Bulletin';
import FiltersHeader from '@/components/FiltersHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NoContentMessage from '@/components/Schedule/NoContentMessage';
import style from '@/containers/Schedules/index.less';
import DeleteModal from '../DeleteModal';

interface Injected {
  scheduleStore: ScheduleStore;
  departmentStore: DepartmentStore;
  payrollStore: PayrollStore;
}

interface State {
  schedules?: Schedule[];
  startFocused: boolean;
  endFocused: boolean;
  filtersModalOpen?: boolean;
  deleteModalOpen: boolean;
  scheduleToDelete?: Schedule;
  estimatedCosts: {
    [scheduleId: number]: number;
  };
  accruedCosts: {
    [scheduleId: number]: number;
  };
}

interface Props {
  status: ScheduleStatus;
  includeHeader?: boolean;
  scheduleHeader?: string;
  popupContent?: React.ReactNode;
  active?: boolean;
}

@inject('scheduleStore', 'departmentStore', 'payrollStore')
@observer
class SchedulesList extends InjectedComponent<Props, State, Injected> {
  state: State = {
    startFocused: false,
    endFocused: false,
    deleteModalOpen: false,
    estimatedCosts: {},
    accruedCosts: {},
  };

  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  fetchCosts = async (schedule: HasID) => {
    const { isPayrollAdmin } = this.context;
    const { payrollStore } = this.injected;

    if (!isPayrollAdmin) return;

    payrollStore.fetchScheduleCosts(schedule).then(({ total: estimates }) => {
      this.setState((state) => ({
        estimatedCosts: {
          ...state.estimatedCosts,
          [schedule.id]: estimates,
        },
      }));
    });

    payrollStore.fetchAccruedScheduleCosts(schedule).then(({ total: accrued }) => {
      this.setState((state) => ({
        accruedCosts: {
          ...state.accruedCosts,
          [schedule.id]: accrued,
        },
      }));
    });
  };

  @computed get schedules() {
    const {
      scheduleStore: { filteredSchedules },
      active,
      status,
    } = this.injected;

    return filteredSchedules.filter(({ startDate, status: schedStatus, id }) => {
      if (status === schedStatus && schedStatus === ScheduleStatus.Assigned) {
        return active ? moment() >= startDate : moment() < startDate;
      }
      return schedStatus === status;
    });
  }

  handleStartChange = (date: moment.Moment | null) => {
    const { scheduleStore } = this.injected;
    const checkedDate = date || undefined;

    scheduleStore.setFilters({ startDate: checkedDate });
  };

  handleEndChange = (date: moment.Moment | null) => {
    const { scheduleStore } = this.injected;
    const checkedDate = date || undefined;

    scheduleStore.setFilters({ endDate: checkedDate });
  };

  handleFilterChange = (name: string, value: string | number) => {
    const { scheduleStore } = this.injected;
    scheduleStore.setFilters({ [name]: value });
  };

  toggleStartFocus = () => this.setState((state) => ({ startFocused: !state.startFocused }));

  toggleEndFocus = () => this.setState((state) => ({ endFocused: !state.endFocused }));

  toggleFiltersModal = () =>
    this.setState((state) => ({
      filtersModalOpen: !state.filtersModalOpen,
    }));

  handleSearchChange = (text: string) => {
    const { scheduleStore } = this.injected;
    scheduleStore.setFilters({ text });
  };

  render() {
    const {
      scheduleStore,
      departmentStore,
      payrollStore,
      status,
      includeHeader,
      popupContent,
      scheduleHeader,
    } = this.injected;

    const { isLoading, errorMessage, clearError, filters, filteredSchedules } = scheduleStore;

    const { isPayrollAdmin } = this.context;

    const {
      startFocused,
      endFocused,
      deleteModalOpen,
      scheduleToDelete,
      estimatedCosts,
      accruedCosts,
    } = this.state;

    if (!filteredSchedules) {
      return (
        <Segment basic className={style.schedule_list}>
          <Bulletin isLoading={isLoading} errorMessage={errorMessage} handleDismiss={clearError} />
        </Segment>
      );
    }

    const columns: ListColumn<Schedule>[] = [
      {
        name: 'Date',
        key: 'date',
        render: (schedule) => (
          <Link
            to={
              schedule.status === ScheduleStatus.Pending
                ? `/scheduling/unpublished/${schedule.id}/position`
                : `/scheduling/published/${schedule.id}/position`
            }
          >
            {schedule.status !== ScheduleStatus.Completed && schedule.endDate < moment() && (
              <Popup
                trigger={
                  <FontAwesomeIcon
                    color="#ff3b30"
                    icon={['far', 'exclamation-triangle']}
                    className={style.incomplete_notification_icon}
                  />
                }
                content={
                  <span>
                    This schedule has ended but has yet to be archived. To move this schedule to
                    past schedules tab please open schedule and click on the 'Archive Schedule'
                    button in the top right corner.
                  </span>
                }
              />
            )}
            {schedule.startDate.format('MMM DD YYYY')} -{schedule.endDate.format('MMM DD YYYY')}
          </Link>
        ),
        width: 3,
      },
      {
        name: 'Name',
        key: 'name',
        width: 3,
        render: (schedule) => schedule.description || 'Unnamed',
      },
      {
        name: 'Department',
        key: 'department',
        width: 3,
        render: ({ department }) =>
          departmentStore.has(department)
            ? departmentStore.get(department)!.description
            : 'Unknown',
      },
      {
        name: 'Est. Cost',
        key: 'cost',
        render: ({ id }) => {
          const error = payrollStore.getError(`scheduleEstimatedCost:${id}`);

          if (error) {
            return (
              <Popup
                wide="very"
                content={<Bulletin error={error} />}
                trigger={
                  <div style={{ cursor: 'pointer' }}>
                    <FontAwesomeIcon
                      color="#ff3b30"
                      icon={['far', 'exclamation-triangle']}
                      className={style.incomplete_notification_icon}
                    />
                    <span className={style.error_class}>ERROR</span>
                  </div>
                }
              />
            );
          }
          if (estimatedCosts[id]) {
            return Intl.NumberFormat('en-CA', { style: 'currency', currency: 'CAD' }).format(
              estimatedCosts[id],
            );
          }
          return <div className={style.table_cell}>-</div>;
        },
      },
      {
        name: 'Accrued Cost',
        key: 'accruedCost',
        render: ({ id }) => {
          const error = payrollStore.getError(`scheduleAccruedCost:${id}`);

          if (error) {
            return (
              <Popup
                wide="very"
                content={<Bulletin error={error} />}
                trigger={
                  <div style={{ cursor: 'pointer' }}>
                    <FontAwesomeIcon
                      color="#ff3b30"
                      icon={['far', 'exclamation-triangle']}
                      className={style.incomplete_notification_icon}
                    />
                    <span className={style.error_class}>ERROR</span>
                  </div>
                }
              />
            );
          }
          if (accruedCosts[id]) {
            return Intl.NumberFormat('en-CA', { style: 'currency', currency: 'CAD' }).format(
              accruedCosts[id],
            );
          }
          return <div className={style.table_cell}>-</div>;
        },
      },
      ...(isPayrollAdmin
        ? [
            {
              name: '',
              key: 'calculate',
              width: 3,
              render: (schedule: Schedule) => {
                if (schedule.status !== ScheduleStatus.Pending) {
                  return (
                    <Button
                      basic
                      color="grey"
                      size="mini"
                      onClick={() => this.fetchCosts(schedule)}
                    >
                      Get Costs
                    </Button>
                  );
                }
              },
            } as const,
          ]
        : []),
    ];

    return (
      <>
        {includeHeader && (
          <FiltersHeader
            filters={filters}
            handleStartChange={this.handleStartChange}
            toggleStartFocus={this.toggleStartFocus}
            startFocused={startFocused}
            handleEndChange={this.handleEndChange}
            toggleEndFocus={this.toggleEndFocus}
            endFocused={endFocused}
            toggleFiltersModal={this.toggleFiltersModal}
            hasNoButton
            departments={filters.departmentIds}
            onDepartmentChange={this.handleFilterChange}
            departmentDropdownName="departmentIds"
            onSearchChange={this.handleSearchChange}
            searchPlaceholder="Search by schedule name"
            scopedFilters
          />
        )}
        {scheduleHeader && (
          <h1 className={style.schedule_headers}>
            {scheduleHeader}
            <Popup
              content={popupContent}
              trigger={<FontAwesomeIcon icon={['far', 'info-circle']} color="#777" />}
            />
          </h1>
        )}
        <ObservingListLayout<Schedule>
          columns={columns}
          items={this.schedules}
          onRemove={
            status === ScheduleStatus.Pending
              ? (sched) => this.setState({ deleteModalOpen: true, scheduleToDelete: sched })
              : undefined
          }
        />
        {!this.schedules.length && <NoContentMessage />}
        <DeleteModal
          open={deleteModalOpen}
          onConfirm={() => {
            scheduleStore
              .delete(scheduleToDelete!)
              .then(() => this.setState({ deleteModalOpen: false, scheduleToDelete: undefined }));
          }}
          onCancel={() => this.setState({ deleteModalOpen: false, scheduleToDelete: undefined })}
          description="this schedule"
          label="Schedule"
        />
      </>
    );
  }
}

export default SchedulesList;
