import * as React from 'react';
import { Formik } from 'formik';
import { inject } from 'mobx-react';
import * as moment from 'moment-timezone';
import { FormButton, Grid, Icon, Modal } from 'semantic-ui-react';
import { IStaffEmployee, EmployeeStore, Position, UserStore } from 'sol-data';
import { getErrorMessage } from '@/lib/utils';
import { getEssentialsPermissions } from '@/lib/essentials-utils';
import InjectedComponent from '../InjectedComponent';
import Tabs from '../Tabs';
import style from './index.less';
import Bulletin from '../Bulletin';
import { employeeEditSchema, EmployeeEditValues, EmployeeContainerTabProps } from './utils';
import { EmployeeCertificatesContainer } from '../EmployeeCertificatesContainer';
import { EmployeeEmergencyContactsContainer } from '../EmployeeEmergencyContactsContainer';
import { EmployeeProfileContainer } from '../EmployeeProfileContainer';

interface Props {
  open: boolean;
  onClose: () => void;
  employee: IStaffEmployee;
  department: string;
  onSubmit: () => void;
  positions: Position[];
}

interface EmployeeTab {
  label: string;
  component: React.ElementType<EmployeeContainerTabProps>;
}

const tabs: EmployeeTab[] = [
  {
    label: 'PROFILE',
    component: EmployeeProfileContainer,
  },
  {
    label: 'EMERGENCY CONTACTS',
    component: EmployeeEmergencyContactsContainer,
  },
  {
    label: 'CERTIFICATES',
    component: EmployeeCertificatesContainer,
  },
];

interface State {
  selectedTab: EmployeeTab;
  isSaving: boolean;
  error?: unknown;
}

interface Injected {
  employeeStore: EmployeeStore;
  userStore: UserStore;
}

@inject('employeeStore')
@inject('userStore')
class EmployeeInformationModal extends InjectedComponent<Props, State, Injected> {
  state: State = {
    selectedTab: tabs[0],
    isSaving: false,
  };
  render() {
    const { open, onClose, employee, department } = this.props;
    const { selectedTab } = this.state;
    const permissions = getEssentialsPermissions(this.injected.userStore.current);
    return (
      <Modal
        className={style.modal}
        closeOnDimmerClick={false}
        open={open}
        onClose={onClose}
        size="large"
        closeIcon={<Icon name="close" size="tiny" color="black" />}
      >
        <Modal.Content className={style.modal_content}>
          <Grid>
            <section className={style.modal_header}>
              <p>Employee Information</p>
              <h3>
                {employee.user.lastName},{employee.user.firstName}
              </h3>
              <p>
                {department},{this.getPosition()}
              </p>
            </section>
            <Grid.Row className={style.main_container}>
              <div className={style.tab_area}>
                <Tabs
                  items={tabs.map(({ label }) => ({ item: label, path: '#' }))}
                  onTabChange={(newTab) => {
                    const tab = tabs.find((itm) => itm.label === newTab);

                    if (tab) {
                      this.setState({
                        selectedTab: tab,
                      });
                    }
                  }}
                  selected={this.state.selectedTab.label}
                />
              </div>
              <Formik
                validationSchema={employeeEditSchema}
                initialValues={employeeEditSchema.cast(
                  {
                    certificate: {
                      orientation: false,
                      jobDescription: false,
                      benefits: false,
                      newHirePaperwork: false,
                      criminalCheck: false,
                      licensePermit: false,
                      agreementBook: false,
                      identification: false,
                      foodSafety: false,
                      whmis: false,
                      firstAid: false,
                      fireSafetyEmergency: false,
                      fluShot: false,
                      covid: false,
                      notes: '',
                      ...(employee.certificates
                        ? {
                            ...employee.certificates,
                            notes: employee.certificates.notes ?? '',
                          }
                        : {}),
                    },
                    emergencyContacts: employee.emergencyContacts ?? {},
                    profile: {
                      ...employee,
                      statusChange: employee.statusChange ?? '',
                      phoneNumbers: employee.user.phoneNumbers || [],
                      fte: employee.fte ?? '',
                    },
                  },
                  { stripUnknown: true },
                )}
                onSubmit={(values) => {}}
                render={(formProps) => (
                  <div className={style.tab_inner_container}>
                    <div className={style.tab_content}>
                      <selectedTab.component
                        formProps={formProps}
                        employee={this.props.employee}
                        readOnly={!permissions.write}
                      />
                      {this.state.error && (
                        <Bulletin
                          errorMessage={getErrorMessage(this.state.error)}
                          handleDismiss={() =>
                            this.setState((prev) => ({ ...prev, error: undefined }))
                          }
                        />
                      )}
                    </div>
                    <div className={style.action_container}>
                      <FormButton onClick={this.props.onClose} className={style.btn_default}>
                        {permissions.write ? 'CANCEL' : 'CLOSE'}
                      </FormButton>
                      {permissions.write && (
                        <FormButton
                          onClick={() => this.handleSubmit(formProps.values)}
                          className={style.btn_primary}
                          loading={this.state.isSaving}
                          disabled={this.state.isSaving || !formProps.isValid}
                        >
                          SAVE
                        </FormButton>
                      )}
                    </div>
                  </div>
                )}
              />
            </Grid.Row>
          </Grid>
        </Modal.Content>
      </Modal>
    );
  }

  getPosition() {
    return (
      this.props.positions.find((pos) => this.props.employee.position.id === pos.id)?.label || 'N/A'
    );
  }

  handleSubmit = async (values: EmployeeEditValues) => {
    this.setState({ isSaving: true, error: undefined });
    try {
      await this.injected.employeeStore.update(this.props.employee.id, {
        ...values.profile,
        startDate: values.profile.startDate ? moment(values.profile.startDate) : null,
        lastDay: values.profile.lastDay ? moment(values.profile.lastDay) : null,
        probationEndAt: values.profile.probationEndAt
          ? moment(values.profile.probationEndAt)
          : null,
      });
      const isEmergencyContactValid = !!Object.values(values.emergencyContacts).find(
        (itm) => !!itm,
      );
      if (isEmergencyContactValid) {
        await this.injected.employeeStore.updateEmergencyContact(
          this.props.employee.id,
          values.emergencyContacts,
        );
      }
      await this.injected.employeeStore.updateCertificates(
        this.props.employee.id,
        values.certificate,
      );
    } catch (err) {
      this.setState({ isSaving: false, error: err });
      return;
    }
    this.setState({ isSaving: false, error: undefined });
    this.props.onSubmit();
  };
}

export default EmployeeInformationModal;
