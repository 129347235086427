import * as React from 'react';

export const defaultConfig = {
  collapsed: false,
  isAdmin: false,
  isPayrollAdmin: false,
  scope: {
    positions: null as number[] | null,
    departments: null as number[] | null,
  },
};

export default React.createContext(defaultConfig);
