import ChangePassword from '@/components/ChangePassword';
import TabLayout, { TabLayoutPane } from '@/components/TabLayout';
import * as React from 'react';
import { Link, useLocation } from 'react-router';
import { Menu } from 'semantic-ui-react';

const panes: TabLayoutPane[] = [
  {
    route: 'change-password',
    menuItem: (
      <Menu.Item as={Link} to="/user-profile/change-password" key="change-password">
        Change Password
      </Menu.Item>
    ),
    render: () => <ChangePassword />,
  },
];

const Users = () => {
  const { pathname } = useLocation();

  return <TabLayout path={pathname} panes={panes} activeBreadcrumb="User Profile" />;
};

export default Users;
