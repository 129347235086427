/* eslint-disable react/require-default-props */
import { Button, Form, Grid, Modal } from '@/semantic-ui/components';
import * as React from 'react';
import { PropsWithChildren } from 'react';
import { ButtonProps, FormProps } from 'semantic-ui-react';
import style from './index.less';

interface ModalBaseProps {
  header: string;
  color?: ButtonProps['color'];
  cancelWord?: string;
  actionWord?: string;
  headerClass?: string;
  noButtons?: boolean;
  onAccept?: ButtonProps['onClick'];
  onCancel?: ButtonProps['onClick'];
  handleSubmit?: FormProps['onSubmit'];
  sideButtons?: boolean;
}

const { Row } = Grid;

const ModalBase = ({
  header,
  children,
  onAccept,
  onCancel,
  cancelWord,
  actionWord,
  handleSubmit,
  color,
  headerClass,
  noButtons,
  sideButtons,
}: PropsWithChildren<ModalBaseProps>) => (
  <>
    <Modal.Header className={`${headerClass} modal_header`}>{header}</Modal.Header>
    <Form onSubmit={handleSubmit} className={style.modal_form}>
      {children}
      <Grid>
        {!noButtons && (
          <Row
            columns={sideButtons ? 4 : 2}
            style={sideButtons ? { justifyContent: 'flex-end' } : undefined}
          >
            <Button type="button" color="grey" onClick={onCancel} className={style.modal_button}>
              {cancelWord}
            </Button>
            <Button
              type="submit"
              onClick={onAccept}
              color={color}
              className={style.modal_button}
              style={sideButtons ? { float: 'right' } : undefined}
            >
              {actionWord}
            </Button>
          </Row>
        )}
      </Grid>
    </Form>
  </>
);

ModalBase.defaultProps = {
  color: 'blue',
  cancelWord: 'Cancel',
  actionWord: 'Save',
  headerClass: style.header_black,
  noButtons: false,
  sideButtons: false,
};

export default ModalBase;
