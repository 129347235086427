import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { UserStore } from '@/store';
import { Formik } from 'formik';
import { object, string } from 'yup';
import { Form, Input, Button } from 'semantic-ui-react';
import Bulletin from '@/components/Bulletin';
import { Pane } from '..';
import style from '../index.less';

interface Props {
  changePane: (pane: Pane) => void;
}

interface Injected {
  userStore: UserStore;
}

const ChangePasswordComponent: React.FC<Props & Injected> = ({ changePane, userStore }) => {
  const token = new URLSearchParams(window.location.search).get('t');

  return (
    <Formik
      initialValues={{
        password: '',
        confirmPassword: '',
      }}
      validationSchema={object().shape({
        password: string().required('Please enter your password'),
        confirmPassword: string().required('Please confirm your password'),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        // already an error message
        if (!token) return;

        if (values.password !== values.confirmPassword) {
          setSubmitting(false);
          return userStore.setError('Passwords do not match');
        }

        try {
          await userStore.resetPassword({ token, password: values.password });

          changePane(Pane.ResetSuccess);
        } finally {
          setSubmitting(false);
        }
      }}
      render={({
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => {
        const showPasswordError = !!touched.password && !!errors.password;
        const showConfirmPasswordError = !!touched.confirmPassword && !!errors.confirmPassword;
        const { isLoading, errorMessage, clearError } = userStore;

        return (
          <Form className={style.login_form} onSubmit={handleSubmit}>
            <Bulletin
              isLoading={isLoading}
              errorMessage={errorMessage}
              handleDismiss={clearError}
            />

            {!token && (
              <p className={style.error_message}>
                No token was found! Re-visit the link that was sent to you.
              </p>
            )}

            <Form.Field error={showPasswordError}>
              <Input
                type="password"
                name="password"
                placeholder="Password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
              />
              {showPasswordError && <p className={style.error_message}>{errors.password}</p>}
            </Form.Field>

            <Form.Field error={showConfirmPasswordError}>
              <Input
                type="password"
                name="confirmPassword"
                placeholder="Confirm Password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.confirmPassword}
              />
              {showConfirmPasswordError && (
                <p className={style.error_message}>{errors.confirmPassword}</p>
              )}
            </Form.Field>

            <Button loading={isSubmitting} primary width="100%" type="submit">
              Reset
            </Button>
          </Form>
        );
      }}
    />
  );
};

export const ChangePassword = inject<Injected>(({ userStore }) => ({ userStore }))(
  observer(ChangePasswordComponent),
);
