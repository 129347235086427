import * as React from 'react';
import { Dropdown, DropdownProps } from '@/semantic-ui/components';
import { DropdownProps as DatePickerProps } from '@/components/DatePicker';

export const monthOptions = [
  {
    text: 'Jan',
    value: 0,
    days: 31,
  },
  {
    text: 'Feb',
    value: 1,
    days: 29,
  },
  {
    text: 'March',
    value: 2,
    days: 31,
  },
  {
    text: 'April',
    value: 3,
    days: 30,
  },
  {
    text: 'May',
    value: 4,
    days: 31,
  },
  {
    text: 'June',
    value: 5,
    days: 30,
  },
  {
    text: 'July',
    value: 6,
    days: 31,
  },
  {
    text: 'August',
    value: 7,
    days: 31,
  },
  {
    text: 'September',
    value: 8,
    days: 30,
  },
  {
    text: 'October',
    value: 9,
    days: 31,
  },
  {
    text: 'November',
    value: 10,
    days: 30,
  },
  {
    text: 'December',
    value: 11,
    days: 31,
  },
];

interface Props extends DatePickerProps {
  onMonthChange: (month: { text: string; value: number; days: number }) => void;
  clearDate?: boolean;
  onBlur: (field: string, value: boolean) => void;
}

const MonthPicker: React.SFC<Props> = ({
  fluid,
  name,
  value,
  placeholder,
  onChange,
  onMonthChange,
  clearDate,
  onBlur,
}) => {
  const handleSelect: DropdownProps['onChange'] = (_, { name: propName, value }) => {
    if (clearDate) {
      onChange('date', undefined);
    }

    const month = monthOptions.find((opt) => opt.value === value)!;
    onChange(propName, value as number);
    onMonthChange(month);
  };

  return (
    <Dropdown
      selection
      name={name}
      placeholder={placeholder}
      fluid={fluid}
      options={monthOptions}
      onChange={handleSelect}
      value={value}
      onBlur={() => onBlur(name, true)}
    />
  );
};

MonthPicker.defaultProps = {
  value: undefined,
  fluid: false,
  placeholder: undefined,
  onChange: () => {},
  onMonthChange: () => {},
  clearDate: false,
};

export default MonthPicker;
