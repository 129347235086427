import * as React from 'react';
import { SingleDatePicker as SingleDatePickerLib, SingleDatePickerShape } from 'react-dates';

interface Props extends Omit<SingleDatePickerShape, 'onFocusChange' | 'focused'> {}

export const SingleDatePicker: React.FC<Props> = (props) => {
  const [isFocused, setFocused] = React.useState(false);
  return (
    <SingleDatePickerLib
      {...props}
      onFocusChange={({ focused }) => {
        setFocused(!!focused);
      }}
      focused={isFocused}
    />
  );
};
