import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { AreaScheduleArea } from 'sol-data';
import { AreaStore, ScheduleComponentStore } from '@/store';
import InjectedComponent from '@/components/InjectedComponent';
import { rowCellRatio } from '../utils';
import style from '../index.less';

interface Props {
  areaSchedule: AreaScheduleArea;
}

interface Injected {
  areaStore: AreaStore;
  scheduleComponentStore: ScheduleComponentStore;
}

@inject('areaStore', 'scheduleComponentStore')
@observer
export default class AreaGroupTitle extends InjectedComponent<Props, {}, Injected> {
  render() {
    const {
      areaSchedule: { area, timeRanges, timeRangeKeys },
      areaStore,
      scheduleComponentStore: {
        sidebarWidth,
        cellDimens: { height: heightDimen },
      },
    } = this.injected;

    // labelWidth is equal to width in area_label class.
    const labelWidth = '50px';

    const labelRows = timeRangeKeys.map((key) => {
      const {
        range: { start, end },
      } = timeRanges[key];

      return (
        <div
          key={key}
          className={style.area_label_row}
          style={{
            width: `calc(${sidebarWidth} - ${labelWidth})`,
            height: `${heightDimen * rowCellRatio}px`,
          }}
        >
          <div className={style.ranges_label}>
            <span>{start.format('HH:mm')}</span>
            <span>-</span>
            <span>{end.format('HH:mm')}</span>
          </div>
        </div>
      );
    });

    return (
      <div className={style.area_container}>
        <div className={style.area_label}>
          <span className={style.area_label_text}>
            {areaStore.has(area) ? areaStore.get(area)!.label : 'Unknown'}
          </span>
        </div>
        <div className={style.area_legend}>{labelRows}</div>
      </div>
    );
  }
}
