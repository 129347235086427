import Breadcrumbs from '@/components/Breadcrumbs';
import Bulletin from '@/components/Bulletin';
import ModalBase from '@/components/ModalBase';
import { Schedule } from '@/components/Schedule';
import { Button, Modal, Segment } from '@/semantic-ui/components';
import { useStores } from '@/store';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { ScheduleInfoHeader } from '../ScheduleInfoHeader';
import style from './index.less';
import { useAsyncEffect } from '@/hooks';

const config = {
  shift: {
    canMove: false,
    canCopy: true,
    canUnassign: false,
  },
  group: {
    canAssign: false,
    canUnassignAllShifts: false,
  },
};

export const UnpublishedSchedule = observer(() => {
  const navigate = useNavigate();
  const params = useParams();
  const scheduleId = params.scheduleId!;
  const [confirmOpen, setConfirmOpen] = useState(false);
  const { scheduleStore } = useStores();

  useAsyncEffect(async () => {
    await scheduleStore.fetch(scheduleId);

    scheduleStore.setCurrent(scheduleId);
  }, [scheduleId, scheduleStore]);

  useEffect(() => {
    return () => {
      scheduleStore.clear();
      scheduleStore.clearCurrent();
    };
  }, []);

  const publishSchedule = async () => {
    setConfirmOpen(false);
    await scheduleStore.publishSchedule(scheduleId);
    navigate('/scheduling/all');
  };

  const { current: schedule, isLoading, errorMessage, clearError } = scheduleStore;

  return (
    <Segment basic padded="very">
      <Bulletin isLoading={isLoading} errorMessage={errorMessage} handleDismiss={clearError} />
      <Breadcrumbs
        activeBreadcrumb="Unpublished Schedule"
        link="/scheduling"
        linkTitle="Schedules"
      />
      {schedule && (
        <>
          <ScheduleInfoHeader />
          <Schedule config={config} />
        </>
      )}
      <div className={style.schedule_bottom_bar}>
        <Button color="blue" type="button" onClick={() => setConfirmOpen(true)}>
          Publish
        </Button>
      </div>
      <Modal open={confirmOpen} size="mini" onClose={() => setConfirmOpen(false)}>
        <ModalBase
          header="Publish Schedule"
          actionWord="Publish"
          onAccept={publishSchedule}
          onCancel={() => setConfirmOpen(false)}
        >
          <p className={style.u_margin_top_tiny}>
            Are you sure you&apos;d like to publish this schedule?
          </p>
        </ModalBase>
      </Modal>
    </Segment>
  );
});
