import { Button, Grid, Modal } from '@/semantic-ui/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { useNavigate } from 'react-router';
import style from './index.less';

interface ICreateNoticeModalProps {
  readonly open: boolean;
  readonly handleClose: () => void;
}

const CreateNoticeModal = ({ open, handleClose }: ICreateNoticeModalProps) => {
  const navigate = useNavigate();

  const handleNavigation = (route: string) => {
    navigate(route);
    handleClose();
  };

  return (
    <Modal open={open} size="tiny" className={style.modal}>
      <Grid
        centered
        style={{
          flexDirection: 'column',
        }}
      >
        <Grid centered>
          <FontAwesomeIcon icon={['fas', 'check-circle']} size="6x" color="#16AE82" />
        </Grid>
        <Grid centered className={style.text_container}>
          <p className={style.p}>The notification has been sent out successfully!</p>
        </Grid>
        <Grid centered className={style.btn_container}>
          <Button
            type="button"
            color="blue"
            fluid
            className={style.btn}
            onClick={() => handleNavigation('/notices/all')}
          >
            Return to Notice Page
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default CreateNoticeModal;
