import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { AreaStore } from '@/store';
import InjectedComponent from '@/components/InjectedComponent';
import FilterDropdown from '@/components/FilterDropdown';
import AppContext from '@/AppContext';

type Props = {
  name: string;
  placeholder?: string;
  value?: number;
  onChange: (name: string, value: number) => void;
  search?: boolean;
  clearable?: boolean;
  limited?: boolean;
};

interface Injected {
  areaStore: AreaStore;
}

@inject('areaStore')
@observer
class AreaFilterDropdown extends InjectedComponent<Props, {}, Injected> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  componentDidMount() {
    const { areaStore } = this.injected;

    if (areaStore.isEmpty) areaStore.fetchAll();
  }

  render() {
    const { onChange, placeholder, name, value, clearable, limited, areaStore } = this.injected;

    const {
      scope: { departments: limitedDepartments },
    } = this.context;

    let options = areaStore.dropdownOptionsShort;

    if (limited && limitedDepartments) {
      // eslint-disable-next-line prettier/prettier
      options = options.filter((v) =>
        limitedDepartments.includes(areaStore.get(v.value)!.departmentId),
      );
    }

    return (
      <FilterDropdown
        name={name || 'areaId'}
        placeholder={placeholder || 'Select an area'}
        value={value}
        options={options}
        changeDropdown={onChange}
        clearable={clearable}
      />
    );
  }
}

export default AreaFilterDropdown;
