import * as React from 'react';
import { Modal, Header, Grid } from '@/semantic-ui/components';
import ModalBase from '@/components/ModalBase';
import style from './index.less';

type BaseProps = {
  onConfirm?: () => void;
  onCancel?: () => void;
  description?: string;
  open: boolean;
  header?: string;
  actionWord?: string;
};

type Props = BaseProps &
  (
    | {
        dialog: string;
      }
    | {
        label: string;
      }
  );

const DeleteModal: React.FC<Props> = (props) => {
  const { onConfirm, onCancel, description, open, header, actionWord } = props;

  const dialog = 'dialog' in props ? props.dialog : undefined;
  const label = 'label' in props ? props.label : undefined;

  return (
    <Modal open={open} onClose={onCancel} size="mini">
      <ModalBase
        header={header || `Delete ${label}`}
        onAccept={onConfirm}
        onCancel={onCancel}
        actionWord={actionWord || 'Delete'}
        color="red"
      >
        {dialog ? (
          <>
            {description && <h5>{description}</h5>}

            <p>{dialog}</p>
          </>
        ) : (
          <p>Are you sure you want to delete{description}?</p>
        )}
      </ModalBase>
    </Modal>
  );
};

DeleteModal.defaultProps = {
  label: undefined,
  onConfirm: () => {},
  onCancel: () => {},
  dialog: undefined,
};

export default DeleteModal;
