import * as React from 'react';
import { Segment, Header, Image } from '@/semantic-ui/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import mpbg from '@/images/mp_bg.png';
import style from './index.less';

const ScreenSizeMessage = () => (
  <Segment padded="very" className={style.screen_size_message}>
    <>
      <Header as="h2">
        <FontAwesomeIcon icon={['far', 'desktop']} size="3x" />
        <Header.Content>
          This app is best viewed on a device with a larger screen, check it out on your laptop or
          tablet.
        </Header.Content>
      </Header>
      <Image src={mpbg} />
    </>
  </Segment>
);

export default ScreenSizeMessage;
