import * as React from 'react';
import { Grid } from '@/semantic-ui/components';
import { FileRejection, useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import style from './index.less';
import { MAX_FILE_SIZE } from '../CreateEducationalDocument';

export interface DragDropProps {
  text: string;
  dragText: string;
  multiple?: boolean;
  accept?: string[];
  maxFiles?: number;
  maxSize?: number;
  onFileReject?: (files: FileRejection[]) => void;
  onFileDrop: (files: File[]) => void;
  onBlur?: React.FocusEventHandler;
  inputProps?: React.HTMLProps<HTMLInputElement>;
}

const DragDrop = ({
  text,
  dragText,
  onFileDrop,
  accept = ['.pdf'],
  multiple = false,
  maxFiles = 1,
  maxSize = MAX_FILE_SIZE,
  inputProps,
}: DragDropProps) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onFileDrop,
    multiple,
    accept,
    maxFiles,
    maxSize,
  });
  const rootProps = getRootProps();

  return (
    <Grid {...rootProps} centered className={style.root}>
      <input
        {...inputProps}
        {...getInputProps()}
        onChange={async ({ currentTarget: { files } }) => {
          if (files) onFileDrop(Array.from(files));
        }}
      />
      <Grid centered className={style.cloud}>
        <FontAwesomeIcon icon={['fal', 'cloud-upload']} size="3x" color="#979797" />
      </Grid>
      <div className={style.text}>
        {isDragActive ? (
          <p>{dragText}</p>
        ) : (
          <>
            <>
              {text}, or
              <span style={{ color: '#D7AB73', cursor: 'pointer' }}> Browse</span>
            </>
            <p>We support pdf, doc and jpg.</p>
            <p>Max file size is 15 MB</p>
          </>
        )}
      </div>
    </Grid>
  );
};

export default DragDrop;
