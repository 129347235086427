import * as React from 'react';
import { orderBy } from 'lodash';
import { quickbooksApi, authApi } from '@/api';
import { moment, Moment } from 'sol-data';
import ListLayout, { ListColumn } from '../ListLayout';
import NoContentMessage from '../Schedule/NoContentMessage';
import style from './index.less';

type BaseLog = {
  id: number;
  insertDatetime: Moment;
  endpointName: string;
};

type Log = BaseLog & { logMessage: string };
type ErrorLog = BaseLog & { errorMessage: string };

type Logs = {
  logs: Log[];
  errors: ErrorLog[];
};

type ItemLog = (Log | ErrorLog) & { message: string };

const columns: ListColumn<ItemLog>[] = [
  {
    name: 'date',
    key: 'date',
    width: 4,
    render: (item) => (
      <span className={'errorMessage' in item && style.error_log}>
        {item.insertDatetime.format('YYYY-MM-DD HH:mm:ss')}
      </span>
    ),
  },
  {
    name: 'message',
    key: 'message',
    width: 12,
    render: (item) => (
      <span className={'errorMessage' in item && style.error_log}>{item.message}</span>
    ),
  },
];

const QbLogs = () => {
  const [allLogs, setLogs] = React.useState<Logs>({
    logs: [],
    errors: [],
  });

  React.useEffect(() => {
    authApi
      .post('/qb-import-key')
      .withBody({ keyName: 'Log Viewer' })
      .json<{ apiKey: string }>()
      .then(async ({ apiKey }) => {
        const logs = await quickbooksApi
          .get('/log')
          .withBearerToken({ token: apiKey })
          .json<Logs>();
        setLogs(logs);
      });
  }, []);

  const { logs, errors } = allLogs;

  const items: ItemLog[] = orderBy(
    (logs as (Log | ErrorLog)[]).concat(errors),
    ['insertDatetime'],
    ['desc'],
  ).map((log) => ({
    ...log,
    message: 'errorMessage' in log ? log.errorMessage : log.logMessage,
    insertDatetime: moment(log.insertDatetime),
  }));

  return (
    <>
      <ListLayout items={items} columns={columns} />
      {!items.length && <NoContentMessage />}
    </>
  );
};

export default QbLogs;
