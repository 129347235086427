import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Modal, Header, Form, Dropdown } from '@/semantic-ui/components';
import { uniqBy } from 'lodash';
import { moment, ID } from 'sol-data';
import CategoryCheckmark from '@/components/CategoryCheckmark';
import { EntityList } from '@/components/EntityList';
import { PayrollStore } from '@/store';
import InjectedComponent from '@/components/InjectedComponent';
import { ListColumn } from '@/components/ListLayout';
import style from '@/less/main.less';
import HolidayModal from './HolidayModal';
import ButtonBar from '../ButtonBar';

interface State {
  modalOpen: boolean;
  year?: number;
}

interface Injected {
  payrollStore: PayrollStore;
}

@inject('payrollStore')
@observer
class PayrollHolidays extends InjectedComponent<{}, State, Injected> {
  state: State = {
    modalOpen: false,
    year: moment().year(),
  };

  componentWillUnmount() {
    const { payrollStore } = this.injected;
    payrollStore.holidays.clearFilter();
  }

  toggleForm = () => this.setState((state) => ({ modalOpen: !state.modalOpen }));

  closeForm = () => {
    const { payrollStore } = this.injected;
    this.setState({ modalOpen: false });
    payrollStore.clearError();
    payrollStore.holidays.clearCurrent();
  };

  setCurrentHolidayId = (id: ID) => {
    const { payrollStore } = this.injected;
    payrollStore.holidays.setCurrent(id);

    this.setState({ modalOpen: true });
  };

  handleRemove = async (id: ID) => {
    const { payrollStore } = this.injected;
    await payrollStore.deleteHoliday(id);
  };

  render() {
    const { payrollStore } = this.injected;

    const { categories: payrollCategories, isLoading } = payrollStore;

    const { filtered: filteredHolidays, errorMessage } = payrollStore.holidays;

    const { modalOpen, year } = this.state;

    const columns: ListColumn[] = [
      {
        name: 'ID',
        key: 'id',
        width: 1,
        render: ({ id }) => <span>{id.toString().padStart(4, '0')}</span>,
      },
      {
        name: 'Name',
        key: 'description',
        width: 3,
        render: ({ description }) => (
          <Header as="h4" color="blue">
            {description}
          </Header>
        ),
      },
      {
        name: 'Month',
        key: 'month',
        width: 3,
        render: ({ date }) => date.format('MMMM'),
      },
      {
        name: 'Date',
        key: 'date',
        render: ({ date }) => date.date(),
      },
      {
        name: 'Year',
        key: 'year',
        render: ({ date }) => date.year(),
      },
    ];

    const checkCols = payrollCategories.values
      .filter(({ isHoliday }) => isHoliday)
      .map(({ label, id }): ListColumn => {
        return {
          name: label,
          key: id,
          textAlign: 'center',
          render: ({ categories }) => categories.includes(id) && <CategoryCheckmark />,
        };
      });

    const combinedColumns = columns.concat(checkCols);

    const items = filteredHolidays
      .filter(({ date }) => date.year() === year)
      .slice()
      .sort((a, b) => (a.date.isBefore(b.date) ? -1 : 1));

    const options = uniqBy(filteredHolidays, ({ date }) => date.year()).map(({ date }) => ({
      text: date.year(),
      value: date.year(),
    }));

    return (
      <>
        <EntityList
          title="Holidays"
          searchLabel="Search in Holidays"
          titleProp="description"
          columns={combinedColumns}
          items={items}
          errorMessage={errorMessage}
          isLoading={isLoading}
          onDismiss={() => payrollStore.clearError()}
          onSearchChange={(filter) => payrollStore.holidays.setFilter(filter)}
          onRemove={this.handleRemove}
          onRefresh={() => payrollStore.fetchAll()}
          searchHeaderContent={
            <Form>
              <Form.Field>
                <Dropdown
                  selection
                  name="year"
                  options={options}
                  value={year}
                  onChange={(e, { value }) => this.setState({ year: value as number })}
                />
              </Form.Field>
            </Form>
          }
          noNewButton
        />
        <ButtonBar>
          <Button
            color="blue"
            type="button"
            onClick={this.toggleForm}
            className={style.confirm_button}
          >
            Add Holiday
          </Button>
        </ButtonBar>
        <Modal open={modalOpen} size="mini" onClose={this.closeForm} closeOnDimmerClick>
          <HolidayModal onCancel={this.closeForm} />
        </Modal>
      </>
    );
  }
}

export default PayrollHolidays;
