import * as React from 'react';
import { Dropdown } from '@/semantic-ui/components';

export interface DropdownProps {
  name: string;
  value?: number;
  fluid?: boolean;
  placeholder?: string;
  onChange: (field: string, value?: number) => void;
}

interface Props extends DropdownProps {
  numberOfDays: number;
  onBlur: (field: string, value: boolean) => void;
}

const DatePicker: React.SFC<Props> = ({
  fluid,
  name,
  value,
  placeholder,
  numberOfDays,
  onChange,
  onBlur,
}) => {
  const dayCount = numberOfDays;

  if (typeof dayCount !== 'number') {
    throw new Error('Not a Number');
  }

  const options = Array.from(Array(dayCount), (_, idx) => ({ text: idx + 1, value: idx }));

  return (
    <Dropdown
      selection
      fluid={fluid}
      name={name}
      value={value}
      placeholder={placeholder}
      options={options}
      onChange={(_, { name: propName, value: propValue }) =>
        onChange(propName, propValue as number)
      }
      onBlur={() => onBlur(name, true)}
    />
  );
};

DatePicker.defaultProps = {
  value: undefined,
  fluid: false,
  placeholder: undefined,
  onChange: () => {},
};

export default DatePicker;
